export function getFilterSizes(products) {
  const sizes = products.map(product => product.standardSize?.filter(s => s).map(size => {
    let cup = size.replace(/[0-9]/g, "");
    let band = size.replace(/[^0-9]/g, "");
    if(cup > 'D') {
      cup = 'E+';
    }
    if(band > 90) {
      band = '95+';
    }
    return band + cup;
  }));

  const sizeSet = [...new Set(sizes.flat())]; // 사이즈 & 컵 구분 적용한 제품 사이즈

  return sizeSet.sort();
}
