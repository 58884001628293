import React from "react";
import styled from "styled-components";
import { OrderListCard } from "../Card/OrderListCard";
import { formatDate } from "../../modules/util";

import { GA4EventTracker } from "../../hook/GA4EventTracker";

const STATUS = {
  placed: "결제 대기",
  paid: "결제 완료",
  cancelled: "고객 취소",
  shipped: "출고 완료",
  refunded: "환불 완료",
  "partially-refunded": "부분 환불 완료",
  "under-refunded": "부분 환불 처리 중",
};

export const OrderList = ({ data, history }) => {
  const createdAt = new Date(data.createdAt.raw);
  const formatCreatedAt = formatDate(createdAt);

  const handleOrderDetail = () => {
    GA4EventTracker("주문상세_보기");
    history.push(`/order/detail/${data._id}`);
  };
  return (
    <Card>
      <OrderInfo>
        <OrderDate>{formatCreatedAt}</OrderDate>
        <OrderStatus>
          {data?.fulfillments[0]?.status === "shipped"
            ? STATUS[data?.status] +
              ", " +
              STATUS[data?.fulfillments[0]?.status]
            : data?.status === "partially-refunded" ||
              data?.status === "under-refunded" ||
              data?.status === "paid"
            ? STATUS[data?.status] + ", 배송 대기"
            : STATUS[data?.status]}
        </OrderStatus>
      </OrderInfo>
      <List>
        {data.items.map((order, index) => (
          <OrderListCard data={order} key={index} history={history} />
        ))}
      </List>
      <OrderDetailButton type="button" onClick={handleOrderDetail}>
        주문 상세
      </OrderDetailButton>
    </Card>
  );
};

const Card = styled.section`
  padding: 20px 16px;
  border-bottom: #f4f4f4 8px solid;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  div:last-child {
    border-bottom: none;
  }
`;

const OrderDate = styled.span`
  font-size: 14px;
  color: var(--color-tertiary-black);
`;

const OrderInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OrderStatus = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: var(--color-poola-blue);
`;

const OrderDetailButton = styled.button`
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-poola-blue);
  background-color: var(--color-white);
  border: 1px solid var(--color-poola-blue);
  border-radius: 4px;
`;
