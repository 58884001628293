const CommonCollection = ["new", "popular"];

const BrandCollectionData = [
  {
    collection: "bodypositive",
    title: "바디 포지티브<br/>브랜드",
    body: "다양한 몸, 다양한 속옷",
  },
  {
    collection: "designer",
    title: "자체제작<br/>디자이너 속옷",
    body: "주목할만한 젊은 디자이너 브랜드",
  },
];

const CategoryByType = [
  {
    title: "윗볼륨 없는",
    en: "noUpVolume",
    products: [
      "comfortlab_30",
      "marunfive_46",
      "poola_1",
      "comfortlab_1",
      "bbongbramarket_19",
      "bbongbramarket_50",
      "slim9_92",
      "winkpink_1",
      "slim9_17",
      "bbongbramarket_16",
    ],
  },

  {
    title: "벌어진가슴",
    en: "gap",
    products: [
      "comfortlab_31",
      "poola_7",
      "comfortlab_25",
      "inull_2",
      "marunfive_11",
      "winkpink_3",
      "ravic_5",
      "poola_2",
      "ravic_18",
      "urinnerf_54",
      "bbongbramarket_48",
    ],
  },
  {
    title: "새가슴",
    en: "bird",
    products: [
      "poola_1",
      "ravic_8",
      "comfortlab_1",
      "comfortlab_38",
      "marunfive_46",
      "ravic_7",
      "inull_1",
      "bbongbramarket_16",
      "bbongbramarket_23",
      "urinnerf_2",
    ],
  },
  {
    title: "처진가슴",
    en: "sag",
    products: [
      "comfortlab_25",
      "comfortlab_31",
      "urinnerf_55",
      "slim9_999999999",
      "urinnerf_27",
      "urinnerf_2",
      "urinnerf_29",
      "urinnerf_54",
      "urinnerf_36",
      "comfortlab_34",
    ],
  },
  {
    title: "D컵 이상",
    en: "big",
    products: [
      "comfortlab_43",
      "comfortlab_29",
      "comfortlab_17",
      "poola_2",
      "urinnerf_2",
      "urinnerf_29",
      "urinnerf_55",
      "urinnerf_37",
      "urinnerf_54",
      "urinnerf_1",
    ],
  },
  {
    title: "A,AA컵",
    en: "small",
    products: [
      "ravic_7",
      "ravic_20",
      "marunfive_22",
      "bbongbramarket_26",
      "bbongbramarket_34",
      "bbongbramarket_21",
      "winkpink_1",
      "poola_1",
      "comfortlab_1",
      "comfortlab_28",
    ],
  },
];

const CategoryByTaste = [
  {
    title: "홈웨어",
    en: "homeware",
    products: [
      "patori_6",
      "patori_1",
      "patori_4",
      "anpa_15",
      "anpa_19",
      "anpa_10",
      "anpa_20",
      "anpa_16",
      "anpa_17",
      "anpa_21",
    ],
  },

  {
    title: "팬티",
    en: "panties",
    products: [
      "slim9_9999997",
      "slim9_18",
      "slim9_993",
      "slim9_9994",
      "urinnerf_49",
      "urinnerf_47",
      "urinnerf_48",
      "anpa_3",
      "marunfive_8",
      "inull_4",
    ],
  },
  {
    title: "고정형패드",
    en: "pad",
    products: [
      "marunfive_46",
      "inull_2",
      "comfortlab_30",
      "urinnerf_2",
      "bbongbramarket_15",
      "bbongbramarket_18",
      "comfortlab_34",
      "comfortlab_38",
      "comfortlab_6",
      "comfortlab_2",
    ],
  },
  {
    title: "심리스",
    en: "seamless",
    products: [
      "slim9_999999999",
      "slim9_17",
      "ravic_20",
      "marunfive_11",
      "slim9_1",
      "slim9_92",
      "inull_2",
      "poola_2",
      "poola_6",
      "bbongbramarket_21",
    ],
  },
  {
    title: "브라렛",
    en: "bralette",
    products: [
      "poola_1",
      "ravic_5",
      "bbongbramarket_15",
      "ravic_23",
      "comfortlab_30",
      "comfortlab_29",
      "comfortlab_25",
      "comfortlab_42",
      "comfortlab_1",
      "comfortlab_2",
    ],
  },
  {
    title: "볼륨업",
    en: "volumeUp",
    products: [
      "comfortlab_33",
      "comfortlab_42",
      "bbongbramarket_25",
      "bbongbramarket_50",
      "bbongbramarket_34",
      "bbongbramarket_23",
      "bbongbramarket_41",
      "bbongbramarket_11",
      "bbongbramarket_13",
      "bbongbramarket_16",
    ],
  },
];

const CategoryByAwards = [
  {
    title: "익스트림 서포트",
    en: "extreme",
    products: [
      "shockabsorber_4",
      "shockabsorber_3",
      "shockabsorber_2",
      "shockabsorber_8",
      "shockabsorber_7",
      "shockabsorber_18",
      "shockabsorber_5",
      "shockabsorber_6",
      "shockabsorber_17",
      "shockabsorber_19",
      "shockabsorber_20",
      "shockabsorber_21",
      "comfortlab_171",
    ],
  },
  {
    title: "하이 서포트",
    en: "high",
    products: ["shockabsorber_13", "comfortlab_166", "comfortlab_140"],
  },
  {
    title: "미디움 서포트",
    en: "medium",
    products: ["comfortlab_139", "comfortlab_153", "comfortlab_172"],
  },
  {
    title: "라이트 서포트",
    en: "light",
    products: [
      "comfortlab_165",
      "comfortlab_167",
      "comfortlab_168",
      "comfortlab_169",
      "comfortlab_170",
    ],
  },
  {
    title: "윗볼륨 없는",
    en: "윗볼륨없는",
    products: [
      "shockabsorber_4",
      "shockabsorber_13",
      "comfortlab_140",
      "comfortlab_153",
    ],
  },
  {
    title: "새가슴",
    en: "새가슴",
    products: [
      "shockabsorber_13",
      "comfortlab_140",
      "comfortlab_166",
      "comfortlab_139",
    ],
  },
  {
    title: "D컵 이상",
    en: "D컵이상",
    products: [
      "shockabsorber_3",
      "shockabsorber_2",
      "shockabsorber_21",
      "shockabsorber_5",
      "shockabsorber_6",
      "shockabsorber_17",
      "shockabsorber_19",
      "shockabsorber_20",
      "comfortlab_171",
      "comfortlab_172",
    ],
  },
  {
    title: "AA, A컵",
    en: "aaa컵",
    products: [
      "shockabsorber_4",
      "shockabsorber_8",
      "shockabsorber_7",
      "shockabsorber_18",
      "shockabsorber_13",
      "comfortlab_170",
      "comfortlab_167",
      "comfortlab_139",
    ],
  },
];

const CollectionTitle = [
  {
    type: "noUpVolume",
    title: "윗볼륨 없는 가슴 추천 브라",
    subTitle: "✅ 비슷한 가슴 분석 유형은?<br />아랫볼륨(B)",
  },
  {
    type: "gap",
    title: "벌어진 가슴 추천 브라",
    subTitle:
      "✅ 비슷한 가슴 분석 유형은?<br />아랫볼륨(B) 혹은 보통볼륨(E)이면서 좁은면적(P)",
  },
  {
    type: "bird",
    title: "새가슴 추천 브라",
    subTitle:
      "✅ 가슴 중앙 뼈가 돌출되어 옆에서 봤을 때<br />가슴 중앙이 동그랗게 나와있으면 새가슴이에요.",
  },
  {
    type: "sag",
    title: "처진 가슴 추천 브라",
    subTitle: "✅ 비슷한 가슴 분석 유형은?<br />C컵 이상의 좁은면적(P)",
  },
  {
    type: "big",
    title: "D컵 이상 추천 브라",
    subTitle: "✅ D컵 이상 브라만 보고 싶다면",
  },
  {
    type: "small",
    title: "AA, A컵 추천 브라",
    subTitle: "✅ A컵 이하 브라만 보고 싶다면",
  },
  {
    type: "homeware",
    title: "홈웨어",
    subTitle: "집에서도 편안함과 스타일을 모두 챙겨보세요",
  },
  {
    type: "panties",
    title: "팬티",
    subTitle: "심리스부터 사각까지 취향별로 골라보세요",
  },
  {
    type: "pad",
    title: "고정형 패드",
    subTitle: "세탁해도 패드가 빠지거나 돌아가지 않아요",
  },
  {
    type: "seamless",
    title: "심리스",
    subTitle: "봉제선을 없애 압박 없이 가벼워요",
  },
  {
    type: "bralette",
    title: "브라렛",
    subTitle: "와이어 없이 안 입은 듯한 착용감을 경험해보세요",
  },
  {
    type: "volumeUp",
    title: "볼륨업",
    subTitle: "볼륨이 필요할 때를 위해 골라왔어요",
  },
];

// 프로모션 페이지에 들어가는 카테고리 데이터
const CATEGORY_PROMOTION = [
  {
    title: "베스트",
    en: "best",
    products: [
      {
        type: "TOP5",
        products: [
          "slim9_9922",
          "marunfive_71",
          "ravic_4",
          "comfortlab_164",
          "slim9_99999999910000000000",
        ],
      },
    ],
  },
  {
    title: "카테고리",
    en: "category",
    products: [
      {
        type: "나시탑",
        products: [
          "slim9_921",
          "slim9_99999999910000000000",
          "slim9_9910000000002",
          "marunfive_69",
          "marunfive_70",
          "marunfive_71",
          "marunfive_72",
          "comfortlab_142",
          "comfortlab_162",
          "comfortlab_163",
          "comfortlab_164",
          "ravic_4",
          "ravic_14",
          "ravic_25",
          "ravic_27",
          "bbongbramarket_58",
        ],
      },
      {
        type: "튜브탑",
        products: [
          "slim9_9922",
          "marunfive_69",
          "ravic_34",
          "romisecret_200",
          "romisecret_184",
          "bbongbramarket_54",
          "bbongbramarket_61",
        ],
      },
      {
        type: "니플패치",
        products: [
          "marunfive_60",
          "marunfive_63",
          "marunfive_58",
          "marunfive_73",
          "slim9_23",
        ],
      },
    ],
  },
  {
    title: "브랜드",
    en: "brand",
    products: [
      {
        type: "마른파이브",
        products: [
          "marunfive_69",
          "marunfive_70",
          "marunfive_71",
          "marunfive_72",
          "marunfive_60",
          "marunfive_63",
          "marunfive_58",
          "marunfive_73",
          "marunfive_16",
        ],
      },
      {
        type: "슬림9",
        products: [
          "slim9_921",
          "slim9_99999999910000000000",
          "slim9_9910000000002",
          "slim9_9922",
          "slim9_23",
        ],
      },
      {
        type: "컴포트랩",
        products: [
          "comfortlab_142",
          "comfortlab_162",
          "comfortlab_163",
          "comfortlab_164",
        ],
      },
      {
        type: "라비크",
        products: ["ravic_4", "ravic_14", "ravic_25", "ravic_27"],
      },
      {
        type: "로미시크릿",
        products: [
          "romisecret_200",
          "romisecret_184",
          "romisecret_26",
          "romisecret_46",
          "romisecret_45",
        ],
      },
      {
        type: "뽕브라마켓",
        products: [
          "bbongbramarket_58",
          "bbongbramarket_54",
          "bbongbramarket_61",
        ],
      },
    ],
  },
];

const CATEGORY_PROMOTION_SPORTSBRA = [
  {
    title: "베스트 5",
    en: "best",
    products: [
      {
        type: "베스트 5",
        products: [
          "comfortlab_166",
          "comfortlab_140",
          "comfortlab_171",
          "shockabsorber_3",
          "shockabsorber_6",
        ],
      },
    ],
  },
  {
    title: "운동 강도",
    en: "level",
    products: [
      {
        type: "익스트림 스포츠",
        products: [
          "shockabsorber_4",
          "shockabsorber_3",
          "shockabsorber_2",
          "shockabsorber_8",
          "shockabsorber_7",
          "shockabsorber_18",
          "shockabsorber_5",
          "shockabsorber_6",
          "shockabsorber_17",
          "shockabsorber_19",
          "shockabsorber_20",
          "shockabsorber_22",
          "comfortlab_171",
        ],
      },
      {
        type: "하이 서포트",
        products: ["comfortlab_166", "comfortlab_140", "comfortlab_139"],
      },
      {
        type: "미디움 서포트",
        products: ["comfortlab_153", "comfortlab_172", "comfortlab_165"],
      },
      {
        type: "라이트 서포트",
        products: [
          "comfortlab_167",
          "comfortlab_168",
          "comfortlab_169",
          "comfortlab_170",
        ],
      },
    ],
  },
  {
    title: "체형",
    en: "body",
    products: [
      {
        type: "D컵 이상",
        products: [
          "shockabsorber_3",
          "shockabsorber_2",
          "shockabsorber_5",
          "shockabsorber_6",
          "shockabsorber_17",
          "shockabsorber_19",
          "shockabsorber_20",
          "shockabsorber_22",
          "comfortlab_171",
          "comfortlab_172",
        ],
      },
      {
        type: "윗볼륨 없는",
        products: ["shockabsorber_4", "comfortlab_140", "comfortlab_153"],
      },
      {
        type: "AA, A컵",
        products: [
          "shockabsorber_4",
          "shockabsorber_8",
          "shockabsorber_7",
          "shockabsorber_18",
          "comfortlab_170",
          "comfortlab_167",
        ],
      },
      {
        type: "새가슴",
        products: ["comfortlab_140", "comfortlab_166", "comfortlab_139"],
      },
    ],
  },
  {
    title: "브랜드",
    en: "brand",
    products: [
      {
        type: "쇼크업소버",
        products: [
          "shockabsorber_4",
          "shockabsorber_8",
          "shockabsorber_7",
          "shockabsorber_5",
          "shockabsorber_6",
          "shockabsorber_3",
          "shockabsorber_2",
          "shockabsorber_17",
          "shockabsorber_22",
          "shockabsorber_19",
          "shockabsorber_18",
          "shockabsorber_20",
        ],
      },
      {
        type: "컴포트랩",
        products: [
          "comfortlab_139",
          "comfortlab_140",
          "comfortlab_153",
          "comfortlab_165",
          "comfortlab_166",
          "comfortlab_167",
          "comfortlab_168",
          "comfortlab_169",
          "comfortlab_170",
          "comfortlab_171",
          "comfortlab_172",
        ],
      },
    ],
  },
];

const PROMOTION_SLUG_SPORTSBRA = [
  "comfortlab_166",
  "comfortlab_140",
  "comfortlab_171",
  "shockabsorber_3",
  "shockabsorber_6",
  "shockabsorber_4",
  "shockabsorber_3",
  "shockabsorber_2",
  "shockabsorber_8",
  "shockabsorber_7",
  "shockabsorber_18",
  "shockabsorber_5",
  "shockabsorber_6",
  "shockabsorber_17",
  "shockabsorber_19",
  "shockabsorber_20",
  "shockabsorber_22",
  "comfortlab_171",
  "comfortlab_166",
  "comfortlab_140",
  "comfortlab_139",
  "comfortlab_153",
  "comfortlab_172",
  "comfortlab_165",
  "comfortlab_167",
  "comfortlab_168",
  "comfortlab_169",
  "comfortlab_170",
  "shockabsorber_3",
  "shockabsorber_2",
  "shockabsorber_5",
  "shockabsorber_6",
  "shockabsorber_17",
  "shockabsorber_19",
  "shockabsorber_20",
  "shockabsorber_22",
  "comfortlab_171",
  "comfortlab_172",
  "shockabsorber_4",
  "comfortlab_140",
  "comfortlab_153",
  "shockabsorber_4",
  "shockabsorber_8",
  "shockabsorber_7",
  "shockabsorber_18",
  "comfortlab_170",
  "comfortlab_167",
  "comfortlab_140",
  "comfortlab_166",
  "comfortlab_139",
  "shockabsorber_4",
  "shockabsorber_8",
  "shockabsorber_7",
  "shockabsorber_5",
  "shockabsorber_6",
  "shockabsorber_3",
  "shockabsorber_2",
  "shockabsorber_17",
  "shockabsorber_22",
  "shockabsorber_19",
  "shockabsorber_18",
  "shockabsorber_20",
  "comfortlab_139",
  "comfortlab_140",
  "comfortlab_153",
  "comfortlab_165",
  "comfortlab_166",
  "comfortlab_167",
  "comfortlab_168",
  "comfortlab_169",
  "comfortlab_170",
  "comfortlab_171",
  "comfortlab_172",
];

const CATEGORY_PROMOTION_CHUSEOK = [
  {
    title: "MD PICK",
    en: "best",
    products: [
      {
        type: "BEST",
        products: [
          "inull_2",
          "comfortlab_25",
          "comfortlab_29",
          "comfortlab_31",
          "comfortlab_30",
          "comfortlab_1",
          "shockabsorber_5",
          "shockabsorber_23",
          "shockabsorber_3",
          "slim9_99927",
          "marunfive_7",
          "marunfive_46",
          "marunfive_24",
          "realcomfy_19",
          "realcomfy_21",
          "winkpink_18",
          "winkpink_19",
          "winkpink_30",
          "urinnerf_36",
          "urinnerf_63",
          "poola_1",
          "poola_6",
          "bbongbramarket_19",
          "bbongbramarket_8",
          "bbongbramarket_23",
          "ravic_14",
          "ravic_8",
        ],
      },
    ],
  },
  {
    title: "체형",
    en: "body",
    products: [
      {
        type: "윗가슴 착붙",
        products: [
          "inull_2",
          "inull_3",
          "comfortlab_6",
          "comfortlab_14",
          "comfortlab_25",
          "comfortlab_29",
          "comfortlab_30",
          "comfortlab_32",
          "comfortlab_1",
          "comfortlab_8",
          "slim9_17",
          "slim9_9926",
          "slim9_9999999999999936",
          "slim9_925",
          "slim9_999999999999937",
          "slim9_99999999999936",
          "slim9_9999999999935",
          "marunfive_11",
          "marunfive_46",
          "realcomfy_14",
          "realcomfy_15",
          "realcomfy_19",
          "realcomfy_20",
          "realcomfy_21",
          "winkpink_26",
          "winkpink_27",
          "winkpink_28",
          "winkpink_29",
          "winkpink_30",
          "winkpink_31",
          "winkpink_32",
          "winkpink_36",
          "urinnerf_2",
          "urinnerf_4",
          "urinnerf_3",
          "urinnerf_63",
          "poola_1",
          "bbongbramarket_16",
          "bbongbramarket_20",
          "bbongbramarket_35",
          "bbongbramarket_42",
          "bbongbramarket_19",
          "bbongbramarket_1",
          "bbongbramarket_8",
          "bbongbramarket_23",
          "ravic_4",
          "ravic_8",
          "ravic_2",
        ],
      },
      {
        type: "볼륨핏",
        products: [
          "comfortlab_174",
          "comfortlab_3",
          "comfortlab_42",
          "comfortlab_8",
          "realcomfy_1",
          "realcomfy_2",
          "realcomfy_6",
          "realcomfy_7",
          "winkpink_36",
          "bbongbramarket_16",
          "bbongbramarket_20",
          "bbongbramarket_35",
          "bbongbramarket_42",
          "bbongbramarket_19",
          "bbongbramarket_26",
          "bbongbramarket_2",
          "bbongbramarket_1",
          "bbongbramarket_8",
          "bbongbramarket_47",
          "bbongbramarket_49",
          "bbongbramarket_10",
          "bbongbramarket_23",
          "bbongbramarket_46",
          "bbongbramarket_15",
        ],
      },
      {
        type: "D컵 이상",
        products: [
          "inull_14",
          "comfortlab_162",
          "comfortlab_3",
          "comfortlab_4",
          "comfortlab_25",
          "comfortlab_29",
          "comfortlab_31",
          "comfortlab_32",
          "comfortlab_44",
          "comfortlab_1",
          "comfortlab_8",
          "comfortlab_24",
          "comfortlab_141",
          "comfortlab_143",
          "shockabsorber_5",
          "shockabsorber_6",
          "shockabsorber_19",
          "shockabsorber_20",
          "shockabsorber_22",
          "shockabsorber_4",
          "shockabsorber_23",
          "shockabsorber_2",
          "shockabsorber_3",
          "shockabsorber_7",
          "shockabsorber_18",
          "shockabsorber_8",
          "shockabsorber_9",
          "shockabsorber_21",
          "shockabsorber_13",
          "marunfive_77",
          "marunfive_13",
          "marunfive_11",
          "marunfive_7",
          "marunfive_78",
          "marunfive_46",
          "marunfive_25",
          "marunfive_24",
          "urinnerf_2",
          "urinnerf_29",
          "urinnerf_36",
          "urinnerf_37",
          "urinnerf_27",
          "urinnerf_5",
          "urinnerf_4",
          "urinnerf_3",
          "urinnerf_22",
          "urinnerf_1",
          "urinnerf_54",
          "urinnerf_55",
          "urinnerf_58",
          "urinnerf_57",
          "urinnerf_61",
          "urinnerf_62",
          "urinnerf_63",
          "poola_3",
          "poola_2",
          "poola_6",
          "ravic_20",
        ],
      },
      {
        type: "새가슴",
        products: [
          "inull_3",
          "comfortlab_6",
          "comfortlab_14",
          "comfortlab_25",
          "comfortlab_30",
          "comfortlab_1",
          "slim9_9926",
          "slim9_925",
          "marunfive_46",
          "realcomfy_3",
          "realcomfy_4",
          "realcomfy_14",
          "realcomfy_15",
          "realcomfy_19",
          "realcomfy_20",
          "realcomfy_21",
          "winkpink_27",
          "winkpink_28",
          "winkpink_29",
          "winkpink_30",
          "winkpink_31",
          "winkpink_32",
          "winkpink_33",
          "winkpink_35",
          "urinnerf_2",
          "poola_1",
          "bbongbramarket_16",
          "bbongbramarket_8",
          "bbongbramarket_23",
          "bbongbramarket_15",
          "ravic_8",
        ],
      },
      {
        type: "벌어진 가슴",
        products: [
          "inull_2",
          "inull_3",
          "inull_14",
          "comfortlab_3",
          "comfortlab_31",
          "comfortlab_30",
          "comfortlab_32",
          "comfortlab_42",
          "comfortlab_44",
          "comfortlab_1",
          "comfortlab_8",
          "slim9_17",
          "slim9_9926",
          "slim9_9999999999999936",
          "slim9_99999930",
          "slim9_99999999999936",
          "slim9_9999999999935",
          "marunfive_77",
          "marunfive_13",
          "marunfive_11",
          "marunfive_25",
          "marunfive_24",
          "realcomfy_3",
          "realcomfy_4",
          "realcomfy_6",
          "realcomfy_7",
          "realcomfy_19",
          "realcomfy_23",
          "realcomfy_24",
          "realcomfy_25",
          "winkpink_24",
          "winkpink_25",
          "winkpink_26",
          "winkpink_33",
          "winkpink_36",
          "urinnerf_4",
          "urinnerf_3",
          "urinnerf_58",
          "poola_7",
          "poola_3",
          "poola_2",
          "poola_6",
          "bbongbramarket_20",
          "bbongbramarket_35",
          "bbongbramarket_21",
          "bbongbramarket_26",
          "bbongbramarket_47",
          "bbongbramarket_49",
          "bbongbramarket_10",
          "bbongbramarket_46",
          "ravic_4",
          "ravic_8",
          "ravic_20",
          "ravic_27",
          "ravic_30",
          "ravic_2",
          "ravic_26",
          "ravic_23",
        ],
      },
      {
        type: "처진 가슴",
        products: [
          "inull_2",
          "comfortlab_4",
          "comfortlab_29",
          "comfortlab_31",
          "comfortlab_32",
          "comfortlab_1",
          "comfortlab_8",
          "comfortlab_141",
          "shockabsorber_5",
          "shockabsorber_6",
          "shockabsorber_19",
          "shockabsorber_20",
          "shockabsorber_22",
          "shockabsorber_4",
          "shockabsorber_23",
          "shockabsorber_2",
          "shockabsorber_3",
          "shockabsorber_7",
          "shockabsorber_18",
          "shockabsorber_8",
          "shockabsorber_9",
          "shockabsorber_21",
          "shockabsorber_13",
          "slim9_99999930",
          "slim9_99999999999936",
          "slim9_9999999999935",
          "marunfive_25",
          "marunfive_24",
          "realcomfy_1",
          "realcomfy_2",
          "realcomfy_6",
          "realcomfy_7",
          "realcomfy_23",
          "realcomfy_24",
          "realcomfy_25",
          "winkpink_24",
          "winkpink_25",
          "winkpink_26",
          "winkpink_31",
          "winkpink_34",
          "urinnerf_29",
          "urinnerf_36",
          "urinnerf_37",
          "urinnerf_27",
          "urinnerf_5",
          "urinnerf_4",
          "urinnerf_3",
          "urinnerf_22",
          "urinnerf_1",
          "urinnerf_54",
          "urinnerf_58",
          "urinnerf_57",
          "urinnerf_61",
          "urinnerf_63",
          "poola_7",
          "poola_3",
          "poola_2",
          "poola_6",
          "bbongbramarket_19",
          "bbongbramarket_49",
          "bbongbramarket_10",
          "bbongbramarket_15",
          "ravic_4",
          "ravic_20",
          "ravic_27",
          "ravic_2",
          "ravic_26",
          "ravic_23",
        ],
      },
    ],
  },
  {
    title: "취향",
    en: "type",
    products: [
      {
        type: "스포츠브라",
        products: [
          "comfortlab_6",
          "comfortlab_42",
          "comfortlab_44",
          "shockabsorber_5",
          "shockabsorber_6",
          "shockabsorber_19",
          "shockabsorber_20",
          "shockabsorber_22",
          "shockabsorber_4",
          "shockabsorber_23",
          "shockabsorber_2",
          "shockabsorber_3",
          "shockabsorber_7",
          "shockabsorber_18",
          "shockabsorber_8",
          "shockabsorber_9",
          "shockabsorber_21",
          "shockabsorber_13",
          "marunfive_25",
          "marunfive_24",
          "winkpink_34",
          "urinnerf_29",
          "urinnerf_57",
        ],
      },
      {
        type: "팬티",
        products: [
          "inull_13",
          "comfortlab_46",
          "comfortlab_45",
          "comfortlab_48",
          "comfortlab_52",
          "comfortlab_50",
          "comfortlab_49",
          "comfortlab_56",
          "comfortlab_55",
          "comfortlab_66",
          "comfortlab_70",
          "comfortlab_71",
          "comfortlab_73",
          "slim9_925",
          "slim9_18",
          "slim9_10",
          "slim9_9999929",
          "slim9_999999999999937",
          "realcomfy_3",
          "realcomfy_5",
          "realcomfy_6",
          "realcomfy_7",
          "realcomfy_11",
          "realcomfy_14",
          "realcomfy_16",
          "realcomfy_17",
          "realcomfy_18",
          "realcomfy_20",
          "realcomfy_22",
          "realcomfy_23",
          "realcomfy_24",
          "realcomfy_25",
          "winkpink_17",
          "winkpink_21",
          "winkpink_22",
          "winkpink_23",
          "winkpink_24",
          "winkpink_25",
          "winkpink_26",
          "winkpink_27",
          "winkpink_28",
          "winkpink_29",
          "winkpink_30",
          "winkpink_32",
          "winkpink_33",
          "winkpink_35",
          "winkpink_36",
          "urinnerf_49",
          "urinnerf_5",
          "urinnerf_4",
          "urinnerf_3",
          "urinnerf_22",
          "urinnerf_47",
          "urinnerf_48",
          "urinnerf_56",
          "urinnerf_58",
          "bbongbramarket_16",
          "bbongbramarket_20",
          "bbongbramarket_35",
          "bbongbramarket_42",
          "bbongbramarket_19",
          "bbongbramarket_21",
          "bbongbramarket_26",
          "bbongbramarket_2",
          "bbongbramarket_1",
          "bbongbramarket_8",
          "bbongbramarket_47",
          "bbongbramarket_49",
          "bbongbramarket_10",
          "bbongbramarket_23",
          "bbongbramarket_46",
          "bbongbramarket_15",
          "ravic_8",
          "ravic_20",
          "ravic_27",
          "ravic_2",
          "ravic_26",
          "ravic_23",
        ],
      },
      {
        type: "홈웨어",
        products: [
          "winkpink_16",
          "winkpink_15",
          "winkpink_14",
          "winkpink_13",
          "winkpink_12",
          "winkpink_9",
          "winkpink_10",
          "winkpink_18",
          "winkpink_19",
          "winkpink_20",
          "winkpink_21",
          "winkpink_22",
        ],
      },
    ],
  },
  {
    title: "스타일",
    en: "style",
    products: [
      {
        type: "노브라웨어",
        products: [
          "inull_15",
          "comfortlab_162",
          "comfortlab_174",
          "comfortlab_142",
          "comfortlab_143",
          "slim9_23",
          "slim9_99927",
          "slim9_9999929",
          "slim9_9922",
          "slim9_9922",
          "marunfive_7",
          "marunfive_78",
          "marunfive_73",
          "realcomfy_9",
          "realcomfy_12",
          "realcomfy_10",
          "realcomfy_13",
          "ravic_14",
          "ravic_25",
        ],
      },
      {
        type: "고정패드",
        products: [
          "inull_2",
          "comfortlab_6",
          "comfortlab_3",
          "comfortlab_4",
          "comfortlab_14",
          "comfortlab_25",
          "comfortlab_29",
          "comfortlab_30",
          "comfortlab_32",
          "comfortlab_42",
          "comfortlab_44",
          "comfortlab_1",
          "comfortlab_8",
          "comfortlab_141",
          "shockabsorber_5",
          "shockabsorber_6",
          "shockabsorber_19",
          "shockabsorber_20",
          "shockabsorber_22",
          "shockabsorber_7",
          "shockabsorber_18",
          "shockabsorber_8",
          "shockabsorber_9",
          "shockabsorber_21",
          "shockabsorber_13",
          "slim9_9926",
          "slim9_925",
          "slim9_99999930",
          "marunfive_13",
          "marunfive_46",
          "realcomfy_3",
          "realcomfy_4",
          "realcomfy_14",
          "realcomfy_15",
          "realcomfy_19",
          "realcomfy_20",
          "realcomfy_21",
          "realcomfy_23",
          "realcomfy_24",
          "realcomfy_25",
          "winkpink_32",
          "winkpink_36",
          "urinnerf_2",
          "urinnerf_27",
          "urinnerf_1",
          "urinnerf_63",
          "bbongbramarket_16",
          "bbongbramarket_20",
          "bbongbramarket_2",
          "bbongbramarket_1",
          "bbongbramarket_8",
          "bbongbramarket_10",
          "bbongbramarket_23",
          "ravic_4",
          "ravic_23",
        ],
      },
    ],
  },
  {
    title: "브랜드",
    en: "brand",
    products: [
      {
        type: "이눌",
        products: ["inull_2", "inull_3", "inull_13", "inull_14", "inull_15"],
      },
      {
        type: "컴포트랩",
        products: [
          "comfortlab_162",
          "comfortlab_174",
          "comfortlab_6",
          "comfortlab_3",
          "comfortlab_4",
          "comfortlab_14",
          "comfortlab_25",
          "comfortlab_29",
          "comfortlab_31",
          "comfortlab_30",
          "comfortlab_32",
          "comfortlab_42",
          "comfortlab_44",
          "comfortlab_1",
          "comfortlab_8",
          "comfortlab_24",
          "comfortlab_46",
          "comfortlab_45",
          "comfortlab_48",
          "comfortlab_52",
          "comfortlab_50",
          "comfortlab_49",
          "comfortlab_56",
          "comfortlab_55",
          "comfortlab_66",
          "comfortlab_70",
          "comfortlab_71",
          "comfortlab_73",
          "comfortlab_142",
          "comfortlab_141",
          "comfortlab_143",
        ],
      },
      {
        type: "쇼크업소버",
        products: [
          "shockabsorber_5",
          "shockabsorber_6",
          "shockabsorber_19",
          "shockabsorber_20",
          "shockabsorber_22",
          "shockabsorber_4",
          "shockabsorber_23",
          "shockabsorber_2",
          "shockabsorber_3",
          "shockabsorber_7",
          "shockabsorber_18",
          "shockabsorber_8",
          "shockabsorber_9",
          "shockabsorber_21",
          "shockabsorber_13",
        ],
      },
      {
        type: "슬림나인",
        products: [
          "slim9_17",
          "slim9_18",
          "slim9_23",
          "slim9_9926",
          "slim9_99927",
          "slim9_9999999999999936",
          "slim9_925",
          "slim9_99999930",
          "slim9_10",
          "slim9_9999929",
          "slim9_999999999999937",
          "slim9_99999999999936",
          "slim9_9999999999935",
          "slim9_9922",
          "slim9_9922",
        ],
      },
      {
        type: "마른파이브",
        products: [
          "marunfive_77",
          "marunfive_13",
          "marunfive_11",
          "marunfive_7",
          "marunfive_78",
          "marunfive_46",
          "marunfive_73",
          "marunfive_25",
          "marunfive_24",
        ],
      },
      {
        type: "리얼컴피",
        products: [
          "realcomfy_1",
          "realcomfy_2",
          "realcomfy_3",
          "realcomfy_4",
          "realcomfy_5",
          "realcomfy_6",
          "realcomfy_7",
          "realcomfy_9",
          "realcomfy_12",
          "realcomfy_10",
          "realcomfy_13",
          "realcomfy_11",
          "realcomfy_14",
          "realcomfy_15",
          "realcomfy_16",
          "realcomfy_17",
          "realcomfy_18",
          "realcomfy_19",
          "realcomfy_20",
          "realcomfy_21",
          "realcomfy_22",
          "realcomfy_23",
          "realcomfy_24",
          "realcomfy_25",
        ],
      },
      {
        type: "윙크핑크",
        products: [
          "winkpink_17",
          "winkpink_16",
          "winkpink_15",
          "winkpink_14",
          "winkpink_13",
          "winkpink_12",
          "winkpink_9",
          "winkpink_10",
          "winkpink_18",
          "winkpink_19",
          "winkpink_20",
          "winkpink_21",
          "winkpink_22",
          "winkpink_23",
          "winkpink_24",
          "winkpink_25",
          "winkpink_26",
          "winkpink_27",
          "winkpink_28",
          "winkpink_29",
          "winkpink_30",
          "winkpink_31",
          "winkpink_32",
          "winkpink_33",
          "winkpink_34",
          "winkpink_35",
          "winkpink_36",
        ],
      },
      {
        type: "유아이너프",
        products: [
          "urinnerf_2",
          "urinnerf_49",
          "urinnerf_29",
          "urinnerf_36",
          "urinnerf_37",
          "urinnerf_27",
          "urinnerf_5",
          "urinnerf_4",
          "urinnerf_3",
          "urinnerf_22",
          "urinnerf_1",
          "urinnerf_47",
          "urinnerf_48",
          "urinnerf_54",
          "urinnerf_55",
          "urinnerf_56",
          "urinnerf_58",
          "urinnerf_57",
          "urinnerf_61",
          "urinnerf_62",
          "urinnerf_63",
        ],
      },
      {
        type: "풀라베이직",
        products: ["poola_1", "poola_7"],
      },
      {
        type: "보드리",
        products: ["poola_3", "poola_2", "poola_6"],
      },
      {
        type: "뽕브라마켓",
        products: [
          "bbongbramarket_16",
          "bbongbramarket_20",
          "bbongbramarket_35",
          "bbongbramarket_42",
          "bbongbramarket_19",
          "bbongbramarket_21",
          "bbongbramarket_26",
          "bbongbramarket_2",
          "bbongbramarket_1",
          "bbongbramarket_8",
          "bbongbramarket_47",
          "bbongbramarket_49",
          "bbongbramarket_10",
          "bbongbramarket_23",
          "bbongbramarket_46",
          "bbongbramarket_15",
        ],
      },
      {
        type: "라비크",
        products: [
          "ravic_14",
          "ravic_4",
          "ravic_8",
          "ravic_20",
          "ravic_27",
          "ravic_30",
          "ravic_25",
          "ravic_2",
          "ravic_26",
          "ravic_23",
        ],
      },
    ],
  },
];

const PROMOTION_SLUG_CHUSEOK = [
  "inull_2",
  "inull_3",
  "inull_13",
  "inull_14",
  "inull_15",
  "comfortlab_162",
  "comfortlab_174",
  "comfortlab_6",
  "comfortlab_3",
  "comfortlab_4",
  "comfortlab_14",
  "comfortlab_25",
  "comfortlab_29",
  "comfortlab_31",
  "comfortlab_30",
  "comfortlab_32",
  "comfortlab_42",
  "comfortlab_44",
  "comfortlab_1",
  "comfortlab_8",
  "comfortlab_24",
  "comfortlab_46",
  "comfortlab_45",
  "comfortlab_48",
  "comfortlab_52",
  "comfortlab_50",
  "comfortlab_49",
  "comfortlab_56",
  "comfortlab_55",
  "comfortlab_66",
  "comfortlab_70",
  "comfortlab_71",
  "comfortlab_73",
  "comfortlab_142",
  "comfortlab_141",
  "comfortlab_143",
  "shockabsorber_5",
  "shockabsorber_6",
  "shockabsorber_19",
  "shockabsorber_20",
  "shockabsorber_22",
  "shockabsorber_4",
  "shockabsorber_23",
  "shockabsorber_2",
  "shockabsorber_3",
  "shockabsorber_7",
  "shockabsorber_18",
  "shockabsorber_8",
  "shockabsorber_9",
  "shockabsorber_21",
  "shockabsorber_13",
  "slim9_17",
  "slim9_18",
  "slim9_23",
  "slim9_9926",
  "slim9_99927",
  "slim9_9999999999999936",
  "slim9_925",
  "slim9_99999930",
  "slim9_10",
  "slim9_9999929",
  "slim9_999999999999937",
  "slim9_99999999999936",
  "slim9_9999999999935",
  "slim9_9922",
  "slim9_9922",
  "marunfive_77",
  "marunfive_13",
  "marunfive_11",
  "marunfive_7",
  "marunfive_78",
  "marunfive_46",
  "marunfive_73",
  "marunfive_25",
  "marunfive_24",
  "realcomfy_1",
  "realcomfy_2",
  "realcomfy_3",
  "realcomfy_4",
  "realcomfy_5",
  "realcomfy_6",
  "realcomfy_7",
  "realcomfy_9",
  "realcomfy_12",
  "realcomfy_10",
  "realcomfy_13",
  "realcomfy_11",
  "realcomfy_14",
  "realcomfy_15",
  "realcomfy_16",
  "realcomfy_17",
  "realcomfy_18",
  "realcomfy_19",
  "realcomfy_20",
  "realcomfy_21",
  "realcomfy_22",
  "realcomfy_23",
  "realcomfy_24",
  "realcomfy_25",
  "winkpink_17",
  "winkpink_16",
  "winkpink_15",
  "winkpink_14",
  "winkpink_13",
  "winkpink_12",
  "winkpink_9",
  "winkpink_10",
  "winkpink_18",
  "winkpink_19",
  "winkpink_20",
  "winkpink_21",
  "winkpink_22",
  "winkpink_23",
  "winkpink_24",
  "winkpink_25",
  "winkpink_26",
  "winkpink_27",
  "winkpink_28",
  "winkpink_29",
  "winkpink_30",
  "winkpink_31",
  "winkpink_32",
  "winkpink_33",
  "winkpink_34",
  "winkpink_35",
  "winkpink_36",
  "urinnerf_2",
  "urinnerf_49",
  "urinnerf_29",
  "urinnerf_36",
  "urinnerf_37",
  "urinnerf_27",
  "urinnerf_5",
  "urinnerf_4",
  "urinnerf_3",
  "urinnerf_22",
  "urinnerf_1",
  "urinnerf_47",
  "urinnerf_48",
  "urinnerf_54",
  "urinnerf_55",
  "urinnerf_56",
  "urinnerf_58",
  "urinnerf_57",
  "urinnerf_61",
  "urinnerf_62",
  "urinnerf_63",
  "poola_1",
  "poola_7",
  "poola_3",
  "poola_2",
  "poola_6",
  "bbongbramarket_16",
  "bbongbramarket_20",
  "bbongbramarket_35",
  "bbongbramarket_42",
  "bbongbramarket_19",
  "bbongbramarket_21",
  "bbongbramarket_26",
  "bbongbramarket_2",
  "bbongbramarket_1",
  "bbongbramarket_8",
  "bbongbramarket_47",
  "bbongbramarket_49",
  "bbongbramarket_10",
  "bbongbramarket_23",
  "bbongbramarket_46",
  "bbongbramarket_15",
  "ravic_14",
  "ravic_4",
  "ravic_8",
  "ravic_20",
  "ravic_27",
  "ravic_30",
  "ravic_25",
  "ravic_2",
  "ravic_26",
  "ravic_23",
];

const CATEGORY_PROMOTION_POOLABASIC = [
  {
    title: "풀라 베이직",
    en: "poolabasic",
    products: [
      {
        type: "전체",
        products: ["poola_1", "poola_7"],
      },
    ],
  },
];

const PROMOTION_SLUG_POOLABASIC = ["poola_1", "poola_7"];

export {
  CommonCollection,
  BrandCollectionData,
  CategoryByType,
  CategoryByTaste,
  CollectionTitle,
  CategoryByAwards,
  CATEGORY_PROMOTION,
  CATEGORY_PROMOTION_SPORTSBRA,
  PROMOTION_SLUG_SPORTSBRA,
  CATEGORY_PROMOTION_CHUSEOK,
  PROMOTION_SLUG_CHUSEOK,
  CATEGORY_PROMOTION_POOLABASIC,
  PROMOTION_SLUG_POOLABASIC,
};
