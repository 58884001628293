import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Axios from "axios";
import { Helmet } from "react-helmet";
import { Header } from "../../components/Common/Header";
import {API_ENDPOINT} from "../../Config";

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  background-color: var(--color-white);
  padding-top: 40px;
`;

const Section = styled.section`
  padding: 20px;
`;

const ReviewContainer = styled.div`
  aspect-ratio: 1 / 1;

  &::after {
    padding-bottom: 100%;
  }
`;

export const InfluencerReviewListPage = ({ history }) => {
  const [allReviews, setAllReviews] = useState([]);

  const getData = async () => {
    try {
      const response = await Axios.get(
          API_ENDPOINT + "/api/reviews/influence"
      );
      setAllReviews(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <title>지금 인기있는 속옷 | 풀라</title>
        <meta property="og:title" content="지금 인기있는 속옷 | 풀라" />
      </Helmet>
      <PageContainer>
        <Header type="BACK" title="지금 인기있는 속옷" history={history} />
        <Section>
          <h2 style={{ fontSize: "18px", fontWeight: 700 }}>
            지금 인기있는 속옷
          </h2>
          <div
            style={{
              position: "relative",
              display: "grid",
              paddingTop: "16px",
              gridTemplateColumns: "repeat(3,1fr)",
              gridGap: "2px 2px",
              width: "100%",
            }}
          >
            {allReviews?.map((review) => {
              return (
                <ReviewContainer
                  key={review.id}
                  onClick={() => history.push(`/influencerReview/${review.id}`)}
                >
                  <img
                    src={review.img_url}
                    alt="thumb"
                    width="100%"
                    height="100%"
                    style={{
                      objectFit: "cover",
                      overflow: "hidden",
                    }}
                  />
                </ReviewContainer>
              );
            })}
          </div>
        </Section>
      </PageContainer>
    </>
  );
};
