import React from "react";
import styled from "styled-components";

export const OrderListCard = ({ data }) => {
  const THUMBNAIL = `https://s3.ap-northeast-2.amazonaws.com/poola-photo-2.0/s200/${data.product.slug}.png`;

  const BRAND_NAME = data.brand.name;
  const PRODUCT_NAME = data.product.name;
  const PRICE = data.total.price.sale.formatted;
  const ORIGINAL_PRICE = data.total.price.original.formatted;

  const NoSale =
    data.total.price.sale.converted === data.total.price.original.converted;

  return (
    <Card>
      <Thumbnail src={THUMBNAIL} alt="thumb" />
      <Info>
        <BrandName>{BRAND_NAME}</BrandName>
        <ProductName>{PRODUCT_NAME}</ProductName>
        <PriceBox>
          <Price>{PRICE}</Price>
          {!NoSale && <OriginalPrice>{ORIGINAL_PRICE}</OriginalPrice>}
        </PriceBox>
      </Info>
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0 24px 0;
  border-bottom: #f4f4f4 1px solid;
  cursor: pointer;
`;

const Thumbnail = styled.img`
  width: 65px;
  height: 65px;
  object-fit: cover;
  border-radius: 6px;
`;
const Info = styled.div`
  padding-left: 12px;
`;

const BrandName = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`;

const ProductName = styled.h4`
  margin: 0;
  padding: 4px 0;
  font-size: 14px;
  font-weight: normal;
`;

const PriceBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 6px;
`;

const OriginalPrice = styled.del`
  font-size: 12px;
  color: var(--color-tertiary-black);
`;

const Price = styled.span`
  font-size: 14px;
  font-weight: bold;
  padding-right: 4px;
`;
