import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { clickTrigger } from "../../modules/trigger";
import { GA4EventTracker } from "../../hook/GA4EventTracker";

const Card = styled(Link)`
  width: 100%;
  display: inline-block;
`;

const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: 71%;
  overflow: hidden;
`;

const ImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  object-fit: cover;
`;

const NewIcon = styled.img`
  margin: 8px 8px 0 0;
  position: absolute;
  top: 0;
  right: 0;
`;

const TextWrapper = styled.div`
  padding-top: 12px;
  white-space: normal;
`;

const CategoryText = styled.em`
  margin-bottom: 4px;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: var(--color-secondary-black);
`;

const TitleText = styled.strong`
  margin-bottom: 6px;
  display: block;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black);
`;

const View = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: var(--color-tertiary-black);
`;

const ArticleCard = ({ data, size = "SMALL", pageType, _onClick }) => {
  const handleClick = () => {
    sessionStorage.setItem("articleInfo", JSON.stringify(data));
    clickTrigger("아티클", {
      articleInfo: {
        title: data.title,
        category: data.category,
        views: data.views,
        likes: data.likes,
        date: data.createdAt && data.createdAt.slice(0, 10),
      },
    });
    GA4EventTracker("아티클_클릭", {
      아티클_제목: data.title,
    });
    GA4EventTracker("아티클_클릭", {
      위치:
        pageType === "검색"
          ? "검색페이지"
          : pageType === "콘텐츠"
          ? "아티클페이지"
          : "아티클탭",
    });
  };

  return (
    <Card
      to={`/article/${data._id}`}
      onClick={(e) => {
        _onClick && _onClick(e, data);
        handleClick();
      }}
    >
      <ImageWrapper>
        <Image src={data.thumb} alt="" width="100%" height="100%" />
        {data.new && (
          <>
            <ImageOverlay />
            <NewIcon
              src={require("../../assets/icons/article/new.svg")}
              alt="최신글"
              width={size === "LARGE" ? "39px" : "33px"}
              height={size === "LARGE" ? "20px" : "16px"}
            />
          </>
        )}
      </ImageWrapper>
      <TextWrapper>
        <CategoryText>{data.category}</CategoryText>
        <TitleText dangerouslySetInnerHTML={{ __html: data.title }} />
        <View>
          <img
            src={require("../../assets/icons/view-" +
              (data.views > 200 ? "hot" : "default") +
              ".svg")}
            alt="조회"
            width="16px"
            height="16px"
            style={{ marginRight: "4px" }}
          />
          {data.views}명
        </View>
      </TextWrapper>
    </Card>
  );
};

ArticleCard.defaultProps = {
  data: {
    _id: "6041209f8d74d162c443433c",
    thumb:
      "https://poola-article.s3.ap-northeast-2.amazonaws.com/thumb/underboob_1.jpeg",
    likes: 0,
    views: 0,
    title: "개발자가 직접 그린 <br class='article-br'>브라 AI 제작기",
    category: "메이드 바이 폴라",
    text: "<body><div>블라블라</div></body>",
    summary: "개발자가 업어키운 인공지능 이야기",
    editor: "말많은 슴디터",
    createdAt: "2021-05-18T14:09:53.463Z",
    updatedAt: "2021-05-18T14:09:53.463Z",
    __v: 0,
  },
  horizontal: false,
};

export default React.memo(ArticleCard);
