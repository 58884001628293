import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { clickTrigger } from "../../modules/trigger";
import Clayful from "clayful/client-js";
import { GA4EventTracker } from "../../hook/GA4EventTracker";

const Card = styled.div`
  padding-bottom: 16px;
`;

const OrderWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const Image = styled.img`
  margin-right: 12px;
  border-radius: 6px;
`;

const Text = styled.strong`
  display: block;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-secondary-black);
`;

const SubText = styled.span`
  color: var(--color-secondary-black);
  line-height: 14px;
  font-size: 12px;
`;

const Caption = styled.span`
  margin-top: ${(props) => props.top || 0}px;
  display: block;
  font-size: 11px;
  line-height: 16px;
  color: var(--color-tertiary-black);
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-poola-blue);
  background-color: var(--color-white);
  border: 1px solid var(--color-poola-blue);
  border-radius: 4px;
`;

export const OrderCard = ({ data, type, history }) => {
  const [productName, setProductName] = useState("");

  useEffect(() => {
    async function fetch() {
      let productClayful = await Clayful.Product.get(
        await data.clayful_productId
      );
      return productClayful.data.name;
    }

    fetch().then((result) => {
      setProductName(result);
    });
  }, []);

  const handleClick = () => {
    // 후기 작성 페이지 이동
    history.push({ pathname: "/my/review/write", state: { review: data } });
    GA4EventTracker("리뷰_작성_클릭");
    clickTrigger("후기작성_이동", {
      productInfo: {
        name: productName,
        brand: data.brand_name,
        option: data.option_name.사이즈 + " / " + data.option_name.색상,
      },
    });
  };

  // 옵션 values 그룹핑
  const options = Object.values(data?.option_name);
  const optionsResult = options.join(" / ");

  return (
    <Card>
      <OrderWrapper>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Image src={data.product_img} alt="" width="65px" height="65px" />
          <div>
            <Text>{data.brand_name}</Text>
            <SubText>{productName}</SubText>
            <Caption>
              {data.option_name && optionsResult}
              구매
            </Caption>
          </div>
        </div>
        {type === "LIST" && (
          <Caption top={2}>
            {data.createdAt.slice(0, 10).replaceAll("-", ".")}
          </Caption>
        )}
      </OrderWrapper>
      {type === "LIST" && <Button onClick={handleClick}>후기 작성</Button>}
    </Card>
  );
};

OrderCard.defaultProps = {
  type: "LIST", // LIST or WRITER
  data: {
    brand_name: "DEFAULT",
    product_name: "Default Product Name",
    img: "https://s3.ap-northeast-2.amazonaws.com/poola-photo-2.0/s400/test_1.png",
    option_name: ["사이즈", "색상"],
    clayful_optionId: "",
    clayful_orderId: "",
    clayful_productId: "",
    createdAt: "2022-01-01T00:00:00.000Z",
  },
};
