import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "../../templates/slick.css";
import "../../templates/slick-theme.css";

const SliderWrapper = styled.div`
  position: relative;
  width: ${({ width }) => width + "px"};
  height: auto;
  margin: 0 auto;

  .slick-dots {
    position: absolute !important;
    margin-top: 10px;
  }
`;

const ReviewImg = styled.img`
  display: block;
  width: ${({ width }) => width + "px"};
  height: ${({ width }) => width + "px"};
  position: relative;
  box-shadow: none;
  object-fit: cover;
`;

const CustomDots = styled.div`
  position: absolute;
  display: block;
  z-index: 555;
  top: -20px;

  text-align: center;
`;

export const SlideReviewList = ({ params, img, influence }) => {
  const [key, setKey] = useState(0); // 이미지 key
  const [width, setWidth] = useState(
    window.innerWidth > 500 ? 500 : window.innerWidth
  );

  useEffect(() => {
    window.addEventListener("resize", function () {
      setWidth(window.innerWidth > 500 ? 500 : window.innerWidth);
    });
  }, []);

  useEffect(() => {
    setKey((key) => key + 1);
  }, [params]);

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    variableWidth: true,
    autoplay: false,
    adaptiveHeight: true,
    dotsClass: `_new`,
    customPaging: (dots) => (
      <CustomDots>
        <img
          src={require("../../assets/icons/banner-indicator-white.png")}
          alt=""
          width="6px"
          height="6px"
          style={{ display: "block" }}
        />
      </CustomDots>
    ),
  };

  return influence ? (
    <SliderWrapper>
      <Slider {...settings} style={{ marginTop: "-16px" }} key={key}>
        {[img].map((banner) => (
          <div key={banner.influence_id}>
            <ReviewImg src={banner.img_url} alt="리뷰이미지" width={width} />
          </div>
        ))}
      </Slider>
    </SliderWrapper>
  ) : (
    img?.length !== 0 && (
      <SliderWrapper width={width}>
        <Slider {...settings} style={{ marginTop: "-16px" }} key={key}>
          {img.map((banner, idx) => (
            <div key={idx}>
              <ReviewImg
                src={
                  typeof banner === "string" && !banner.includes("https")
                    ? "https://d1bkjagntxw4mp.cloudfront.net/reviews/" + banner + "?h=500"
                    : banner + "?h=500"
                }
                alt="리뷰이미지"
                width={width}
              />
            </div>
          ))}
        </Slider>
      </SliderWrapper>
    )
  );
};
