import React from "react";
import styled, {css} from "styled-components";
import {CouponCard} from "../Card/CouponCard";

const List = styled.ul`
  margin: 0;
  padding: 0 16px;
  list-style: none;
`;

const ListItem = styled.li`
  width: 100%;
  padding: 6px 0;

  ${props => props.type === 'DOWNLOAD' && css`
    &:first-child {
      margin-top: 6px;
      padding-top: 12px;
      border-top: 1px solid var(--color-disabled-gray);
    }
  `}
`;

export const CouponList = ({data, type, selectedCoupon, _onClick}) => {
  const Coupons =  data && data.map(coupon => {
    const selected = selectedCoupon && selectedCoupon._id === coupon._id;

    return (
      <ListItem key={coupon._id} type={type}>
        <CouponCard data={coupon} type={type} selected={selected} _onClick={_onClick} />
      </ListItem>
    )
  });

  return (
    <List>
      {Coupons}
    </List>
  )
}