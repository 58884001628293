import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Axios from "axios";
import { API_ENDPOINT, IMAGE_SERVER_LOCATION } from "../../Config";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Header } from "../../components/Common/Header";
import { SlideReviewList } from "../../components/List/SlideReviewList";
import { formatPrice } from "../../modules/util";
import { Label } from "../../components/Common/Label";
import { TitleCard } from "../../components/Card/TitleCard";
import Spinner from "react-bootstrap/Spinner";

const PageContainer = styled.main`
  padding-top: 40px;
  width: 100%;
  max-width: 500px;
  background-color: var(--color-white);
`;

const Section = styled.section`
  padding: ${({ padding }) => padding};
  border-bottom: ${({ border }) => border + " solid #EBEBEB"};
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 16.28px;
  cursor: pointer;
`;

const DetailType = styled.div`
  margin-top: 8px;
  padding: 20px;
  background-color: #f1f4f8;
  color: var(--color-poola-blue);
  line-height: 22px;
  font-size: 13px;

  p {
    display: inline;
    margin: 0;
  }
`;

const ReviewRating = styled.div`
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 700;
    color: var(--color-poola-blue);
  }
`;

const DetailButton = styled.button`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-black);
  background-color: var(--color-white);
  border: 1px solid var(--color-border-gray);
  border-radius: 4px;

  img {
    margin-left: 8px;
  }
`;

const Caption = styled.span`
  display: inline-block;
  padding-right: 4px;
  font-size: 12px;
  line-height: 15.02px;
  color: var(--color-${(props) => props.color});
`;

const ReviewContainer = styled.div`
  aspect-ratio: 1 / 1;

  &::after {
    padding-bottom: 100%;
  }
`;

export const InfluencerReviewPage = ({ history }) => {
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  const { reviewId } = useParams();
  const [spinner, setSpinner] = useState(true); // 로딩
  const [data, setData] = useState([]); // 리뷰 상세 정보
  const [allData, setAllData] = useState([]); // 전체 리뷰
  const [img, setImg] = useState([]); // 리뷰 이미지
  const [products, setProducts] = useState([]); // 상품 정보
  const [userReviews, setUserReviews] = useState([]); // 유저 리뷰
  const [dataReviews, setDataReviews] = useState([]); // 크롤링 리뷰

  let userCup = user.size?.replace(/[0-9]/g, ""); // 유저 컵 사이즈
  let userBand = user.size?.replace(/[^0-9]/g, ""); // 유저 밴드 사이즈

  const getData = async () => {
    try {
      const response = await Axios.get(
          API_ENDPOINT + "/api/reviews/influence"
      );
      const reviewData = response.data.data.filter(
        (x) => x.id === Number(reviewId)
      )[0];
      setData(response.data.data.filter((x) => x.id === Number(reviewId)));

      setSpinner(() => true);
      if (reviewData?.productId !== "" || reviewData?.productId !== null) {
        const rawCup = userCup === "AA" || userCup === "AAA" ? "A" : userCup;
        const rawBand = userBand < 65 ? 65 : userBand > 100 ? 100 : userBand;

        try {
          setTimeout(() => {}, 2000);
          const res = await Axios.get(
            API_ENDPOINT +
              `/v1/api/products/${reviewData?.product_id}?userEmail=${
                user.email
              }&band=${rawBand === undefined ? null : rawBand}&cup=${
                rawCup === undefined ? null : rawCup
              }`
          );

          setSpinner(false);
          setProducts(res.data.productDetail);
          setUserReviews(res.data.reviews);
          try {
            const reviewRes = await Axios.get(
              API_ENDPOINT +
                `/api/dummy-reviews/products/${res.data.productDetail?.No}`
            );
            setDataReviews(reviewRes.data.reviews);
          } catch (e) {
            console.log(e);
          }
        } catch (e) {
          console.log(e);
        }
      }
      setData(response.data.data.filter((x) => x.id === Number(reviewId)));
      setAllData(response.data.data.filter((x) => x.id !== Number(reviewId)));

      if (reviewData?.img_url !== "" && reviewData?.img_url !== null) {
        setImg(reviewData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
    setImg([]);
  }, [reviewId]);

  const productThumb = () => {
    if (products?.No !== undefined) {
      return products?.isMP
        ? products?.thumb
        : IMAGE_SERVER_LOCATION + "s600/" + products?.No + ".png?h=500";
    } else {
      return null;
    }
  };

  const floatRate = () => {
    let sum = 0;
    let count = 0;
    let userRateSum = 0;
    let dataRateSum = 0;

    if (userReviews && userReviews.length !== 0) {
      userReviews.forEach((data) => {
        count += 1;
        userRateSum += data.rate;
      });
    }
    if (dataReviews && dataReviews.length !== 0) {
      dataReviews.forEach((data) => {
        count += 1;
        sum += data.rate;
        dataRateSum += data.rate;
      });
    }
    //계속 NaN 뜨길래 추가해줌
    if(userRateSum === 0 && dataRateSum ===0){
      return 0
    }
    return Math.round(((userRateSum + dataRateSum) / count) * 10) / 10; // 소수점 아래 한자리
  };
  // 상품 콘텐츠
  const Product = () => {
    return data[0]?.is_poola_product.data[0] === 1 ? (
      <FlexBox
        onClick={() => {
          history.push(`/product/${data[0]?.product_id}`);
        }}
      >
        <img
          src={productThumb()}
          alt="thumb"
          width="85px"
          height="85px"
          style={{ marginRight: "12px" }}
        />
        <div style={{ display: "inline-block" }}>
          <div
            style={{
              paddingBottom: "8px",
              fontSize: "13px",
              fontWeight: 700,
            }}
          >
            <span style={{ paddingRight: "4px" }}>{products?.brand}</span>
            <span
              style={{
                fontWeight: 400,
              }}
            >
              {products?.name}
            </span>
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: 700,
              paddingBottom: "7px",
            }}
          >
            {formatPrice(products?.discount?.price)}원
          </div>
          <div style={{ display: "inline-block", paddingRight: "6px" }}>
            <Label
              productMatch
              notRecommend={
                user.type && products?.recommend < 0.75 ? true : false
              }
              userType={!user.type ? false : true}
              noBorder
              flag={user.type && products?.recommend < 0.84 ? true : false}
              text={
                !user.type
                  ? "나와 잘 맞을까?"
                  : 0.9 <= products?.recommend
                  ? "찰떡궁합"
                  : 0.84 <= products?.recommend && products?.recommend < 90
                  ? "잘 맞아요"
                  : 0.75 <= products?.recommend && products?.recommend < 0.84
                  ? "보통이에요"
                  : products?.recommend < 0.75
                  ? "잘 안맞아요"
                  : null
              }
            />
          </div>
          <div
            style={{
              marginTop: "6px",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../assets/icons/star-filled.svg")}
              alt="rate"
              width="12px"
              height="12px"
            />
            <Caption color="poola-blue">{floatRate()}</Caption>
            <Caption color="tertiary-black">
              {`(${userReviews?.length + dataReviews?.length})`}
            </Caption>
          </div>
        </div>
      </FlexBox>
    ) : (
      <div style={{ display: "inline-block" }}>
        <div
          style={{
            paddingBottom: "8px",
            fontSize: "13px",
            fontWeight: 700,
          }}
        >
          <span style={{ paddingRight: "4px" }}>{data[0]?.brand}</span>
          <span
            style={{
              fontWeight: 400,
            }}
          >
            {data[0]?.productName}
          </span>
        </div>
      </div>
    );
  };

  // 리뷰 상세
  const Review = (data) => {
    const review =
      data[0]?.text &&
      data[0]?.text.replaceAll("*", "<br />").replaceAll("**", "<br /><br />");
    return (
      <>
        {data[0]?.is_account_open.data[0] === 1&& (
          <p
            style={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "16px",
              margin: 0,
              paddingBottom: data[0]?.has_text.data[0] === 1 && "12px",
            }}
          >
            {data[0]?.influence_id}
          </p>
        )}
        {data[0]?.has_text.data[0] === 1 && (
          <p
            dangerouslySetInnerHTML={{ __html: review }}
            style={{
              fontSize: "13px",
              fontWeight: 400,
              lineHeight: "21px",
              margin: 0,
            }}
          />
        )}
      </>
    );
  };

  // 전체 리뷰
  const AllReviews = allData?.map((review) => {
    return (
      <ReviewContainer
        key={review.id}
        onClick={() => history.push(`/influencerReview/${review.id}`)}
      >
        <img
          src={review.img_url}
          alt="thumb"
          width="100%"
          height="100%"
          style={{
            objectFit: "cover",
            overflow: "hidden",
          }}
        />
      </ReviewContainer>
    );
  });

  return (
    <>
      {spinner ? (
        <div
          style={{
            height: "calc(100vh - 153px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner
            animation="border"
            style={{
              color: "var(--color-poola-blue)",
              borderRightColor: "transparent",
            }}
          />
        </div>
      ) : (
        <PageContainer>
          <Header type={"BACK-HOME-REVIEW"} history={history} />
          <SlideReviewList params={reviewId} img={img} influence />
          <Section border="8px">
            <Section padding="20px 16px 16px 16px" border="1px">
              {Review(data)}
            </Section>
            {data[0]?.is_poola_product.data[0] === 1 || data[0]?.brand_name ? (
              <Section padding="16px">{Product()}</Section>
            ) : null}
          </Section>
          <Section padding="20px 0">
            <TitleCard text="지금 인기있는 속옷" />
            <div
              style={{
                position: "relative",
                display: "grid",
                padding: "10px 16px",
                gridTemplateColumns: "repeat(3,1fr)",
                gridGap: "2px 2px",
                width: "100%",
              }}
            >
              {AllReviews}
            </div>
          </Section>
        </PageContainer>
      )}
    </>
  );
};
