import React from "react";
import styled, { css } from "styled-components";
import check from "../../assets/icons/check-white.svg";

const STYLE = {
  // height, line-height
  LARGE: [100, 20],
  MEDIUM: [48, 22],
  SMALL: [48, 22],
};

const Button = styled.button`
  width: 100%;
  height: ${(props) => STYLE[props.size][0]}px;
  margin: auto;
  padding: ${(props) =>
    props.size === "SMALL" || props.size === "XSMALL" ? 0 : "0 20px 0 24px"};
  display: flex;
  justify-content: ${(props) =>
    (props.size === "MEDIUM" && props.hasImg && "start") || "center"};
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  color: var(--color-poola-blue);
  background-color: var(--color-white);
  border: 1px solid
    var(--color-${(props) => (props.selected ? "poola-blue" : "disabled-gray")});
  border-radius: 8px;

  ${(props) =>
    props.size === "LARGE" &&
    props.hasImg &&
    css`
      justify-content: space-between;
    `}

  ${(props) =>
    props.size === "MEDIUM" &&
    css`
      height: 48px;
      line-height: 22px;
      flex-direction: column;

      ${props.isMultiLine &&
      css`
        height: 72px;
      `}

      ${props.hasImg &&
      css`
        width: 104px;
        height: 145px;
        padding: 0;
        line-height: 14px;
        border: none;
      `}
    `}
`;

const ImageOverlay = styled.div`
  width: 104px;
  height: 104px;
  position: absolute;
  background: url(${check}) no-repeat center var(--color-poola-blue-op50);
  border-radius: 50%;
`;

const Image = styled.img`
  max-width: 100%;
  object-fit: contain;

  ${(props) =>
    props.size === "LARGE" &&
    css`
      height: 100%;
    `}

  ${(props) =>
    props.size === "MEDIUM" &&
    css`
      margin-bottom: 10px;
    `}
`;

const AnswerText = styled.em`
  text-align: ${(props) => (props.hasImg ? "left" : "center")};
  word-break: keep-all;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: ${(props) => STYLE[props.size][1]}px;

  ${(props) =>
    props.size === "LARGE" &&
    css`
      width: 40%;
    `}

  ${(props) =>
    props.size === "MEDIUM" &&
    css`
      line-height: ${(props) => (props.hasImg ? 14 : 22)}px;
    `}
`;

const AnswerSubText = styled.span`
  ${(props) =>
    props.size === "MEDIUM" &&
    css`
      ${props.hasImg
        ? css`
            margin-bottom: 6px;
            font-size: 11px;
            line-height: 11px;
            width: 180px;
          `
        : css`
            font-size: 14px;
            line-height: 22px;
          `}
    `}
`;

const TipText = styled.div`
  line-height: 19px;
  font-size: 13px;
  width: 85%;
  margin: 8px auto 8px;
  text-align: center;
  color: var(--color-poola-blue);
`;

export const AnswerCard = ({ data, size, index, selected, _onClick }) => {
  return (
    <>
      <Button
        value={
          size === "SMALL" || (data.img && size === "MEDIUM") ? data.a : index
        }
        size={size}
        onClick={_onClick}
        selected={selected}
        isMultiLine={data.sub}
        hasImg={data.img}
      >
        {data.img && size === "MEDIUM" && selected && <ImageOverlay />}
        {data.img && (
          <Image
            src={require(`../../assets/images/curation/${data.img}`)}
            alt=""
            size={size}
          />
        )}
        {data.sub && (
          <AnswerSubText size={size} hasImg={data.img}>
            {data.sub}
          </AnswerSubText>
        )}
        <AnswerText size={size} hasAnswerTip={data.tip} hasImg={data.img}>
          {data.a}
        </AnswerText>
      </Button>
      {data.tip && <TipText>{data.tip}</TipText>}
    </>
  );
};
