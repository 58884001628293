import React, {useEffect, useState} from "react";
import styled from "styled-components";

const Modal = styled.div`
  width: 100%;
  max-width: 500px;
  height: 100%;
`;

const Dialog = styled.dialog`
  width: 100%;
  max-width: ${props => props.width - 48}px;
  padding: 24px 17px;
  margin: 0;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  background-color: var(--color-white);
  border-radius: 16px;
  border: none;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  z-index: 10;
  background-color: var(--color-black);
  opacity: 0.5;
`;

const HeadingText = styled.h2`
  margin-bottom: 12px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-black);
`;

const Text = styled.p`
  margin-bottom: 32px;
  text-align: center;
  font-size: 13px;
  line-height: 19px;
  color: var(--color-secondary-black);
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  width: 48%;
  max-width: 250px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-${props=> props.apply && 'white' || 'poola-blue'});
  background: var(--color-${props => props.apply && 'poola-blue' || 'white'});
  border: 1px solid var(--color-poola-blue);
  border-radius: 4px;
`;

export const ReviewDialog = ({text, visible, _onClose, _onClick}) => {
  const [width, setWidth] = useState(window.innerWidth > 500 ? 500 : window.innerWidth); // dialog width

  useEffect(() => {
    window.addEventListener('resize', function() {
      setWidth(window.innerWidth > 500 ? 500 : window.innerWidth)
    });
  },[])

  useEffect(() => { // 모달 배경 스크롤 방지
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`
    return () => {
      const scrollY = document.body.style.top
      document.body.style.cssText = "position:''; top:'';"
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      _onClose();
    }
  }

  return (
    <Modal>
      <ModalOverlay visible={visible} onClick={handleOverlayClick} />
      <Dialog open width={width}>
        <HeadingText>후기를 {text}하겠습니까?</HeadingText>
        <Text>작성한 후기를 {text}하겠습니다.</Text>
        <ButtonWrapper>
          <Button onClick={_onClose}>취소</Button>
          <Button apply onClick={_onClick}>{text}</Button>
        </ButtonWrapper>
      </Dialog>
    </Modal>
  )
};
