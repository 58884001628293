import React from "react";
import styled, { css } from "styled-components";
import { ProductOrderCard } from "../Card/ProductOrderCard";
import { formatPrice } from "../../modules/util";

const PRICE = {
  // font-size, line-height, font-color,
  SHIPPING: [14, 23, "tertiary-black"],
  TOTAL: [18, 26, "poola-blue"],
};
const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const BrandListItem = styled.li`
  padding: 20px 16px;
  border-bottom: 8px solid var(--color-bg-gray);
`;

const TotalPriceContainer = styled.div`
  padding: 0 0 0 32px;
`;

const PriceContainer = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-disabled-gray);

  &:first-child {
    margin-bottom: 10px;
  }

  div {
    display: flex;
    align-items: center;
  }
`;

const TitleText = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-secondary-black);
`;

const FreeInfoText = styled.span`
  margin-left: 6px;
  font-size: 12px;
  color: var(--color-poola-blue);
`;

const PriceText = styled.em`
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => PRICE[props.type][0]}px;
  line-height: ${(props) => PRICE[props.type][1]}px;
  color: var(--color-${(props) => PRICE[props.type][2]});
`;

const CheckButton = styled.button`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  padding: 0;
  background-color: var(--color-white);
  border: none;
`;

const ProductListItem = styled.li`
  padding: 16px 0 20px;
  border-bottom: 1px solid var(--color-disabled-gray);

  ${(props) =>
    props.type === "CART" &&
    css`
      &:last-of-type {
        border-bottom: none;
      }
    `}
`;

const BrandText = styled.h3`
  margin-bottom: ${(props) => props.mb || 0}px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-secondary-black);

  ${(props) =>
    props.as === "h2" &&
    css`
      font-size: 16px;
      line-height: 19px;
    `}
`;

const ShippingWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-secondary-black);
`;

const ShippingFeeText = styled.em`
  font-style: normal;
  font-weight: bold;
  color: var(--color-black);
`;

const InfoText = styled.span`
  margin-left: 4px;
  font-size: 11px;
  line-height: 11px;
  color: var(--color-tertiary-black);
`;

export const ProductOrderList = ({
  data,
  type,
  selectedProducts,
  setSelectedProducts,
  _onQuantityClick,
  _onDeleteClick,
}) => {
  function handleProductClick(product) {
    if (selectedProducts.some((sp) => product.itemId === sp)) {
      setSelectedProducts(selectedProducts.filter((p) => product.itemId !== p));
    } else {
      setSelectedProducts([...selectedProducts, product.itemId]);
    }
  }

  const productListByBrand = data.map((brand) => {
    // 브랜드 별 제품 목록
    const product =
      type === "CART" &&
      brand.products
        .filter((product) =>
          selectedProducts.some((sp) => sp === product.itemId)
        )
        .reduce(
          (acc, cur) =>
            acc +
            cur.optionalBundles
              .filter((ob) => ob.available)
              .reduce((a, c) => a + c.price.sale * c.quantity, 0) +
            cur.price.sale * cur.quantity,
          0
        );
    const shipping =
      type === "CART" &&
      (brand.products.filter((p) =>
        selectedProducts.some((sp) => sp === p.itemId)
      ).length > 0
        ? brand.freeShipping === 0
          ? brand.shippingFee
          : product > brand.freeShipping
          ? 0
          : brand.shippingFee
        : 0);

    // 제품 목록
    const products =
      brand.products &&
      brand.products.length > 0 &&
      brand.products.map((product) => {
        const isSelected =
          type === "CART" &&
          selectedProducts.some((sp) => sp === product.itemId);

        return (
          <ProductListItem key={product.variantId} type={type}>
            <div style={{ display: "flex" }}>
              {type === "CART" && (
                <CheckButton
                  onClick={() => handleProductClick(product)}
                  disabled={!product.available}
                >
                  <img
                    src={require(`../../assets/icons/check-${
                      product.available
                        ? isSelected
                          ? "clicked.svg"
                          : "default.png"
                        : "disabled.svg"
                    }`)}
                    alt=""
                    width="24px"
                    height="24px"
                  />
                </CheckButton>
              )}
              <ProductOrderCard
                data={
                  type === "CART"
                    ? { ...product, isSelected: isSelected, isBundle: false }
                    : product
                }
                type="PRODUCT"
                isCart={type === "CART"}
                _onQuantityClick={_onQuantityClick}
                _onDeleteClick={_onDeleteClick}
              />
            </div>
            {product.optionalBundles &&
              product.optionalBundles.map((bundle) => (
                <ProductOrderCard
                  key={bundle.variantId}
                  data={{
                    ...bundle,
                    isSelected: isSelected,
                    isBundle: true,
                    parentId: product.itemId,
                  }}
                  type="BUNDLE"
                  isCart={type === "CART"}
                  _onQuantityClick={_onQuantityClick}
                  _onDeleteClick={_onDeleteClick}
                />
              ))}
          </ProductListItem>
        );
      });

    return (
      <BrandListItem key={brand.vendor?._id ?? brand.brand}>
        <BrandText
          as={type === "CART" ? "h2" : "h3"}
          mb={type === "CART" && 14}
        >
          {brand.vendor?.name ?? brand.brand}
        </BrandText>
        <List>{products}</List>
        {type === "CART" ? (
          <TotalPriceContainer>
            <PriceContainer>
              {brand.freeShipping > 0 && brand.freeShipping > product ? (
                <div>
                  <TitleText>배송비</TitleText>
                  <FreeInfoText>
                    {formatPrice(brand.freeShipping - product)}원 추가 구매 시
                    무료
                  </FreeInfoText>
                </div>
              ) : (
                <TitleText>배송비</TitleText>
              )}
              <PriceText type="SHIPPING">
                {brand.products.filter((p) =>
                  selectedProducts.some((sp) => sp === p.itemId)
                ).length > 0
                  ? brand.freeShipping === 0
                    ? brand.shippingFee === 0
                      ? "무료"
                      : formatPrice(brand.shippingFee) + "원"
                    : product >= brand.freeShipping
                    ? "무료"
                    : formatPrice(brand.shippingFee) + "원"
                  : "0원"}
              </PriceText>
            </PriceContainer>
            <PriceContainer>
              <TitleText>총 결제금액</TitleText>
              <PriceText type="TOTAL">
                {brand.freeShipping <= product
                  ? formatPrice(product)
                  : formatPrice(product + shipping)}
                원
              </PriceText>
            </PriceContainer>
          </TotalPriceContainer>
        ) : (
          <ShippingWrapper>
            {brand.isSpecialArea && brand.shippingFee > 0 ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>배송비</span>
                <InfoText>* 제주도, 도서 산간 지역 추가 비용</InfoText>
              </div>
            ) : (
              <span>배송비</span>
            )}
            <ShippingFeeText>
              {formatPrice(brand.shippingFee)}원
            </ShippingFeeText>
          </ShippingWrapper>
        )}
      </BrandListItem>
    );
  });

  return <List>{productListByBrand}</List>;
};
