import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { clickTrigger } from "../../modules/trigger";
import Clayful from "clayful/client-js";
import { getTranslateTypeForReview } from "../../modules/util";

const Card = styled.article`
	width: 100%;
	padding: 20px 0 0 0;
	background-color: var(--color-white);
`;

const InfoWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const RatingWrapper = styled.div`
	height: 16px;
	margin-bottom: 16px;
	line-height: 1;
`;

const Div = styled.div`
	margin-bottom: ${(props) => props.bottom || 0}px;
	display: ${(props) => props.display || "flex"};
	flex-direction: ${(props) => props.direction || "row"};
	justify-content: ${(props) => props.justify || "flex-start"};
`;

const ImageList = styled.ul`
	padding: 0 0 8px 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
`;

const ImageItem = styled.li`
	margin: 0 8px 8px 0;
`;

const Text = styled.strong`
	margin-bottom: 10px;
	display: block;
	font-weight: bold;
	font-size: 13px;
	line-height: 13px;
	color: var(--color-secondary-black);
`;

const Caption = styled.span`
	margin-bottom: ${(props) => props.bottom || 0}px;
	display: block;
	font-size: 11px;
	line-height: ${(props) => props.height || 0}px;
	color: var(--color-tertiary-black);

	${(props) =>
		props.isdate &&
		css`
			display: inline-block;
			text-align: end;
		`}
`;

const BodyText = styled.p`
	margin: 0 0 16px 0;
	font-size: 12px;
	line-height: 20px;
	color: var(--color-secondary-black);
	word-break: break-all;
`;

const Button = styled.button`
	width: 37px;
	height: 24px;
	padding: 0;
	margin-left: 6px;
	font-weight: 400;
	font-size: 11px;
	color: var(--color-secondary-black);
	background-color: var(--color-bg-gray);
	border: none;
	border-radius: 4px;
`;

const HelpedButton = styled.button`
	padding: 0;
	margin-bottom: 16px;
	display: inline-flex;
	align-items: center;
	font-weight: 400;
	font-size: 11px;
	line-height: 11px;
	color: var(--color-tertiary-black);
	background-color: var(--color-white);
	border: none;
`;

export const ReviewCard = ({ data, type, userId, setDelReview, setModalVisible, history }) => {
	const intRate = Math.ceil(data.rate); // 정수
	const floatRate = Math.ceil(data.rate * 10) / 10; // 소수점 아래 한자리

	const handleUpdateClick = () => {
		// 리뷰 수정
		history.push({
			pathname: "/my/review/write/" + data._id,
			state: { review: data },
		});
		clickTrigger("후기수정_이동", {
			productInfo: {
				name: data.product_name,
				brand: data.brand_name,
				option: data.option_name && data.option_name.사이즈 + " / " + data.option_name.색상,
			},
		});
	};

	const handleDeleteClick = () => {
		// 리뷰 삭제
		setDelReview(data);
		setModalVisible(true);
	};

	const Images =
		data.img &&
		data.img.map((img, i) => {
			return (
				<ImageItem key={i}>
					<img
						src={img.url}
						alt=""
						height="65px"
						onClick={() => window.open(img.url)}
						style={{ borderRadius: "6px", cursor: "pointer" }}
					/>
				</ImageItem>
			);
		});

	// 옵션 values 그룹핑
	const options = data.option_name && Object.values(data.option_name).filter((value) => value !== "");
	const optionsResult = data.option_name && options.join(" / ");

	return (
		<Card>
			<InfoWrapper>
				<div>
					{type === "MY" ? (
						<Link
							to={`/product/${data.clayful_productId}/true`}
							onClick={() =>
								clickTrigger("제품", {
									productInfo: {
										name: data.product_name,
										brand: data.brand_name,
										option: data.option_name && optionsResult,
									},
								})
							}
						>
							<Text>{type === "MY" && data.brand_name}</Text>
							<Caption bottom={8} height={16}>
								{type === "MY" && data.product_name + " / " + optionsResult}
							</Caption>
						</Link>
					) : (
						<>
							<Text>
								{type === "DEFAULT" &&
									data.user_size.size + (data.user_size.special && " / " + data.user_size.special)}
								{type === "POOLA" && data.userId}
								{type === "OTHERS" && data.userId}
							</Text>
							<Text>
								{type === "DEFAULT" &&
									data.user_size.type &&
									getTranslateTypeForReview(data.user_size.type)}
							</Text>
							<Caption bottom={8} height={16}>
								{type === "DEFAULT" && data.option_name && optionsResult !== ""
									? data.product_name + " / " + optionsResult + " 구매"
									: type === "DEFAULT" && data.option_name && optionsResult === ""
									? data.product_name + " 구매"
									: null}
								{type === "POOLA" && data.option_name && optionsResult !== ""
									? data.product_name + " / " + optionsResult + " 구매"
									: type === "POOLA" && data.option_name && optionsResult === ""
									? data.product_name + " 구매"
									: null}
								{type === "OTHERS" && data.option && data.option !== ""
									? data.product_name + " / " + data.option + " 구매"
									: type === "OTHERS" && data.option && data.option === ""
									? data.product_name + " 구매"
									: null}
							</Caption>
						</>
					)}
					<RatingWrapper>
						{[...Array(5)].map((r, i) => {
							if (i > intRate && i !== 4)
								return (
									<img
										src={require("../../assets/icons/star-default.svg")}
										alt=""
										width="16px"
										height="16px"
										style={{ marginRight: "4px" }}
										key={i}
									/>
								);
							else if (i > intRate && i === 4)
								return (
									<img
										src={require("../../assets/icons/star-default.svg")}
										alt=""
										width="16px"
										height="16px"
										key={i}
									/>
								);
							else if (i < intRate && i !== 4)
								return (
									<img
										src={require("../../assets/icons/star-filled.svg")}
										alt=""
										width="16px"
										height="16px"
										style={{ marginRight: "4px" }}
										key={i}
									/>
								);
							else if (i < intRate && i === 4)
								return (
									<img
										src={require("../../assets/icons/star-filled.svg")}
										alt=""
										width="16px"
										height="16px"
										key={i}
									/>
								);
							else if (i === intRate && i !== 4) {
								if ((floatRate - intRate).toFixed(2) >= 0.5)
									return (
										<img
											src={require("../../assets/icons/star-half.svg")}
											alt=""
											width="16px"
											height="16px"
											style={{ marginRight: "4px" }}
											key={i}
										/>
									);
								else
									return (
										<img
											src={require("../../assets/icons/star-default.svg")}
											alt=""
											width="16px"
											height="16px"
											style={{ marginRight: "4px" }}
											key={i}
										/>
									);
							} else {
								if ((floatRate - intRate).toFixed(2) >= 0.5)
									return (
										<img
											src={require("../../assets/icons/star-half.svg")}
											alt=""
											width="16px"
											height="16px"
											key={i}
										/>
									);
								else
									return (
										<img
											src={require("../../assets/icons/star-default.svg")}
											alt=""
											width="16px"
											height="16px"
											key={i}
										/>
									);
							}
						})}
					</RatingWrapper>
				</div>
				{type === "MY" || (type === "DEFAULT" && userId === data.userId) ? (
					<Div direction="column">
						<Div bottom={20} display="inline-flex" justify="flex-end">
							<Button onClick={handleUpdateClick}>수정</Button>
						</Div>
						<Caption height={11} isdate>
							{data.createdAt?.slice(0, 10).replaceAll("-", ".")}
						</Caption>
					</Div>
				) : (
					<Div direction="column" justify="end">
						<Caption bottom={18} height={11} isdate>
							{data.createdAt?.slice(0, 10).replaceAll("-", ".")}
						</Caption>
					</Div>
				)}
			</InfoWrapper>
			{data.body && <BodyText>{data.body}</BodyText>}
			{type !== "OTHERS" && data.img && data.img.length > 0 && <ImageList>{Images}</ImageList>}
		</Card>
	);
};

ReviewCard.defaultProps = {
	type: "DEFAULT", // DEFAULT(제품 상세 - 기본), OTHERS(제품 상세 - 크롤링), MY(후기 목록)
	data: {
		productNo: "urinnerf_46",
		brand: "유아이너프",
		rate: 0,
		id: "abcd****",
		date: "21.11.11",
		option: "사이즈: 80A/ 색상: 블랙",
		review:
			"화면이랑 컬러도 동일하고, 착용감도 편해요. 예쁜 제품을 저렴하게 구매해서 만족스럽습니다. 저랑 비슷한 사이즈 여성분 참고하세요.",
		img: ["", ""],
		// likes: 123,
	},
};
