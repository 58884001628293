import React from "react";
import styled, { css } from "styled-components";
import { CouponText } from "../../assets/data/jsons/ClayfulData";
import ArrowRightIcon from "../../assets/icons/arrow/right-secondary-black.svg";

const STYLE = {
  // font-size, line-height, font-weight, color
  TITLE: ["20px", "29px", "bold", "var(--color-black)"],
  "TITLE-DISABLED": ["20px", "29px", "bold", "var(--color-tertiary-black)"],
  CONTENT: ["13px", "19px", "400", "var(--color-black)"],
  "CONTENT-DISABLED": ["13px", "19px", "400", "var(--color-tertiary-black)"],
  INFO: ["11px", "16px", "400", "var(--color-tertiary-black)"],
  "INFO-R": ["11px", "16px", "400", "var(--color-negative-red)"],
};

const Card = styled.div`
  width: 100%;
  display: inline-block;
  padding: 16px;
  background-color: var(--color-white);
  border-radius: 8px;
  border: 2px solid
    var(--color-${(props) => (props.selected ? "poola-blue" : "white")});

  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--color-disabled-gray);
      border: 2px solid var(--color-disabled-gray);
    `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: ${(props) => props.direction || "row"};
  padding-bottom: ${(props) => props.bottom || 0}px;
`;

const Title = styled.h2`
  text-align: left;
  font-size: ${(props) => STYLE[props.type][0]};
  line-height: ${(props) => STYLE[props.type][1]};
  font-weight: ${(props) => STYLE[props.type][2]};
  color: ${(props) => STYLE[props.type][3]};
  margin-bottom: 0;
`;

const Text = styled.p`
  font-size: ${(props) => STYLE[props.type][0]};
  line-height: ${(props) => STYLE[props.type][1]};
  font-weight: ${(props) => STYLE[props.type][2]};
  color: ${(props) => STYLE[props.type][3]};
  margin-bottom: ${(props) => props.bottom || 0}px;
`;

const Button = styled.button`
  width: ${(props) => (props.type === "DELETE" && 37) || 24}px;
  height: 24px;
  padding: 0;
  font-size: 11px;
  color: var(--color-secondary-black);
  background-color: ${(props) =>
    (props.type === "DELETE" && "var(--color-bg-gray)") || "initial"};
  border: none;
  border-radius: 4px;
`;

const Description = styled.div`
  margin-top: 10px;
  border-top: 1px solid var(--color-disabled-gray);

  p {
    margin: 0;
    padding-top: 10px;
    line-height: 1;
  }

  a {
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-secondary-black);

    &::after {
      margin-left: 2px;
      display: inline-block;
      height: 20px;
      width: 20px;
      content: "";
      background: url(${ArrowRightIcon}) no-repeat 0 0;
    }
  }
`;

export const CouponCard = ({ data, type, selected, _onClick }) => {
  const categories =
    data.category.type &&
    data.category.type !== "any" &&
    (
      data.category.products ||
      data.category.brands ||
      data.category.collections ||
      data.category.shippingPolicies
    )
      .map((m) => m.name)
      .join(", ");

  const substringCouponName = data.name.includes("리뷰 Lv")
    ? data.name.substring(data.name.indexOf("리뷰"))
    : data.name;

  return (
    <Card disabled={type === "DISABLED" || type === "DELETE"}>
      <Wrapper bottom={10}>
        <Title
          type={
            ((type === "DISABLED" || type === "DELETE") && "TITLE-DISABLED") ||
            "TITLE"
          }
        >
          {substringCouponName}
        </Title>
        {type === "SELECT" && (
          <Button onClick={() => _onClick(data)}>
            <img
              src={require(`../../assets/icons/check-${
                selected ? "clicked.svg" : "default.png"
              }`)}
              alt=""
              width="24px"
              height="24px"
            />
          </Button>
        )}
        {type === "DISABLED" && (
          <Button onClick={_onClick}>
            <img
              src={require("../../assets/icons/check-disabled.png")}
              alt=""
              width="24px"
              height="24px"
            />
          </Button>
        )}
        {type === "DOWNLOAD" && (
          <Button onClick={() => _onClick(data)}>
            <img
              src={require("../../assets/icons/download-default.svg")}
              alt=""
              width="24px"
              height="24px"
            />
          </Button>
        )}
        {type === "DELETE" && (
          <Button type={type} onClick={() => _onClick(data)}>
            삭제
          </Button>
        )}
      </Wrapper>
      <Wrapper direction="column">
        <Text
          type={
            ((type === "DISABLED" || type === "DELETE") &&
              "CONTENT-DISABLED") ||
            "CONTENT"
          }
          bottom={2}
        >
          {CouponText[data.type + "Type"]} - {data.discount.value.converted}{" "}
          할인
        </Text>
        <Text type="INFO">
          {data.only ? CouponText["usedOnly"] : CouponText["usedTogether"]}
        </Text>
        {(data.discount.min || data.discount.max) && (
          <Text type="INFO">
            {data.discount.min && `최소 ${data.discount.min.converted}`}
            {data.discount.min && data.discount.max && ", "}
            {data.discount.max && `최대 ${data.discount.max.converted}`} 할인
          </Text>
        )}
        {(data.price.min || data.price.max) && (
          <Text type="INFO">
            {data.type === "product" && CouponText["applicableProductPrice"]}
            {data.type === "cart" && CouponText["applicableOrderTotal"]}
            {data.type === "shipping" && CouponText["applicableShipmentFee"]}
            :&nbsp;
            {data.price.min ? data.price.min.converted + " 이상" : ""}
            {data.price.min && data.price.max && ", "}
            {data.price.max ? data.price.max.converted + " 이하 " : ""}
          </Text>
        )}
        {data.category.type && data.category.type !== "any" && (
          <Text
            type="INFO"
            data-balloon-length="fit"
            data-balloon-pos="up"
            data-balloon={
              data.category.type === "include"
                ? categories + CouponText["canOnlyUsedFor"]
                : categories + CouponText["cannotUsedFor"]
            }
          >
            {data.category.products && CouponText["onlyForCertainProducts"]}
            {data.category.brands && CouponText["onlyForCertainBrands"]}
            {data.category.collections &&
              CouponText["onlyForCertainCollections"]}
            {data.category.shippingPolicies &&
              CouponText["onlyForShippingPolicies"]}
            <span>ℹ</span>
          </Text>
        )}
        {data.expiresAt ? (
          data.expiresAt.ago.slice(-1) === "전" ? (
            <Text type="INFO-R">
              {data.expiresAt.raw.slice(0, 10).replaceAll("-", ".")} 만료(사용
              불가)
            </Text>
          ) : (
            <Text type="INFO">
              {data.expiresAt.raw.slice(0, 10).replaceAll("-", ".")} 만료
            </Text>
          )
        ) : (
          <Text type="INFO">{CouponText["withoutExpiration"]}</Text>
        )}
      </Wrapper>
      {type === "DEFAULT" && data.description && (
        <Description dangerouslySetInnerHTML={{ __html: data.description }} />
      )}
    </Card>
  );
};

CouponCard.defaultProps = {
  data: {
    _id: "CEKHXAA84KAF",
    name: "테스트 쿠폰",
    description: "",
    discount: {
      min: null,
      max: null,
      type: "percentage",
      value: {
        raw: 10,
        formatted: "10%",
        converted: "10%",
      },
    },
    category: {
      type: "any",
    },
    price: {
      min: {
        raw: 0,
        convertedRaw: 0,
        formatted: "0원",
        converted: "0원",
      },
      max: null,
    },
    active: true,
    only: false,
    expiresAt: null,
    type: "cart",
    slug: "CEKHXAA84KAF",
  },
  type: "DEFAULT", // DEFAULT, SELECT, DISABLED, DOWNLOAD, DELETE
};
