import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {clickTrigger} from "../../modules/trigger";

const BottomNavContainer = styled.nav`
  width: 100%;
  max-width: 500px;
  padding: 0 0 env(safe-area-inset-bottom); /* iOS 11.2 이상 */
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 10;
  background: var(--color-white);
  box-shadow: inset 0 0.5px 0 rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(27.1828px);
`;

const Nav = styled(Link)`
  width: 20%;
  padding: 7px 0 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--color-tertiary-black);
  background: none;
  border: none;
`;

const Text = styled.span`
  padding-top: 4px;
  color: var(--color-${props => props.clicked ? 'poola-blue' : 'tertiary-black'});
`;

export const BottomNavigator = ({type}) => {

  const navigators = [['home', '스토어'], ['article', '아티클'], ['category', '카테고리'], ['brand', '브랜드'], ['my', '나의']];

  return (
    <BottomNavContainer>
      {navigators.map(nav=> {
        return (
          <Nav key={nav[0]} to={nav[0] === 'home' ? '/' : '/' + nav[0]} onClick={() => clickTrigger(nav[1] + '_이동')}>
            {nav[0] === type.toLowerCase() ?
              <img src={require(`../../assets/icons/bottom-navigator/${nav[0]}-clicked.svg`)} alt="" width="24px" height="24px" />
              :
              <img src={require(`../../assets/icons/bottom-navigator/${nav[0]}-default.svg`)} alt="" width="24px" height="24px" />
            }
            <Text clicked={nav[0] === type.toLowerCase()}>{nav[1]}</Text>
          </Nav>
        )
      })}
    </BottomNavContainer>
  )
}

BottomNavigator.defaultProps = {
  type: 'DEFAULT'
}
