import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

const RedirectOrderPage = () => {
  let location = window.location;
  let {orderId} = useParams();

  useEffect(() => {
    let imp_success = new URLSearchParams(location.search).get("imp_success")
    if(imp_success && imp_success === 'true') {
      window.location.href = '/order/success/'+orderId;
    } else {
      window.location.href = 'order/detail/'+orderId;
    }
  }, [orderId]);

  return (
    <>
    </>
  )
}
export default RedirectOrderPage
