import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { clickTrigger } from "../../modules/trigger";

const HEADING = {
	// font-size, line-height, padding-left, margin-bottom
	DEFAULT: [16, 20, 16, 12],
	SINGLE: [16, 16, 0, 0],
	MULTI: [18, 22, 0, 0],
};

const Card = styled(Link)`
	padding: 0 12px 12px 16px;
	display: flex;
	align-items: flex-start;
`;

const TypeText = styled.em`
	width: 44px;
	height: 44px;
	padding: 12px 0 8px 0;
	display: inline-block;
	text-align: center;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 24px;
	color: var(--color-white);
	background: var(--color-poola-blue);
	border-radius: 50%;
`;

const SubText = styled.span`
	margin: 0;
	color: var(--color-secondary-black);
	line-height: 16px;
	font-size: 13px;
	font-weight: 400;
	padding-bottom: 4px;
`;

const Wrapper = styled.div`
	margin: 0;
	display: flex;
	align-items: ${(props) => props.align};
	justify-content: space-between;
`;

const HeadingText = styled.h2`
	padding-left: ${(props) => HEADING[props.type][2]}px;
	margin-bottom: ${(props) => HEADING[props.type][3]}px;
	font-weight: bold;
	font-size: ${(props) => HEADING[props.type][0]}px;
	line-height: ${(props) => HEADING[props.type][1]}px;

	strong {
		color: var(--color-poola-orange);
	}
`;

const LinkText = styled.span`
	margin-bottom: 0;
	display: inline-flex;
	font-size: 14px;
	line-height: 20px;
	color: var(--color-tertiary-black);
	font-weight: 500;
`;

export const TitleCard = ({
	text,
	subText,
	linkTo,
	userType,
	isTextArrow,
	event,
	ga4Event,
	em,
	noArrow,
	newline,
	userName,
}) => {
	return linkTo ? (
		<Card
			to={linkTo}
			onClick={() => {
				if (ga4Event) {
					ga4Event();
				}
				clickTrigger(event);
			}}
		>
			{userType && (
				<div style={{ marginRight: "10px" }}>
					<TypeText>{userType}</TypeText>
				</div>
			)}
			<div style={{ width: "100%", display: "inline-grid" }}>
				{subText ? (
					<>
						<SubText>{subText}</SubText>
						<Wrapper align="flex-start">
							<HeadingText type="MULTI">{text}</HeadingText>
							{!noArrow && (
								<img
									src={require("../../assets/icons/arrow/right-tertiary-black.svg")}
									alt="이동"
									width="20px"
									height="20px"
								/>
							)}
						</Wrapper>
					</>
				) : (
					<Wrapper align="center">
						{em ? (
							<HeadingText
								type="SINGLE"
								dangerouslySetInnerHTML={{ __html: text }}
							/>
						) : (
							<HeadingText type="SINGLE">{text}</HeadingText>
						)}
						<LinkText
							onClick={() => {
								if (ga4Event) {
									ga4Event();
								}
							}}
						>
							{isTextArrow && "전체보기"}
							<img
								src={require("../../assets/icons/arrow/right-tertiary-black.svg")}
								alt="이동"
								width="20px"
								height="20px"
							/>
						</LinkText>
					</Wrapper>
				)}
			</div>
		</Card>
	) : newline ? (
		<HeadingText type="DEFAULT">
			{text}
			<br />
			{subText}
		</HeadingText>
	) : userName ? (
		<HeadingText type="DEFAULT">
			{userName}
			{text}
		</HeadingText>
	) : (
		<HeadingText type="DEFAULT">{text}</HeadingText>
	);
};
