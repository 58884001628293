const OrderRequests = [
  '배송 메모를 선택해주세요',
  '배송 전에 미리 연락주세요',
  '부재 시 문 앞에 놔주세요',
  '부재 시 경비실에 맡겨주세요',
  '부재 시 핸드폰으로 연락주세요',
  '직접 입력'
];

const DownloadableCoupons = [ // 다운로드 가능한 쿠폰 목록 (ID)
  'WE9NH5WZQGFU', // 첫 구매 할인 3,000원
];

const CouponText = {
  coupons:                   '쿠폰 목록',
  hasNoCoupons:              '소유 쿠폰이 하나도 없습니다.',
  productType:               '한 상품 적용',
  cartType:                  '카트 적용',
  shippingType:              '배송비 적용',
  usedOnly:                  '다른 할인이 없는 경우에만 사용 가능',
  usedTogether:              '다른 할인이 있어도 사용 가능',
  applicableProductPrice:    '적용 가능 상품가',
  applicableOrderTotal:      '적용 가능 주문 총액',
  applicableShipmentFee:     '적용 가능 배송 내역 배송비',
  onlyForCertainProducts:    '특정 상품에만 사용 가능',
  onlyForCertainBrands:      '특정 브랜드에만 사용 가능',
  onlyForCertainCollections: '특정 콜렉션에만 사용 가능',
  onlyForShippingPolicies:   '특정 배송정책에만 사용 가능',
  canOnlyUsedFor:            '에만 사용 가능한 쿠폰입니다.',
  cannotUsedFor:             ' 외에만 사용 가능한 쿠폰입니다.',
  withoutExpiration:         '만료일시 없음',
  inactiveCoupon:            '사용이 불가능한 쿠폰입니다.',
};

export {OrderRequests, DownloadableCoupons, CouponText}