import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getFilterSizes } from "../../modules/sizefilter";
import {
  getNoProducts,
  getFilteredProducts,
  getCollectionProducts,
} from "../../modules/product";
import { Header } from "../../components/Common/Header";
import { ScrollFilterList } from "../../components/List/ScrollFilterList";
import { ProductList } from "../../components/List/ProductList";
import { Helmet } from "react-helmet";
import { BrandCard } from "../../components/Card/BrandCard";
import { TestCard } from "../../components/Card/TestCard";
import { BottomNavigator } from "../../components/Common/BottomNavigator";
import { BrandCollectionData } from "../../assets/data/jsons/CollectionData";

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  padding: 0 0 max(49px, calc(env(safe-area-inset-bottom) + 49px));
  background-color: var(--color-white);
`;

const PageHeader = styled.header`
  position: relative;
`;

const TextWrapper = styled.div`
  position: absolute;
  left: 16px;
  bottom: 28px;
`;

const HeadingText = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--color-white);
`;

const BodyText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-white);
`;

const BrandSection = styled.section`
  margin: 21px 0 20px 0;
`;

const List = styled.ul`
  padding: 0 13px;
  margin: 0;
  list-style: none;
  overflow-x: scroll;
  white-space: nowrap;
`;

const ListItem = styled.li`
  margin-right: 10px;
  display: inline-block;
  &:last-child {
    margin-right: 0;
  }
`;

const FilterWrapper = styled.div`
  padding-top: 12px;
  border-top: 8px solid var(--color-bg-gray);
`;

const ProductSection = styled.section`
  padding: 16px 0 40px 0;
`;

const BrandCollectionPage = ({ history }) => {
  const { type } = useParams(); // designer, bodypositive
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  const [braProducts, setBraProducts] = useState([]); // 브라 제품
  const [otherProducts, setOtherProducts] = useState([]); // 브라 아닌 제품
  const [products, setProducts] = useState([]); // 필터링된 제품(사이즈 맞는 브라 + 모든 팬티)
  const [brands, setBrands] = useState([]); // 브랜드 정보
  const [brandFilter, setBrandFilter] = useState(""); // 선택된 브랜드 필터
  const [size, setSize] = useState([]); // 선택 가능한 사이즈
  const [allProducts, setAllProducts] = useState([]); // 모든 제품
  const [sizeFilter, setSizeFilter] = useState([]); // 선택된 사이즈 필터
  const [headerOpacity, setHeaderOpacity] = useState(false); // 스크롤 위치에 따라 헤더 버튼 색 변경
  const collectionData = BrandCollectionData.find(
    (data) => data.collection === type
  ); // 브랜드 컬렉션

  useEffect(() => {
    window.addEventListener("scroll", updateScrollPosition);
  }, []);

  useEffect(() => {
    // 브랜드 컬렉션 정보 불러오기
    getCollectionProducts(user?.email, collectionData.collection, true)
      .then((r) => {
        const braProducts = r.products.filter(
          (product) => product.isWire === 0 || product.isWire === 1
        );
        const otherProducts = r.products.filter(
          (product) => product.isWire !== 0 && product.isWire !== 1
        );

        setAllProducts(r.products);
        setProducts(braProducts.concat(otherProducts));
        setBraProducts(braProducts);
        setOtherProducts(otherProducts);
        setBrands(r.brands);
      })
      .catch(() => {
        console.log("컬렉션 정보를 가져오는 데에 실패했습니다.");
      });
  }, [user]);

  useEffect(() => {
    // 제품 로딩 후 브랜드 목록, 사이즈 필터 설정
    setSize(getFilterSizes(braProducts));
  }, [braProducts]);

  useEffect(() => {
    // 사이즈 필터 선택 시
    setProducts(
      getFilteredProducts(sizeFilter, braProducts).concat(otherProducts)
    );
  }, [sizeFilter]);

  function updateScrollPosition() {
    if (
      window.scrollY >
      (window.innerWidth > 500 ? 500 : window.innerWidth) - 20
    ) {
      setHeaderOpacity(true);
    } else {
      setHeaderOpacity(false);
    }
  }

  function handleClickButton(brand) {
    if (brand.name === brandFilter) {
      const braProducts = allProducts.filter(
        (product) => product.isWire === 0 || product.isWire === 1
      );
      const otherProducts = allProducts.filter(
        (product) => product.isWire !== 0 && product.isWire !== 1
      );

      setBraProducts(braProducts);
      setOtherProducts(otherProducts);
      setBrandFilter("");
      setSizeFilter([]); // 사이즈 필터 초기화
    } else {
      const braProducts = allProducts.filter(
        (product) =>
          product.brand === brand.name &&
          (product.isWire === 0 || product.isWire === 1)
      );
      const otherProducts = allProducts.filter(
        (product) =>
          product.brand === brand.name &&
          product.isWire !== 0 &&
          product.isWire !== 1
      );

      setBraProducts(braProducts);
      setOtherProducts(otherProducts);
      setBrandFilter(brand.name);
      setSizeFilter([]); // 사이즈 필터 초기화
    }
  }

  const Brands = brands.map((brand, index) => {
    return (
      <ListItem key={index}>
        <BrandCard
          data={brand}
          type="button"
          align="VERTICAL"
          margin={16}
          selected={brand.name === brandFilter}
          _onClick={() => handleClickButton(brand)}
        />
      </ListItem>
    );
  });

  return (
    <>
      <Helmet>
        <title>
          {type === "designer"
            ? "자체제작 디자이너 속옷 "
            : type === "bodypositive"
            ? "바디 포지티브 "
            : "추석맞이 네고 위크!"}
          | 체형별 속옷 플랫폼 풀라
        </title>
        <meta
          property="og:description"
          content="브라렛, 심리스, 푸쉬업, 스포츠 브라, 니플패치, 네모팬티까지! 다양한 라이프스타일을 위한 다양한 속옷"
        />
      </Helmet>
      {
        <>
          <Header type="BACK" history={history} opacity={headerOpacity} />
          <PageContainer>
            <PageHeader>
              <img
                src={require(`../../assets/images/collection/${
                  type !== "bodypositive"
                    ? collectionData.collection
                    : `collection_may_summer`
                }.png`)}
                alt={type}
                width="100%"
                height="100%"
              />

              <TextWrapper>
                {type === "bodypositive" ? (
                  <HeadingText>여름에 입기 좋은 언더&홈웨어</HeadingText>
                ) : (
                  <HeadingText
                    dangerouslySetInnerHTML={{ __html: collectionData.title }}
                  />
                )}
                <BodyText>
                  {type === "bodypositive"
                    ? "최대 73% OFF  |  05.25 - 05.31"
                    : collectionData.body}
                </BodyText>
              </TextWrapper>
            </PageHeader>
            <BrandSection>
              <List>{Brands}</List>
            </BrandSection>
            <FilterWrapper>
              <ScrollFilterList
                type="SIZE"
                tags={size}
                filter={sizeFilter}
                setFilter={setSizeFilter}
              />
              {(!user?.size || !user?.type) && <TestCard />}
            </FilterWrapper>
            <ProductSection>
              <ProductList data={products} align="VERTICAL" />
            </ProductSection>
          </PageContainer>
        </>
      }
      <BottomNavigator />
    </>
  );
};
export default BrandCollectionPage;
