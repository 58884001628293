import TagManager from "react-gtm-module";

export function clickTrigger(eventName, data) {
  const tagManagerArgs = {
    dataLayer: {
      clicked: eventName,
      ...data
    },
  };
  TagManager.dataLayer(tagManagerArgs);
}
