import { useEffect } from "react";

// type: 전환 유형 값
// value: 전환 값
export function useScript(type, value, targetElementId) {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "//wcs.naver.net/wcslog.js";
    script.type = "text/javascript";

    const nasaScript = document.createElement("script");

    nasaScript.innerHTML = `
      var _nasa={};
      if (window.wcs) _nasa["cnv"] = wcs.cnv('${type}', '${value}');
    `;

    const targetElement = document.getElementById(targetElementId);

    if (targetElement) {
      targetElement.parentNode.insertBefore(script, targetElement.nextSibling);
      targetElement.parentNode.insertBefore(nasaScript, script.nextSibling);
    } else {
      document.body.appendChild(script);
      document.body.appendChild(nasaScript);
    }

    return () => {
      if (targetElement) {
        targetElement.parentNode.removeChild(script);
        targetElement.parentNode.removeChild(nasaScript);
      } else {
        document.body.removeChild(script);
        document.body.removeChild(nasaScript);
      }
    };
  }, [type, value, targetElementId]);
}
