const ArticleCategoryData = [
  {
    category: "브라학개론",
    text: "당신이 처음 듣는 신박한 브라 이야기",
  },
  {
    category: "후기를 풀라",
    text: "슴슴이들의 실감나는 내돈내산 구매 후기",
  },
  {
    category: "인터뷰 풀라",
    text: "여성은 여성이 돕는다, 풀라가 만난 여성들",
  },
  {
    category: "비하인드 풀라",
    text: "풀라를 만드는 사람들의 목소리",
  },
];

export {ArticleCategoryData};
