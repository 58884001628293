import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Header } from "../../components/Common/Header";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { LoginAlert } from "../../components/Common/LoginAlert";
import Clayful from "clayful/client-js";
import { OrderDetailCard } from "../../components/Card/OrderDetailCard";
import { SnackBar } from "../../components/Common/SnackBar";
import Axios from "axios";

const STATUS = {
  placed: "결제 대기",
  paid: "결제 완료",
  cancelled: "고객 취소",
  shipped: "출고 완료",
  refunded: "환불 완료",
  accepted: "환불 승인",
  requested: "환불 요청",
  "partially-refunded": "부분 환불 완료",
  "under-refunded": "부분 환불 처리 중",
};

const OrderDetailPage = ({ history }) => {
  let { orderId } = useParams();
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보

  const [data, setData] = useState({});
  const [refundedItems, setRefundedItems] = useState(null);
  const [spinner, setSpinner] = useState(true);
  const [showSnackBar, setShowSnackBar] = useState(false); // 환불 및 주문 취소 스낵바
  const [snackbarText, setSnackbarText] = useState(""); // 스낵바 문구

  async function getOrder() {
    const Order = Clayful.Order;
    const options = { customer: sessionStorage.getItem("__tct__") };

    Order.getForMe(orderId, options, function (err, result) {
      if (err) console.log(err);
      if (result) {
        setData(result.data);
        setRefundedItems(result.data.refunds);
        setSpinner(false);
      }
    });
  }

  useEffect(() => {
    if (user.email) {
      getOrder();
    }
  }, []);

  const handleRefundButton = () => {
    history.push(`/order/refund/${orderId}`);
  };

  const extractRefundedReason = (refundItem) => {
    const reason = refundItem.reason;
    const regex = /\[([^\]]+)\]/;
    const match = regex.exec(reason);
    if (match) {
      const extractedText = match[1];
      return extractedText;
    } else {
      return reason;
    }
  };

  async function cacelledRefund(refundId) {
    const tct = sessionStorage.getItem("__tct__");

    const headers = {
      Accept: "application/json",
      "Accept-Encoding": "gzip",
      "Content-Type": "application/json",
      Authorization:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjZhMWYwZTMwYjQzZjhhYmIyMjE0MTQwZTk0ZWYyNDZhYzEyZTJjYjMyNTUzMGQ1ZmU2NGQ1ODNjYTVlYjhlMTEiLCJyb2xlIjoiY2xpZW50IiwiaWF0IjoxNjMxMjg1Njg4LCJzdG9yZSI6IkVDSkVBM0RUTlpKNy45OThEV0dLSEJOWFQiLCJzdWIiOiJMSEFIWVozN1dFMlgifQ.7RPEyRE6D-2jMoyw6Bx1O1z4bcagvkF_lxhB1aXtAJw",
      "Authorization-Customer": tct,
    };

    Axios.post(
      `https://api.clayful.io/v1/me/orders/${orderId}/refunds/${refundId}/cancellation`,
      null,
      {
        headers: headers,
      }
    ).then((res) => {
      setSnackbarText("환불 요청이 취소되었습니다.");
      setShowSnackBar(true);
      setTimeout(() => {
        setShowSnackBar(false);
      }, 6000);
    });
  }

  async function cancelledOrder() {
    const tct = sessionStorage.getItem("__tct__");
    const headers = {
      Accept: "application/json",
      "Accept-Encoding": "gzip",
      "Content-Type": "application/json",
      Authorization:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjZhMWYwZTMwYjQzZjhhYmIyMjE0MTQwZTk0ZWYyNDZhYzEyZTJjYjMyNTUzMGQ1ZmU2NGQ1ODNjYTVlYjhlMTEiLCJyb2xlIjoiY2xpZW50IiwiaWF0IjoxNjMxMjg1Njg4LCJzdG9yZSI6IkVDSkVBM0RUTlpKNy45OThEV0dLSEJOWFQiLCJzdWIiOiJMSEFIWVozN1dFMlgifQ.7RPEyRE6D-2jMoyw6Bx1O1z4bcagvkF_lxhB1aXtAJw",
      "Authorization-Customer": tct,
    };

    Axios.post(
      `https://api.clayful.io/v1/me/orders/${orderId}/cancellation`,
      null,
      {
        headers: headers,
      }
    ).then((res) => {
      setSnackbarText("주문이 취소되었습니다.");
      setShowSnackBar(true);
      setTimeout(() => {
        setShowSnackBar(false);
        window.location.reload();
      }, 3000);
    });
  }

  const vbanks =
    data &&
    data?.transactions
      ?.reduce((vbanks, transaction) => {
        return vbanks.concat(transaction.vbanks || []);
      }, [])
      .filter((vbank) => {
        const expiresAt = vbank?.expiresAt;

        // 만료 기간이 없으면 만료 되지 않은 것으로 간주
        if (!expiresAt) return true;

        return false;
      });

  const handleCancelOrder = () => {
    cancelledOrder();
  };

  return (
    <>
      <Header
        type="BACK"
        title="주문 내역"
        history={history}
        func="ORDER-DETAIL"
      />
      {user.email && !spinner ? (
        <PageContainer>
          <Section noBorder>
            <Table>
              <tbody>
                <tr>
                  <th>주문 번호</th>
                  <td>{data._id}</td>
                </tr>
                <tr>
                  <th>주문 날짜</th>
                  <td>{data?.transactions[0]?.createdAt?.formatted}</td>
                </tr>
                <tr>
                  <th>주문 상태</th>
                  <ColorTd>
                    {data?.fulfillments[0]?.status === "shipped"
                      ? STATUS[data?.status] +
                        ", " +
                        STATUS[data?.fulfillments[0]?.status]
                      : data?.status === "partially-refunded" ||
                        data?.status === "under-refunded" ||
                        data?.status === "paid"
                      ? STATUS[data?.status] + ", 배송 대기"
                      : STATUS[data?.status]}
                  </ColorTd>
                </tr>
                {vbanks.length !== 0 && (
                  <>
                    <tr>
                      <th>입금 은행</th>
                      <td>{vbanks[0]?.name}</td>
                    </tr>
                    <tr>
                      <th>입금 계좌</th>
                      <td>{vbanks[0]?.number}</td>
                    </tr>
                    <tr>
                      <th>결제 금액</th>
                      <ColorTd>{vbanks[0]?.amount?.formatted}</ColorTd>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </Section>
          <Section>
            <Title>주문상품</Title>
            {data.items.map((item) => (
              <OrderDetailCard data={item} key={item._id} history={history} />
            ))}
          </Section>
          <Section>
            <Title>결제 금액</Title>
            <TableBox>
              <Table>
                <tbody>
                  <tr>
                    <th>총 제품 금액</th>
                    <td>{data.total.price.original.formatted}</td>
                  </tr>
                  <tr>
                    <th>총 배송비</th>
                    <td>{data.total.shipping.fee.sale.formatted}</td>
                  </tr>
                  <tr>
                    <th>총 할인 금액</th>
                    <td>-{data.total.discounted.formatted}</td>
                  </tr>
                  <tr>
                    <th>총 결제 금액</th>
                    <TotalPrice>{data.total.price.sale.formatted}</TotalPrice>
                  </tr>
                </tbody>
              </Table>
            </TableBox>
          </Section>

          <Section>
            <Title>주문자</Title>
            <TableBox>
              <Table>
                <tbody>
                  <tr>
                    <th>이름</th>
                    <td>{data.customer.name.full}</td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td>{data.customer.email}</td>
                  </tr>
                  <tr>
                    <th>연락처</th>
                    <td>{data.customer.mobile}</td>
                  </tr>
                </tbody>
              </Table>
            </TableBox>
          </Section>

          <Section>
            <Title>배송지</Title>
            <TableBox>
              <Table>
                <tbody>
                  <tr>
                    <th>받는 사람</th>
                    <td>{data.address.shipping.name.full}</td>
                  </tr>
                  <tr>
                    <th>연락처</th>
                    <td>{data.address.shipping.mobile}</td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td>{data.address.shipping.address1}</td>
                  </tr>
                  <tr>
                    <th>상세 주소</th>
                    <td>{data.address.shipping.address2}</td>
                  </tr>
                  <tr>
                    <th>우편번호</th>
                    <td>{data.address.shipping.postcode}</td>
                  </tr>
                </tbody>
              </Table>
            </TableBox>
          </Section>

          {data.fulfillments.length !== 0 && (
            <Section>
              <Title>배송 내역</Title>
              <TableBox>
                <Table>
                  <tbody>
                    <tr>
                      <th>배송 품목</th>
                      <td>
                        {data.items.map((item, index) => (
                          <div key={index}>
                            <ShippedProductName>
                              {item.product.name} × {item.quantity.converted}
                            </ShippedProductName>
                          </div>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <th>택배사</th>
                      <td>
                        {data.fulfillments[0].tracking.company} (
                        {data.fulfillments[0].tracking.uid})
                      </td>
                    </tr>
                    <tr>
                      <th>배송 상태</th>
                      <ColorTd>{STATUS[data.fulfillments[0].status]}</ColorTd>
                    </tr>
                  </tbody>
                </Table>
              </TableBox>
            </Section>
          )}
          {data.status === "paid" ||
          data.status === "refunded" ||
          data.status === "partially-refunded" ||
          data.status === "under-refunded" ? (
            <Section>
              <Title>환불 요청</Title>
              {data.status !== "refunded" && (
                <RefundButton type="button" onClick={handleRefundButton}>
                  환불 요청
                </RefundButton>
              )}
              {refundedItems.length === 0 ? (
                <RefundText>환불 내역이 없습니다.</RefundText>
              ) : (
                refundedItems.map((refund, idx) => (
                  <TableBox key={idx}>
                    <Table>
                      <tbody>
                        <tr>
                          <th>
                            {refund.items.map((item, index) => (
                              <b key={index}>
                                {item.item.product?.name}
                                {index < refund.items.length - 1 && ", "}
                              </b>
                            ))}
                          </th>
                          <td>
                            {refund.status !== "accepted" &&
                              refund.status !== "cancelled" && (
                                <CancelRefundButton
                                  type="button"
                                  onClick={() => cacelledRefund(refund._id)}
                                >
                                  환불 취소
                                </CancelRefundButton>
                              )}
                          </td>
                        </tr>
                        <tr>
                          <th>환불 금액</th>
                          <td>{refund.total.price.withTax.formatted}</td>
                        </tr>
                        <tr>
                          <th>환불 사유</th>
                          <td>{extractRefundedReason(refund)}</td>
                        </tr>
                        <tr>
                          <th>환불 상태</th>
                          <ColorTd>{STATUS[refund.status]}</ColorTd>
                        </tr>
                      </tbody>
                    </Table>
                  </TableBox>
                ))
              )}
            </Section>
          ) : null}
          {showSnackBar && (
            <SnackBar
              type="ALERT"
              text={snackbarText}
              bottom={56}
              show={showSnackBar}
            />
          )}
          {vbanks.length !== 0 && data?.status === "placed" && (
            <CancelOrderButton type="button" onClick={handleCancelOrder}>
              주문 취소하기
            </CancelOrderButton>
          )}
        </PageContainer>
      ) : (
        <>
          <div
            style={{
              height: "calc(100vh - 40px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              animation="border"
              style={{
                color: "var(--color-poola-blue)",
                borderRightColor: "transparent",
              }}
            />
          </div>
          <LoginAlert isLogin={user.email} history={history} />
        </>
      )}
    </>
  );
};

export default OrderDetailPage;

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  padding-top: 40px;
  background-color: var(--color-white);
`;

const Section = styled.section`
  position: relative;
  padding: 20px 16px;
  border-top: ${({ noBorder }) =>
    noBorder ? "none" : "8px solid var(--color-bg-gray)"};
`;

const Title = styled.h4`
  color: var(--color-poola-blue);
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`;

const TableBox = styled.div`
  padding-top: 18px;
`;

const Table = styled.table`
  width: 100%;
  tbody {
    tr {
      th {
        padding: 4px 8px 4px 0;
        font-weight: normal;
        text-align: left;
        font-size: 14px;
        line-height: 22px;
        color: var(--color-tertiary-black);
        vertical-align: top;

        b {
          color: var(--color-black);
        }
      }

      td {
        padding: 4px 0;
        font-size: 14px;
        line-height: 22px;
        text-align: end;
      }
    }
  }
`;

const ColorTd = styled.td`
  padding: 4px 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  color: var(--color-poola-blue);
  text-align: end;
`;

const TotalPrice = styled.td`
  padding: 4px 0;
  font-size: 20px !important;
  font-weight: bold;
  color: var(--color-poola-blue);
`;

const ShippedProductName = styled.span``;

const RefundButton = styled.button`
  position: absolute;
  border: none;
  font-size: 14px;
  color: var(--color-tertiary-black);
  text-decoration: underline;
  background: none;
  right: 10px;
  top: 18px;
`;

const RefundText = styled.div`
  padding: 20px 0;
  font-size: 14px;
`;

const CancelRefundButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  color: var(--color-poola-blue);
  text-decoration: underline;
`;

const CancelOrderButton = styled.button`
  border: none;
  background-color: var(--color-bg-gray);
  width: 100%;
  height: 80px;
  color: var(--color-tertiary-black);
  font-size: 14px;
  text-decoration: underline;
`;
