const ResultText = [
  {
    index: 1,
    key: 111,
    name: "TSC",
  },
  {
    index: 2,
    key: 112,
    name: "TSW",
  },
  {
    index: 3,
    key: 113,
    name: "TSE",
  },
  {
    index: 4,
    key: 121,
    name: "TPC",
  },
  {
    index: 5,
    key: 122,
    name: "TPW",
  },
  {
    index: 6,
    key: 123,
    name: "TPE",
  },
  {
    index: 7,
    key: 131,
    name: "TEC",
  },
  {
    index: 8,
    key: 132,
    name: "TEW",
  },
  {
    index: 9,
    key: 133,
    name: "TEE",
  },
  {
    index: 10,
    key: 211,
    name: "BSC",
  },
  {
    index: 11,
    key: 212,
    name: "BSW",
  },
  {
    index: 12,
    key: 213,
    name: "BSE",
  },
  {
    index: 13,
    key: 221,
    name: "BPC",
  },
  {
    index: 14,
    key: 222,
    name: "BPW",
  },
  {
    index: 15,
    key: 223,
    name: "BPE",
  },
  {
    index: 16,
    key: 231,
    name: "BEC",
  },
  {
    index: 17,
    key: 232,
    name: "BEW",
  },
  {
    index: 18,
    key: 233,
    name: "BEE",
  },
  {
    index: 19,
    key: 311,
    name: "ESC",
  },
  {
    index: 20,
    key: 312,
    name: "ESW",
  },
  {
    index: 21,
    key: 313,
    name: "ESE",
  },
  {
    index: 22,
    key: 321,
    name: "EPC",
  },
  {
    index: 23,
    key: 322,
    name: "EPW",
  },
  {
    index: 24,
    key: 323,
    name: "EPE",
  },
  {
    index: 25,
    key: 331,
    name: "EEC",
  },
  {
    index: 26,
    key: 332,
    name: "EEW",
  },
  {
    index: 27,
    key: 333,
    name: "EEE",
  },
];

const FitQuestion = [
  // 착용감 기반 테스트 질문
  {
    // 1번 질문
    key: 1,
    multiple: false,
    question: "평소 입는 브라 사이즈를 알려주세요",
    answers1: [
      // 컵
      { a: "A" },
      { a: "B" },
      { a: "C" },
      { a: "D" },
      { a: "E" },
      { a: "F" },
      { a: "G" },
      { a: "H" },
      { a: "I" },
    ],
    answers2: [
      // 밴드
      { a: "65" },
      { a: "70" },
      { a: "75" },
      { a: "80" },
      { a: "85" },
      { a: "90" },
      { a: "95" },
      { a: "100" },
    ],
    answers3: [
      // 브랜드
      { a: "모름" },
      { a: "트라이엄프" },
      { a: "비너스" },
      { a: "솔브" },
      { a: "비비안" },
      { a: "원더브라" },
      { a: "코데즈컴바인" },
      { a: "엘르" },
      { a: "캘빈클라인" },
      { a: "예스" },
      { a: "보디가드" },
      { a: "게스" },
      { a: "에블린" },
      { a: "세컨스킨" },
      { a: "유니클로" },
      { a: "탑텐" },
      { a: "휠라" },
      { a: "기타" },
    ],
  },
  {
    // 2번 질문
    key: 2,
    multiple: false,
    question: "컵은 잘 맞나요?",
    size: "LARGE",
    answers: [
      { a: "가슴이 많이 삐져나와요", img: "2-1-가슴이 많이 삐져나와요.png" },
      { a: "가슴이 조금 삐져나와요", img: "2-2-가슴이 조금 삐져나와요.png" },
      { a: "잘 맞아요", img: "2-3-잘 맞아요.png" },
      { a: "컵이 조금 떠요", img: "2-4-컵이 조금 떠요.png" },
      { a: "컵이 많이 떠요", img: "2-5-컵이 많이 떠요.png" },
    ],
  },
  {
    // 3번 질문
    key: 3,
    multiple: false,
    question: "밴드는 어때요?",
    size: "LARGE",
    answers: [
      { a: "위로 올라가요", img: "3-1-위로 올라가요.png" },
      { a: "잘 맞아요", img: "3-2-잘 맞아요.png" },
      { a: "밴드가 조여요", img: "3-3-밴드가 조여요.png" },
    ],
  },
  {
    // 4번 질문
    key: 4,
    multiple: false,
    question: "주로 어느 후크를 쓰나요?",
    size: "LARGE",
    answers: [
      { a: "가장 조이는 후크", img: "4-1-가장 조이는 후크.png" },
      { a: "중간 후크", img: "4-2-중간 후크.png" },
      { a: "가장 헐렁한 후크", img: "4-3-가장 헐렁한 후크.png" },
    ],
  },
  {
    // 5번 질문
    key: 5,
    multiple: false,
    question: "어깨끈은 잘 맞나요?",
    size: "LARGE",
    answers: [
      { a: "살을 파고들어요", img: "5-1-살을 파고들어요.png" },
      { a: "잘 맞아요", img: "5-2-잘 맞아요.png" },
      { a: "자주 흘러내려요", img: "5-3-자주 흘러내려요.png" },
    ],
  },
  {
    // 6번 질문
    key: 6,
    multiple: true,
    question: "컵 밖 어디로 살이 삐져나오나요?",
    size: "MEDIUM",
    answers: [
      { a: "컵 옆" },
      { a: "컵 아래" },
      { a: "컵 위" },
      { a: "삐져나오지 않아요" },
    ],
    tipImg: "6-tip.png",
  },
  {
    // 7번 질문
    key: 7,
    multiple: true,
    question: "불편한 곳을 모두 골라주세요",
    size: "MEDIUM",
    answers: [
      { a: "와이어가 가슴 중앙을 찔러요" },
      { a: "와이어가 가슴 옆을 찔러요" },
      { a: "와이어가 가슴 아래를 눌러요" },
      { a: "브래지어 앞 부분이 떠요" },
      { a: "불편한 곳 없어요" },
    ],
    tipImg: "7-tip.png",
  },
  {
    // 8번 질문
    key: 8,
    multiple: false,
    question: "주로 어느쪽에 볼륨이 있나요?",
    size: "LARGE",
    answers: [
      { a: "위", img: "8-1-위.png" },
      { a: "아래", img: "8-2-아래.png" },
      { a: "차이 없어요", img: "8-3-차이 없어요.png" },
    ],
    tip:
      "허리를 숙였을 때 젖꼭지를 기준으로\n 가슴 볼륨이 위쪽과 아랫쪽 중 \n어디에 몰리는지로 판단하면 더 정확해요",
  },
  {
    // 9번 질문
    key: 9,
    multiple: false,
    question: "옆에서 본 가슴 모양은 어떤가요?",
    size: "LARGE",
    answers: [
      { a: "완만해요", img: "9-1-완만해요.png" },
      { a: "돌출되어있어요", img: "9-2-돌출되어있어요.png" },
      { a: "보통이에요", img: "9-3-보통이에요.png" },
    ],
    tip:
      "가슴이 넓은 면적에 퍼져 납작하면 완만,\n좁은 면적에 앞으로 튀어나왔으면\n돌출을 선택해주세요",
  },
  {
    // 10번 질문
    key: 10,
    multiple: false,
    question: "두 가슴 간 거리를 알려주세요",
    size: "LARGE",
    answers: [
      { a: "가까워요", img: "10-1-가까워요.png" },
      { a: "멀어요", img: "10-2-멀어요.png" },
      { a: "보통이에요", img: "10-3-보통이에요.png" },
    ],
    tip:
      "손가락 하나를 가슴 중앙의 뼈에 댔을 때,\n손가락이 양 가슴에 모두 닿으면 가까운 가슴이에요.\n가슴 사이에 손가락이 3개 이상 들어가면 먼 가슴이에요.",
  },
  {
    // 11번 질문
    key: 11,
    multiple: true,
    question: "혹시 해당하는 유형이 있나요?",
    size: "LARGE",
    answers: [
      {
        a: "새가슴",
        img: "11-1-새가슴.png",
        tip:
          "가슴 중앙 뼈가 돌출되어있어 옆에서 봤을 때\n가슴 중앙이 동그랗게 나와있으면 새가슴이에요.",
      },
      {
        a: "짝가슴",
        img: "11-2-짝짝이.png",
        tip: "양쪽 가슴 사이즈가 한 컵 이상 차이나면 짝가슴이에요",
      },
      { a: "해당하는 것이 없어요", img: "", tip: "" },
    ],
  },
  {
    // 12번 질문
    key: 12,
    multiple: false,
    question: "어떤 착용감을 선호하나요?",
    size: "LARGE",
    answers: [
      { a: "안 입은 듯 헐렁한 느낌", img: "12-1-헐렁한 느낌.png" },
      { a: "내 몸에 딱 맞는 느낌", img: "12-2-딱 맞는 느낌.png" },
      { a: "전체적으로 타이트한 느낌", img: "12-3-타이트한 느낌.png" },
    ],
  },
  {
    // 13번 질문
    key: 13,
    multiple: true,
    question: "좋아하는 스타일을 모두 골라주세요",
    size: "MEDIUM-I",
    answers: [
      { a: "브라렛", img: "13-1-브라렛.png", sub: "답답한 와이어가 싫다면" },
      { a: "심리스", img: "13-2-심리스.png", sub: "불편한 봉제선을 없앤" },
      { a: "볼륨업", img: "13-3-볼륨업.png", sub: "볼륨패드로 모아주는" },
      {
        a: "스포츠브라",
        img: "13-4-스포츠브라.png",
        sub: "흔들림 없이 잡아주는",
      },
      { a: "미니마이저", img: "13-5-미니마이저.png", sub: "가슴이 작아보이는" },
      { a: "홑겹", img: "13-6-홑겹.png", sub: "한 겹의 컵으로 가벼운" },
      { a: "몰드", img: "13-7-몰드.png", sub: "일체형 컵으로 탄탄하게" },
    ],
  },
];

const SizeQuestion = [
  // 실측 기반 테스트 질문
  {
    // 1번 질문
    key: 1,
    multiple: false,
    question: "아래 사이즈를 모두 입력해주세요",
    answers: [
      {
        a:
          "가슴 바로 밑 평평한 부분을 조이지 않을 정도로 \n팽팽하게 줄자를 둘러서 측정",
        type: "under",
        img: "1-1-밑가슴 둘레.png",
        sub: "밑가슴 둘레",
      },
      {
        a: "줄자가 젖꼭지 양쪽을 지나가도록 \n일직선으로 둘러서 측정",
        type: "upper",
        img: "1-2-윗가슴 둘레.png",
        sub: "윗가슴 둘레",
      },
    ],
    tip: "브라를 착용하지 않은 상태에서 \n양발을 모으고 서서 측정하면 돼요",
  },
  {
    // 2번 질문
    key: 2,
    multiple: false,
    question: "주로 어느쪽에 볼륨이 있나요?",
    size: "LARGE",
    answers: [
      { a: "위", img: "8-1-위.png" },
      { a: "아래", img: "8-2-아래.png" },
      { a: "차이 없어요", img: "8-3-차이 없어요.png" },
    ],
    tip:
      "허리를 숙였을 때 젖꼭지를 기준으로\n 가슴 볼륨이 위쪽과 아랫쪽 중 \n어디에 몰리는지로 판단하면 더 정확해요",
  },
  {
    // 3번 질문
    key: 3,
    multiple: false,
    question: "옆에서 본 가슴 모양은 어떤가요?",
    size: "LARGE",
    answers: [
      { a: "완만해요", img: "9-1-완만해요.png" },
      { a: "돌출되어있어요", img: "9-2-돌출되어있어요.png" },
      { a: "보통이에요", img: "9-3-보통이에요.png" },
    ],
    tip:
      "가슴이 넓은 면적에 퍼져 납작하면 완만,\n좁은 면적에 앞으로 튀어나왔으면\n돌출을 선택해주세요",
  },
  {
    // 4번 질문
    key: 4,
    multiple: false,
    question: "두 가슴 간 거리를 알려주세요",
    size: "LARGE",
    answers: [
      { a: "가까워요", img: "10-1-가까워요.png" },
      { a: "멀어요", img: "10-2-멀어요.png" },
      { a: "보통이에요", img: "10-3-보통이에요.png" },
    ],
    tip:
      "손가락 하나를 가슴 중앙의 뼈에 댔을 때,\n손가락이 양 가슴에 모두 닿으면 가까운 가슴이에요.\n가슴 사이에 손가락이 3개 이상 들어가면 먼 가슴이에요.",
  },
  {
    // 5번 질문
    key: 5,
    multiple: true,
    question: "혹시 해당하는 유형이 있나요?",
    size: "LARGE",
    answers: [
      {
        a: "새가슴",
        img: "11-1-새가슴.png",
        tip:
          "가슴 중앙 뼈가 돌출되어있어 옆에서 봤을 때\n가슴 중앙이 동그랗게 나와있으면 새가슴이에요.",
      },
      {
        a: "짝가슴",
        img: "11-2-짝짝이.png",
        tip: "양쪽 가슴 사이즈가 한 컵 이상 차이나면 짝가슴이에요",
      },
      { a: "해당하는 것이 없어요", img: "", tip: "" },
    ],
  },
  {
    // 6번 질문
    key: 6,
    multiple: false,
    question: "어떤 착용감을 선호하나요?",
    size: "LARGE",
    answers: [
      { a: "안 입은 듯 헐렁한 느낌", img: "12-1-헐렁한 느낌.png" },
      { a: "내 몸에 딱 맞는 느낌", img: "12-2-딱 맞는 느낌.png" },
      { a: "전체적으로 타이트한 느낌", img: "12-3-타이트한 느낌.png" },
    ],
  },
  {
    // 7번 질문
    key: 7,
    multiple: true,
    question: "좋아하는 스타일을 모두 골라주세요",
    size: "MEDIUM-I",
    answers: [
      { a: "브라렛", img: "13-1-브라렛.png", sub: "답답한 와이어가 싫다면" },
      { a: "심리스", img: "13-2-심리스.png", sub: "불편한 봉제선을 없앤" },
      { a: "볼륨업", img: "13-3-볼륨업.png", sub: "볼륨패드로 모아주는" },
      {
        a: "스포츠브라",
        img: "13-4-스포츠브라.png",
        sub: "흔들림 없이 잡아주는",
      },
      { a: "미니마이저", img: "13-5-미니마이저.png", sub: "가슴이 작아보이는" },
      { a: "홑겹", img: "13-6-홑겹.png", sub: "한 겹의 컵으로 가벼운" },
      { a: "몰드", img: "13-7-몰드.png", sub: "일체형 컵으로 탄탄하게" },
    ],
  },
];

const IntroQuestion = [
  // 테스트 유형
  {
    question: "먼저 테스트 방식을 골라주세요",
    size: "MEDIUM",
    answers: [
      { a: "더 정확한 실측 사이즈 기반 추천", sub: "집에 줄자가 있다면," },
      { a: "더 간편한 착용감 기반 추천", sub: "줄자가 없거나 밖에 있다면," },
    ],
  },
];

const DefaultResultDesc = (userName) => {
  return [
    {
      category: "BIRD",
      data:
        "새가슴은 가슴 중앙의 뼈가 두드러지게 튀어나온 유형이에요. 가슴이 양옆으로 벌어진 경우가 많죠. 국내 여성 중 꽤 흔한 가슴 유형이랍니다!",
    },
    {
      category: "WIRE",
      data: [
        {
          ko: "",
          type: "와이어 있음",
          matchDesc: [
            {
              cup: ["A", "B"],
              desc: "와이어가 가슴을 받쳐줘 볼륨업 효과가 있어요.",
            },
            {
              cup: ["C", "D"],
              desc: "와이어가 가슴을 받쳐줘 볼륨업 효과가 있어요.",
            },
          ],
        },
        {
          type: "와이어 없음",
          matchDesc: [
            {
              cup: ["A", "B"],
              desc: "와이어가 없어 편해요.",
            },
            {
              cup: ["C", "D"],
              desc:
                "와이어가 없어 편하지만, D컵 이상이 입으면 가슴을 잡아주지 못해 불편할 수 있어요.",
            },
          ],
        },
      ],
    },
    {
      category: "FULLNESS",
      data: [
        {
          type: "B",
          ko: "아랫볼륨",
          title: "컵",
          desc:
            "<b>하프컵이나 3/4컵을 추천해요.</b> 윗볼륨이 적기 때문에, 윗가슴을 덮지 않는 컵이 잘 맞아요. <i>풀컵은 컵 위가 뜰 수 있으니 피해주세요.</i>",
          extraDesc:
            "<i>C컵 이상은 패드가 두꺼운 브라를 피해주세요. 젖꼭지가 컵 밖으로 나올 수 있거든요.</i>",
          good: ["하프컵", "3-4컵"],
          bad: ["풀컵"],
          matchDesc: [
            {
              type: "풀컵",
              desc: [
                {
                  cup: ["A", "B"],
                  desc: `아랫볼륨이 많은 ${userName}님에게 풀컵은 컵 위가 뜰 수 있으니 피해주세요.`,
                },
                {
                  cup: ["C", "D"],
                  desc: `아랫볼륨이 많은 ${userName}님에게 풀컵은 컵 위가 뜰 수 있으니 피해주세요. 단, C컵 이상은 안정적인 착용감을 위해 패드가 두꺼운 브라를 추천하지 않아요.`,
                },
              ],
            },
            {
              type: "3/4컵",
              desc: [
                {
                  cup: ["A", "B"],
                  desc: `아랫볼륨이 많은 ${userName}님에게 3/4컵을 추천해요. 윗가슴을 덮지 않아 컵이 안 뜰거에요.`,
                },
                {
                  cup: ["C", "D"],
                  desc: `아랫볼륨이 많은 ${userName}님에게 3/4컵을 추천해요. 윗가슴을 덮지 않아 컵이 안 뜰거에요.
                  다만, C컵 이상은 안정적인 착용감을 위해 패드가 두꺼운 브라를 추천하지 않아요.`,
                },
              ],
            },
            {
              type: "하프컵",
              desc: [
                {
                  cup: ["A", "B"],
                  desc: `아랫볼륨이 많은 ${userName}님에게 하프컵을 추천해요. 윗가슴을 덮지 않아 컵이 안 뜰거에요.`,
                },
                {
                  cup: ["C", "D"],
                  desc: `아랫볼륨이 많은 ${userName}님에게 하프컵을 추천해요. 윗가슴을 덮지 않아 컵이 안 뜰거에요.
                    다만, C컵 이상은 안정적인 착용감을 위해 패드가 두꺼운 브라를 추천하지 않아요.`,
                },
              ],
            },
          ],
        },
        {
          type: "T",
          ko: "윗볼륨",
          title: "컵",
          desc:
            "<b>풀컵을 가장 추천해요.</b> 윗가슴까지 안정적으로 잡아주거든요. <b>3/4컵도 잘 맞습니다.</b> <i>하프컵은 가슴 중간을 누를 수 있으니 피해주세요.</i>",
          extraDesc:
            "<i>C컵 이상은 패드가 두꺼운 브라를 피해주세요. 젖꼭지가 컵 밖으로 나올 수 있거든요.</i>",
          good: ["풀컵", "3-4컵"],
          bad: ["하프컵"],
          matchDesc: [
            {
              type: "풀컵",
              desc: [
                {
                  cup: ["A", "B"],
                  desc: `윗볼륨이 많은 ${userName}님에게 풀컵을 가장 추천해요. 가슴 위까지 안정적으로 잡아주거든요.`,
                },
                {
                  cup: ["C", "D"],
                  desc: `윗볼륨이 많은 ${userName}님에게 풀컵을 가장 추천해요. 가슴 위까지 안정적으로 잡아주거든요.
                    다만, C컵 이상은 안정적인 착용감을 위해 패드가 두꺼운 브라를 추천하지 않아요.`,
                },
              ],
            },
            {
              type: "3/4컵",
              desc: [
                {
                  cup: ["A", "B"],
                  desc: `윗볼륨이 많은 ${userName}님에게 3/4컵이 잘 맞아요.`,
                },
                {
                  cup: ["C", "D"],
                  desc: `윗볼륨이 많은 ${userName}님에게 3/4컵이 잘 맞아요.
                    다만, C컵 이상은 안정적인 착용감을 위해 패드가 두꺼운 브라를 추천하지 않아요.`,
                },
              ],
            },
            {
              type: "하프컵",
              desc: [
                {
                  cup: ["A", "B"],
                  desc: `윗볼륨이 많은 ${userName}님에게 하프컵은 가슴 위쪽을 누를 수 있으니 피해주세요.`,
                },
                {
                  cup: ["C", "D"],
                  desc: `윗볼륨이 많은 ${userName}님에게 하프컵은 가슴 위쪽을 누를 수 있으니 피해주세요.
                    다만, C컵 이상은 안정적인 착용감을 위해 패드가 두꺼운 브라를 추천하지 않아요.`,
                },
              ],
            },
          ],
        },
        {
          type: "E",
          ko: "위아래가 비슷한 볼륨",
          title: "컵",
          desc:
            "<b>모든 컵이 잘 맞아요.</b> 볼륨감을 더하고 싶다면 하프컵을, 안정감을 원한다면 풀컵을 추천해요.",
          extraDesc:
            "<i>C컵 이상은 패드가 두꺼운 브라를 피해주세요. 젖꼭지가 컵 밖으로 나올 수 있거든요.</i>",
          good: ["풀컵", "3-4컵", "하프컵"],
          bad: [],
          matchDesc: [
            {
              type: "풀컵",
              desc: [
                {
                  cup: ["A", "B"],
                  desc: `안정감을 원한다면 위아래 볼륨이 비슷한 ${userName}님에게 풀컵을 추천해요.`,
                },
                {
                  cup: ["C", "D"],
                  desc: `안정감을 원한다면 위아래 볼륨이 비슷한 ${userName}님에게 풀컵을 추천해요.
                    다만, C컵 이상은 안정적인 착용감을 위해 패드가 두꺼운 브라를 추천하지 않아요.`,
                },
              ],
            },
            {
              type: "3/4컵",
              desc: [
                {
                  cup: ["A", "B"],
                  desc: `위아래 볼륨이 비슷한 ${userName}님에게 3/4컵이 잘 맞아요.`,
                },
                {
                  cup: ["C", "D"],
                  desc: `위아래 볼륨이 비슷한 ${userName}님에게 3/4컵이 잘 맞아요.
                    다만, C컵 이상은 안정적인 착용감을 위해 패드가 두꺼운 브라를 추천하지 않아요.`,
                },
              ],
            },
            {
              type: "하프컵",
              desc: [
                {
                  cup: ["A", "B"],
                  desc: `볼륨감을 더하고 싶다면 위아래 볼륨이 비슷한 ${userName}님에게 하프컵을 추천해요.`,
                },
                {
                  cup: ["C", "D"],
                  desc: `볼륨감을 더하고 싶다면 위아래 볼륨이 비슷한 ${userName}님에게 하프컵을 추천해요.
                   다만, C컵 이상은 안정적인 착용감을 위해 패드가 두꺼운 브라를 추천하지 않아요.`,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      category: "PROJECTION",
      data: [
        {
          type: "S",
          ko: "넓은 면적 가슴",
          title: "브라",
          desc:
            "<b>넓은 면적에 퍼진 볼륨을 안정적으로 모아주는 저중심 브라가 좋아요.</b> ('중심'은 컵과 컵을 연결하는, 브라의 중심이에요) 저중심 브라는 가슴을 옆에서 밀어주는 기능이 있거든요. <i>고중심 브라는 와이어가 가슴 중앙을 찌를 수 있으니 피해주세요.</i>",
          good: ["저중심 브라", "보통 중심 브라"],
          bad: ["고중심 브라"],
          matchDesc: [
            {
              type: "고중심 브라",
              desc: `가슴 면적이 넓은 ${userName}님에게 고중심 브라는 답답할 수 있어요.`,
            },
            {
              type: "저중심 브라",
              desc: `가슴 면적이 넓은 ${userName}님에게는 가슴을 편하게 모아주는 저중심 브라를 추천해요. `,
            },
            {
              type: "보통 중심 브라",
              desc: `가슴 면적이 넓은 ${userName}님에게는 보통 높이의 중심도 괜찮아요.`,
            },
          ],
        },
        {
          type: "P",
          ko: "좁은 면적 가슴",
          title: "브라",
          desc:
            "<b>좁은 면적에 모인 볼륨을 탄탄하게 받쳐주는 고중심 브라가 좋아요.</b> ('중심'은 컵과 컵을 연결하는, 브라의 중심이에요) 고중심 브라는 돌출된 가슴을 아래에서 받쳐줘, 가슴을 안정적으로 잡아주거든요. <i>저중심 브라는 가슴을 더 돌출되게 만들 수 있으니 피해주세요.</i>",
          good: ["고중심 브라", "보통 중심 브라"],
          bad: ["저중심 브라"],
          matchDesc: [
            {
              type: "고중심 브라",
              desc: `가슴 면적이 좁은 ${userName}님에게 가슴을 아래에서 받쳐주는 고중심 브라를 추천해요.`,
            },
            {
              type: "저중심 브라",
              desc: `가슴 면적이 좁은 ${userName}님에게 저중심 브라는 가슴을 더 돌출되게 만들 수 있어요.`,
            },
            {
              type: "보통 중심 브라",
              desc: `가슴 면적이 넓은 ${userName}님에게는 보통 높이의 중심도 괜찮아요.`,
            },
          ],
        },
        {
          type: "E",
          ko: "고르게 퍼진 가슴",
          title: "브라",
          desc:
            "<b>저중심, 고중심 브라가 모두 잘 맞아요.</b> ('중심'은 컵과 컵을 연결하는, 브라의 중심이에요) 보정을 원한다면 저중심, 잡아주는 착용감을 원한다면 고중심 브라가 좋아요.",
          good: ["저중심 브라", "고중심 브라", "보통 중심 브라"],
          bad: [],
          matchDesc: [
            {
              type: "고중심 브라",
              desc: `잡아주는 착용감을 원한다면, 가슴 면적이 고른 ${userName}님에게는 고중심 브라가 잘 맞아요. `,
            },
            {
              type: "저중심 브라",
              desc: `볼륨감을 원한다면, 가슴 면적이 고른 ${userName}님에게는 저중심 브라가 잘 맞아요.`,
            },
            {
              type: "보통 중심 브라",
              desc: `가슴 면적이 넓은 ${userName}님에게는 보통 높이의 중심이 잘 맞을 거예요.`,
            },
          ],
        },
      ],
    },
    {
      category: "CLOSENESS",
      data: [
        {
          type: "C",
          ko: "가까운 가슴",
          title: "팁",
          desc:
            "<b>컵 사이 간격이 1~2cm 이하인 브라를 추천해요.</b> 그래야 컵이 가슴을 잘 분리해, 가슴 사이의 땀이나 마찰을 방지할 수 있어요.",
          good: ["간격이 좁은 브라", "보통 간격 브라"],
          bad: ["간격이 넓은 브라"],
          matchDesc: [
            {
              type: "간격이 넓은 브라",
              desc: `가슴 사이가 가까운 ${userName}님에게 컵 간격이 넓은 브라는 추천하지 않아요.`,
            },
            {
              type: "간격이 좁은 브라",
              desc: `가슴 사이가 가까운 ${userName}님에게 컵 사이 간격이 가까운 브라를 추천해요.`,
            },
            {
              type: "보통 간격 브라",
              desc: `가슴 사이가 가까운 ${userName}님에게 컵 사이 간격이 보통인 브라도 괜찮아요.`,
            },
          ],
        },
        {
          type: "W",
          ko: "먼 가슴",
          title: "팁",
          desc:
            "<b>컵 사이 간격이 1~2cm 이상인 브라를 추천해요.</b> 그래야 와이어가 가슴 중앙을 찌르지 않아요.",
          good: ["간격이 넓은 브라", "보통 간격 브라"],
          bad: ["간격이 좁은 브라"],
          matchDesc: [
            {
              type: "간격이 넓은 브라",
              desc: `가슴 사이가 먼 ${userName}님에게 컵 사이 간격이 먼 브라를 추천해요. `,
            },
            {
              type: "간격이 좁은 브라",
              desc: `가슴 사이가 먼 ${userName}님에게 컵 사이 간격이 좁은 브라는 추천하지 않아요.`,
            },
            {
              type: "보통 간격 브라",
              desc: `가슴 사이가 먼 ${userName}님에게 컵 사이 간격이 보통인 브라도 괜찮아요.`,
            },
          ],
        },
        {
          type: "E",
          ko: "보통 거리 가슴",
          title: "팁",
          desc:
            "<b>컵 사이 간격이 1~2cm인 브라를 추천해요.</b> 그래야 컵이 가슴을 잘 분리해서 잡아준답니다.",
          good: ["보통 간격 브라"],
          bad: ["간격이 좁은 브라", "간격이 넓은 브라"],
          matchDesc: [
            {
              type: "간격이 넓은 브라",
              desc: `가슴 사이가 보통인 ${userName}님에게 컵 사이가 넓은 브라는 추천하지 않아요.`,
            },
            {
              type: "간격이 좁은 브라",
              desc: `가슴 사이가 보통인 ${userName}님에게 컵 사이가 좁은 브라는 추천하지 않아요.`,
            },
            {
              type: "보통 간격 브라",
              desc: `가슴 사이가 보통인 ${userName}님에게 컵 사이 간격이 보통인 브라를 추천해요.`,
            },
          ],
        },
      ],
    },
  ];
};

const SpecialResultDesc = (userName) => [
  {
    category: ["AA", "AB-BIRD", "C+BIRD"], // AA & A,B 새가슴 & C 이상 새가슴
    data: [
      {
        type: "",
        ko: "",
        title: "컵",
        desc:
          "<b>3/4컵과 풀컵을 추천해요.</b> 단, 풀컵은 컵 위가 뜰 수 있으니 납작한 모양의 컵을 잘 찾아야해요.",
        good: ["3-4컵", "풀컵"],
        bad: ["하프컵"],
        matchDesc: [
          {
            type: "풀컵",
            desc: `새가슴인 ${userName}님에게 풀컵을 추천해요. 단, 컵 위가 뜰 수 있으니 납작한 모양의 컵을 추천해요.`,
          },
          {
            type: "3/4컵",
            desc: `새가슴인 ${userName}님에게 3/4컵을 추천해요`,
          },
          {
            type: "하프컵",
            desc: `새가슴인 ${userName}님에게 가슴위를 누를 수 있는 하프컵은 추천하지 않아요.`,
          },
        ],
      },
      {
        type: "",
        ko: "",
        title: "브라",
        desc:
          "<b>중심이 낮은, 저중심 브라가 좋아요.</b> ('중심'은 컵과 컵을 연결하는, 브라의 중심이에요) 저중심 브라는 가슴을 옆에서 밀어, 모아주는 기능이 있어요. <i>고중심 브라는 와이어가 가슴 중앙을 찌를 수 있으니 피해주세요.</i>",
        good: ["저중심 브라", "보통 중심 브라"],
        bad: ["고중심 브라"],
        matchDesc: [
          {
            type: "고중심 브라",
            desc: `가슴 면적이 넓은 ${userName}님에게 고중심 브라는 답답할 수 있어요.`,
          },
          {
            type: "저중심 브라",
            desc: `가슴 면적이 넓은 ${userName}님에게는 가슴을 편하게 모아주는 저중심 브라를 추천해요. `,
          },
          {
            type: "보통 중심 브라",
            desc: `가슴 면적이 넓은 ${userName}님에게는 보통 높이의 중심도 괜찮아요.`,
          },
        ],
      },
      {
        type: "",
        ko: "",
        title: "와이어",
        desc:
          "<i>와이어가 가슴뼈를 찌를 수 있으니 피해주세요.</i> 와이어가 꼭 필요하다면 <b>소프트 와이어를 추천</b>해요.",
        good: ["노와이어"],
        bad: ["와이어"],
        matchDesc: [
          {
            user: "AA",
            desc: [
              {
                type: "와이어 있음",
                desc: `${userName}님, 와이어가 가슴뼈를 찌를 수 있으니 피해주세요. 꼭 필요하다면 소프트 와이어를 추천해요.`,
              },
              {
                type: "와이어 없음",
                desc: "와이어가 없어 편해요.",
              },
            ],
          },
          {
            user: "AB-BIRD",
            desc: [
              {
                type: "와이어 있음",
                desc: `새가슴인 ${userName}님에게 와이어가 가슴뼈를 찌를 수 있으니 피해주세요.`,
              },
              {
                type: "와이어 없음",
                desc: "와이어가 없어 편해요.",
              },
            ],
          },
          {
            user: "C+BIRD",
            desc: [
              {
                type: "와이어 있음",
                desc: `새가슴인 ${userName}님에게 와이어가 가슴뼈를 찌를 수 있으니 피해주세요. `,
              },
              {
                type: "와이어 없음",
                desc:
                  "와이어가 없어 편하지만, D컵 이상은 가슴을 잡아주지 못해 불편할 수 있어요.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    category: ["AA", "AB-BIRD"],
    data: [
      {
        title: "팁",
        desc:
          "<b>삼각 브라렛</b>은 컵이 가슴에 밀착되어, 볼륨이 적은 가슴에 잘 맞아요. 볼륨감을 더하고 싶다면 <b>푸쉬업 브라</b>를 추천해요.",
        good: ["삼각 브라렛", "푸쉬업 브라"],
        bad: [],
      },
    ],
  },
  {
    category: ["80CD/E"], // 밴드 80 이상 C, D, 밴드 무관 E 이상
    data: [
      {
        type: "",
        ko: "",
        title: "컵",
        desc:
          "<b>풀컵을 가장 추천해요.</b> 윗가슴을 안정적으로 감싸주거든요. <b>3/4컵도 괜찮아요.</b> <i>하프컵은 가슴 중간을 누를 수 있어 피해주세요. C컵 이상은 패드가 두꺼운 브라를 피해주세요. 젖꼭지가 컵 밖으로 나올 수 있거든요.</i>",
        good: ["풀컵", "3-4컵"],
        bad: ["하프컵"],
        matchDesc: [
          {
            type: "풀컵",
            desc: `${userName}님에게 풀컵을 가장 추천해요. 가슴 위까지 안정적으로 잡아주거든요.
              다만, C컵 이상은 안정적인 착용감을 위해 패드가 두꺼운 브라를 추천하지 않아요.`,
          },
          {
            type: "3/4컵",
            desc: `${userName}님에게 3/4컵이 잘 맞아요.
            다만, C컵 이상은 안정적인 착용감을 위해 패드가 두꺼운 브라를 추천하지 않아요.`,
          },
          {
            type: "하프컵",
            desc: `${userName}님에게 하프컵은 가슴 위쪽을 누를 수 있으니 피해주세요.
            다만, C컵 이상은 안정적인 착용감을 위해 패드가 두꺼운 브라를 추천하지 않아요.`,
          },
        ],
      },
      {
        type: "",
        ko: "",
        title: "브라",
        desc:
          "<b>중심이 높은, 고중심 브라가 좋아요.</b> ('중심'은 컵과 컵을 연결하는, 브라의 중심이에요) 고중심 브라는 아래에서 받쳐주는 기능이 있어 가슴을 안정적으로 잡아줘요. <i>저중심 브라는 가슴을 더 돌출되게 만들 수 있어 피해야 해요.</i>",
        good: ["고중심 브라", "보통 중심 브라"],
        bad: ["저중심 브라"],
        matchDesc: [
          {
            type: "고중심 브라",
            desc: `가슴 면적이 좁은 ${userName}님에게 가슴을 아래에서 받쳐주는 고중심 브라를 추천해요.`,
          },
          {
            type: "저중심 브라",
            desc: `가슴 면적이 좁은 ${userName}님에게 저중심 브라는 가슴을 더 돌출되게 만들 수 있어요.`,
          },
          {
            type: "보통 중심 브라",
            desc: `${userName}님에게는 보통 높이의 중심도 괜찮아요.`,
          },
        ],
      },
      {
        title: "팁",
        desc:
          "<i>브라렛은 가슴 무게를 받쳐줄 수 없으니 피해주세요.</i> 넓은 밴드와 어깨끈은 큰 가슴을 안정적으로 받쳐줄거에요. 신축성이 좋은 <b>스포츠 브라</b>도 잘 맞을 거예요.",
        good: ["넓은 밴드 브라", "넓은 어깨끈 브라", "스포츠 브라"],
        bad: ["삼각 브라렛"],
      },
      {
        type: "",
        ko: "",
        title: "와이어",
        desc:
          "<i>와이어가 가슴뼈를 찌를 수 있으니 피해주세요.</i> 와이어가 꼭 필요하다면 <b>소프트 와이어를 추천</b>해요.",
        good: ["와이어 있음", "와이어 없음"],
        bad: [],
        matchDesc: [
          {
            user: "80CD/E",
            desc: [
              {
                type: "와이어 있음",
                desc: "와이어가 가슴을 받쳐줘 볼륨을 더해줘요.",
              },
              {
                type: "와이어 없음",
                desc:
                  "와이어가 없어 편하지만, D컵 이상은 가슴을 잡아주지 못해 불편할 수 있어요.",
              },
            ],
          },
        ],
      },
    ],
  },
];

const TypeDesc = [
  {
    category: "FULLNESS",
    data: [
      {
        type: "B",
        desc: "가슴 아래쪽에 볼륨이 많고(<b>B</b>ottom), ",
      },
      {
        type: "T",
        desc: "가슴 위쪽에 볼륨이 많고(<b>T</b>op), ",
      },
      {
        type: "E",
        desc: "위아래 가슴 볼륨이 같고(<b>E</b>ven), ",
      },
    ],
  },
  {
    category: "PROJECTION",
    data: [
      {
        type: "S",
        desc: "볼륨이 넓은 면적에 퍼져 완만한 모양이며(<b>S</b>hallow), ",
      },
      {
        type: "P",
        desc: "볼륨이 좁은 면적에 모여 돌출된 모양이며(<b>P</b>rojected), ",
      },
      {
        type: "E",
        desc: "볼륨이 고르게 퍼져있으며(<b>E</b>ven), ",
      },
    ],
  },
  {
    category: "CLOSENESS",
    data: [
      {
        type: "C",
        desc: "가슴간 거리가 1~2cm 이하로 가까운(<b>C</b>lose) 유형입니다.",
      },
      {
        type: "W",
        desc: "가슴간 거리가 1~2cm 이상으로 먼(<b>W</b>ide) 유형입니다.",
      },
      {
        type: "E",
        desc:
          "가슴간 거리가 1~2cm로 멀지도 가깝지도 않은(<b>E</b>ven) 유형입니다.",
      },
    ],
  },
];

export {
  ResultText,
  FitQuestion,
  SizeQuestion,
  IntroQuestion,
  DefaultResultDesc,
  SpecialResultDesc,
  TypeDesc,
};
