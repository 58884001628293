import React from "react";
import styled, { css } from "styled-components";

import { formatPrice } from "../../modules/util";

import checkbox_default from "../../assets/icons/check/check-default.png";
import checkbox_checked from "../../assets/icons/check/check-clicked.png";

export const OrderDetailCard = ({
  data,
  history,
  refund,
  _onQuantityClick,
  checked,
  checkHandler,
  refundData,
}) => {
  const THUMBNAIL = `https://s3.ap-northeast-2.amazonaws.com/poola-photo-2.0/s200/${data.product.slug}.png`;

  const PRODUCT_ID = data.product._id;
  const BRAND_NAME = data.brand.name;
  const PRODUCT_NAME = data.product.name;
  const QUANTITY = refundData ? refundData.quantity.raw : data.quantity.raw;
  const ORIGIN_QUANTITY = data.quantity.raw;
  const PRICE = data.total.price.sale.raw / (ORIGIN_QUANTITY / QUANTITY);
  const ORIGINAL_PRICE =
    data.total.price.original.raw / (ORIGIN_QUANTITY / QUANTITY);
  const SHIPPING_METHOD_NAME = data.shippingMethod.name;

  const ITEM_ID = data._id;

  const NoSale =
    data.total.price.sale.converted === data.total.price.original.converted;

  // 옵션 values 그룹핑
  const optionValues = data.variant.types.map(
    (option) => option.variation.value
  );
  const combinedValue = optionValues.join(" / ");

  const handleProductDetail = () => {
    history.push(`/product/${PRODUCT_ID}/true`);
  };

  return (
    <Card>
      <Thumbnail src={THUMBNAIL} alt="thumb" onClick={handleProductDetail} />
      <Info>
        {refund && (
          <CheckButton type="button" onClick={() => checkHandler(ITEM_ID)}>
            <img
              src={!checked[ITEM_ID] ? checkbox_default : checkbox_checked}
              alt="checkbox_default"
            />
          </CheckButton>
        )}
        <BrandName>{BRAND_NAME}</BrandName>
        <ProductName>{PRODUCT_NAME}</ProductName>
        <Option>옵션 : {combinedValue}</Option>
        <Option>배송 : {SHIPPING_METHOD_NAME}</Option>
        {refund ? (
          <QuantityWrapper>
            <QuantityButton
              position="LEFT"
              disabled={QUANTITY < 2}
              onClick={() => _onQuantityClick("minus", ITEM_ID)}
            >
              <img
                src={require(`../../assets/icons/cart-minus${
                  QUANTITY < 2 ? "-disabled" : ""
                }.svg`)}
                width="16px"
                height="16px"
                alt="-"
              />
            </QuantityButton>
            <QuantityInput>{QUANTITY}</QuantityInput>
            <QuantityButton
              position="RIGHT"
              disabled={QUANTITY >= ORIGIN_QUANTITY}
              onClick={() => _onQuantityClick("plus", ITEM_ID)}
            >
              <img
                src={require(`../../assets/icons/cart-plus${
                  QUANTITY >= ORIGIN_QUANTITY ? "-disabled" : ""
                }.svg`)}
                width="16px"
                height="16px"
                alt="+"
              />
            </QuantityButton>
          </QuantityWrapper>
        ) : (
          <Quantity>수량 : {QUANTITY}</Quantity>
        )}
        <PriceBox>
          {!NoSale && (
            <OriginalPrice>{formatPrice(ORIGINAL_PRICE)}원</OriginalPrice>
          )}
          <Price>{formatPrice(PRICE)}원</Price>
        </PriceBox>
      </Info>
    </Card>
  );
};

const Card = styled.div`
  position: relative;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
`;

const Thumbnail = styled.img`
  width: 65px;
  height: 65px;
  border-radius: 6px;
  object-fit: cover;
`;

const Info = styled.div`
  padding-left: 12px;
`;

const BrandName = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`;

const ProductName = styled.h4`
  margin: 0;
  padding: 4px 0;
  font-size: 14px;
  font-weight: normal;
`;

const Option = styled.div`
  font-size: 14px;
  color: var(--color-tertiary-black);
  padding-bottom: 2px;
`;

const Quantity = styled.div`
  font-size: 14px;
`;

const PriceBox = styled.div`
  position: absolute;
  bottom: 18px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OriginalPrice = styled.del`
  font-size: 12px;
  color: var(--color-tertiary-black);
`;

const Price = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-left: 8px;
`;

const QuantityWrapper = styled.div`
  padding-top: 12px;
  display: inline-flex;
`;

const QuantityButton = styled.button`
  width: 28px;
  height: 28px;
  padding: 4px;
  line-height: 1;
  background: var(--color-white);
  border: 1px solid var(--color-border-gray);

  ${(props) =>
    props.position === "LEFT"
      ? css`
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        `
      : css`
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        `}
`;

const QuantityInput = styled.p`
  width: 28px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: var(--color-secondary-black);
  border-top: 1px solid var(--color-border-gray);
  border-bottom: 1px solid var(--color-border-gray);
`;

const CheckButton = styled.button`
  position: absolute;
  background: none;
  padding: 0;
  top: 16px;
  right: 0;
  border: none;

  img {
    width: 16px;
    height: 16px;
  }
`;
