import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Axios from "axios";
import { API_ENDPOINT } from "../../Config";
import { useSelector } from "react-redux";
import { formatPrice } from "../../modules/util";
import { Label } from "../../components/Common/Label";
import { clickTrigger } from "../../modules/trigger";
import { GA4EventTracker } from "../../hook/GA4EventTracker";

export const ProductCollectionCard = ({ data, ellipsis, history, chip }) => {
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  const [heart, setHeart] = useState(data.wish);

  useEffect(() => {
    setHeart(data.wish);
  }, [data]);

  const updateWishList = (body) => {
    // 찜 목록에 추가하기
    Axios.post(
      API_ENDPOINT +
        `/api/user/${body.userEmail}/wish/product/${body.productId}`,
      { action: body.action }
    ).then((response) => {
      if (response.data.success) {
        console.log("찜 성공");
      } else {
        console.log("찜 실패");
      }
    });
  };

  const handleButtonClick = (e, product) => {
    e.preventDefault();
    e.stopPropagation();
    if (!user.email) {
      window.location.href = "/login";
      clickTrigger("찜하기", {
        productInfo: {
          name: data.name,
          brand: data.brand,
          price: data.price,
          fit: data.recommend * 100,
        },
      });
    } else {
      if (heart) {
        // 찜 선택 -> 해제
        let heartbody = {
          userEmail: user.email,
          productId: product.productId,
          action: "delete",
        };
        clickTrigger("찜취소", {
          productInfo: {
            name: data.name,
            brand: data.brand,
            price: data.price,
            fit: data.recommend * 100,
          },
        });
        updateWishList(heartbody);
        setHeart(!heart);
      } else {
        let heartbody = {
          userEmail: user.email,
          productId: product.productId,
          action: "add",
        };
        clickTrigger("찜하기", {
          productInfo: {
            name: data.name,
            brand: data.brand,
            price: data.price,
            fit: data.recommend * 100,
          },
        });
        GA4EventTracker("찜하기_클릭", {
          제품명: data.name,
        });
        updateWishList(heartbody);
        setHeart(!heart);
      }
    }
  };

  return (
    <Container ellipsis={ellipsis}>
      <FlexBox
        ellipsis={ellipsis}
        onClick={() => {
          history.push(
            `/product/${data?.productId}?type=promotion&chip=${chip}`
          );
        }}
      >
        <ImageContainer>
          <img
            src={data.thumb}
            alt="thumb"
            width="120px"
            height="120px"
            style={{ borderRadius: "4px", objectFit: "cover" }}
          />
          {data?.isPoolaWeek && (
            <PromotionLabel>{data?.isPoolaWeek && "프로모션"}</PromotionLabel>
          )}
        </ImageContainer>
        <InfoWrapper ellipsis={ellipsis}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Text bold>
              <span style={{ paddingRight: "4px" }}>{data?.brand}</span>
            </Text>
            <WishButton onClick={(e) => handleButtonClick(e, data)}>
              <img
                src={require("../../assets/icons/heart-" +
                  (heart ? "filled" : "default") +
                  ".png")}
                alt={heart ? "찜하기" : "찜 취소"}
                width="16px"
                height="16px"
              />
            </WishButton>
          </div>
          <Text>{data?.productName}</Text>
          {data.discount.value !== null && data.discount.value !== 0 ? (
            <PriceWrapper>
              <Percentage>
                {data?.discount?.type === "percentage"
                  ? data.discount.value
                  : Math.round((data.discount.value / data.price) * 100)}
                %
              </Percentage>
              <Price>
                {formatPrice(data?.price - data?.discount?.value)}원
              </Price>
              <DefaultPrice>{formatPrice(data?.price)}원</DefaultPrice>
            </PriceWrapper>
          ) : (
            <PriceWrapper>
              <Price>{formatPrice(data?.price)}원</Price>
            </PriceWrapper>
          )}
          <MatchBox>
            {(user?.type && data?.recommend !== null) || !user?.type ? (
              <div
                style={{
                  display: "inline-block",
                  paddingRight: "6px",
                }}
              >
                <Label
                  collection
                  notRecommend={
                    user.type && data?.recommend < 0.75 ? true : false
                  }
                  userType={!user.type ? false : true}
                  noBorder
                  flag={user.type && data?.recommend < 0.84 ? true : false}
                  text={
                    !user.type
                      ? "나와 잘 맞을까?"
                      : 0.9 <= data?.recommend
                      ? "찰떡궁합"
                      : 0.84 <= data?.recommend && data?.recommend < 90
                      ? "잘 맞아요"
                      : 0.75 <= data?.recommend && data?.recommend < 0.84
                      ? "보통이에요"
                      : data?.recommend < 0.75
                      ? "잘 안맞아요"
                      : null
                  }
                />
              </div>
            ) : null}

            {data.review.rate !== 0 && (
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../../assets/icons/star-filled.svg")}
                  alt=""
                  width="12px"
                  height="12px"
                />
                <Caption color="poola-blue">{data.review.rate}</Caption>
                <Caption color="tertiary-black">({data.review.count})</Caption>
              </div>
            )}
          </MatchBox>
          {data.standardColor && data.standardColor.length > 0 && (
            <ColorList>
              {data.standardColor.map((color, i) => (
                <ColorChip color={color} key={i} />
              ))}
            </ColorList>
          )}
        </InfoWrapper>
      </FlexBox>
      <ReviewContainer>
        <h3>BEST REVIEW</h3>
        <Review>
          <h4>
            {data.bestReview.productName} / {data.bestReview.options} 구매
          </h4>
          <span>{data.bestReview.date}</span>
        </Review>
        <ReviewContents ellipsis={ellipsis}>
          {data.bestReview.review}
        </ReviewContents>
      </ReviewContainer>
    </Container>
  );
};

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 16.28px;
  cursor: pointer;
  height: ${({ ellipsis }) => ellipsis && "140px"};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ ellipsis }) => (ellipsis ? "295px" : "100%")};
`;

const Caption = styled.span`
  display: inline-block;
  padding-right: 4px;
  font-size: 12px;
  line-height: 15.02px;
  color: var(--color-${(props) => props.color});
`;

const Text = styled.div`
  padding-bottom: 8px;
  font-size: 13px;
  font-weight: ${({ bold }) => bold && "bold"};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
`;

const Price = styled.span`
  margin-top: ${(props) => props.top || 0}px;
  padding-right: 4px;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-black);
`;

const PriceWrapper = styled.div`
  display: flex;
  padding-bottom: 13px;
`;

const Percentage = styled.em`
  padding-right: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-poola-orange);
`;

const DefaultPrice = styled.del`
  font-size: 11px;
  line-height: 14px;
  color: var(--color-tertiary-black);
`;

const MatchBox = styled.div`
  display: flex;
  align-items: center;
`;

const ColorList = styled.ul`
  padding: 0;
  margin-top: 12px;
  margin-bottom: 0;
  list-style: none;
  line-height: 1;
`;

const ColorChip = styled.li`
  width: 12px;
  height: 12px;
  margin-right: 4px;
  display: inline-block;
  background-color: ${(props) => props.color};
  border: 1px solid var(--color-disabled-gray);
  border-radius: 50%;
`;

const ReviewContainer = styled.div`
  padding: 20px 0;
  h3 {
    margin: 0;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--color-disabled-gray);
    font-size: 14px;
    font-weight: bold;
  }
`;

const Review = styled.div`
  width: 100%;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  h4 {
    margin: 0;
    font-size: 13px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
  }

  span {
    font-size: 13px;
    color: var(--color-tertiary-black);
  }
`;

const ReviewContents = styled.div`
  line-height: 20px;
  font-size: 13px;
  margin: 0;
  padding: 0;
  width: 100%;
  ${({ ellipsis }) =>
    ellipsis &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    `}
`;
const WishButton = styled.button`
  height: 16px;
  padding: 0;
  display: flex;
  background: none;
  border: none;
`;

const PromotionLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding: 2.5px 4px;
  background-color: var(--color-poola-orange);
  border-radius: 4px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  margin-right: 12px;
  border-radius: 4px;
`;

const InfoWrapper = styled.div`
  display: inline-block;
  width: ${({ ellipsis }) => (ellipsis ? "60%" : "70%")};
`;
