import React, { useEffect } from "react";
import styled, { css } from "styled-components";

const BUTTON = {
  // color, background-color
  BLUE: ["white", "poola-blue"],
  WHITE: ["poola-blue", "white"],
};

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
`;

const DialogContainer = styled.dialog`
  width: 100%;
  max-width: 324px;
  padding: 20px 16px;
  margin: 0;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  background-color: var(--color-white);
  border-radius: 16px;
  border: none;
`;

const HeadingText = styled.h2`
  margin-bottom: 12px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-black);
  word-break: keep-all;
`;

const Text = styled.div`
  margin-bottom: 24px;
  margin-top: ${(props) => (props.sub ? "-19px" : "0")};
  text-align: center;
  font-size: 13px;
  line-height: 21px;
  color: var(--color-secondary-black);
  word-break: keep-all;
`;

const ButtonWrapper = styled.div`
  margin-top: ${(props) => props.mt}px;
  width: 100%;

  ${(props) =>
    props.isMultiple
      ? css`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 8px;
        `
      : css`
          display: flex;
          justify-content: center;
        `};
`;

const ErrorButtonWrapper = styled.div`
  margin-top: ${(props) => props.mt}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Button = styled.button`
  width: 100%;
  max-width: 142px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-${(props) => BUTTON[props.color][0]});
  background: var(--color-${(props) => BUTTON[props.color][1]});
  border: 1px solid var(--color-poola-blue);
  border-radius: 4px;
`;

const ErrorButton = styled.button`
  width: 100%;
  max-width: ${(props) => (props.wide ? "186px" : "98px")};
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-${(props) => BUTTON[props.color][0]});
  background: var(--color-${(props) => BUTTON[props.color][1]});
  border: 1px solid var(--color-poola-blue);
  border-radius: 4px;
`;

export const Dialog = ({ isOpen, text, _onClose, _onClick, wide, type }) => {
  useEffect(() => {
    // 모달 배경 스크롤 방지
    if (isOpen) {
      document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`;

      return () => {
        const scrollY = document.body.style.top;

        document.body.style.cssText = "position:''; top:'';";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      };
    }
  }, [isOpen]);

  function handleOverlayClick(e) {
    if (e.target === e.currentTarget) {
      _onClose();
    }
  }

  return (
    isOpen && (
      <ModalWrapper onClick={handleOverlayClick}>
        <DialogContainer>
          <HeadingText>{text.title}</HeadingText>
          <Text>{text.desc}</Text>
          {text?.subDesc && <Text sub>{text.subDesc}</Text>}
          {type !== "errorModal" ? (
            <ButtonWrapper
              mt={text.desc ? 24 : 32}
              isMultiple={text.button.length > 1}
            >
              {text.button.length > 1 ? (
                <>
                  <Button color="WHITE" onClick={_onClose}>
                    {text.button[0]}
                  </Button>
                  <Button color="BLUE" onClick={_onClick}>
                    {text.button[1]}
                  </Button>
                </>
              ) : (
                <Button color="BLUE" onClick={_onClick}>
                  {text.button}
                </Button>
              )}
            </ButtonWrapper>
          ) : (
            <ErrorButtonWrapper mt={text.desc ? 24 : 32}>
              <ErrorButton color="WHITE" onClick={_onClose}>
                {text.button[0]}
              </ErrorButton>
              <ErrorButton color="BLUE" onClick={_onClick} wide>
                {text.button[1]}
              </ErrorButton>
            </ErrorButtonWrapper>
          )}
        </DialogContainer>
      </ModalWrapper>
    )
  );
};
