import React, { useState, useEffect } from "react";
import Axios from "axios";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Header } from "../../components/Common/Header";
import { ScrollFilterList } from "../../components/List/ScrollFilterList";
import { useLocation } from "react-router-dom";
import userProfile from "../../assets/icons/user-profile.svg";
import { ProductCollectionCard } from "../../components/Card/ProductCollectionCard";

export const RecommendPage = ({ history }) => {
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const [filter, setFilter] = useState([type]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  async function getProducts() {
    try {
      const response = await Axios.get(
        "https://dev.poolapi.link/api/worry/list"
      );
      setCategories(response.data);

      const productsResponse = await Axios.get(
        `https://dev.poolapi.link/api/worry/detail/${user.email}`
      );
      setProducts(productsResponse.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getProducts();
  }, []);

  // 필터된 고민 상품
  const recommendProducts = products.filter(
    (products) => products.type === filter[0]
  )[0]?.products;

  const contents = products.filter((obj) => obj.type === filter[0])[0]
    ?.contents;

  function handleMoreButton(id) {
    history.push(`/product/${id}?type=review`);
  }

  return (
    <PageContainer>
      <Header type="BACK" title="고민별 추천 제품" history={history} />
      <ScrollFilterList
        type="TROUBLE"
        tags={categories}
        filter={filter}
        setFilter={setFilter}
      />
      <Section>
        <UserVoice>
          <ProfileInfo>
            <ProfileImg src={userProfile} alt="logo" />

            <div>
              <h3>{contents?.nickname}</h3>
              <span>{contents?.data}</span>
            </div>
          </ProfileInfo>
          <VoiceSection>{contents?.text}</VoiceSection>
        </UserVoice>
      </Section>
      <Section>
        {recommendProducts?.map((product, idx) => (
          <ReviewContents key={idx}>
            <ProductCollectionCard
              data={product}
              history={history}
              chip={filter}
            />
            <MoreButton
              type="button"
              onClick={() => handleMoreButton(product.productId)}
            >
              리뷰 더보기
              <img
                src={require("../../assets/icons/arrow/right-tertiary-black.svg")}
                alt="arrow"
                width="20px"
                height="20px"
              />
            </MoreButton>
          </ReviewContents>
        ))}
      </Section>
    </PageContainer>
  );
};

const PageContainer = styled.main`
  padding-top: 40px;
  width: 100%;
  max-width: 500px;
  background-color: var(--color-white);
`;

const Section = styled.section`
  padding: 12px 16px;
`;

const UserVoice = styled.article`
  background-color: #fafafa;
  padding: 16px 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
`;

const ProfileInfo = styled.div`
  display: flex;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
  }

  span {
    text-align: right;
    font-size: 12px;
    color: var(--color-tertiary-black);
  }
`;

const ProfileImg = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 9px;
`;

const VoiceSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0 8px 32px;
  font-size: 14px;
`;

const ReviewContents = styled.div`
  padding: 16px;
  border: 2px solid #f5f5f5;
  border-radius: 8px;
  margin-bottom: 24px;
`;

const MoreButton = styled.button`
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: var(--color-tertiary-black);
  background-color: var(--color-white);
  border: none;
`;
