import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import {API_ENDPOINT} from "../../Config";
import {ProductList} from "../../components/List/ProductList";

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  min-height: 100vh;
  padding: 40px 0 max(49px, calc(env(safe-area-inset-bottom) + 49px));
  background-color: var(--color-white);
`;

const Section = styled.section`
  padding-top: ${props => props.top || 0}px;
  padding-bottom: ${props => props.bottom || 0}px;
`;

const SojiPage = () => {
  const [products, setProducts] = useState([]); // 최상단 제품 (풀라에서 인기 있는 or 찰떡궁합)

  useEffect(() => {
    const cancelToken = Axios.CancelToken;
    const source = cancelToken.source();

    (async () => {
      await Axios.get(API_ENDPOINT + `/api/products/getAllProducts`, {
        cancelToken: source.token
      }).then(response => {
        if (response.data.success) {
          console.log(response.data.products);
          setProducts(response.data.products);
        } else {
          console.log("제품 정보를 가져오는 데에 실패했습니다.");
        }
      });
    })();

    return () => {
      source.cancel("request cancelled");
    };
  }, []);

  return (
    <PageContainer>
      <Section top={40}>
        {products.length === 0 ?
          <div style={{margin:'150px auto', textAlign:'center'}}>
            <Spinner animation="border" style={{color:'var(--color-poola-blue)', borderRightColor:'transparent'}}/>
          </div>
          :
          <ProductList data={products} align={'HORIZONTAL'}/>
        }
      </Section>
    </PageContainer>
  )
}

export default SojiPage;
