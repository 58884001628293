import React from "react";
import styled, { ThemeProvider } from "styled-components";
import theme from "../Common/Theme";
import ArticleCard from "../Card/ArticleCard";

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  ${({ theme, align }) => theme.alignList[align]};
`;

const ListItem = styled.li`
  width: ${(props) => (props.size === "LARGE" ? 74 : 49)}%;
  display: inline-flex;
  vertical-align: top;

  ${({ theme, align }) => theme.alignItem[align]};
`;

export const ArticleList = ({
  data,
  size,
  align = "VERTICAL",
  pageType,
  _onClick,
}) => {
  const Articles = data.map((article) => {
    return (
      <ListItem size={size} align={align} key={article._id}>
        <ArticleCard
          data={article}
          size={size}
          pageType={pageType}
          _onClick={_onClick}
        />
      </ListItem>
    );
  });

  return (
    <ThemeProvider theme={theme}>
      <List align={align}>{Articles}</List>
    </ThemeProvider>
  );
};
