import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Header } from "../../components/Common/Header";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { LoginAlert } from "../../components/Common/LoginAlert";
import { GA4EventTracker } from "../../hook/GA4EventTracker";
import Clayful from "clayful/client-js";
import Axios from "axios";

import { Selectbox } from "../../components/Common/Selectbox";
import { OrderDetailCard } from "../../components/Card/OrderDetailCard";
import checkbox_default from "../../assets/icons/check/check-default.png";
import checkbox_checked from "../../assets/icons/check/check-clicked.png";
import { formatPrice } from "../../modules/util";
import { BottomButton } from "../../components/Common/BottomButton";
import { SnackBar } from "../../components/Common/SnackBar";

const OPTIONS = [
  {
    label: "고객 변심",
    shippingFee: {
      someItems: false, // 일부 상품만 환불시 배송비 환불 (X)
      allItems: false, // 전체 상품 환불시 배송비 환불 (X)
    },
  },
  {
    label: "제품 하자",
    shippingFee: {
      someItems: false, // 일부 상품만 환불시 배송비 환불 (X)
      allItems: true, // 전체 상품 환불시 배송비 환불 (O)
    },
  },
  {
    label: "배송 전 취소",
    shippingFee: {
      someItems: false, // 일부 상품만 환불시 배송비 환불 (X)
      allItems: true, // 전체 상품 환불시 배송비 환불 (O)
    },
  },
];

const OrderRequestRefundPage = ({ history }) => {
  let { orderId } = useParams();
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보

  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [refundReason, setRefundReason] = useState(""); // 환불 사유;
  const [detailReason, setDetailReason] = useState(""); // 상세 사유;
  const [selectToggle, setSelectToggle] = useState(false);
  const [refundItems, setRefundItems] = useState([]); // 환불 요청 상품
  const [allChecked, setAllChecked] = useState(true); // 전체 선택
  const [checked, setChecked] = useState({}); // 개별 선택
  const [allData, setAllData] = useState([]); // 전체 데이터
  const [showSnackBar, setShowSnackBar] = useState(false); // 환불 요청 완료 스낵바

  const handleSelectRefundReason = () => {
    setSelectToggle((prev) => !prev);
  };

  const handleSelectOptionsRefundReason = (value) => {
    setSelectToggle((prev) => !prev);
    setRefundReason(value);
  };

  const handleDetailReason = (e) => {
    setDetailReason(e.target.value);
  };

  // 전체 상품 체크박스 활성화
  const createInitialCheckboxes = (data) => {
    return data.reduce((acc, item) => {
      acc[item._id] = true;
      return acc;
    }, {});
  };

  const handleAllCheck = () => {
    setAllChecked(!allChecked);

    if (allChecked) {
      setRefundItems([]);
      setChecked({});
    } else {
      setRefundItems(data);
      setChecked(createInitialCheckboxes(data));
    }
  };

  const deleteRefundItem = (id) => {
    setRefundItems((prev) => {
      return prev.filter((item) => item._id !== id);
    });
  };

  const addRefundItem = (id) => {
    const itemIndex = refundItems.findIndex((item) => item._id === id);
    if (itemIndex === -1) {
      const addItem = data.find((item) => item._id === id);
      setRefundItems((prev) => [...prev, addItem]);
    }
  };

  // 개별선택 handler
  const handleCheckboxChange = (id) => {
    const newCheckboxes = { ...checked };
    newCheckboxes[id] = !newCheckboxes[id];
    setChecked(newCheckboxes);

    if (!newCheckboxes[id]) {
      deleteRefundItem(id);
    } else {
      addRefundItem(id);
    }
  };

  async function getOrder() {
    const Order = Clayful.Order;
    const options = { customer: sessionStorage.getItem("__tct__") };

    Order.getForMe(orderId, options, function (err, result) {
      if (err) console.log(err);
      if (result) {
        const refundsInProcess =
          result.data.refunds &&
          result.data.refunds.filter(({ status }) => status !== "cancelled");

        const notRefundedItems = result.data.items?.filter((item) => {
          return !refundsInProcess?.some((refund) => {
            return refund.items.some((x) => x.item._id === item._id);
          });
        });
        setAllData(result.data);
        setData(notRefundedItems);
        setRefundItems(notRefundedItems);
        setChecked(createInitialCheckboxes(notRefundedItems));
        setSpinner(false);
      }
    });
  }

  useEffect(() => {
    if (user.email) {
      getOrder();
    }
  }, []);

  useEffect(() => {
    const count = Object.values(checked).filter((value) => value === true)
      .length;
    if (count === data.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [checked]);

  function calculateRefundAmount(orderData, selectedOption) {
    if (
      (selectedOption === "배송 전 취소" || selectedOption === "제품 하자") &&
      refundItems?.length === allData.items.length
    ) {
      // "배송 전 취소" 옵션 선택 시, 모든 상품 환불일 때 배송비 합산
      const totalShippingFee = orderData.reduce((acc, item) => {
        return (
          acc +
          item.shipments.reduce((feeAcc, shipment) => {
            return feeAcc + shipment?.fee?.original?.convertedRaw;
          }, 0)
        );
      }, 0);

      return totalShippingFee;
    } else {
      // 다른 옵션 선택 시, 배송비 환불하지 않음
      return 0;
    }
  }

  const totalRefundProductsAmount = refundItems.reduce((acc, item) => {
    const quantity = Number(item?.quantity?.converted) / item?.quantity?.raw;
    const price = item?.total?.price?.sale?.raw || 0;
    return acc + price / quantity;
  }, 0);

  // 환불 계산
  // 환불 배송비
  const refundShippingFee = calculateRefundAmount([allData], refundReason);
  // 환불 총 금액
  const totalRefundAmount = formatPrice(
    refundShippingFee + totalRefundProductsAmount
  );

  // 전체 주문 상품이 환불 되었는 지 확인
  const hasRefundableItem = data?.length !== 0;
  const disabledRefundButton = refundReason === "" || refundItems?.length === 0;

  // 환불하고 나서 order/detail/orderId 로 push

  async function requestRefunds() {
    const tct = sessionStorage.getItem("__tct__");

    const headers = {
      Accept: "application/json",
      "Accept-Encoding": "gzip",
      "Content-Type": "application/json",
      Authorization:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjZhMWYwZTMwYjQzZjhhYmIyMjE0MTQwZTk0ZWYyNDZhYzEyZTJjYjMyNTUzMGQ1ZmU2NGQ1ODNjYTVlYjhlMTEiLCJyb2xlIjoiY2xpZW50IiwiaWF0IjoxNjMxMjg1Njg4LCJzdG9yZSI6IkVDSkVBM0RUTlpKNy45OThEV0dLSEJOWFQiLCJzdWIiOiJMSEFIWVozN1dFMlgifQ.7RPEyRE6D-2jMoyw6Bx1O1z4bcagvkF_lxhB1aXtAJw",
      "Authorization-Customer": tct,
    };

    const payload_items = refundItems.map((x) => ({
      item: x._id,
      quantity: x.quantity.raw,
    }));

    const payload = {
      items: payload_items,
      reason: `[${refundReason}]: ${detailReason}`,
      shipments: [],
    };

    Axios.post(
      `https://api.clayful.io/v1/me/orders/${orderId}/refunds`,
      payload,
      { headers: headers }
    ).then((res) => {
      setShowSnackBar(true);

      setTimeout(() => {
        setShowSnackBar(false);
        GA4EventTracker("주문상세_보기");
        history.push(`/order/detail/${orderId}`);
      }, 3000);
    });
  }

  const _onQuantityClick = (type, ID) => {
    if (type === "minus") {
      const updatedMinusRefundItems = refundItems.map((item) => {
        if (item._id === ID && item.quantity.raw > 1) {
          return {
            ...item,
            quantity: {
              ...item.quantity,
              raw: item.quantity.raw - 1,
            },
          };
        }
        return item;
      });
      setRefundItems(updatedMinusRefundItems);
    }

    if (type === "plus") {
      const updatedPlusRefundItems = refundItems.map((item) => {
        if (
          item._id === ID &&
          item.quantity.raw < Number(item.quantity.formatted)
        ) {
          return {
            ...item,
            quantity: {
              ...item.quantity,
              raw: item.quantity.raw + 1,
            },
          };
        }
        return item;
      });
      setRefundItems(updatedPlusRefundItems);
    }
  };

  return (
    <>
      <Header type="BACK" title="환불" history={history} />
      {user.email && !spinner ? (
        <PageContainer>
          {hasRefundableItem ? (
            <>
              <Section noBorder>
                <Title>환불 사유</Title>
                <SelectContainer>
                  <Selectbox
                    select={refundReason}
                    toggle={selectToggle}
                    _onClick={handleSelectRefundReason}
                    _onClickOptions={handleSelectOptionsRefundReason}
                    label={"환불 사유 선택"}
                    options={OPTIONS}
                  />
                </SelectContainer>
                <Textarea
                  placeholder="자세한 환불 이유를 입력해주세요."
                  onChange={handleDetailReason}
                />
              </Section>
              <Section>
                <TitleBox>
                  <Title>환불 요청 상품</Title>
                  <div>
                    <AllCheckboxText>전체 선택</AllCheckboxText>
                    <CheckButton type="button" onClick={handleAllCheck}>
                      <img
                        src={allChecked ? checkbox_checked : checkbox_default}
                        alt="check_default"
                      />
                    </CheckButton>
                  </div>
                </TitleBox>
                {data?.map((item, index) => {
                  const refundData = refundItems.find(
                    ({ _id }) => _id === item._id
                  );
                  return (
                    <OrderDetailCard
                      data={item}
                      key={index}
                      history={history}
                      refund
                      checked={checked}
                      checkHandler={handleCheckboxChange}
                      _onQuantityClick={_onQuantityClick}
                      refundData={refundData}
                    />
                  );
                })}
              </Section>
              <Section>
                <Title>환불 금액</Title>
                {refundReason === "" && (
                  <RefundText>환불 사유 선택 후에 계산됩니다.</RefundText>
                )}
                {refundReason !== "" && (
                  <TableBox>
                    <Table>
                      <tbody>
                        <tr>
                          <th>상품 환불액</th>
                          <td>{formatPrice(totalRefundProductsAmount)}원</td>
                        </tr>
                        <tr>
                          <th>배송비 환불액</th>
                          <td>{formatPrice(refundShippingFee)}원</td>
                        </tr>
                        <tr>
                          <th>총 금액</th>
                          <ColorTd>{totalRefundAmount}원</ColorTd>
                        </tr>
                      </tbody>
                    </Table>
                  </TableBox>
                )}
              </Section>
              <BottomButton
                text={"환불 요청"}
                disabled={disabledRefundButton}
                onClick={requestRefunds}
              />
              {showSnackBar && (
                <SnackBar
                  type="ALERT"
                  text="환불 요청이 완료되었습니다."
                  bottom={56}
                  show={showSnackBar}
                />
              )}
            </>
          ) : (
            <NoHasRefundableText>환불할 상품이 없습니다.</NoHasRefundableText>
          )}
        </PageContainer>
      ) : (
        <>
          <div
            style={{
              height: "calc(100vh - 40px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              animation="border"
              style={{
                color: "var(--color-poola-blue)",
                borderRightColor: "transparent",
              }}
            />
          </div>
          <LoginAlert isLogin={user.email} history={history} />
        </>
      )}
    </>
  );
};

export default OrderRequestRefundPage;

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  padding-top: 40px;
  padding-bottom: 56px;
  background-color: var(--color-white);
`;

const Section = styled.section`
  position: relative;
  padding: 20px 16px;
  border-top: ${({ noBorder }) =>
    noBorder ? "none" : "8px solid var(--color-bg-gray)"};
`;

const Title = styled.h4`
  color: var(--color-poola-blue);
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`;

const SelectContainer = styled.div`
  padding: 20px 0 12px 0;
`;

const Textarea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 10px 16px;
  min-height: 109px;
  font-family: inherit;
  border: 1px solid var(--color-disabled-gray);
  color: var(--color-black);
  font-size: 14px;
  outline: none;

  ::placeholder {
    color: var(--color-tertiary-black);
    font-size: 13px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AllCheckboxText = styled.span`
  font-size: 11px;
  color: var(--color-tertiary-black);
  padding-right: 8px;
`;

const CheckButton = styled.button`
  background: none;
  border: none;
  padding: 0;

  img {
    width: 16px;
    height: 16px;
  }
`;

const RefundText = styled.div`
  padding: 20px 0;
  font-size: 14px;
  color: var(--color-tertiary-black);
`;

const TableBox = styled.div`
  padding-top: 18px;
`;

const Table = styled.table`
  width: 100%;
  tbody {
    tr {
      th {
        padding: 4px 8px 4px 0;
        font-weight: normal;
        text-align: left;
        font-size: 14px;
        line-height: 22px;
        color: var(--color-tertiary-black);
        vertical-align: top;

        b {
          color: var(--color-black);
        }
      }

      td {
        padding: 4px 0;
        font-size: 14px;
        line-height: 22px;
        text-align: end;
      }
    }
  }
`;

const ColorTd = styled.td`
  padding: 4px 0;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-poola-blue);
  text-align: end;
`;

const NoHasRefundableText = styled.h4`
  color: var(--color-tertiary-black);
  padding: 40px 0;
  font-size: 16px;
  text-align: center;
`;
