import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {clickTrigger} from "../../modules/trigger";

const Card = styled(Link)`
  height: 46px;
  margin: 12px 16px 0;
  padding: 0 16px 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-bg-gray);
  border-radius: 4px;
`;

const Text = styled.span`
  margin: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: var(--color-secondary-black);
`;

const TestText = styled.em`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-poola-blue);
`;

export const TestCard = () => {

  return (
    <Card to="/test" onClick={() => clickTrigger('NudgeTest배너')}>
      <Text>내 가슴과 매칭률을 보고 싶다면?</Text>
      <TestText>테스트 하러가기</TestText>
    </Card>
  )
}
