import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Axios from "axios";
import { API_ENDPOINT } from "../../Config";
import { clickTrigger } from "../../modules/trigger";
import { SignOutDialog } from "../../components/Modal/SignOutDialog";
import { Header } from "../../components/Common/Header";
import { LoginAlert } from "../../components/Common/LoginAlert";
import { getUser } from "../../lib/api/auth";
import Spinner from "react-bootstrap/Spinner";

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  padding-top: 40px;
  padding-bottom: 56px;
  background-color: var(--color-white);
`;

const InputWrapper = styled.div`
  padding: 0 16px;
  margin-bottom: 16px;
`;

const Label = styled.label`
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 23px;
`;

const Input = styled.input`
  width: 100%;
  height: 38px;
  padding: 0 12px;
  font-size: 14px;
  color: var(--color-black);
  border-radius: 4px;
  border: 1px solid var(--color-border-gray);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: none;
    border: 1px solid var(--color-poola-blue);
  }
  &::placeholder {
    color: var(--color-tertiary-black);
  }
  &:disabled {
    background-color: var(--color-disabled-gray);
    color: var(--color-tertiary-black);
  }
`;

const SignOutButton = styled.button`
  margin: 60px auto 0;
  display: block;
  font-size: 14px;
  line-height: 22px;
  background-color: var(--color-white);
  border: none;
  color: var(--color-tertiary-black);
  text-decoration-line: underline;
`;

const Button = styled.button`
  width: 100%;
  max-width: 500px;
  height: 56px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 500;
  color: var(
    --color-${(props) => (props.isactive ? "white" : "tertiary-black")}
  );
  background-color: var(
    --color-${(props) => (props.isactive ? "poola-blue" : "disabled-gray")}
  );
  border: none;
`;

const UpdateInfoPage = ({ history, location }) => {
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  const [name, setName] = useState(""); // 이름
  const [phone, setPhone] = useState(""); // 연락처
  const [email, setEmail] = useState(""); // 이메일
  const [activeBtn, setActiveBtn] = useState(false); // 적용하기 버튼 활성화 여부
  const [modalVisible, setModalVisible] = useState(false);
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    if (user.email) {
      getUser({ email: user?.email }).then((r) => {
        const user = r.data.user;

        setName(user.name);
        setPhone(user.phone);
        setEmail(user.email);
        setSpinner(false);
      });
    }
  }, []);

  const handleInputChange = (e) => {
    if (e.currentTarget.name === "name") {
      setName(checkName(e.target.value));
    } else if (e.currentTarget.name === "phone") {
      setPhone(checkNumber(e.target.value));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const regExp = /^(010|011|016|017|018|019)-\d{3,4}-\d{4}$/u; // 010~019 전화번호 정규식

    if (!name) alert("이름을 입력해주세요.");
    else if (!regExp.test(phone)) alert("유효한 휴대폰 번호를 입력해주세요.");
    else {
      if (activeBtn) {
        let userBody = {
          email: user.email,
          name: name,
          phone: phone,
        };
        setActiveBtn(false);
        Axios.put(API_ENDPOINT + "/api/oauth/setUser", userBody).then(
          (response) => {
            if (response.data.success) {
              if (
                document.referrer &&
                document.referrer.indexOf("/order") !== -1
              ) {
                // 이전 페이지가 주문하기인 경우
                window.location = document.referrer;
              } else {
                alert("성공적으로 변경되었습니다.");
              }
            } else {
              console.log("기본 정보 변경 실패");
              alert("오류가 발생했습니다. 다시 시도해주세요.");
              setActiveBtn(true);
            }
          }
        );
      }
    }
    clickTrigger("수정하기");
  };

  const checkName = (name) => {
    // 이름 입력 양식
    const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi; // 특수문자 제외 정규식
    const text = name.replace(regExp, "");

    if (regExp.test(name)) alert("특수문자를 제외한 이름을 입력해주세요.");
    else if (!text) setActiveBtn(false);
    else setActiveBtn(true);

    return text;
  };

  const checkNumber = (number) => {
    // 전화번호 입력 양식
    const num = number.replace(/[^0-9]/g, "");
    let phone = "";
    if (num.length >= 10) {
      // 10자리 이상일 때만 버튼 활성화
      setActiveBtn(true);
    } else {
      if (activeBtn) {
        setActiveBtn(false);
      }
    }

    if (num.length < 4) {
      return num;
    } else if (num.length < 7) {
      phone += num.substr(0, 3);
      phone += "-";
      phone += num.substr(3);
      return phone;
    } else if (num.length < 11) {
      phone += num.substr(0, 3);
      phone += "-";
      phone += num.substr(3, 3);
      phone += "-";
      phone += num.substr(6);
      return phone;
    } else {
      phone += num.substr(0, 3);
      phone += "-";
      phone += num.substr(3, 4);
      phone += "-";
      phone += num.substr(7, 4);
      return phone;
    }
  };

  const openSignOutModal = (event) => {
    event.preventDefault();
    setModalVisible(true);
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setModalVisible(false);
    }
  };

  const deleteUser = (email) => {
    // 풀라 계정 삭제
    Axios.delete(API_ENDPOINT + `/api/oauth/signout/${email}`).then(
      (response) => {
        if (response.data.success) {
          // 로그아웃
          window.sessionStorage.clear();
          window.sessionStorage.removeItem("user");
          window.localStorage.clear();
          console.log("계정 삭제 성공");
          window.sessionStorage.setItem("signout", "true");
          window.location.href = "/my"; // 마이페이지로 이동
        } else {
          console.log("계정 삭제 실패");
        }
      }
    );
  };

  const disconnectOauth = () => {
    if (user.platform === "KAKAO") {
      // 카카오 연결 해제
      window.Kakao.API.request({
        url: "/v1/user/unlink",
        success: function (response) {
          console.log("카카오 연결 해제 성공");
          deleteUser(email);
        },
        fail: function (error) {
          alert("탈퇴 처리에 실패했습니다. 관리자에게 문의하세요.");
          console.log(error);
        },
      });
    } else if (user.platform === "GOOGLE") {
      // 구글 연결 해제

      if (!window["gapi"].auth2) {
        window["gapi"].load("auth2", function () {
          window["gapi"].auth2.init();
        });
        return window["gapi"].auth2
          ?.getAuthInstance()
          ?.disconnect()
          .then(function () {
            console.log("구글 연결 해제 성공");
            deleteUser(email);
          });
      }
      return window["gapi"].auth2
        ?.getAuthInstance()
        ?.disconnect()
        .then(function () {
          console.log("구글 연결 해제 성공");
          deleteUser(email);
        });
    }
    clickTrigger("탈퇴하기");
  };

  return (
    <>
      <Header type="BACK" title="기본 정보" history={history} />
      {user.email ? (
        <PageContainer>
          {spinner ? (
            <div
              style={{
                height: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                animation="border"
                style={{
                  color: "var(--color-poola-blue)",
                  borderRightColor: "transparent",
                }}
              />
            </div>
          ) : (
            <form onSubmit={handleSubmit} style={{ paddingTop: "24px" }}>
              <InputWrapper>
                <Label htmlFor="name">이름*</Label>
                <Input
                  type="text"
                  name="name"
                  value={name || ""}
                  placeholder="(필수)이름을 입력해주세요"
                  onChange={handleInputChange}
                />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="email">이메일</Label>
                <Input value={email || ""} name="email" disabled />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="phone">휴대폰 번호*</Label>
                <Input
                  type="text"
                  name="phone"
                  value={phone || ""}
                  placeholder="(필수)연락처를 입력해주세요"
                  onChange={handleInputChange}
                />
              </InputWrapper>
              <Button type="submit" isactive={activeBtn} disabled={!activeBtn}>
                적용하기
              </Button>
              <SignOutButton onClick={openSignOutModal}>탈퇴하기</SignOutButton>
            </form>
          )}
        </PageContainer>
      ) : (
        <>
          <div
            style={{
              height: "calc(100% - 40px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              animation="border"
              style={{
                color: "var(--color-poola-blue)",
                borderRightColor: "transparent",
              }}
            />
          </div>
          <LoginAlert isLogin={user.email} history={history} />
        </>
      )}
      {modalVisible && (
        <SignOutDialog
          isSignOut={false}
          visible={modalVisible}
          signOutHandler={disconnectOauth}
          setModalVisible={setModalVisible}
          onClose={handleOverlayClick}
        />
      )}
    </>
  );
};

export default UpdateInfoPage;
