import {css} from "styled-components";

const alignList = {
  VERTICAL: css`
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
  `,
  HORIZONTAL: css`
    padding: 0 16px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    @media (hover: none) { // mobile
      -ms-overflow-style: none; // IE and Edge
      &::-webkit-scrollbar {
        display: none; // Chrome, Safari, Opera
      }
    }
  `,
};

const alignItem = {
  VERTICAL: css`
    padding-bottom: 28px;
    &:nth-last-child(1), &:nth-last-child(2) {
      padding-bottom: 0;
    }
  `,
  HORIZONTAL: css`
    margin-right: 4px;
    &:last-child {
      margin-right: 0;
    }
  `,
}

const theme = {alignList, alignItem}

export default theme;