import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Axios from "axios";
import { API_ENDPOINT } from "../../Config";
import { useSelector } from "react-redux";
import { clickTrigger } from "../../modules/trigger";
import { GA4EventTracker } from "../../hook/GA4EventTracker";
import ReactPixel from "react-facebook-pixel";

const Card = styled(Link)`
	width: 100%;
	display: inline-block;
`;

const ImageWrapper = styled.div`
	width: 100%;
	position: relative;
	padding-top: 100%;
	overflow: hidden;
`;

const Image = styled.img`
	position: absolute;
	top: 0;
	bottom: 0;
	object-fit: cover;
`;

const CircleTag = styled.em`
	z-index: 1;
	width: 38px;
	height: 38px;
	padding: 13px 0 11px 0;
	position: absolute;
	left: 8px;
	top: 8px;
	text-align: center;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 14px;
	color: #ffffff;
	background-color: var(--color-poola-orange);
	border-radius: 50%;
`;

const PromotionLabel = styled.div`
	z-index: 1;
	position: absolute;
	bottom: 0;
	left: 0;
	font-size: 12px;
	font-weight: bold;
	color: white;
	padding: 5px 6px 4px;
	background-color: var(--color-poola-orange);
`;

const InfoWrapper = styled.div`
	padding-top: 12px;
	white-space: normal;
`;

const BrandText = styled.em`
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	color: var(--color-secondary-black);
`;

const WishButton = styled.button`
	height: 16px;
	padding: 0 8px 0 0;
	display: flex;
	background: none;
	border: none;
`;

const ProductText = styled.strong`
	margin-top: 7px;
	display: block;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	color: var(--color-secondary-black);
`;

const PriceWrapper = styled.div`
	margin-top: 8px;
	display: flex;
`;

const Percentage = styled.em`
	padding-right: 4px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 14px;
	color: var(--color-poola-orange);
`;

const Price = styled.span`
	margin-top: ${(props) => props.top || 0}px;
	padding-right: 4px;
	display: flex;
	font-weight: bold;
	font-size: 14px;
	line-height: 14px;
	color: var(--color-black);
`;

const DefaultPrice = styled.del`
	font-size: 11px;
	line-height: 14px;
	color: var(--color-tertiary-black);
`;

const Caption = styled.span`
	display: inline-block;
	font-size: 12px;
	line-height: 14px;
	color: var(--color-${(props) => props.color});
`;

const Matching = styled.em`
	padding: 3px 6px;
	margin-right: 6px;
	display: flex;
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
	font-weight: 700;
	color: var(--color-poola-${(props) => props.color});
	border-radius: 3px;
	background-color: var(--color-poola-${(props) => props.color}-light);
`;

const Flag = styled.button`
	height: 20px;
	padding: 4px 6px 3px;
	margin: 6px 3px 0 0;
	font-weight: 500;
	font-size: 11px;
	line-height: 13px;
	color: #848d9a;
	background-color: #f1f4f8;
	border: none;
	border-radius: 3px;
`;

const ColorList = styled.ul`
	padding: 0;
	margin-top: 12px;
	list-style: none;
	line-height: 1;
`;

const ColorChip = styled.li`
	width: 12px;
	height: 12px;
	margin-right: 4px;
	display: inline-block;
	background-color: ${(props) => props.color};
	border: 1px solid var(--color-disabled-gray);
	border-radius: 50%;
`;

const ProductCard = ({ data, type, rating, event = "제품", _onClick, dumRank, collection, which, my, detail }) => {
	const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
	const [heart, setHeart] = useState(data.wish);
	const isBra = data.isWire === 0 || data.isWire === 1;
	const intRate = Math.ceil(rating.rate); // 정수
	const floatRate = (Math.ceil((rating.rate / rating.count) * 10) / 100) * 10; // 소수점 아래 한자리

	useEffect(() => {
		setHeart(data.wish);
	}, [data]);

	const GA4EventHandler = (eventName, key, value) => {
		let eventParams = {};
		eventParams[key] = value;
		GA4EventTracker(eventName, eventParams);
	};

	const updateWishList = (body) => {
		// 찜 목록에 추가하기
		Axios.post(API_ENDPOINT + `/api/user/${body.userEmail}/wish/product/${body.productId}`, {
			action: body.action,
		}).then((response) => {
			if (response.data.success) {
				// console.log("찜 성공");
			} else {
				console.log("찜 실패");
			}
		});
	};

	const handleButtonClick = (e, product) => {
		e.preventDefault();
		e.stopPropagation();
		if (!user.email) {
			window.location.href = "/login";

			clickTrigger("찜하기", {
				productInfo: {
					name: data.name,
					brand: data.brand,
					price: data.price,
					rate: floatRate,
					reviews: rating.count,
					fit: data.recommend * 100,
				},
			});
		} else {
			if (heart) {
				// 찜 선택 -> 해제
				let heartbody = {
					userEmail: user.email,
					productId: product._id,
					action: "delete",
				};
				clickTrigger("찜취소", {
					productInfo: {
						name: data.name,
						brand: data.brand,
						price: data.price,
						rate: floatRate,
						reviews: rating.count,
						fit: data.recommend * 100,
					},
				});
				updateWishList(heartbody);
				setHeart(!heart);
			} else {
				let heartbody = {
					userEmail: user.email,
					productId: product._id,
					action: "add",
				};
				clickTrigger("찜하기", {
					productInfo: {
						name: data.name,
						brand: data.brand,
						price: data.price,
						rate: floatRate,
						reviews: rating.count,
						fit: data.recommend * 100,
					},
				});
				GA4EventHandler("찜하기_클릭", "제품명", data.name);
				updateWishList(heartbody);
				setHeart(!heart);
				ReactPixel.track("AddToWishlist", {
					currency: "KRW",
					value: data.price,
					content_ids: product._id,
					content_name: data.name,
					content_type: "product",
				});
			}
		}
	};

	return (
		<Card
			to={`/product/${data._id}`}
			onClick={() => {
				sessionStorage.setItem("productInfo", JSON.stringify(data));
				clickTrigger(event, {
					productInfo: {
						name: data.name,
						brand: data.brand,
						price: data.price,
						rate: floatRate,
						reviews: rating.count,
						fit: data.recommend * 100,
					},
				});
				if ((!detail && collection) || (!detail && which)) {
					GA4EventHandler("제품_클릭", "위치", collection ?? which);
				}
				if (collection) {
					GA4EventHandler("컬렉션_제품_클릭", "컬렉션명", collection);
					GA4EventHandler("컬렉션_제품_클릭", "제품명", data.name);
				}
				if (my) {
					GA4EventHandler("홈부터개인화_제품_클릭", "제품명", data.name);
				}
				if (which === "카테고리페이지") {
					GA4EventHandler("카테고리_제품_클릭", "제품명", data.name);
				}
				if (which === "브랜드페이지") {
					GA4EventHandler("브랜드_제품_클릭", "제품명", data.name);
					GA4EventHandler("브랜드_제품_클릭", "브랜드명", data.brand);
				}
				if (which === "추천상품") {
					GA4EventHandler("제품상세_추천상품_클릭", "제품명", data.name);
					GA4EventHandler("제품상세_추천상품_클릭", "브랜드명", data.brand);
				}
				if (which === "연관상품") {
					GA4EventHandler("제품상세_연관상품_클릭", "제품명", data.name);
					GA4EventHandler("제품상세_연관상품_클릭", "브랜드명", data.brand);
				}
				if (which === "인기상품") {
					GA4EventHandler("제품상세_인기상품_클릭", "제품명", data.name);
					GA4EventHandler("제품상세_인기상품_클릭", "브랜드명", data.brand);
				}

				GA4EventHandler("제품_클릭", "제품명", data.name);
				GA4EventHandler("제품_클릭", "브랜드명", data.brand);
			}}
		>
			<ImageWrapper>
				{type === "RANKING" && <CircleTag>{dumRank + 1}위</CircleTag>}
				{/* {PROMOTION_SLUG_POOLABASIC.some((p) => p === data?.No) && (
          <PromotionLabel>단독특가</PromotionLabel>
        )} */}
				<Image src={data.isMP ? data.thumb : data.img} alt={data.name} width="100%" height="100%" />
			</ImageWrapper>
			<InfoWrapper>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<BrandText>{data.brand}</BrandText>
					<WishButton onClick={(e) => handleButtonClick(e, data)}>
						<img
							src={require("../../assets/icons/heart-" + (heart ? "filled" : "default") + ".png")}
							alt={heart ? "찜하기" : "찜 취소"}
							width="16px"
							height="16px"
						/>
					</WishButton>
				</div>
				<ProductText>{data.name}</ProductText>
				{data.discount && data.discount.value !== null && data.discount.value !== 0 ? (
					<PriceWrapper>
						<Percentage>
							{data?.discount?.type === "percentage"
								? data.discount.value
								: Math.round((data.discount.value / data.price) * 100)}
							%
						</Percentage>
						<Price>
							{(data?.price - data?.discount?.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
						</Price>
						<DefaultPrice>{data?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</DefaultPrice>
					</PriceWrapper>
				) : (
					<Price top={8}>{data?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</Price>
				)}
				{type === "REVIEW" ? (
					<div style={{ marginTop: "12px", display: "flex", alignItems: "center" }}>
						{[...Array(5)].map((r, i) => {
							if (i > intRate && i !== 4)
								return (
									<img
										src={require("../../assets/icons/star-default.svg")}
										alt=""
										width="12px"
										height="12px"
										key={i}
									/>
								);
							else if (i > intRate && i === 4)
								return (
									<img
										src={require("../../assets/icons/star-default.svg")}
										alt=""
										width="12px"
										height="12px"
										key={i}
									/>
								);
							else if (i < intRate && i !== 4)
								return (
									<img
										src={require("../../assets/icons/star-filled.svg")}
										alt=""
										width="12px"
										height="12px"
										key={i}
									/>
								);
							else if (i < intRate && i === 4)
								return (
									<img
										src={require("../../assets/icons/star-filled.svg")}
										alt=""
										width="12px"
										height="12px"
										key={i}
									/>
								);
							else if (i === intRate && i !== 4) {
								if ((floatRate - intRate).toFixed(2) >= 0.5)
									return (
										<img
											src={require("../../assets/icons/star-half.svg")}
											alt=""
											width="12px"
											height="12px"
											key={i}
										/>
									);
								else
									return (
										<img
											src={require("../../assets/icons/star-default.svg")}
											alt=""
											width="12px"
											height="12px"
											key={i}
										/>
									);
							} else {
								if ((floatRate - intRate).toFixed(2) >= 0.5)
									return (
										<img
											src={require("../../assets/icons/star-half.svg")}
											alt=""
											width="12px"
											height="12px"
											key={i}
										/>
									);
								else
									return (
										<img
											src={require("../../assets/icons/star-default.svg")}
											alt=""
											width="12px"
											height="12px"
											key={i}
										/>
									);
							}
						})}
						<Caption color="tertiary-black">({rating.count})</Caption>
					</div>
				) : type === "RESULT" ? (
					<>
						{(isBra || data.recommend || (rating && rating.count > 0)) && (
							<>
								{rating && rating.count > 0 && (
									<div
										style={{
											marginTop: "6px",
											display: "inline-flex",
											alignItems: "center",
										}}
									>
										<img
											src={require("../../assets/icons/star-filled.svg")}
											alt=""
											width="12px"
											height="12px"
										/>
										<Caption color="poola-blue">{rating.rate}</Caption>
										<Caption color="tertiary-black">({rating.count})</Caption>
									</div>
								)}
								{isBra && data.recommend !== null && (
									<>
										<div style={{ marginTop: "16px", display: "flex" }}>
											<Matching color={data.recommend < 0.75 ? "red" : "blue"}>
												{0.9 <= data.recommend
													? "찰떡궁합"
													: 0.84 <= data.recommend && data.recommend < 0.9
													? "잘 맞아요"
													: 0.75 <= data.recommend && data.recommend < 0.84
													? "보통이에요"
													: data.recommend < 0.75
													? "잘 안맞아요"
													: null}
												&nbsp;
												{data.recommend >= 0.84 && (
													<img
														src={require("../../assets/icons/up.png")}
														alt="match"
														width="12px"
														height="12px"
													/>
												)}
											</Matching>
										</div>
										<div>
											{data.isFlagOpen ? (
												data.flag.map((f) => (
													<Flag disabled key={f}>
														#{f}
													</Flag>
												))
											) : (
												<>
													<Flag disabled>#{data.flag[0]}</Flag>
													<Flag onClick={(e) => _onClick(e, data)}>
														+{data.flag.length - 1}
													</Flag>
												</>
											)}
										</div>
									</>
								)}
							</>
						)}
					</>
				) : (
					<>
						{(data.approval_status || isBra || data.recommend || (rating && rating.count > 0)) && (
							<div style={{ marginTop: "12px", display: "flex" }}>
								{(isBra || data.recommend) && (
									<>
										{data.recommend ? (
											<Matching color={data.recommend < 0.75 ? "red" : "blue"}>
												{0.9 <= data.recommend
													? "찰떡궁합"
													: 0.84 <= data.recommend && data.recommend < 0.9
													? "잘 맞아요"
													: 0.75 <= data.recommend && data.recommend < 0.84
													? "보통이에요"
													: data.recommend < 0.75
													? "잘 안맞아요"
													: null}
												&nbsp;
												{data.recommend >= 0.84 && (
													<img
														src={require("../../assets/icons/up.png")}
														alt="match"
														width="12px"
														height="12px"
													/>
												)}
											</Matching>
										) : !user.type ? (
											<Matching color="blue">나와 잘 맞을까?</Matching>
										) : null}
									</>
								)}
								{rating && rating.count > 0 && (
									<div style={{ display: "inline-flex", alignItems: "center" }}>
										<img
											src={require("../../assets/icons/star-filled.svg")}
											alt=""
											width="12px"
											height="12px"
										/>
										<Caption color="poola-blue">{rating.rate}</Caption>
										<Caption color="tertiary-black">({rating.count})</Caption>
									</div>
								)}
							</div>
						)}
						{data.standardColor && data.standardColor.length > 0 && (
							<ColorList>
								{data.standardColor.map((color, i) => (
									<ColorChip color={color} key={i} />
								))}
							</ColorList>
						)}
					</>
				)}
			</InfoWrapper>
		</Card>
	);
};

ProductCard.defaultProps = {
	data: {
		img: "https://s3.ap-northeast-2.amazonaws.com/poola-photo-2.0/w200/2ndskin_17.png",
		No: 0,
		brand: "풀라",
		name: "심리스 V넥 브라렛",
		price: "8900",
		recommend: 0.99,
		standardColor: ["#FFF5F5", "#5E8550", "#EB805F"],
	},
	align: "VERTICAL",
	margin: 28,
	rating: {
		rate: 0,
		count: 0,
	},
};

export default React.memo(ProductCard);
