import React from "react";
import styled from "styled-components";

const Button = styled.button`
  width: 100%;
  max-width: 500px;
  height: max(56px, calc(env(safe-area-inset-bottom) + 56px));
  padding: 0 0 calc(env(safe-area-inset-bottom));
  position: fixed;
  bottom: 0;
  z-index: 10;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-white);
  background-color: ${props => props.bgColor};
  border: none;

  &:disabled {
    color: var(--color-tertiary-black);
    background-color: var(--color-disabled-gray);
  }
`;

export const BottomButton = ({text, bgColor = 'var(--color-poola-blue)', disabled, onClick}) => {

  return (
    <Button bgColor={bgColor} disabled={disabled} onClick={onClick}>
      {text}
    </Button>
  );
}