import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { LoginAlert } from "../../components/Common/LoginAlert";
import { GA4EventTracker } from "../../hook/GA4EventTracker";
import Clayful from "clayful/client-js";
import { useScript } from "../../hook/useScript";
import ReactPixel from "react-facebook-pixel";

const OrderSuccessPage = ({ history }) => {
  let { orderId } = useParams();
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보

  const [spinner, setSpinner] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0); // 결제 금액
  const [data, setData] = useState([]); //주문 정보

  const getOrder = () => {
    const Order = Clayful.Order;
    const options = { customer: sessionStorage.getItem("__tct__") };

    Order.getForMe(orderId, options, function (err, result) {
      if (err) console.log(err.code);
      if (result) {
        setTotalAmount(result.data.total.amount.raw);
        setData(result.data);
        setSpinner(false);

        const extractedItems = result.data.items.map((item) => {
          return {
            id: item.variant._id,
            quantity: item.quantity.raw,
          };
        });

        ReactPixel.track("Purchase", {
          currency: "KRW",
          value: result.data.total.amount.raw,
          contents: extractedItems,
        });
      }
    });
  };

  useEffect(() => {
    if (user.email) {
      getOrder();
    }
  }, []);

  useScript("1", totalAmount, "check-script");

  const handleOrderDetail = () => {
    GA4EventTracker("주문상세_보기");
    history.push(`/order/detail/${orderId}`);
  };

  const TOTAL_PRICE = data.total?.amount?.formatted;
  const CREATED_DATE = data.createdAt?.formatted;

  return (
    <>
      {user.email && !spinner ? (
        <PageContainer>
          <div
            style={{
              height: "40px",
              padding: "8px 16px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Link to="/">
              <img
                src={require("../../assets/icons/header-close.svg")}
                alt="닫기"
                width="24px"
                height="24px"
              />
            </Link>
          </div>
          <Section>
            <Title>주문이 완료되었습니다.</Title>
            <Table>
              <tbody>
                <tr>
                  <th>주문번호</th>
                  <td>{orderId}</td>
                </tr>
                <tr>
                  <th>주문날짜</th>
                  <td>{CREATED_DATE}</td>
                </tr>
                <tr>
                  <th>결제금액</th>
                  <ColorTd>{TOTAL_PRICE}</ColorTd>
                </tr>
              </tbody>
            </Table>
            <Button type="button" onClick={handleOrderDetail}>
              주문 상세보기
            </Button>
          </Section>
          <div id="check-script" />
        </PageContainer>
      ) : (
        <>
          <div
            style={{
              height: "calc(100vh - 40px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              animation="border"
              style={{
                color: "var(--color-poola-blue)",
                borderRightColor: "transparent",
              }}
            />
          </div>
          <LoginAlert isLogin={user.email} history={history} />
        </>
      )}
    </>
  );
};

export default OrderSuccessPage;

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  background-color: var(--color-white);
`;

const Section = styled.section`
  padding: 0 16px;
`;

const Title = styled.h2`
  margin: 0;
  padding: 20px 0 24px 0;
  font-size: 20px;
  line-height: 26px;
  color: var(--color-poola-blue);
  font-weight: bold;
  text-align: center;
`;

const Table = styled.table`
  width: 100%;
  tbody {
    tr {
      th {
        padding: 4px 8px 4px 0;
        font-weight: normal;
        text-align: left;
        font-size: 14px;
        line-height: 22px;
        color: var(--color-tertiary-black);
        vertical-align: top;
      }

      td {
        padding: 4px 0;
        font-size: 14px;
        line-height: 22px;
        text-align: end;
      }
    }
  }
`;

const ColorTd = styled.td`
  padding: 4px 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  color: var(--color-poola-blue);
  text-align: end;
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  background-color: var(--color-white);
  border: 1px solid var(--color-poola-blue);
  border-radius: 4px;
  font-size: 14px;
  color: var(--color-poola-blue);
  margin-top: 16px;
`;
