import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Helmet } from "react-helmet";
import { Header } from "../../components/Common/Header";
import { ScrollFilterList } from "../../components/List/ScrollFilterList";
import {API_ENDPOINT} from "../../Config";
import {getTranslateType} from "../../modules/util";

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  background-color: var(--color-white);
  padding-top: 40px;
`;

const Section = styled.section`
  padding: ${({ padding }) => padding};
  border-bottom: ${({ border }) => border + " solid #EBEBEB"};
  cursor: pointer;

  &:last-child {
    border: none;
  }

  h2 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    margin: 0;
  }
`;

const ReviewRating = styled.div`
  background-color: #dee8f9;
  height: 28px;
  border-radius: 20px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-poola-blue);
  font-size: 14px;
  font-weight: 700;

  img {
    width: 12px;
    height: 12px;
  }

  span {
    padding-left: 2px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0 0 0;
`;

const ReviewImgBox = styled.div`
  padding-top: 20px;
  max-width: 500px;
  display: grid;
  grid-template-columns: repeat(5, 80px);
  grid-gap: 8px;

  img {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
`;

const FilterContainer = styled.div`
  padding: 12px 0 8px 0;
  position: sticky;
  top: 40px;
  background-color: white;
`;

export const ReviewNewListPage = ({ history }) => {
  const [allReviews, setAllReviews] = useState([]);
  const [reviews, setReviews] = useState([]); // 리뷰
  const [loading, setLoading] = useState(false); // 리뷰 더불러오기 로딩 상태
  const [sizeFilter, setSizeFilter] = useState([]); // 선택한 사이즈

  const sizes = [
    "55AA",
    "65AA",
    "70AA",
    "75AA",
    "80AA",
    "90AA",
    "55A",
    "60A",
    "65A",
    "70A",
    "75A",
    "80A",
    "85A",
    "90A",
    "95A",
    "100A",
    "105A",
    "60B",
    "55B",
    "65B",
    "70B",
    "75B",
    "80B",
    "85B",
    "90B",
    "95B",
    "100B",
    "105B",
    "110B",
    "60C",
    "65C",
    "70C",
    "75C",
    "80C",
    "85C",
    "90C",
    "95C",
    "100C",
    "60D",
    "80D",
    "75D",
    "85D",
    "90D",
    "95D",
    "100D",
    "105D",
    "70E",
    "75E",
    "80E",
    "95E",
    "100E",
    "80F",
    "95G",
    "95H",
    "85F",
    "95F",
  ];

  const getData = async () => {
    try {
      const response = await Axios.get(API_ENDPOINT + "/api/reviews");
      const updatedReviews = response.data.data.map((review) => {
        const translatedType = getTranslateType(review.type);
        // Return a new object with the updated type
        return {
          ...review,
          type: translatedType,
        };
      });
      setAllReviews(updatedReviews);
      setReviews(updatedReviews.slice(0, 10));
    } catch (e) {
      console.log(e);
    }
  };

  const loadMoreReviews = async () => {
    setLoading(true);
    let nextReviews = [];
    if (sizeFilter.length === 0) {
      nextReviews = allReviews.slice(reviews.length, reviews.length + 10);
    } else {
      nextReviews = allReviews
        .filter((x) => sizeFilter.some((y) => y === x.size))
        .slice(reviews.length, reviews.length + 5);
    }

    await new Promise((resolve) => setTimeout(resolve, 1300));

    setReviews((prevReviews) => [...prevReviews, ...nextReviews]);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            allReviews.length > reviews.length &&
            reviews.length >= 10
          ) {
            loadMoreReviews();
            observer.unobserve(entry.target);
          }
        });
      },
      {
        rootMargin: "50%",
      }
    );

    const reviewItems = document.querySelectorAll(".DetailReviewList-item");
    const lastTarget = reviewItems[reviewItems.length - 1];
    if (lastTarget) {
      observer.observe(lastTarget);
    }

    return () => observer.disconnect();
  }, [reviews.length, allReviews]);

  useEffect(() => {
    if (sizeFilter?.length !== 0) {
      setReviews(
        allReviews.filter((x) => sizeFilter.some((y) => y === x.size))
      );
      window.scrollTo(0, 0);
    } else {
      setReviews(allReviews.slice(0, 10));
    }
  }, [sizeFilter]);
  return (
    <>
      <Helmet>
        <title>비슷한 체형의 리뷰 | 풀라</title>
        <meta property="og:title" content="비슷한 체형의 리뷰 | 풀라" />
      </Helmet>
      <PageContainer>
        <Header type="BACK" title="비슷한 체형의 리뷰" history={history} />
        <Section padding="24px 16px 12px">
          <h2>비슷한 체형의 리뷰를 살펴보세요</h2>
        </Section>
        <FilterContainer>
          <ScrollFilterList
            type="SIZE"
            tags={sizes}
            filter={sizeFilter}
            setFilter={setSizeFilter}
          />
        </FilterContainer>
        {reviews.map((review, index) => {
          return (
            <Section
              border="1px"
              padding="0 16px 16px 16px"
              key={index}
              onClick={() => {
                history.push(`/reviewDetail/${review.id}`);
              }}
              className="DetailReviewList-item"
            >
              <FlexBox>
                <div style={{ display: "inline-block", marginRight: "8px" }}>
                  <ReviewRating>
                    <img
                      src={require("../../assets/icons/star-filled.svg")}
                      alt="rating"
                    />
                    <span>{review.rating}</span>
                  </ReviewRating>
                </div>
                <span
                  style={{
                    fontSize: "12px",
                    color: "var(--color-poola-blue)",
                    fontWeight: 700,
                  }}
                >
                  {review.size} {review.type && "/ " + review.type}
                </span>
              </FlexBox>
              {review.brand && (
                <FlexBox>
                  <span
                    style={{
                      fontSize: "13px",
                      fontWeight: 700,
                      paddingRight: "4px",
                    }}
                  >
                    {review.brand}
                  </span>
                  <span style={{ fontSize: "13px" }}>{review.productName}</span>
                </FlexBox>
              )}
              <FlexBox></FlexBox>
              <p
                style={{
                  margin: 0,
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: 400,
                }}
              >
                {review.text}
              </p>
              {review?.thumb !== "" && review?.thumb !== null && (
                  <ReviewImgBox>
                    <img
                        src={
                          typeof review.thumb === "string" &&
                          !review.thumb.includes("https")
                              ? "https://d1bkjagntxw4mp.cloudfront.net/reviews/" +
                              review.thumb +
                              "?h=500"
                              : review.thumb + "?h=500"
                        }
                        alt="thumb"
                    />
                  </ReviewImgBox>
              )}
            </Section>
          );
        })}
        {loading && (
          <div
            style={{
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              animation="border"
              style={{
                color: "#D8D8D8",
                borderRightColor: "transparent",
              }}
            />
          </div>
        )}
      </PageContainer>
    </>
  );
};
