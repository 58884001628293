import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Axios from "axios";
import Clayful from "clayful/client-js";
import { API_ENDPOINT } from "../../Config";
import { Header } from "../../components/Common/Header";
import { BottomButton } from "../../components/Common/BottomButton";
import CouponFullScreen from "../../components/Modal/CouponFullScreen";
import { AddressBottomSheet } from "../../components/Modal/AddressBottomSheet";
import { ProductOrderList } from "../../components/List/ProductOrderList";
import ArrowRightIcon from "../../assets/icons/arrow/right-tertiary-black.svg";
import ArrowDownIcon from "../../assets/icons/arrow/down-black.svg";
import DefaultCheckIcon from "../../assets/icons/check-default.png";
import ClickedCheckIcon from "../../assets/icons/check-clicked.svg";
import { OrderRequests } from "../../assets/data/jsons/ClayfulData";
import { DownloadableCoupons } from "../../assets/data/jsons/ClayfulData";
import { getUser } from "../../modules/user";
import { formatPrice } from "../../modules/util";
import { LoginAlert } from "../../components/Common/LoginAlert";
import Spinner from "react-bootstrap/Spinner";
import { Dialog } from "../../components/Modal/Dialog";
import { GA4EventTracker } from "../../hook/GA4EventTracker";
import ReactPixel from "react-facebook-pixel";

const TEXT_BUTTON = {
  // width, height, margin-left, color, background-color, border,
  SMALL: ["100%", 38, 8, "poola-blue", "white", "poola-blue"],
  MEDIUM: ["100%", 40, 0, "poola-blue", "white", "poola-blue"],
  "MEDIUM-R": ["100%", 40, 0, "white", "poola-blue", "white"],
};

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  padding: 40px 0 max(56px, calc(env(safe-area-inset-bottom) + 56px));
  background-color: var(--color-white);
`;

const Section = styled.section`
  padding: 20px 16px;
  border-bottom: 8px solid var(--color-bg-gray);

  ${(props) =>
    props.isOrderList &&
    css`
      padding: 20px 0 0 0;
      border-bottom: none;
    `}
  &:last-of-type {
    border-bottom: none;
  }

  .heading_container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .coupon_description {
    margin: 6px 0 0 0;
    font-size: 13px;
    line-height: 19px;
    color: var(--color-negative-red);
  }
`;

const HeadingText = styled.h2`
  margin-bottom: ${(props) => props.mb || 0}px;
  padding-left: ${(props) => props.pl || 0}px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-poola-blue);
`;

const SubText = styled.h3`
  margin-bottom: ${(props) => props.mb || 0}px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-secondary-black);
`;

const InputContainer = styled.div`
  margin-top: 12px;
  display: flex;
`;

const Label = styled.label`
  margin: 0 10px 0 0;
  flex-basis: 64px;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 38px;
  color: var(--color-tertiary-black);

  span {
    color: var(--color-poola-blue);
  }
`;

const Input = styled.input`
  width: 100%;
  height: 38px;
  padding: 0 12px;
  flex-grow: 1;
  color: var(--color-black);
  border: 1px solid var(--color-border-gray);
  border-radius: 4px;
  font-size: 14px;
  line-height: 23px;
  caret-color: var(--color-poola-blue);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::placeholder {
    color: var(--color-tertiary-black);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--color-poola-blue);
  }

  &:disabled {
    outline: none;
    border: 1px solid var(--color-border-gray);
    background-color: var(--color-disabled-gray);
  }
`;

const Select = styled.select`
  width: 100%;
  height: 44px;
  padding: 0 32px 0 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: var(
    --color-${(props) => (props.value === 0 ? "tertiary-black" : "black")}
  );
  background: url(${ArrowDownIcon}) no-repeat calc(100% - 12px) center;
  border: 1px solid var(--color-border-gray);
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none; // 사파리, 크롬 하위버전용
  -moz-appearance: none; // 사파리, 크롬 하위버전용

  &:focus {
    outline: none;
  }

  &:disabled {
    color: var(--color-tertiary-black);
  }
`;

const CouponButton = styled.button`
  width: 100%;
  height: 44px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: var(
    --color-${(props) => (props.isSelected ? "black" : "tertiary-black")}
  );
  border: 1px solid var(--color-border-gray);
  border-radius: 4px;
  background-color: var(--color-white);

  ${(props) =>
    props.isDisabled &&
    css`
      background-color: var(--color-disabled-gray);
    `}
`;

const CheckLabel = styled.label`
  margin: 0;
  position: relative;
  padding-right: 24px;
  font-size: 11px;
  line-height: 16px;
  color: var(--color-secondary-black);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type="checkbox"] {
    display: none;
  }

  span {
    width: 16px;
    height: 16px;
    background: var(--color-white);
    position: absolute;
    top: 0;
    right: 0;
    background: var(--color-white)
      url(${(props) => (props.checked ? ClickedCheckIcon : DefaultCheckIcon)})
      no-repeat right center;
    background-size: contain;
  }
`;

const ModalButton = styled.button`
  height: 20px;
  padding: 0 22px 0 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-tertiary-black);
  background: var(--color-white) url(${ArrowRightIcon}) no-repeat right center;
  background-size: contain;
  border: none;
`;

const TextButton = styled.button`
  width: ${(props) => TEXT_BUTTON[props.size][0]};
  height: ${(props) => TEXT_BUTTON[props.size][1]}px;
  margin-left: ${(props) => TEXT_BUTTON[props.size][2] || 0}px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  word-break: keep-all;
  color: var(--color-${(props) => TEXT_BUTTON[props.size][3]});
  background-color: var(--color-${(props) => TEXT_BUTTON[props.size][4]});
  border: 1px solid var(--color-${(props) => TEXT_BUTTON[props.size][5]});
  border-radius: 4px;

  ${(props) =>
    props.size === "SMALL" &&
    css`
      max-width: 114px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

const ShippingAddressWrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
`;

const ShippingMemo = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--color-bg-gray);
`;

const DefaultIcon = styled.em`
  width: 66px;
  height: 23px;
  padding: 5px;
  display: inline-block;
  flex-basis: 66px;
  flex-shrink: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 11px;
  color: var(--color-poola-blue);
  border: 1px solid rgba(0, 57, 143, 0.2);
  border-radius: 20px;
`;

const ShippingName = styled.span`
  margin-right: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-black);
`;

const Text = styled.p`
  margin-bottom: ${(props) => props.mb || 0}px;
  flex-grow: 0;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-black);
`;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 109px;
  padding: 16px;
  margin-top: 12px;
  font-size: 13px;
  line-height: 19px;
  color: var(--color-black);
  border: 1px solid var(--color-disabled-gray);
  border-radius: 4px;
  resize: none;
  caret-color: var(--color-poola-blue);

  &::placeholder {
    color: var(--color-tertiary-black);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--color-poola-blue);
  }
`;

const PaymentMethodContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48%, auto));
  gap: 11px 10px;
`;

const TotalList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;

  li {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0;

      em {
        color: var(--color-poola-blue);
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
      }
    }

    strong {
      font-weight: 400;
      color: var(--color-tertiary-black);
    }

    em {
      font-style: normal;
      color: var(--color-black);
    }
  }
`;

const ErrorText = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: var(--color-negative-red);
  margin-bottom: ${(props) => props.mb || 0}px;

  span {
    text-decoration: underline;
  }
`;

const CheckoutPage = ({ history }) => {
  let { cartId } = useParams();
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  const dispatch = useDispatch();
  const [order, setOrder] = useState({}); // 주문
  const [customer, setCustomer] = useState({
    customer: user.name,
    phone: user.phone,
  }); // 주문자
  const [recipient, setRecipient] = useState({ name: "", phone: "" }); // 받는 분
  const [isSameForRecipient, setIsSameForRecipient] = useState(false); // 받는 분 - 주문자 정보와 동일 체크 여부
  const [allAddress, setAllAddress] = useState({}); // 주소록
  const [address, setAddress] = useState({
    // 입력 배송지
    postcode: "00000",
    country: "KR",
    state: null,
    city: "서울",
    address1: "서울",
    address2: "",
  });
  const [updateAddress, setUpdateAddress] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(0); // 선택한 배송지 인덱스 (default: 기본 배송지)
  const [allCoupons, setAllCoupons] = useState([]); // 전체 쿠폰
  const [paymentMethods, setPaymentMethods] = useState([]); // 결제 수단
  const [applicableCoupons, setApplicableCoupons] = useState([]); // 사용 가능 쿠폰
  const [downloadCoupons, setDownloadCoupons] = useState([]); // 다운로드 가능 쿠폰
  const [coupon, setCoupon] = useState({});
  const [shippingMemo, setShippingMemo] = useState(0); // 배송 메모 인덱스
  const [memo, setMemo] = useState(null); // 배송 메모 - 직접 입력
  const [paymentMethod, setPaymentMethod] = useState(0); // 선택한 결제 수단 인덱스
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false); // 쿠폰 모달 활성화
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false); // 배송지 모달 활성화
  const [isActive, setIsActive] = useState(false); // 결제하기 버튼 활성화
  const [validatePhoneNumber, setValidatePhoneNumber] = useState(false); // 휴대폰 번호 validation false 모달 활성화
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false); // 결제하기 실패 시 모달 활성화
  const [validateName, setValidateName] = useState(false); // 이름 validation 모달

  const [selectedReviewCoupon, setSelectedReviewCoupon] = useState(""); // 선택한 리뷰 쿠폰
  const shippingRequests = OrderRequests; // 배송 메모

  const order_productNames =
    order?.items &&
    order.items.flatMap((item) => item.products.map((product) => product.name));

  const order_brand = order?.items && order.items.map((item) => item.brand);

  useEffect(() => {
    if (user.email) {
      getPaymentMethods();
      getAddress();
      getCoupons();

      setCustomer({
        name: user.name,
        phone: user.phone,
      });
    }
  }, [user]);

  useEffect(() => {
    getAddress();
  }, [cartId]);

  useEffect(() => {
    setUpdateAddress({
      ...address,
      name: { full: recipient.name },
      mobile: recipient.phone,
    });
    if (allAddress[0]) {
      allAddress[selectedAddress].mobile = recipient.phone;
      delete allAddress[selectedAddress].name;
      allAddress[selectedAddress].name = { full: recipient.name };
    }
  }, [address, recipient]);

  useEffect(() => {
    // 배송지, 쿠폰 변경 시 카트 다시 불러옴
    if (user.email) {
      const request = {
        address: allAddress[0]
          ? { ...allAddress[selectedAddress], country: "KR" }
          : address,
        coupon: {
          cart:
            coupon.constructor === Object && Object.keys(coupon).length > 0
              ? { coupon: coupon.slug }
              : undefined,
        },
      };
      let requestItems;

      if (cartId !== "null") {
        // 바로 구매
        requestItems = cartId;
      } else {
        requestItems = history.location.state.join("+");
      }

      Axios.post(
        API_ENDPOINT +
          `/api/orders/products/${requestItems}/user/${user.email}`,
        request
      ).then((response) => {
        if (response.data.success) {
          const order = response.data.order;
          const discountSum = calculateDiscounts(
            order.items.flatMap((item) => item.products)
          ); // 제품 할인 & 쿠폰 할인
          const applicableCouponsSet = new Set();
          let applicableCoupons = allCoupons;

          // 사용 가능한 쿠폰: 전체 쿠폰 중 해당 카트에 적용 가능한 쿠폰
          // 제품 할인 금액이 있으면 중복 할인 적용 허용 쿠폰만 사용 가능 (only: false)
          if (discountSum.product > 0) {
            applicableCoupons = allCoupons.filter((coupon) => !coupon.only);
          }

          // 제품 할인된 금액에서 쿠폰 사용 가능 총 구매가 검사
          applicableCoupons = applicableCoupons.filter((coupon) =>
            applicableByPrice(
              response.data.order.total.itemPrice -
                response.data.order.total.discounted,
              coupon.price
            )
          );
          // 쿠폰 적용 가능 범주 검사
          order.items
            .flatMap((item) =>
              item.products.map((it) => ({ ...it, brandId: item.brandId }))
            )
            .forEach((item) => {
              applicableCoupons.forEach((coupon) => {
                if (applicableByCategory(item, coupon.category)) {
                  applicableCouponsSet.add(coupon);
                }
              });
            });

          setApplicableCoupons(() => Array.from(applicableCouponsSet));
          setOrder(order);
        }
      });
    }
  }, [selectedAddress, allCoupons, coupon, address, allAddress]);

  useEffect(() => {
    if (order.items && order.items.length > 0) {
      // 상품 필수
      if (allAddress[0]) {
        // 기본 배송지 있는 경우
        if (
          customer.name !== "" &&
          customer.phone !== "" &&
          recipient.name !== "" &&
          recipient.phone !== ""
        )
          setIsActive(true);
        else setIsActive(false);
      } else {
        // 기본 배송지 없는 경우(첫 주문)
        if (
          customer.name !== "" &&
          customer.phone !== "" &&
          recipient.name !== "" &&
          recipient.phone !== "" &&
          address.address2 !== ""
        )
          setIsActive(true);
        else setIsActive(false);
      }
    }
  }, [customer, recipient, address]);

  function getPaymentMethods() {
    Axios.get(API_ENDPOINT + "/api/order/paymentMethod").then((response) => {
      if (response.data.success) {
        setPaymentMethods(response.data.paymentMethods);
      } else {
        console.log("결제 수단을 불러오는데 실패했습니다.");
      }
    });
  }

  function getAddress() {
    const Customer = Clayful.Customer;
    const customerOptions = {
      customer: sessionStorage.getItem("__tct__"),
      query: { fields: "address" },
    };

    Customer.getMe(customerOptions, function (err, result) {
      if (err) console.log(err.code);

      const addressList = result?.data.address.secondaries;
      const unshiftAddressList = addressList?.unshift(
        result.data.address.primary
      );
      const settingAddress = setAllAddress(addressList);

      return [unshiftAddressList, settingAddress];
    });
  }

  function getCoupons() {
    const Customer = Clayful.Customer;
    const options = { customer: sessionStorage.getItem("__tct__") };

    Customer.listCouponsForMe(options, function (err, result) {
      if (err) console.log(err.code);

      // 전체 쿠폰: 활성화, 만료 기간 전, 카트 전체 타입 쿠폰
      // 사용 가능 쿠폰: 전체 쿠폰 중 카트 적용 가능한 쿠폰
      // 발급 가능 쿠폰: 다운로드 쿠폰 목록 중 유저가 소유하지 않은 쿠폰(활성화, 만료일 전, 카트 전체 타입 쿠폰)
      const allCoupons = result?.data.filter(
        (coupon) =>
          coupon.active &&
          (!coupon.expiresAt ||
            new Date(coupon.expiresAt.raw).valueOf() > new Date().valueOf()) &&
          coupon.type === "cart"
      );
      const downloadCouponIds = DownloadableCoupons.filter(
        (coupon) => !allCoupons.map((c) => c.slug).includes(coupon)
      );

      setAllCoupons(allCoupons);
      setDownloadCoupons([]);

      if (downloadCouponIds.length > 0) {
        const Coupon = Clayful.Coupon;
        const options = {
          query: {
            ids: downloadCouponIds.toString(),
            active: true,
            type: "cart",
          },
        };

        Coupon.list(options, function (err, result) {
          if (err) console.log(err.code);

          setDownloadCoupons(
            result &&
              result.data.filter(
                (coupon) =>
                  !coupon.expiresAt ||
                  new Date(coupon.expiresAt.raw).valueOf() >
                    new Date().valueOf()
              )
          );
        });
      }
    });
  }

  function calculateDiscounts(items) {
    // 제품 할인 & 쿠폰 할인 각각 금액 계산
    let discountedSum = { product: 0, coupon: 0 };

    items.forEach((item) => {
      // 제품 할인
      if (item.price.original > item.price.sale) {
        discountedSum.product += item.price.original > item.price.sale;
      }
      if (item.optionalBundles) {
        // 번들 제품 할인
        let sum = calculateDiscounts(item.optionalBundles);
        discountedSum.product += sum.product;
        discountedSum.coupon += sum.coupon;
      }
    });

    return discountedSum;
  }

  function applicableByCategory(item, category) {
    // 쿠폰 적용 가능 범주 검사 (TODO: 특정 콜렉션)
    const { brandId, productId } = item;

    if (category.type === "any") {
      return true;
    }

    const subjectsToTest = {
      brands: [].concat(brandId ? brandId : []),
      products: [].concat(productId ? productId : []),
    };

    const categoryToTest = ["brands", "products"].find((c) => category[c]);

    const toTest = subjectsToTest[categoryToTest];
    const pool = category[categoryToTest].map(({ _id }) => _id);

    if (category.type === "include") {
      return pool.some((v) => toTest.indexOf(v) >= 0);
    }

    if (category.type === "exclude") {
      return pool.every((v) => toTest.indexOf(v) === -1);
    }
  }

  function applicableByPrice(price, { min, max }) {
    // 사용 가능 총 구매가

    return (
      (min === null || min.raw <= price) && (max === null || max.raw >= price)
    );
  }

  function loadPostcode() {
    // 도로명 주소 불러오기
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function (data) {
          const result = {
            postcode: data.zonecode || data.postcode,
            country: "KR",
            state: data.sido,
            city: data.sigungu.split(" ")[0] || data.sido,
            address1: data.address,
            address2: "",
          };
          setAddress({ ...address, ...result });
        },
      });
      postcode.open();
    });
  }

  const handleSelectChange = (e) => {
    setShippingMemo(e.target.value);

    if (e.target.value === "5") setMemo("");
    else setMemo(null);
  };

  const handleInputChange = ({ target: { id, value } }) => {
    // 정보 입력
    const field = id.split("_");

    if (field[0] === "customer") {
      // 주문자 정보 입력 시
      if (field[1] === "phone")
        setCustomer({ ...customer, [field[1]]: validatePhone(value) });
      else setCustomer({ ...customer, [field[1]]: value });
    } else if (field[0] === "recipient") {
      // 받는 분 정보 입력 시
      if (field[1] === "phone")
        setRecipient({ ...recipient, [field[1]]: validatePhone(value) });
      else setRecipient({ ...recipient, [field[1]]: value });
    } else if (field[0] === "address") {
      // 배송지 정보 입력 시
      setAddress({ ...address, [field[1]]: value });
    } else if (field[0] === "memo") {
      // 배송 메모 직접 입력 시
      if (value.length > 250)
        alert("배송 메모는 250자 이내로 작성할 수 있습니다");
      else setMemo(value);
    }
  };

  function handleCheckboxChange() {
    if (isSameForRecipient) setRecipient({ name: "", phone: "" });
    else setRecipient({ name: customer.name, phone: customer.phone });

    setIsSameForRecipient(!isSameForRecipient);
  }

  useEffect(() => {
    if (
      customer.name !== recipient.name ||
      customer.phone !== recipient.phone
    ) {
      setIsSameForRecipient(false);
    }
  }, [customer, recipient]);

  function validatePhone(phone) {
    // 휴대폰 번호 확인
    let pText = "";
    const num = phone.replace(/[^0-9]/g, "");

    if (num.length < 4) return num;
    else if (num.length < 7) pText += num.substr(0, 3) + "-" + num.substr(3);
    else if (num.length < 11)
      pText += num.substr(0, 3) + "-" + num.substr(3, 3) + "-" + num.substr(6);
    else
      pText +=
        num.substr(0, 3) + "-" + num.substr(3, 4) + "-" + num.substr(7, 4);
    return pText;
  }

  async function sendSlackMessage(id) {
    const message = `주문이 들어왔어용🥰 주문번호는 ${id} 입니다!`;
    await Axios.post(
      "https://hooks.slack.com/services/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",
      JSON.stringify({ text: message }),
      {
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.post["Content-Type"];
            return data;
          },
        ],
      }
    );
  }

  // 리뷰 레벨 쿠폰에서 레벨 추출 함수
  function extractLevelFromCoupon(couponString) {
    const levelPattern = /Lv (\d+)/;
    const match = couponString.match(levelPattern);
    if (match && match[1]) {
      const level = parseInt(match[1], 10);
      return level;
    } else {
      return null;
    }
  }

  async function handlePayButtonClick() {
    // 결제하기 버튼 클릭
    const options = {
      customer: sessionStorage.getItem("__tct__"),
      client:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjZhMWYwZTMwYjQzZjhhYmIyMjE0MTQwZTk0ZWYyNDZhYzEyZTJjYjMyNTUzMGQ1ZmU2NGQ1ODNjYTVlYjhlMTEiLCJyb2xlIjoiY2xpZW50IiwiaWF0IjoxNjMxMjg1Njg4LCJzdG9yZSI6IkVDSkVBM0RUTlpKNy45OThEV0dLSEJOWFQiLCJzdWIiOiJMSEFIWVozN1dFMlgifQ.7RPEyRE6D-2jMoyw6Bx1O1z4bcagvkF_lxhB1aXtAJw",
    };
    // 유저 정보가 주문자 정보와 다르면 정보 수정
    if (user.name !== customer.name || user.phone !== customer.phone) {
      let customerInfo = {
        email: user.email,
        name: customer.name,
        phone: customer.phone,
      };
      await Axios.put(API_ENDPOINT + "/api/oauth/setUser", customerInfo).then(
        (response) => {
          if (response.data.success) {
          } else {
            console.log("기본 정보 변경에 실패했습니다.");
          }
        }
      );
    }

    // 배송지 처음 입력 시 기본 배송지로 저장
    if (allAddress[0] === null) {
      const Customer = Clayful.Customer;
      const payload = {
        address: {
          primary: {
            ...address,
            name: { full: recipient.name },
            mobile: recipient.phone,
          },
        },
      };

      Customer.updateMe(payload, options, function (err, result) {
        if (err) console.log(err.code);
      });
    }

    const orderPrice =
      order.total.itemPrice -
      order.total.discounted +
      order.total.shippingPrice;

    ReactPixel.track("InitiateCheckout", {
      currency: "KRW",
      value: orderPrice,
    });

    // 주문 결제
    const request = {
      address: allAddress[0]
        ? { ...allAddress[selectedAddress], country: "KR" }
        : updateAddress,
      coupon: order.coupon || {},
      request:
        memo === null
          ? shippingMemo === 0
            ? null
            : shippingRequests[shippingMemo]
          : memo,
      order,
      options,
    };
    let requestItems;

    if (cartId !== "null") {
      // 바로 구매
      requestItems = cartId;
    } else {
      requestItems = history.location.state.join("+");
    }

    const regex = /[!@#$%^&*(),.?":{}|<>0-9]/g;
    if (regex.test(customer.name) || regex.test(recipient.name)) {
      return setValidateName(true);
    }

    // 결제 이전 휴대폰 번호 validation
    if (
      customer.phone?.substr(0, 2) !== "01" ||
      recipient.phone?.substr(0, 2) !== "01" ||
      customer.phone?.length !== 13 ||
      recipient.phone?.length !== 13
    ) {
      setValidatePhoneNumber(true);
    } else {
      Axios.post(
        API_ENDPOINT +
          `/api/orders/checkout/${requestItems}/user/${user.email}`,
        request
      ).then((response) => {
        if (response.data.success === false) {
          setIsOpenErrorModal(true);
        }
        if (response.data.success) {
          const responseOrder = response.data.order;
          const IMP = window.IMP;

          dispatch(getUser({ email: user?.email })); // 장바구니 조회

          IMP.request_pay(
            {
              pg: paymentMethods[paymentMethod].meta.pg,
              pay_method: paymentMethods[paymentMethod].meta.payMethod,
              merchant_uid: responseOrder._id,
              name: order.items[0].products[0].name + " ...",
              amount:
                order.total.itemPrice -
                order.total.discounted +
                order.total.shippingPrice,
              currency: "KRW",
              buyer_name: customer.name,
              buyer_tel: customer.phone,
              buyer_email: user.email || null,
              m_redirect_url:
                API_ENDPOINT +
                "/api/orders/check/" +
                responseOrder._id +
                "/" +
                sessionStorage.getItem("back") +
                "/" +
                sessionStorage.getItem("productId") +
                "/" +
                order.total.totalPrice +
                "?options=" +
                Object.entries(options)
                  .map(([key, val]) => `${key}=${val}`)
                  .join("&"),
              // m_redirect_url: history.push(
              //   "/order/detail/" + responseOrder._id
              // ),
            },
            async function (response) {
              if (response.success) {
                function deleteReviewCoupon() {
                  Axios.delete(
                    `https://api-qa-v3.poola.co.kr/api/coupon/${
                      user.email
                    }/${extractLevelFromCoupon(selectedReviewCoupon)}`
                  ).then((response, err) => {
                    if (err) {
                      console.log(err.code);
                    }
                  });
                }
                if (selectedReviewCoupon !== "") {
                  deleteReviewCoupon();
                }
                setTimeout(() => {
                  sendSlackMessage(responseOrder._id);

                  const ga4Event_name = () => {
                    return order_productNames?.forEach((name) => {
                      const eventParams = {
                        제품명: name,
                      };
                      GA4EventTracker("구매_완료", {
                        ...eventParams,
                      });
                    });
                  };
                  const ga4Event_brand = () => {
                    return order_brand?.forEach((name) => {
                      const eventParams = {
                        브랜드명: name,
                      };
                      GA4EventTracker("구매_완료", {
                        ...eventParams,
                      });
                    });
                  };

                  const formatDate = () => {
                    const date = new Date();
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const day = date.getDate();

                    return `${year}.${month}.${day}`;
                  };
                  ga4Event_name();
                  ga4Event_brand();
                  GA4EventTracker("구매_완료", {
                    날짜: formatDate(),
                  });
                  GA4EventTracker("구매_완료", {
                    금액: formatPrice(
                      order.total.itemPrice -
                        order.total.discounted +
                        order.total.shippingPrice
                    ),
                  });
                  if (coupon?.name) {
                    GA4EventTracker("구매_완료", {
                      쿠폰명: coupon?.name,
                    });
                  }
                  history.push("/order/success/" + responseOrder._id);
                }, 2000);
              } else {
                await Clayful.Order.cancelForMe(
                  responseOrder._id,
                  {
                    reason:
                      "결제에 실패 하였습니다. 잔액 혹은 네트워크 상태를 확인해주세요!",
                  },
                  {
                    customer: sessionStorage.getItem("__tct__"),
                    client:
                      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjZhMWYwZTMwYjQzZjhhYmIyMjE0MTQwZTk0ZWYyNDZhYzEyZTJjYjMyNTUzMGQ1ZmU2NGQ1ODNjYTVlYjhlMTEiLCJyb2xlIjoiY2xpZW50IiwiaWF0IjoxNjMxMjg1Njg4LCJzdG9yZSI6IkVDSkVBM0RUTlpKNy45OThEV0dLSEJOWFQiLCJzdWIiOiJMSEFIWVozN1dFMlgifQ.7RPEyRE6D-2jMoyw6Bx1O1z4bcagvkF_lxhB1aXtAJw",
                  },
                  (err, response) => {
                    console.log(err);
                  }
                );
                history.push("/order/detail/" + responseOrder._id);
                GA4EventTracker("주문상세_보기");
              }
            }
          );
        }
      });
    }
  }

  const shippingMemoOptions = shippingRequests.map((request, i) => {
    if (i === 0)
      return (
        <option disabled value={i} key={i}>
          {request}
        </option>
      );
    return (
      <option value={i} key={i}>
        {request}
      </option>
    );
  });

  const paymentMethodList = paymentMethods.map((method, i) => (
    <TextButton
      size={paymentMethod === i ? "MEDIUM-R" : "MEDIUM"}
      key={i}
      onClick={() => setPaymentMethod(i)}
    >
      {method.ko}
    </TextButton>
  ));

  function substringCouponName(couponName) {
    return couponName.includes("리뷰 Lv")
      ? couponName.substring(couponName.indexOf("리뷰"))
      : couponName;
  }

  return (
    <>
      <Header type="BACK" title="주문/결제" history={history} />
      {user.email ? (
        <PageContainer>
          <Section isOrderList>
            <HeadingText mb={10} pl={16}>
              주문 상품
            </HeadingText>
            <ProductOrderList data={order.items || []} type="ORDER" />
          </Section>
          <Section>
            <HeadingText mb={20}>주문자</HeadingText>
            <InputContainer>
              <Label htmlFor="customer_name">
                이름<span>*</span>
              </Label>
              <Input
                type="text"
                id="customer_name"
                value={customer.name || ""}
                placeholder="(필수)이름을 입력해주세요"
                onChange={handleInputChange}
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="customer_email">이메일</Label>
              <Input
                type="email"
                id="customer_email"
                value={user.email}
                disabled
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="customer_phone">
                휴대폰<span>*</span>
              </Label>
              <Input
                type="text"
                id="customer_phone"
                value={customer.phone || ""}
                placeholder="(필수)번호를 입력해주세요"
                onChange={handleInputChange}
              />
            </InputContainer>
          </Section>
          <Section>
            <div className="heading_container">
              <HeadingText>받는 분</HeadingText>
              <CheckLabel checked={isSameForRecipient}>
                주문자 정보와 동일합니다
                <span />
                <input
                  type="checkbox"
                  checked={isSameForRecipient}
                  onChange={handleCheckboxChange}
                />
              </CheckLabel>
            </div>
            <InputContainer>
              <Label htmlFor="recipient_name">
                이름<span>*</span>
              </Label>
              <Input
                type="text"
                id="recipient_name"
                value={recipient.name || ""}
                placeholder="(필수)이름을 입력해주세요"
                onChange={handleInputChange}
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="recipient_phone">
                휴대폰<span>*</span>
              </Label>
              <Input
                type="text"
                id="recipient_phone"
                value={recipient.phone || ""}
                placeholder="(필수)번호를 입력해주세요"
                onChange={handleInputChange}
              />
            </InputContainer>
          </Section>
          <Section>
            {allAddress[0] ? ( // 기본 배송지 유무
              <>
                <div className="heading_container">
                  <HeadingText>배송지</HeadingText>
                  <ModalButton onClick={() => setIsOpenAddressModal(true)}>
                    배송지 변경
                  </ModalButton>
                </div>
                <ShippingAddressWrapper>
                  {allAddress[selectedAddress]?.company && (
                    <ShippingName>
                      {allAddress[selectedAddress]?.company}
                    </ShippingName>
                  )}
                  {selectedAddress === 0 && (
                    <DefaultIcon>기본 배송지</DefaultIcon>
                  )}
                </ShippingAddressWrapper>
                <Text>
                  {allAddress[selectedAddress]?.address1 +
                    ((allAddress[selectedAddress]?.address2 &&
                      " " + allAddress[selectedAddress]?.address2) ||
                      "") +
                    " (" +
                    allAddress[selectedAddress]?.postcode +
                    ")"}
                </Text>
              </>
            ) : (
              <>
                <HeadingText>배송지</HeadingText>
                <InputContainer>
                  <Label htmlFor="address_company">배송지명</Label>
                  <Input
                    id="address_company"
                    value={address.company || ""}
                    onChange={handleInputChange}
                  />
                </InputContainer>
                <InputContainer>
                  <Label htmlFor="address_postcode">
                    우편번호<span>*</span>
                  </Label>
                  <Input
                    id="address_postcode"
                    value={address.postcode === "00000" ? "" : address.postcode}
                    readOnly
                    onClick={loadPostcode}
                  />
                  <TextButton size="SMALL" onClick={loadPostcode}>
                    우편번호 찾기
                  </TextButton>
                </InputContainer>
                <InputContainer>
                  <Label htmlFor="address_address1" onClick={loadPostcode}>
                    주소<span>*</span>
                  </Label>
                  <Input
                    id="address_address1"
                    value={address.address1 === "서울" ? "" : address.address1}
                    readOnly
                    onClick={loadPostcode}
                  />
                </InputContainer>
                <InputContainer>
                  <Label htmlFor="address_address2">
                    상세주소<span>*</span>
                  </Label>
                  <Input
                    id="address_address2"
                    value={address.address2}
                    placeholder="상세주소를 입력해주세요"
                    onChange={handleInputChange}
                  />
                </InputContainer>
              </>
            )}
            <ShippingMemo>
              <SubText mb={8}>배송 메모</SubText>
              <Select onChange={handleSelectChange} value={shippingMemo}>
                {shippingMemoOptions}
              </Select>
              {memo !== null && (
                <Textarea
                  id="memo_write"
                  value={memo}
                  placeholder="배송 메모를 입력해주세요"
                  onChange={handleInputChange}
                />
              )}
            </ShippingMemo>
          </Section>
          <Section>
            <HeadingText mb={20}>풀라 쿠폰</HeadingText>
            <CouponButton
              isDisabled={applicableCoupons.length === 0}
              isSelected={
                coupon.constructor === Object && Object.keys(coupon).length > 0
              }
              onClick={() => setIsOpenCouponModal(true)}
            >
              {coupon.constructor === Object && Object.keys(coupon).length > 0
                ? substringCouponName(coupon.name)
                : "사용 가능 쿠폰 " +
                  applicableCoupons.length +
                  "개 / 전체 " +
                  allCoupons.length +
                  "개"}
              <img
                src={require(`../../assets/icons/arrow/right-${
                  coupon ? "black" : "tertiary-black"
                }.svg`)}
                alt="쿠폰 적용"
                width="20px"
                height="20px"
              />
            </CouponButton>
            <p className="coupon_description">
              *배송비를 제외한 구매 금액을 기준으로 적용됩니다
            </p>
          </Section>
          <Section>
            <HeadingText mb={paymentMethod === 2 ? 15 : 20}>
              결제 수단
            </HeadingText>
            {paymentMethod === 2 && (
              <ErrorText mb={15}>
                *<span>Chrome 브라우저</span>에서 <span>카카오페이</span> 결제
                시, 오류가 발생할 수 있습니다. 다른 브라우저나 결제 수단을
                이용해주세요.
                <br />
                불편을 드려 죄송합니다.
              </ErrorText>
            )}
            <PaymentMethodContainer>{paymentMethodList}</PaymentMethodContainer>
          </Section>
          <Section>
            <HeadingText mb={20}>결제 금액</HeadingText>
            {order.constructor === Object && Object.keys(order).length > 0 ? (
              <TotalList>
                <li>
                  <strong>총 제품 금액</strong>
                  <em>{formatPrice(order.total.itemPrice)}원</em>
                </li>
                <li>
                  <strong>총 배송비</strong>
                  <em>{formatPrice(order.total.shippingPrice)}원</em>
                </li>
                <li>
                  <strong>총 할인 금액</strong>
                  <em>-{formatPrice(order.total.discounted)}원</em>
                </li>
                <li>
                  <strong>총 결제 금액</strong>
                  <em>
                    {formatPrice(
                      order.total.itemPrice -
                        order.total.discounted +
                        order.total.shippingPrice
                    )}
                    원
                  </em>
                </li>
              </TotalList>
            ) : (
              <></>
            )}
          </Section>
          <BottomButton
            text="결제하기"
            disabled={!isActive}
            onClick={handlePayButtonClick}
          />
        </PageContainer>
      ) : (
        <>
          <div
            style={{
              height: "calc(100vh - 40px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              animation="border"
              style={{
                color: "var(--color-poola-blue)",
                borderRightColor: "transparent",
              }}
            />
          </div>
          <LoginAlert isLogin={user.email} history={history} />
        </>
      )}
      {isOpenAddressModal && (
        <AddressBottomSheet
          open={isOpenAddressModal}
          setOpen={setIsOpenAddressModal}
          allAddress={allAddress}
          setAllAddress={setAllAddress}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      )}
      {isOpenCouponModal && (
        <CouponFullScreen
          isOpen={isOpenCouponModal}
          setClose={setIsOpenCouponModal}
          allCoupons={allCoupons}
          applicableCoupons={applicableCoupons}
          downloadCoupons={downloadCoupons}
          coupon={coupon}
          setCoupon={setCoupon}
          getCoupons={getCoupons}
          setSelectedReviewCoupon={setSelectedReviewCoupon}
        />
      )}
      {validateName && (
        <Dialog
          isOpen={validateName}
          text={{
            title: "이름을 수정해주세요",
            desc: "숫자와 특수문자는 빼주세요.",
            button: ["확인"],
          }}
          _onClick={() => setValidateName(false)}
          _onClose={() => setValidateName(false)}
        />
      )}
      {validatePhoneNumber && (
        <Dialog
          isOpen={validatePhoneNumber}
          text={{
            title: "휴대폰 번호를 수정해주세요",
            desc: "유효하지 않은 휴대폰 번호입니다.",
            subDesc: "(유효한 번호 : 01*-****-****)",
            button: ["확인"],
          }}
          _onClick={() => setValidatePhoneNumber(false)}
          _onClose={() => setValidatePhoneNumber(false)}
        />
      )}
      {isOpenErrorModal && (
        <Dialog
          isOpen={isOpenErrorModal}
          text={{
            title: "POOLA 고객센터로 문의해주세요",
            desc: "어머나! 알 수 없는 오류가 발생했습니다.",
            subDesc: "고객센터로 문의주시면 친절히 안내해 드리겠습니다.",
            button: ["닫기", "고객센터 바로가기"],
          }}
          type="errorModal"
          _onClick={() => window.open("https://pf.kakao.com/_QQxgxgs")}
          _onClose={() => setIsOpenErrorModal()}
        />
      )}
    </>
  );
};

export default CheckoutPage;
