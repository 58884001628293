import React, { Suspense, useEffect, lazy } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import ReactGa from "react-ga";
import "./templates/setting.css";
import TestIntroPage from "./pages/Curation/TestIntroPage";
import CheckoutPage from "./pages/Order/CheckoutPage";
import OrderSuccessPage from "./pages/Order/OrderSuccessPage";
import OrderListPage from "./pages/Order/OrderListPage";
import OrderDetailPage from "./pages/Order/OrderDetailPage";
import OrderRequestRefundPage from "./pages/Order/OrderRequestRefundPage";
import EventPage from "./pages/Home/EventPage";
import ArticleDetailPage from "./pages/Article/ArticleDetailPage";
import ReviewListPage from "./pages/Review/ReviewListPage";
import ReviewFormPage from "./pages/Review/ReviewFormPage";
import RedirectOrderPage from "./modules/order-redirect";
import UpdateInfoPage from "./pages/My/UpdateInfoPage";
import ArticleListPage from "./pages/Article/ArticleListPage";
import BrandCollectionPage from "./pages/Brand/BrandCollectionPage";
import SojiPage from "./pages/Home/SojiPage";
import { ReviewDetailPage } from "./pages/Home/ReviewDetailPage";
import { ReviewNewListPage } from "./pages/Home/ReviewListPage";
import { InfluencerReviewPage } from "./pages/Home/InfluencerReviewPage";
import { InfluencerReviewListPage } from "./pages/Home/InfluencerReviewListPage";
import { RecommendPage } from "./pages/Home/RecommendPage";

const LoginPage = lazy(() => import("./pages/Home/LoginPage"));
const WishListPage = lazy(() => import("./pages/My/WishListPage"));
const ProductDetailNewPage = lazy(() =>
  import("./pages/Product/ProductDetailNewPage")
);
const ProductCollectionPage = lazy(() =>
  import("./pages/Product/ProductCollectionPage")
);
const CouponPage = lazy(() => import("./pages/My/CouponPage"));
const BrandPage = lazy(() => import("./pages/Brand/BrandListPage"));
const BrandDetailPage = lazy(() => import("./pages/Brand/BrandDetailPage"));
const HomePage = lazy(() => import("./pages/Home/HomePage"));
const MyPage = lazy(() => import("./pages/My/MyPage"));
const CartPage = lazy(() => import("./pages/My/CartPage"));
const SearchPage = lazy(() => import("./pages/Home/SearchPage"));
const TestPage = lazy(() => import("./pages/Curation/TestPage"));
const TestResultPage = lazy(() => import("./pages/Curation/TestResultPage"));
const SizeFAQPage = lazy(() => import("./pages/Curation/SizeFAQPage"));
const SisterSizeFAQPage = lazy(() =>
  import("./pages/Curation/SisterSizeFAQPage")
);
const CategoryListPage = lazy(() =>
  import("./pages/Category/CategoryListPage")
);
const CategoryDetailPage = lazy(() =>
  import("./pages/Category/CategoryDetailPage")
);
const PromotionPage = lazy(() => import("./pages/Home/PromotionPage"));
const AlertPage = lazy(() => import("./pages/Curation/AlertPage"));

function App() {
  useEffect(() => {
    ReactGa.initialize("UA-190381013-1");
    ReactGa.pageview(window.location.pathname + window.location.search);
    const tagManagerArgs = {
      gtmId: "GTM-NNHJ55V",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Suspense fallback={<div />}>
      <meta charSet="utf-8" />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/my/wish" component={WishListPage} />
        <Route
          exact
          path="/product/:productId/:clayful?"
          component={ProductDetailNewPage}
        />
        <Route exact path="/test" component={TestIntroPage} />
        <Route exact path="/test/:type" component={TestPage} />
        <Route exact path="/test/result/:code" component={TestResultPage} />
        <Route exact path="/faq/size" component={SizeFAQPage} />
        <Route exact path="/faq/sister-size" component={SisterSizeFAQPage} />
        <Route exact path="/category" component={CategoryListPage} />
        <Route exact path="/category/:style" component={CategoryDetailPage} />
        <Route
          exact
          path="/collection/product/:type"
          component={ProductCollectionPage}
        />
        <Route exact path="/search" component={SearchPage} />
        <Route exact path="/brand" component={BrandPage} />
        <Route exact path="/brand/:brandId" component={BrandDetailPage} />
        <Route
          exact
          path="/collection/brand/:type"
          component={BrandCollectionPage}
        />
        <Route exact path="/my" component={MyPage} />
        <Route exact path="/cart" component={AlertPage} />
        <Route exact path="/order/:cartId" component={AlertPage} />
        <Route
          exact
          path="/order/redirect/:orderId"
          component={RedirectOrderPage}
        />
        <Route
          exact
          path="/order/success/:orderId"
          component={OrderSuccessPage}
        />
        <Route
          exact
          path="/order/detail/:orderId"
          component={OrderDetailPage}
        />
        <Route
          exact
          path="/order/refund/:orderId"
          component={OrderRequestRefundPage}
        />
        <Route exact path="/orderlist" component={AlertPage} />
        <Route exact path="/my/info" component={UpdateInfoPage} />
        <Route exact path="/coupon" component={AlertPage} />
        <Route exact path="/event/:eventId" component={EventPage} />
        <Route exact path="/article" component={ArticleListPage} />
        <Route exact path="/article/:articleId" component={ArticleDetailPage} />
        <Route exact path="/my/reviews" component={ReviewListPage} />
        <Route exact path="/my/review/write" component={ReviewFormPage} />
        <Route
          exact
          path="/my/review/write/:reviewId"
          component={ReviewFormPage}
        />
        <Route
          exact
          path="/reviewDetail/:reviewId"
          component={ReviewDetailPage}
        />
        <Route exact path="/reviewList" component={ReviewNewListPage} />
        <Route
          exact
          path="/influencerReview/:reviewId"
          component={InfluencerReviewPage}
        />
        <Route
          exact
          path="/influencerReview"
          component={InfluencerReviewListPage}
        />
        <Route exact path="/recommend" component={RecommendPage} />
        <Route exact path="/promotion/:promotionId" component={PromotionPage} />
      </Switch>
    </Suspense>
  );
}

export default withRouter(App);
