import React from "react";
import styled, { css } from "styled-components";
import { AnswerCard } from "../Card/AnswerCard";

const VerticalBlock = styled.ul`
  margin: auto;
  padding: 0;
  list-style: none;
  width: 100%;
  display: grid;
  gap: 12px;

  ${(props) =>
    props.size === "SMALL" &&
    css`
      max-width: ${(props) => (parseInt(props.type) === 3 ? 228 : 308)}px;
      grid-template-columns: repeat(${(props) => parseInt(props.type)}, 1fr);
    `}

  ${(props) =>
    props.size === "MEDIUM" &&
    css`
      row-gap: 8px;
    ` &&
    props.type === "I" &&
    css`
      grid-template-columns: repeat(4, 1fr);
      justify-content: center;
      column-gap: 15px;
      row-gap: 32px;

      @media screen and (max-width: 500px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 374px) {
        grid-template-columns: repeat(2, 1fr);
      }
    `}
`;

const ListItem = styled.li`
  width: ${(props) => (props.size === "SMALL" ? "68px" : "100%")};
`;

export const AnswerList = ({ data, size, selected, _onClick }) => {
  const cardSize = size?.split("-")[0];
  const cardType = size?.split("-").length > 0 && size.split("-").reverse()[0];

  const List = data?.map((answer, i) => {
    return (
      <ListItem size={cardSize} key={answer.a}>
        <AnswerCard
          data={answer}
          size={cardSize}
          index={i + 1}
          selected={
            cardSize === "SMALL" || size === "MEDIUM-I"
              ? selected.toString().includes(answer.a)
              : selected.toString().includes((i + 1).toString())
          }
          _onClick={_onClick}
        />
      </ListItem>
    );
  });

  return (
    <VerticalBlock size={cardSize} type={cardType}>
      {List}
    </VerticalBlock>
  );
};
