import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const RouteChangeTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (
      !window.location.href.includes("localhost") &&
      !window.location.href.includes("likenotonly")
    ) {
      ReactGA.initialize("G-D9LKTC2046");
      setInitialized(true);
    }
  }, []);

  // location 변경 감지시 pageview 이벤트 전송
  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send("pageview");
    }
  }, [initialized, location]);

  // 개발용
  useEffect(() => {
    if (
      window.location.href.includes("localhost") ||
      window.location.href.includes("likenotonly")
    ) {
      ReactGA.initialize("G-XRXJNM3X8P");
      ReactGA.set({ page: location.pathname });
      ReactGA.send("pageview");
    }
  }, [location]);

  return null;
};

export default RouteChangeTracker;
