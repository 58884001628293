//SERVER ROUTES
require("dotenv").config();
let isTest = window.location.hostname.includes("likenotonly.");
//window.location.hostname - localhost
export const USER_SERVER = "/user";
export const API_ENDPOINT_PROD = `https://api-${isTest ? "qa" : "poola"}-v3.poola.co.kr`;

export const IMAGE_SERVER_LOCATION = "https://d1bkjagntxw4mp.cloudfront.net/";
export const API_ENDPOINT_LOCAL = "http://localhost:3001";
export const API_ENDPOINT = (() => {
	return window.location.hostname === "localhost" ? API_ENDPOINT_LOCAL : API_ENDPOINT_PROD;
	// return API_ENDPOINT_PROD;
})();
