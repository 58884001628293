import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { API_ENDPOINT } from "../../Config";
import { Header } from "../../components/Common/Header";
import { BottomNavigator } from "../../components/Common/BottomNavigator";
import { Helmet } from "react-helmet";
import { clickTrigger } from "../../modules/trigger";
import { ScrollFilterList } from "../../components/List/ScrollFilterList";
import { ArticleList } from "../../components/List/ArticleList";
import { ArticleCategoryData } from "../../assets/data/jsons/ArticleCategoryData";

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  padding: 40px 0 max(49px, calc(env(safe-area-inset-bottom) + 49px));
  background-color: var(--color-white);
`;

const StickyTab = styled.div`
  margin-top: 12px;
  @supports (position: sticky) or (position: -webkit-sticky) {
    position: -webkit-sticky; // Safari
    position: sticky;
    top: 40px;
    z-index: 100;
    background-color: var(--color-white);
  }
`;

const Section = styled.section`
  padding: 32px 16px 24px 16px;

  ${(props) =>
    props.$type === "PICK" &&
    css`
      padding: 20px 0 24px 0;
      border-bottom: 8px solid var(--color-bg-gray);
    `}
`;

const Footer = styled.footer`
  padding: 32px 16px 24px 16px;
  border-top: 8px solid var(--color-bg-gray);
`;

const ButtonWrapper = styled.div`
  margin: 32px 0 0 0;
  display: flex;
  justify-content: space-between;
`;

const HeadingText = styled.h2`
  margin-bottom: 0;
  font-weight: bold;
  font-size: ${(props) => props.size || 20}px;
  line-height: ${(props) => props.size || 29}px;
  color: var(--color-black);
  text-align: ${(props) => props.center && "center"};

  ${(props) =>
    props.$type === "PICK" &&
    css`
      padding-left: 16px;
      margin-bottom: 24px;
    `}
`;

const Text = styled.p`
  margin: ${(props) => props.top || 0}px 0 ${(props) => props.bottom || 0}px 0;
  text-align: ${(props) => props.center && "center"};
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: var(--color-tertiary-black);
`;

const Button = styled.button`
  width: ${(props) => props.width || 100}%;
  height: 40px;
  margin-top: ${(props) => props.top || 0}px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-${(props) => props.color || "poola-blue"});
  background-color: var(--color-${(props) => props.bgcolor || "white"});
  border: 1px solid var(--color-poola-blue);
  border-radius: 4px;
`;

const ArticleListPage = ({ history }) => {
  const articleCategories = [
    "전체",
    "브라학개론",
    "후기를 풀라",
    "인터뷰 풀라",
    "비하인드 풀라",
  ]; // 아티클 카테고리
  const [categoryFilter, setCategoryFilter] = useState(
    articleCategories.includes(history.location.state)
      ? [history.location.state]
      : ["전체"]
  ); // 선택된 카테고리 필터
  const [showCategories, setShowCategories] = useState([
    "풀라 Pick",
    "브라학개론",
    "후기를 풀라",
    "인터뷰 풀라",
    "비하인드 풀라",
  ]); // 보여줘야할 카테고리
  const [articles, setArticles] = useState([]); // 전체 아티클
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    const cancelToken = Axios.CancelToken;
    const source = cancelToken.source();

    getAllArticles(source);

    return () => {
      source.cancel("request cancelled");
    };
  }, []);

  useEffect(() => {
    setShowCategories(
      categoryFilter.includes("전체")
        ? [
            "풀라 Pick",
            "브라학개론",
            "후기를 풀라",
            "인터뷰 풀라",
            "비하인드 풀라",
          ]
        : categoryFilter
    );
    setArticles(
      articles.map((a) => {
        a.current = 1;
        return a;
      })
    );
    window.scrollTo(0, 0);
  }, [categoryFilter]);

  const getAllArticles = (source) => {
    // 전체 카테고리 아티클 불러오기
    const categories = [
      "pick",
      "브라학개론",
      "후기를 풀라",
      "인터뷰 풀라",
      "비하인드 풀라",
    ];

    const requests = categories.map((category) => {
      if (category === "pick")
        return Axios.get(
          API_ENDPOINT + "/api/articles/category/pick?detail=true",
          {
            cancelToken: source.token,
          }
        );
      else
        return Axios.get(
          API_ENDPOINT +
            `/api/articles/category/${category}?limit=8&page=1&detail=true`,
          {
            cancelToken: source.token,
          }
        );
    });

    Axios.all(requests)
      .then(
        Axios.spread((...responses) => {
          const result = responses.map((r) => {
            if (r.data.articles[0].pick)
              return {
                category: "풀라 Pick",
                list: r.data.articles,
                page: 2,
                current: 1,
              };
            else
              return {
                category: r.data.articles[0].category,
                list: r.data.articles,
                page: 2,
                current: 1,
              };
          });
          setArticles(result);
          setSpinner(false);
        })
      )
      .catch((errors) => {
        console.error(errors);
      });
  };

  const handleButtonClick = (category) => {
    // 더보기 버튼 클릭 시 다음 페이지 아티클 저장
    const moreArticles = articles.filter((a) => a.category === category)[0];
    setArticles(
      articles.map((a) => {
        if (a.category === category) {
          a.current += 1;
        }
        return a;
      })
    );
    if (moreArticles.page - moreArticles.current < 2) {
      Axios.get(
        API_ENDPOINT +
          `/api/articles/category/${category}?limit=4&page=${
            moreArticles.page + 1
          }&detail=true`
      ).then((response) => {
        if (response.data.success) {
          setArticles(
            articles.map((a) => {
              if (a.category === category) {
                a.list = a.list.concat(response.data.articles);
                a.page += 1;
              }
              return a;
            })
          );
        } else {
          alert("아티클 정보를 가져오는데에 실패했습니다.");
        }
      });
    }
  };

  // 보여줄 카테고리 아티클
  const ArticleSection = articles
    .filter((article) => showCategories.includes(article.category))
    .map((article, i) => {
      const categoryData = ArticleCategoryData.find(
        (data) => data.category === article.category
      );
      return (
        <Section $type={article.category === "풀라 Pick" && "PICK"} key={i}>
          <HeadingText $type={article.category === "풀라 Pick" && "PICK"}>
            {article.category}
          </HeadingText>
          {categoryData && (
            <Text top={6} bottom={24}>
              {categoryData.text}
            </Text>
          )}
          {article.category === "풀라 Pick" ? (
            <ArticleList
              data={article.list}
              align="HORIZONTAL"
              size="LARGE"
              pageType="콘텐츠목록"
            />
          ) : (
            <>
              <ArticleList
                data={article.list.slice(0, article.current * 4)}
                align="VERTICAL"
                pageType="콘텐츠목록"
              />
              {article.list.length > article.current * 4 && (
                <Button
                  top={28}
                  onClick={() => {
                    handleButtonClick(article.category);
                    clickTrigger(
                      article.category.replace(" ", "") + "아티클_더보기"
                    );
                  }}
                >
                  더보기
                  <img
                    src={require("../../assets/icons/arrow/down-blue.svg")}
                    width="20px"
                    height="20px"
                    alt=""
                    style={{ marginLeft: "8px" }}
                  />
                </Button>
              )}
            </>
          )}
        </Section>
      );
    });

  return (
    <>
      <Helmet>
        <title>아티클 | 풀라</title>
        <meta property="og:title" content="아티클 | 풀라" />
      </Helmet>
      <Header type={"OTHERS"} title={"아티클"} history={history} />
      <PageContainer>
        <StickyTab>
          <ScrollFilterList
            type="ARTICLE"
            tags={articleCategories}
            filter={categoryFilter}
            setFilter={setCategoryFilter}
          />
        </StickyTab>
        {spinner ? (
          <div
            style={{
              height: "calc(100vh - 153px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              animation="border"
              style={{
                color: "var(--color-poola-blue)",
                borderRightColor: "transparent",
              }}
            />
          </div>
        ) : (
          ArticleSection
        )}
        <Footer>
          <HeadingText size={16} height={16} center>
            준비한 글을 재밌게 보셨나요?
          </HeadingText>
          <Text top={12} center>
            더 궁금한 내용이 있다면 풀라에게 알려주세요.
            <br />
            보내주신 의견은 서비스 개선 목적으로만 활용됩니다.
          </Text>
          <ButtonWrapper top={32}>
            <Button
              width={49}
              onClick={() => {
                clickTrigger("의견주기");
                window.open("https://35qp8ul61bs.typeform.com/to/v4fFb2PQ");
              }}
            >
              풀라에게 의견주기
            </Button>
            <Button
              width={49}
              color="white"
              bgcolor="poola-blue"
              onClick={() => {
                clickTrigger("Instagram");
                window.open("https://instagram.com/poola4women");
              }}
            >
              풀라 인스타그램
            </Button>
          </ButtonWrapper>
        </Footer>
      </PageContainer>
      <BottomNavigator type="ARTICLE" />
    </>
  );
};

export default ArticleListPage;
