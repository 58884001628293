import React, {useEffect} from "react";
import styled from "styled-components";

const TextWrapper = styled.div`
  padding-bottom: ${props => props.bottom || 32}px;
`;

const ModalOverlay = styled.div`
  background-color: var(--color-black);
  opacity: 0.5;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
`;

const SignOutModal = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 24px ${props => props.padding || 16}px;
  background: #FFFFFF;
  border-radius: 16px;
  z-index : 25;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  ${props => props.single ? 'display: block' : 'justify-content: space-between'};
`;

const SignOutButton = styled.button`
  width: ${props => props.single ? 100 : 48}%;
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  background-color: ${props => props.color === 'BLUE' ? `var(--color-poola-blue)` : `var(--color-white)`};
  color: ${props => props.color === 'BLUE' ? `var(--color-white)` : `var(--color-poola-blue)`};
  border: ${props => props.color === 'BLUE' ? 'none' : `1px solid var(--color-poola-blue)`};
  border-radius: 4px;
`;

const ModalContainer = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 0 24px;
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 11;
`;

export const SignOutDialog = ({isSignOut, visible, setModalVisible, onClose, signOutHandler}) => {

  useEffect(() => { // 모달 배경 스크롤 방지
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`
    return () => {
      const scrollY = document.body.style.top
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  return (
      <>
        {isSignOut ?
            <>
              <ModalOverlay visible={visible} onClick={onClose}/>
              <ModalContainer onClick={onClose}>
                <SignOutModal padding={24}>
                  <TextWrapper bottom={24}>
                    <p style={{fontWeight: "bold", lineHeight:"16px", textAlign: "center", marginBottom:'12px'}}>탈퇴가 완료되었습니다</p>
                    <p style={{textAlign: "center", margin:0, fontSize:"13px", lineHeight:"19px", color:'#444444'}}>그동안 풀라를 이용해주셔서 감사합니다</p>
                  </TextWrapper>
                  <ButtonWrapper single={true}>
                    <SignOutButton color={'BLUE'} single={true} onClick={onClose}>
                      확인
                    </SignOutButton>
                  </ButtonWrapper>
                </SignOutModal>
              </ModalContainer>
            </>
            :
            <>
              <ModalOverlay visible={visible} onClick={onClose}/>
              <ModalContainer onClick={onClose}>
                <SignOutModal padding={17}>
                  <TextWrapper bottom={32}>
                    <p style={{fontWeight: "bold", lineHeight:"16px", textAlign: "center", marginBottom:'12px'}}>탈퇴하시겠습니까?</p>
                    <p style={{textAlign: "center", margin:0, fontSize:"13px", lineHeight:"19px", color:'#444444'}}>모든 정보가 삭제됩니다</p>
                  </TextWrapper>
                  <ButtonWrapper>
                    <SignOutButton color={'WHITE'} onClick={() => setModalVisible(false)}>
                      안 할래요
                    </SignOutButton>
                    <SignOutButton color={'BLUE'} single={false} onClick={signOutHandler}>
                      탈퇴할래요
                    </SignOutButton>
                  </ButtonWrapper>
                </SignOutModal>
              </ModalContainer>
            </>
        }
      </>
  )
};