import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "core-js";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import Axios from "axios";
import Clayful from "clayful/client-js";
import rootReducer, { rootSaga } from "./modules";
import { getUser } from "./modules/user";
import ScrollTop from "./components/Common/ScrollTop";
import { HelmetProvider } from "react-helmet-async";
import RouteChangeTracker from "./modules/RouteChangeTracker";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

Clayful.config({
  client:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjdkYzE5ODUxNjAxNWQxZmY0NjViM2NjMWVmODE3MzYxNmU3NjUxMWJmMGZhZTE0ZWY0ZDYyZWU3OTU4ZDIyMmUiLCJyb2xlIjoiY2xpZW50IiwiaWF0IjoxNjMxMDMyMzExLCJzdG9yZSI6IkVDSkVBM0RUTlpKNy45OThEV0dLSEJOWFQiLCJzdWIiOiJIRE1ZTEpVN1BMUVUifQ.Qji4tseQvMZN7KvR41Mg6lKy4K88DGbJr0XRlx2yBbQ",
});
Clayful.install("request", require("clayful/plugins/request-axios")(Axios));

function loadUser() {
  try {
    const userEmail = sessionStorage.getItem("user");
    if (userEmail) {
      store.dispatch(getUser({ email: userEmail }));
    }
  } catch (e) {
    console.log("sessionStorage is not working");
  }
}

sagaMiddleware.run(rootSaga);
loadUser();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <RouteChangeTracker />
      <ScrollTop />
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
