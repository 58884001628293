import React, { useEffect } from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  background-color: var(--color-black);
  opacity: 0.5;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
`;

const ModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  z-index: 20;
  background-color: white;
  border-radius: 8px 8px 0 0;
  padding: 32px 16px 10px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const SubTitleBox = styled.div`
  padding: 24px 0 16px;
`;

const SubTitle = styled.h3`
  margin: 0;
  padding: 0 0 6px 0;
  color: var(--color-poola-blue);
  font-size: 16px;
  font-weight: bold;
`;

const SubDesc = styled.span`
  font-size: 12px;
  color: var(--color-secondary-black);
`;

const ProgressBox = styled.div`
  width: 100%;
  height: 28px;
  padding: 6px 0px;
  position: relative;

  progress[value] {
    width: 100%;
    height: 18px;
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    display: inline-block;
    position: absolute;
    top: 6px;
  }
  progress[value]::-webkit-progress-bar {
    border-radius: 4px 0 0 4px;
    background: none;
    border: none;
  }
  progress[value]::-webkit-progress-value {
    border: none;
    border-radius: 4px 0 0 4px;
    background: linear-gradient(to left, #00398f 0%, #5a9afb 100%);
  }
`;

const RadiusProgressBox = styled.div`
  width: 100%;
  height: 28px;
  padding: 6px 0px;
  position: relative;

  progress[value] {
    width: 100%;
    height: 18px;
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    display: inline-block;
    position: absolute;
    top: 6px;
  }
  progress[value]::-webkit-progress-bar {
    border-radius: 4px;
    background: none;
    border: none;
  }
  progress[value]::-webkit-progress-value {
    border: none;
    border-radius: 4px;
    background: linear-gradient(to left, #00398f 0%, #5a9afb 100%);
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  font-size: 13px;
  line-height: 19px;
  color: #777777;
  border-radius: 4px;
  border: 1px solid var(--color-disabled-gray);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    width: 50%;
    height: 16px;
    border-right: 1px solid var(--color-disabled-gray);

    &:last-child {
      border-right: none;
    }
  }
`;

const GraphBox = styled.div``;

const Level = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    color: var(--color-secondary-black);
    font-size: 14px;
  }
`;

const ReviewCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0 8px;

  span {
    position: relative;
    color: var(--color-secondary-black);
    font-size: 12px;
  }
`;

const Button = styled.button`
  border: none;
  background-color: var(--color-poola-blue);
  color: white;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  height: 40px;
  margin: 56px 0 40px;
  border-radius: 4px;
`;

export const ReviewCompleteBottomSheet = ({ reviewCount, level, _onClose }) => {
  useEffect(() => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const progress_max =
    reviewCount < 3
      ? 2
      : reviewCount > 2 && reviewCount < 6
      ? 3
      : 5 < reviewCount
      ? 4
      : 4;

  const progress_value =
    reviewCount === 0
      ? 0.05
      : reviewCount === 1 || reviewCount === 3 || reviewCount === 6
      ? 1
      : reviewCount === 2 || reviewCount === 4 || reviewCount === 7
      ? 2
      : reviewCount === 5 || reviewCount === 8
      ? 3
      : 4;

  const prevLevel =
    level === 1 || (level === 2 && reviewCount < 3)
      ? 1
      : (level === 2 && reviewCount < 6) || (level === 3 && reviewCount < 6)
      ? 2
      : level >= 3 && reviewCount > 5
      ? 3
      : 3;
  const nextLevel =
    level === 1 || (level === 2 && reviewCount < 3)
      ? 2
      : (level === 2 && reviewCount < 6) || (level === 3 && reviewCount < 6)
      ? 3
      : level >= 3 && reviewCount > 5
      ? 4
      : 4;

  return (
    <div>
      <ModalOverlay onClick={_onClose} />
      <ModalContainer>
        <Title>리뷰 작성 완료!</Title>
        <SubTitleBox>
          <SubTitle>리뷰레벨</SubTitle>
          <SubDesc>레벨이 오를 때마다 쿠폰을 드려요</SubDesc>
        </SubTitleBox>
        <GraphBox>
          <Level>
            <span>Lv {prevLevel}</span>
            <span>Lv {nextLevel}</span>
          </Level>
          {reviewCount === 2 || reviewCount === 5 || reviewCount === 9 ? (
            <RadiusProgressBox>
              <ProgressBar>
                {Array.from({ length: progress_max }, (_, index) => (
                  <div key={index} />
                ))}
              </ProgressBar>
              <progress value={progress_value} max={progress_max} />
            </RadiusProgressBox>
          ) : (
            <ProgressBox>
              <ProgressBar>
                {Array.from({ length: progress_max }, (_, index) => (
                  <div key={index} />
                ))}
              </ProgressBar>
              <progress value={progress_value} max={progress_max} />
            </ProgressBox>
          )}
        </GraphBox>
        <ReviewCount>
          <span>
            {reviewCount < 3
              ? 0
              : reviewCount > 2 && reviewCount < 6
              ? 2
              : 5 < reviewCount
              ? 5
              : 5}
            개
          </span>
          <span>
            {reviewCount < 3
              ? 1
              : 2 <= reviewCount && reviewCount < 6
              ? 3
              : 5 < reviewCount
              ? 6
              : 6}
            개
          </span>
          <span>
            {reviewCount < 3
              ? 2
              : 3 <= reviewCount && reviewCount < 6
              ? 4
              : reviewCount >= 6
              ? 7
              : 1}
            개
          </span>
          {reviewCount >= 3 && (
            <span>{reviewCount < 6 ? 5 : reviewCount > 5 ? 8 : 1}개</span>
          )}
          {reviewCount >= 6 && <span>9개</span>}
        </ReviewCount>
        <Button type="button" onClick={_onClose}>
          확인
        </Button>
      </ModalContainer>
    </div>
  );
};
