import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { API_ENDPOINT } from "../../Config";
import { Header } from "../../components/Common/Header";
import { ArticleList } from "../../components/List/ArticleList";
import { BottomNavigator } from "../../components/Common/BottomNavigator";
import "../../templates/article.css";
import { Helmet } from "react-helmet";
import { clickTrigger } from "../../modules/trigger";
import ReactPixel from "react-facebook-pixel";

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  padding: 40px 0 max(49px, calc(env(safe-area-inset-bottom) + 49px));
  background-color: var(--color-white);
`;

const Section = styled.section`
  padding: 28px 16px 32px 16px;
  border-top: 8px solid var(--color-bg-gray);
`;

const Article = styled.article`
  padding: 47px 0 32px 0;
`;

const ScrollProgress = styled.div`
  width: 100%;
  max-width: 500px;
  height: 3px;
  position: fixed;
  z-index: 10;
  background-color: var(--color-disabled-gray);

  div {
    height: 100%;
    background-color: var(--color-poola-blue);
  }
`;

const ArticleHeader = styled.header`
  margin-bottom: 32px;
  padding: 0 16px;
`;

const HeadingText = styled.h2`
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
`;

const Caption = styled.span`
  margin-right: 8px;
  font-weight: 400;
  font-size: ${(props) => (props.line && 11) || 13}px;
  line-height: 19px;
  color: var(
    --color-${(props) => (props.line && "border-gray") || "secondary-black"}
  );
`;

const CountWrapper = styled.div`
  padding: 20px 0 0 0;
  margin: 0 24px;
  display: flex;
  border-top: 1px solid var(--color-border-gray);
`;

const Button = styled.button`
  padding: 0;
  margin-right: 26px;
  display: flex;
  align-items: flex-end;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-tertiary-black);
  border: none;
  background: none;
  outline: none;
`;

const ButtonLink = styled(Link)`
  width: 100%;
  height: 40px;
  margin-top: 28px;
  padding: 12px;
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-poola-blue);
  background-color: var(--color-white);
  border: 1px solid var(--color-poola-blue);
  border-radius: 4px;
  &:hover {
    color: var(--color-poola-blue);
  }
`;

const ArticleDetailPage = ({ history }) => {
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  let { articleId } = useParams(); // 아티클 id
  const articleInfoFromSession = JSON.parse(
    window.sessionStorage.getItem("articleInfo")
  );
  const [article, setArticle] = useState(articleInfoFromSession); // 아티클 정보
  const [others, setOthers] = useState([]); // 다른 아티클 목록
  const [isLike, setIsLike] = useState(false); // 좋아요 여부(로그인 유저)
  const [likes, setLikes] = useState(0); // 전체 좋아요 개수
  const [likedPeople, setLikedPeople] = useState([]); // 아티클에 좋아요 누른 유저
  const [width, setWidth] = useState(0); // 진행바
  const progressRef = useRef();
  const [spinner, setSpinner] = useState(true); // 아티클 전체

  useEffect(() => {
    window.scrollTo(0, 0); // 스크롤 맨 위로
    setIsLike(false);
  }, [article]);

  useEffect(() => {
    const cancelToken = Axios.CancelToken;
    const source = cancelToken.source();

    Axios.get(API_ENDPOINT + `/api/articles/${articleId}?limit=4&detail=true`, {
      cancelToken: source.token,
    }).then((response) => {
      if (response.data.success) {
        setArticle(response.data.article);
        setOthers(response.data.others);
        setLikes(response.data.article.likes);
        setLikedPeople(response.data.liked_people);
        ReactPixel.track("ViewContent", {
          content_name: response.data.article?.title?.replace(
            "<br class='article-br'>",
            " "
          ),
        });
        setSpinner(false);
      } else {
        alert("아티클을 불러오는 데에 실패했습니다.");
      }
    });

    return () => {
      source.cancel("request cancelled");
    };
  }, [articleId]);

  useEffect(() => {
    if (user?.email && likedPeople && likedPeople.includes(user?._id)) {
      setIsLike(true);
    }
  }, [user.email, likedPeople]);

  const updateLike = (action) => {
    Axios.put(
      API_ENDPOINT +
        `/api/articles/${articleId}/likes/users/${user.email ? user?._id : ""}`
    ).then((response) => {
      if (response.data.success) {
        if (response.data.action === action) {
          // console.log("아티클 좋아요");
        } else {
          console.log("아티클 좋아요에 실패했습니다.");
          setIsLike(response.data.action === "add");
          setLikes(response.data.likes);
        }
      } else {
        console.log("아티클 좋아요 업데이트에 실패했습니다.");
        setIsLike(action === "delete"); // add였다면 좋아요X delete였다면 좋아요O로 바꾸기
      }
    });
  };

  const handleLikeButton = (event) => {
    if (event.currentTarget.name === "like") {
      setIsLike(true);
      setLikes(likes + 1);
      updateLike("add");
      clickTrigger("좋아요");
    } else if (event.currentTarget.name === "unlike") {
      // 좋아요 취소
      if (user?.email) {
        setIsLike(false);
        setLikes(likes - 1);
        updateLike("delete");
        clickTrigger("좋아요취소");
      }
    }
  };

  const handleScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop === 0) {
      setWidth(0);
      return;
    }
    const windowHeight = scrollHeight - clientHeight;
    const currentPercent = scrollTop / windowHeight;
    setWidth(currentPercent * 100);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [handleScroll]);

  return (
    <>
      <Helmet>
        <title>
          {`${article?.title?.replace("<br class='article-br'>", " ")} | 풀라`}
        </title>
        <meta
          property="og:title"
          content={`${article?.title?.replace(
            "<br class='article-br'>",
            " "
          )} | 풀라`}
        />
      </Helmet>
      {spinner ? (
        <div
          style={{
            height: "calc(100vh - 49px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner
            animation="border"
            style={{
              color: "var(--color-poola-blue)",
              borderRightColor: "transparent",
            }}
          />
        </div>
      ) : (
        <>
          <Header type="BACK" title={article?.category} history={history} />
          <PageContainer>
            <ScrollProgress ref={progressRef}>
              <div style={{ width: width + "%" }} />
            </ScrollProgress>
            <Article>
              <ArticleHeader>
                <h2
                  className="article-title"
                  dangerouslySetInnerHTML={{ __html: article?.title }}
                />
                <Caption>Edit by {article?.editor}</Caption>
                <Caption line>|</Caption>
                <Caption>
                  {article.createdAt && article.createdAt.slice(0, 10)}
                </Caption>
              </ArticleHeader>
              <div
                className="article"
                dangerouslySetInnerHTML={{ __html: article?.text }}
              />
              <CountWrapper>
                <Button
                  name={isLike ? "unlike" : "like"}
                  onClick={handleLikeButton}
                >
                  <img
                    src={require("../../assets/icons/thumbs-up-" +
                      (isLike ? "clicked.svg" : "default.svg"))}
                    alt="좋아요"
                    width="24px"
                    height="24px"
                    style={{ marginRight: "8px" }}
                  />
                  {likes}
                </Button>
                <div
                  style={{
                    display: "flex",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    alignItems: "flex-end",
                    color: "var(--color-tertiary-black)",
                  }}
                >
                  <img
                    src={require(`../../assets/icons/view-` +
                      (article?.views >= 200 ? "hot" : "default") +
                      ".svg")}
                    alt="조회"
                    width="24px"
                    height="24px"
                    style={{ marginRight: "8px" }}
                  />
                  {article.views}
                </div>
              </CountWrapper>
            </Article>
            <Section>
              <HeadingText>모든 속옷 이야기, 여기서 풀라</HeadingText>
              <ArticleList data={others} align="VERTICAL" pageType="콘텐츠" />
              <ButtonLink
                to="/article"
                onClick={() => clickTrigger("아티클_전체보기")}
              >
                전체보기
              </ButtonLink>
            </Section>
          </PageContainer>
        </>
      )}
      <BottomNavigator />
    </>
  );
};

export default ArticleDetailPage;
