import React from "react";
import styled from "styled-components";

const ReviewCard = styled.div`
  padding: 10px 16px;
  cursor: pointer;
`;

export const ReviewContentCard = ({ data, history }) => {
  return (
    <ReviewCard
      key={data?.id}
      onClick={() => history.push(`/reviewDetail/${data?.id}`)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingBottom: "8px",
            }}
          >
            <div
              style={{
                backgroundColor: "#dee8f9",
                height: "22px",
                borderRadius: "20px",
                padding: "4px 6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "8px",
              }}
            >
              <img
                src={require("../../assets/icons/star-filled.svg")}
                alt="rating"
                width="12px"
                height="12px"
              />
              <span
                style={{
                  marginLeft: "2px",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "var(--color-poola-blue)",
                }}
              >
                {data?.rating}
              </span>
            </div>
            <span
              style={{
                color: "var(--color-poola-blue)",
                fontWeight: 700,
                fontSize: "12px",
              }}
            >
              {data?.size} {data?.type && "/ " + data?.type}
            </span>
          </div>
          {data?.brand && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: "8px",
              }}
            >
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: 700,
                  paddingRight: "4px",
                }}
              >
                {data?.brand}
              </span>
              <span style={{ fontSize: "13px" }}>{data?.productName}</span>
            </div>
          )}
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              fontSize: "12px",
              lineHeight: "18px",
            }}
          >
            {data?.text}
          </div>
        </div>
        {data?.thumb && (
          <div style={{ marginLeft: "12px" }}>
            <img
              src={
                typeof data.thumb === "string" && !data.thumb.includes("https")
                  ? "https://d1bkjagntxw4mp.cloudfront.net/reviews/" + data?.thumb + "?h=500"
                  : data?.thumb + "?w=100&h=100&q=80"
              }
              alt="상단이미지"
              width="68px"
              height="68px"
              style={{ objectFit: "cover" }}
            />
          </div>
        )}
      </div>
    </ReviewCard>
  );
};
