import React from "react";
import styled, { css } from "styled-components";
import { formatPrice } from "../../modules/util";
import { IMAGE_SERVER_LOCATION } from "../../Config";

const Card = styled.article`
  width: 100%;

  ${(props) =>
    props.isCart
      ? css`
          position: relative;

          ${(props) =>
            props.type === "BUNDLE" &&
            css`
              padding: 20px 0 0 32px;
            `}
        `
      : css`
          display: flex;

          ${(props) =>
            props.type === "BUNDLE" &&
            css`
              padding: 20px 0 0;
            `}
        `}
`;

const Image = styled.img`
  margin-right: 12px;
  border-radius: 6px;
  object-fit: cover;
  opacity: ${(props) => props.opacity || 1.0};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  word-break: break-word;
  ${(props) =>
    props.isCart
      ? css`
          padding-right: 16px;
        `
      : css``}
`;

const ProductText = styled.strong`
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-${(props) => props.color || "secondary"}-black);
`;

const OptionText = styled.span`
  margin-top: 4px;
  display: block;
  font-size: 13px;
  line-height: 19px;
  color: var(--color-tertiary-black);
`;

const PriceWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  ${(props) =>
    props.isCart &&
    css`
      flex-direction: column;
      position: absolute;
      right: 0;
      bottom: 0;
      text-align: right;
    `}
`;

const PriceText = styled.em`
  margin-right: ${(props) => props.mr || 0}px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: var(
    --color-${(props) => (!props.isActive ? "tertiary-black" : "black")}
  );
`;

const DelPriceText = styled.del`
  font-size: 12px;
  line-height: 19px;
  color: var(--color-tertiary-black);
`;

const QuantityWrapper = styled.div`
  padding-top: 12px;
  display: inline-flex;
`;

const QuantityButton = styled.button`
  width: 28px;
  height: 28px;
  padding: 4px;
  line-height: 1;
  background: var(--color-white);
  border: 1px solid var(--color-border-gray);

  ${(props) =>
    props.position === "LEFT"
      ? css`
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        `
      : css`
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        `}
`;

const QuantityInput = styled.p`
  width: 28px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: var(--color-secondary-black);
  border-top: 1px solid var(--color-border-gray);
  border-bottom: 1px solid var(--color-border-gray);
`;

const DeleteButton = styled.button`
  padding: 0;
  position: absolute;
  top: ${(props) => (props.type === "PRODUCT" ? 0 : 20)}px;
  right: 0;
  background-color: var(--color-white);
  border: none;
`;

export const ProductOrderCard = ({
  data,
  type,
  isCart,
  _onQuantityClick,
  _onDeleteClick,
}) => {
  return (
    <Card type={type} isCart={isCart}>
      <InfoWrapper isCart={isCart}>
        {type !== "BUNDLE" && (
          <Image
            src={
              data.thumb
                ? `${data.thumb}`
                : IMAGE_SERVER_LOCATION + "s400/" + data.No + ".png?h=500"
            }
            alt=""
            width="65px"
            height="65px"
            opacity={data.available ? 1.0 : 0.5}
          />
        )}
        <div>
          <ProductText color={data.available ? "secondary" : "tertiary"}>
            {data.name}
          </ProductText>
          <OptionText>
            {data.option} {data.quantity}개
          </OptionText>
          {!isCart && (
            <PriceWrapper>
              <PriceText mr={8} isActive>
                {formatPrice(data.price.sale * data.quantity)}원
              </PriceText>
              {data.price.original > data.price.sale && (
                <DelPriceText>
                  {formatPrice(data.price.original * data.quantity)}원
                </DelPriceText>
              )}
            </PriceWrapper>
          )}
        </div>
      </InfoWrapper>
      {isCart && (
        <>
          <QuantityWrapper>
            <QuantityButton
              value="minus"
              position="LEFT"
              disabled={
                data.available ? data.quantity < 2 || !data.isSelected : true
              }
              onClick={(e) => _onQuantityClick(e, data)}
            >
              <img
                src={require(`../../assets/icons/cart-minus${
                  (
                    data.available
                      ? data.quantity < 2 || !data.isSelected
                      : true
                  )
                    ? "-disabled"
                    : ""
                }.svg`)}
                width="16px"
                height="16px"
                alt="-"
              />
            </QuantityButton>
            <QuantityInput>{data.quantity}</QuantityInput>
            <QuantityButton
              value="plus"
              position="RIGHT"
              disabled={data.available ? !data.isSelected : true}
              onClick={(e) => _onQuantityClick(e, data)}
            >
              <img
                src={require(`../../assets/icons/cart-plus${
                  (data.available ? !data.isSelected : true) ? "-disabled" : ""
                }.svg`)}
                width="16px"
                height="16px"
                alt="+"
              />
            </QuantityButton>
          </QuantityWrapper>
          <PriceWrapper isCart>
            {data.available ? (
              <>
                {data.isSelected && data.price.original > data.price.sale && (
                  <DelPriceText>
                    {formatPrice(data.price.original * data.quantity)}원
                  </DelPriceText>
                )}
                <PriceText isActive={data.isSelected}>
                  {formatPrice(data.price.sale * data.quantity)}원
                </PriceText>
              </>
            ) : (
              <PriceText isActive={false}>품절</PriceText>
            )}
          </PriceWrapper>
          <DeleteButton type={type} onClick={() => _onDeleteClick(data)}>
            <img
              src={require("../../assets/icons/cart-item-delete.svg")}
              width="16px"
              height="16px"
              alt="삭제"
            />
          </DeleteButton>
        </>
      )}
    </Card>
  );
};

ProductOrderCard.defaultProps = {
  data: {
    product: {
      name: "Test",
      slug: "test_1",
    },
    variant: {
      types: [{ option: { name: "사이즈" }, variation: { value: "FREE" } }],
    },
    price: {
      sale: { convertedRaw: 0, converted: "0원" },
      original: { convertedRaw: 0, converted: "0원" },
    },
    quantity: { converted: "1" },
  },
};
