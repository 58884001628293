export function formatPrice(number) {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getRandomData(reviews) {
  const result = [];
  const copiedData = [...reviews];

  for (let i = 0; i < 2; i++) {
    const randomIndex = Math.floor(Math.random() * copiedData.length);
    result.push(copiedData.splice(randomIndex, 1)[0]);
  }

  return result;
}

export function getRandomOriginalData(reviews) {
  const shuffle = () => Math.random() - 0.5;
  const result = [...reviews].sort(shuffle);
  return result;
}

export function getRandomNickname() {
  // 동물 이름과 숫자 배열 생성
  const animals = ["고양이", "강아지", "햄스터", "여우", "토끼"];
  let numbers = [];

  // 10부터 99까지 숫자 추가
  for (let j = 10; j < 100; j++) {
    numbers.push(String(j));
  }

  // 랜덤 동물 이름과 숫자 선택
  const randomAnimal = animals[Math.floor(Math.random() * animals.length)];
  const randomNumber = numbers[Math.floor(Math.random() * numbers.length)];

  return { randomAnimal, randomNumber };
}

export function getRandomBodyType() {
  // 체형 타입
  const types = [
    "70A-BPW",
    "75B-EEE",
    "65C-BEW",
    "80D-BPE",
    "70C-TEE",
    "65AA-BSW",
    "70B-EEW",
    "75A-BEE",
  ];

  return types[Math.floor(Math.random() * types.length)];
}

export function getNextType(type) {
  const types = [
    "70A-BPW",
    "75B-EEE",
    "65C-BEW",
    "80D-BPE",
    "70C-TEE",
    "65AA-BSW",
    "70B-EEW",
    "75A-BEE",
  ];
  const index = types.indexOf(type);
  if (index >= 0 && index < types.length - 1) {
    return types[index + 1];
  } else {
    return types[0]; // 타입이 없는 경우 null 반환
  }
}

export function getTranslateType(type) {
  const memo = [
    {
      T: "윗볼륨",
      B: "아래볼륨",
      E: "일정한 볼륨",
    },
    {
      P: "돌출",
      S: "완만",
      E: "고르게 퍼진",
    },
    {
      W: "먼",
      C: "가까운",
      E: "보통 거리",
    },
  ];

  const result = [];
  for (let i = 0; i < 3; i++) {
    const key = type[i];
    if (memo[i][key]) {
      result.push(memo[i][key]);
    }
  }
  return result.join("/");
}

export function getTranslateTypeForReview(type) {
  const memo = [
    {
      T: "윗볼륨",
      B: "아래볼륨",
      E: "일정한 볼륨",
    },
    {
      P: "돌출",
      S: "완만",
      E: "고르게 퍼진",
    },
    {
      W: "먼 가슴",
      C: "가까운 가슴",
      E: "보통 거리 가슴",
    },
  ];

  const result = [];
  for (let i = 0; i < 3; i++) {
    const key = type[i];
    if (memo[i][key]) {
      result.push(memo[i][key]);
    }
  }
  return result.join("/");
}

export const getFilteredReviews = (review) => {
  const filteredReviews = review.filter((item) => {
    const createdAt = new Date(item.createdAt);
    const julyFirst2023 = new Date("2023-07-19");

    return createdAt > julyFirst2023;
  });

  return filteredReviews;
};

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}.${month}.${day}`;
};
