import React, { useEffect, useMemo } from "react";
import styled, { ThemeProvider } from "styled-components";
import theme from "../Common/Theme";
import ProductCard from "../Card/ProductCard";

const List = styled.ul`
	margin: 0;
	padding: 0 16px;
	list-style: none;

	${({ theme, align }) => theme.alignList[align]};
`;

const ListItem = styled.li`
	width: 49%;
	display: inline-block;
	vertical-align: top;
	line-height: 1;

	${({ theme, align }) => theme.alignItem[align]};
`;

export const ProductList = ({
	data = [],
	type,
	align = "VERTICAL",
	event,
	detail,
	_onClick,
	collection,
	which,
	my,
	sort,
	filter,
}) => {
	// const getSortedAndFilteredData = () => {
	// 	let sortedData = [...data];
	// 	// 	추천도 상위 30%만 필터
	// 	// if (filter === "Top30PercentRecommend") {
	// 	// 	sortedData.sort(
	// 	// 		(a, b) =>
	// 	// 			(b.recommend ? b.recommend : 0) - (a.recommend ? a.recommend : 0)
	// 	// 	);
	// 	// 	const top30PercentIndex = Math.ceil(sortedData.length * 0.3);
	// 	// 	sortedData = sortedData.slice(0, top30PercentIndex);
	// 	// }
	// 	// if (sort === "reviewCount") {
	// 	// 	sortedData.sort(
	// 	// 		(a, b) =>
	// 	// 			(b.review ? b.review.count : 0) - (a.review ? a.review.count : 0)
	// 	// 	);
	// 	// }
	// 	if (sort === "recommend") {
	// 		sortedData = data.filter((item) => item.recommend >= 0.84);
	// 	}
	// 	if (filter === "six") {
	// 		const selectedData = [];
	// 		const count = Math.min(6, sortedData.length); // 데이터가 6개 미만일 경우 대비
	// 		// 선택된 인덱스를 추적하여 중복 선택 방지
	// 		const selectedIndexes = new Set();
	// 		while (selectedData.length < count) {
	// 			const randomIndex = Math.floor(Math.random() * sortedData.length);
	// 			if (!selectedIndexes.has(randomIndex)) {
	// 				selectedIndexes.add(randomIndex);
	// 				selectedData.push(sortedData[randomIndex]);
	// 			}
	// 		}
	// 		sortedData = selectedData;
	// 	}
	// 	if (filter === "ten") {
	// 		const selectedData = [];
	// 		const count = Math.min(10, sortedData.length); // 데이터가 10개 미만일 경우 대비
	// 		// 선택된 인덱스를 추적하여 중복 선택 방지
	// 		const selectedIndexes = new Set();
	// 		while (selectedData.length < count) {
	// 			const randomIndex = Math.floor(Math.random() * sortedData.length);
	// 			if (!selectedIndexes.has(randomIndex)) {
	// 				selectedIndexes.add(randomIndex);
	// 				selectedData.push(sortedData[randomIndex]);
	// 			}
	// 		}
	// 		sortedData = selectedData;
	// 	}
	// 	return sortedData;
	// };

	// const sortedAndFilteredData = getSortedAndFilteredData();

	const sortedAndFilteredData = useMemo(() => {
		let sortedData = [...data];

		if (sort === "recommend") {
			sortedData = sortedData.filter((item) => item.recommend >= 0.84);
		}

		if (filter === "six") {
			const selectedData = [];
			const count = Math.min(6, sortedData.length);
			const selectedIndexes = new Set();

			while (selectedData.length < count) {
				const randomIndex = Math.ceil(Math.random() * sortedData.length);
				if (!selectedIndexes.has(randomIndex)) {
					selectedIndexes.add(randomIndex);
					selectedData.push(sortedData[randomIndex]);
				}
			}
			sortedData = selectedData;
		}

		if (filter === "ten") {
			const selectedData = [];
			const count = Math.min(10, sortedData.length);
			const selectedIndexes = new Set();

			while (selectedData.length < count) {
				const randomIndex = Math.ceil(Math.random() * sortedData.length);
				if (!selectedIndexes.has(randomIndex)) {
					selectedIndexes.add(randomIndex);
					selectedData.push(sortedData[randomIndex]);
				}
			}
			sortedData = selectedData;
		}

		return sortedData;
	}, [data, sort, filter]);

	const Products = sortedAndFilteredData?.map((product, index) => {
		let productRating = { rate: 0, count: 0 };
		let count = product.review ? product.review.count : 0; // 리뷰 수
		let userRateSum = product.review ? product.review.rate * product.review.count : 0;

		if (count === 0) {
			productRating.rate = 0;
		} else {
			productRating.rate = Math.ceil((userRateSum / count) * 10) / 10; // 소수점 아래 한자리
		}
		productRating.count = count;

		return (
			<ListItem align={align} key={product._id}>
				<ProductCard
					data={product}
					type={type}
					rating={productRating}
					event={event}
					_onClick={_onClick}
					detail={detail}
					dumRank={index}
					collection={collection}
					which={which}
					my={my}
				/>
			</ListItem>
		);
	});

	return (
		<ThemeProvider theme={theme}>
			<List align={align}>{Products}</List>
		</ThemeProvider>
	);
};
