import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { clickTrigger } from "../../modules/trigger";
import theme from "../Common/Theme";
import { GA4EventTracker } from "../../hook/GA4EventTracker";

const COLOR = {
  DEFAULT: ["poola-blue", "white"],
  DISABLED: ["icon-gray", "disabled-gray"],
};

const FilterList = styled.ul`
  padding: ${({ padding }) => (padding ? padding : "12px 16px !important")};
  margin: 0;
  list-style: none;
  overflow-x: auto !important;

  ${({ theme, align }) => theme.alignList[align]};
`;

const ListItem = styled.li`
  margin-right: 6px;
  display: inline-block;
  &:last-child {
    margin: 0;
  }
`;

const Tag = styled.button`
  min-width: 50px;
  height: 28px;
  padding: 6px 12px;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(
    --color-${(props) => (props.selected ? COLOR[props.type][1] : COLOR[props.type][0])}
  );
  background-color: var(
    --color-${(props) => (props.selected ? COLOR[props.type][0] : COLOR[props.type][1])}
  );
  border: 1px solid
    var(
      --color-${(props) => (props.type === "DEFAULT" ? COLOR[props.type][0] : COLOR[props.type][1])}
    );
  border-radius: 20px;
`;

export const ScrollFilterList = ({
  type,
  tags,
  disabled,
  filter,
  setFilter,
  padding,
}) => {
  let hashTags;

  const handleClick = (tag) => {
    if (type === "ARTICLE") {
      // 아티클 카테고리 필터 선택
      if (filter.includes(tag)) {
        // 다시 누르면 필터 선택 취소
        setFilter(["전체"]);
      } else {
        // 필터 선택
        setFilter([tag]);
      }
      clickTrigger("아티클카테고리필터", { categoryName: tag });
    } else {
      if (type === "TROUBLE" || type === "SINGLE") {
        // TROUBLE 필터와 SINGLE 필터는 단일 선택만 가능하도록 수정
        setFilter([tag]);
        if (type === "TROUBLE") {
          clickTrigger("고민필터", { category: tag });
          GA4EventTracker("고민칩_클릭", {
            칩_이름: tag,
          });
        }
      } else {
        if (filter.includes(tag)) setFilter(filter.filter((t) => tag !== t));
        else setFilter([...filter, tag]);

        if (type === "SIZE") clickTrigger("사이즈필터", { size: tag });
        else if (type === "HASHTAG")
          clickTrigger("해시태그필터", { hashTag: tag });
      }
    }
  };

  if (type === "HASHTAG") {
    hashTags = tags.map((tag) => {
      return "#" + tag;
    });
  }

  const Tags = tags.map((tag, i) => {
    if (disabled.includes(tag)) {
      return (
        <ListItem key={i}>
          <Tag type="DISABLED" disabled>
            {tag}
          </Tag>
        </ListItem>
      );
    } else {
      return (
        <ListItem key={i}>
          <Tag
            type="DEFAULT"
            selected={filter && filter.includes(tag)}
            onClick={() => handleClick(tag)}
          >
            {type === "HASHTAG" ? hashTags[i] : tag}
          </Tag>
        </ListItem>
      );
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <FilterList align="HORIZONTAL" padding={padding}>
        {Tags}
      </FilterList>
    </ThemeProvider>
  );
};

ScrollFilterList.defaultProps = {
  type: "SIZE",
  tags: [
    "65A",
    "65B",
    "65C",
    "65D",
    "65E+",
    "70A",
    "70B",
    "70C",
    "70D",
    "70E+",
    "75A",
    "75B",
    "75C",
    "75D",
    "75E+",
    "80A",
    "80B",
    "80C",
    "80D",
    "80E+",
    "85A",
    "85B",
    "85C",
    "85D",
    "85E+",
    "90A",
    "90B",
    "90C",
    "90D",
    "90E+",
    "95+A",
    "95+B",
    "95+C",
    "95+D",
    "95+E+",
  ],
  //tags: ['자체제작', '빅사이즈', '심플', '섹시'],
  disabled: [],
};
