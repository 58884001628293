import { createAction, handleActions } from "redux-actions";
import { takeLatest, call, put } from "redux-saga/effects";
import * as authAPI from "../lib/api/auth";
import { createRequestActionTypes } from "../lib/createRequestSaga";

const TEMP_SET_USER = "user/TEMP_SET_USER"; // 새로고침 이후 임시 로그인 처리
// 회원 정보 확인
const SET_USER_INFO = "user/SET_USER_INFO";
const [CHECK, CHECK_SUCCESS, CHECK_FAILURE] = createRequestActionTypes(
  "user/CHECK"
);
const LOGOUT = "user/LOGOUT";
const userEmail = sessionStorage.getItem("user"); // TODO: DEFAULT 값 설정하기

export const tempSetUser = createAction(TEMP_SET_USER, (user) => user);
export const setUserInfo = createAction(SET_USER_INFO, (user) => user);
export const getUser = createAction(CHECK, (body) => body);
export const logout = createAction(LOGOUT);

// const checkSaga = createRequestSaga(CHECK, authAPI.getUser);

function* checkSaga(action) {
  try {
    const response = yield call(authAPI.getUser, action.payload);
    // console.log(response.data);
    if (response.data.success)
      yield put({ type: CHECK_SUCCESS, payload: response.data });
    else return alert("로그인에 실패했습니다. 관리자에게 문의하세요.");
  } catch (e) {
    yield put({ type: CHECK_FAILURE, payload: e });
  }
}

function checkFailureSaga() {
  try {
    sessionStorage.removeItem("user"); // sessionStorage 에서 user 제거하고
  } catch (e) {
    console.log("localStorage is not working");
  }
}

function* logoutSaga() {
  try {
    // yield call(authAPI.logout); // logout API 호출
    sessionStorage.removeItem("user"); // localStorage 에서 user 제거
  } catch (e) {
    console.log(e);
  }
}

export function* userSaga() {
  yield takeLatest(CHECK, checkSaga);
  yield takeLatest(CHECK_FAILURE, checkFailureSaga);
  yield takeLatest(LOGOUT, logoutSaga);
}

const initialState = {
  user: { email: userEmail },
  checkError: null,
};

export default handleActions(
  {
    [TEMP_SET_USER]: (state, { payload: user }) => ({
      ...state,
      user,
    }),
    [SET_USER_INFO]: ({ payload: user }) => ({
      user,
    }),
    [CHECK_SUCCESS]: (state, { payload: user }) => ({
      ...state,
      user: user.user,
      checkError: null,
    }),
    [CHECK_FAILURE]: (state, { payload: error }) => ({
      ...state,
      user: null,
      checkError: error,
    }),
    [LOGOUT]: (state) => ({
      ...state,
      user: null,
    }),
  },
  initialState
);
