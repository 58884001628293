import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { clickTrigger } from "../../modules/trigger";
import { GA4EventTracker } from "../../hook/GA4EventTracker";

const HeaderBlock = styled.header`
  width: 100%;
  max-width: 500px;
  height: ${({ type }) => (type === "HOME" ? "48px" : "40px")};
  padding: 0 16px 0
    ${(props) => (props.type === "HOME" || props.type === "OTHERS" ? 16 : 12)}px;
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.type === "CLOSE" ? `flex-end` : `space-between`};
  background-color: ${(props) =>
    props.opacity && (props.color || "var(--color-white)")};
`;

const HeaderText = styled.h1`
  margin: 0;
  font-family: ${(props) =>
    props.poola ? `'Pretended', sans-serif` : `inherit`};
  font-weight: bold;
  font-size: ${(props) =>
    props.type === "HOME" || props.type === "OTHERS" ? 18 : 16}px;
  line-height: ${(props) =>
    props.type === "HOME" || props.type === "OTHERS" ? 18 : 16}px;
  color: var(--color-poola-blue);
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) =>
    (props.type === "BACK" ||
      props.type === "CLOSE" ||
      props.type === "BACK-HOME" ||
      props.type === "BACK-CLOSE") &&
    css`
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    `}
`;

const HeaderIconWrapper = styled.div`
  width: ${({ close }) => (close ? "64px" : "112px")};
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
`;

const CartText = styled.div`
  width: 24px;
  height: 24px;
  padding: 10px 0 3px 0;
  text-align: center;
  color: var(--color-white);
  font-size: 11px;
  line-height: 11px;
`;

const LoginText = styled.span`
  width: 63px;
  height: 28px;
  padding: 4px;
  display: inline-block;
  text-align: center;
  color: var(--color-poola-blue);
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  border: 1.4px solid var(--color-poola-blue);
  border-radius: 20px;
`;

const Logo = styled.img`
  margin-top: 8px;
  cursor: pointer;
`;

export const Header = ({
  type,
  title,
  func,
  history,
  pageType,
  heart,
  onClickHeart,
  color,
  opacity = true,
  location,
}) => {
  const goHome = window.history.state && window.history.state.back === "home";
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보

  const handleButtonClick = () => {
    onClickHeart && onClickHeart();
  };

  const handleBackClick = (event) => {
    event.preventDefault();

    if (sessionStorage.getItem("checkout") === "failed") {
      history.replace("/");
    }

    if (pageType === "CategoryDetail")
      return history.replace(history.location.state);
    else if (pageType === "ProductDetail")
      sessionStorage.removeItem("productInfo");
    else if (pageType === "BrandDetail") sessionStorage.removeItem("brandInfo");

    if (location === 1) {
      history.replace("/");
    } else {
      history.goBack();
    }

    if (func === "GA4_EVENT") {
      GA4EventTracker("로그인_패스", {
        버튼: "뒤로가기 클릭",
      });
      history.goBack();
    }
    sessionStorage.removeItem("checkout");
  };

  const handleCloseClick = (event) => {
    event.preventDefault();
    window.open("", "_self").close();
  };

  const handleBackClickByOrder = () => {
    history.push("/orderList");
    GA4EventTracker("주문내역_보기");
  };

  return (
    <HeaderBlock type={type} color={color} opacity={opacity === true ? 1 : 0}>
      {(type === "DETAIL" || type === "BACK") && goHome ? (
        <img
          src={require("../../assets/icons/bottom-navigator/home-clicked.svg")}
          alt="홈으로"
          onClick={() => {
            history.push("/");
            clickTrigger("홈_이동");
          }}
          style={{ cursor: "pointer" }}
        />
      ) : (
        (type === "DETAIL" ||
          type === "BACK" ||
          type === "DETAIL-NEW" ||
          type === "BACK-HOME-REVIEW") && (
          <>
            {opacity ? (
              <img
                src={require("../../assets/icons/arrow/left-blue.svg")}
                alt="뒤로가기"
                onClick={(e) => {
                  func === "CLOSE"
                    ? handleCloseClick(e)
                    : func === "MY"
                    ? history.push("/my")
                    : func === "ORDER-DETAIL"
                    ? handleBackClickByOrder()
                    : handleBackClick(e);
                  clickTrigger("뒤로가기");
                }}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <img
                src={require("../../assets/icons/arrow/left-white.svg")}
                alt="뒤로가기"
                onClick={(e) => {
                  func === "CLOSE" ? handleCloseClick(e) : handleBackClick(e);
                  clickTrigger("뒤로가기");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
          </>
        )
      )}
      {type === "BACK-HOME-REVIEW" && (
        <>
          {opacity ? (
            <img
              src={require("../../assets/icons/bottom-navigator/home-clicked.svg")}
              alt="홈으로"
              onClick={() => {
                history.push("/");
                clickTrigger("홈_이동");
              }}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <img
              src={require("../../assets/icons/bottom-navigator/home-white.svg")}
              alt="홈으로"
              onClick={() => {
                history.push("/");
                clickTrigger("홈_이동");
              }}
              style={{ cursor: "pointer" }}
            />
          )}
        </>
      )}
      {type === "BACK-HOME" && (
        <img
          src={require("../../assets/icons/bottom-navigator/home-clicked.svg")}
          alt="홈으로"
          onClick={() => {
            history.push("/");
            clickTrigger("홈_이동");
          }}
          style={{ cursor: "pointer" }}
        />
      )}
      {type === "HOME" || type === "OTHERS" ? (
        <>
          {type === "HOME" ? (
            <Logo
              src={require("../../assets/images/new_logo.svg")}
              alt="logo"
            />
          ) : (
            <HeaderText poola type={type}>
              {title}
            </HeaderText>
          )}
        </>
      ) : (
        title && <HeaderText type={type}>{title}</HeaderText>
      )}
      {type === "DETAIL-NEW" &&
        (heart ? (
          <HeaderIconWrapper close>
            <img
              src={require("../../assets/icons/bottom-navigator/home-clicked.svg")}
              alt="홈으로"
              onClick={() => {
                history.push("/");
                clickTrigger("홈_이동");
              }}
              style={{ cursor: "pointer" }}
            />
            <div
              style={{
                width: "60%",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => handleButtonClick()}
            >
              <img
                src={require("../../assets/icons/header-heart-filled.svg")}
                alt="찜하기"
                style={{ paddingLeft: "16px" }}
              />
            </div>
          </HeaderIconWrapper>
        ) : (
          <HeaderIconWrapper close>
            <img
              src={require("../../assets/icons/bottom-navigator/home-clicked.svg")}
              alt="홈으로"
              onClick={() => {
                history.push("/");
                clickTrigger("홈_이동");
              }}
              style={{ cursor: "pointer" }}
            />
            <div
              style={{
                width: "60%",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => handleButtonClick()}
            >
              <img
                src={require("../../assets/icons/header-heart-line.svg")}
                alt="찜하기"
                style={{ paddingLeft: "16px" }}
              />
            </div>
          </HeaderIconWrapper>
        ))}
      {type === "DETAIL" &&
        (heart ? (
          <img
            src={require("../../assets/icons/header-heart-filled.svg")}
            alt="찜하기"
            onClick={() => handleButtonClick()}
          />
        ) : (
          <img
            src={require("../../assets/icons/header-heart-line.svg")}
            alt="찜하기"
            onClick={() => handleButtonClick()}
          />
        ))}
      {type === "CLOSE" && (
        <img
          src={require("../../assets/icons/header-close.svg")}
          alt="닫기"
          onClick={(e) => {
            handleBackClick(e);
            clickTrigger("닫기");
          }}
        />
      )}
      {(type === "HOME" || type === "OTHERS") && (
        <>
          {!user.email ? (
              <HeaderIconWrapper>
              <>
                <Link
                    to={"/search"}
                    onClick={() => {
                      clickTrigger("검색_이동");
                      GA4EventTracker("검색_시작");
                    }}
                >
                  <img
                      src={require("../../assets/icons/header-search.svg")}
                      alt="검색"
                  />
                </Link>

            <Link
              to={"/login"}
              style={{ position: "relative" }}
              onClick={() => {
                clickTrigger("로그인_이동");
                GA4EventTracker("로그인_시작", {
                  위치: "상단바",
                });
              }}
            >
              <LoginText>로그인</LoginText>
            </Link>
              </>
              </HeaderIconWrapper>
          ) : (
              <HeaderIconWrapper close>
            <>
              <Link
                  to={"/search"}
                  onClick={() => {
                    clickTrigger("검색_이동");
                    GA4EventTracker("검색_시작");
                  }}
              >
                <img
                    src={require("../../assets/icons/header-search.svg")}
                    alt="검색"
                />
              </Link>
              <Link
                to={"/my/wish"}
                onClick={() => {
                  clickTrigger("찜_이동");
                  GA4EventTracker("찜한제품_보기");
                }}
              >
                <img
                  src={require("../../assets/icons/header-heart-line.svg")}
                  alt="찜 목록"
                />
              </Link>
              {/*<Link to={"/cart"} onClick={() => clickTrigger("장바구니_이동")}>*/}
              {/*  <CartText*/}
              {/*    style={{ backgroundImage: 'url("icons/header-cart.svg")' }}*/}
              {/*  >*/}
              {/*    {user?.cart}*/}
              {/*  </CartText>*/}
              {/*</Link>*/}
            </>
              </HeaderIconWrapper>

          )}
          </>
      )}
      {type === "BACK-CLOSE" && (
        <>
          <img
            src={require("../../assets/icons/arrow/left-blue.svg")}
            alt="뒤로가기"
            onClick={() => {
              handleBackClickByOrder();
              clickTrigger("주문내역_이동");
            }}
            style={{ cursor: "pointer" }}
          />

          <img
            src={require("../../assets/icons/header-close.svg")}
            alt="닫기"
            onClick={(e) => {
              history.push("/");
              clickTrigger("홈_이동");
            }}
          />
        </>
      )}
    </HeaderBlock>
  );
};

Header.defaultProps = {
  type: "OTHERS",
  title: "",
};
