const Axios = require("axios");
const { API_ENDPOINT } = require("../Config");

export function getNoProducts(email, noList, brand) {
	return new Promise((resolve, reject) => {
		Axios.post(API_ENDPOINT + `/api/products/no/user/${email}?brand=${brand}`, {
			NoList: noList,
		}).then((response) => {
			if (response.data.success) {
				resolve(response.data);
			} else {
				reject();
			}
		});
	});
}

export function getCollectionProducts(email, collection, brand) {
	return new Promise((resolve, reject) => {
		Axios.get(
			API_ENDPOINT +
				`/api/products/collection/${collection}/user/${email}?brand=${brand}`
		).then((response) => {
			if (response.data.success) {
				resolve(response.data);
			} else {
				reject();
			}
		});
	});
}

export function getFilteredProducts(sizeFilter, allProducts) {
	if (Array.isArray(sizeFilter) && sizeFilter.length === 0) {
		return allProducts;
	} else {
		return allProducts.filter(
			(product) =>
				product.standardSize?.filter((size) => {
					let cup = size?.replace(/[0-9]/g, "");
					let band = size?.replace(/[^0-9]/g, "");
					if (cup > "D") {
						cup = "E+";
					}
					if (band > 90) {
						band = "95+";
					}
					return sizeFilter.indexOf(band + cup) !== -1;
				}).length > 0
		);
	}
}
