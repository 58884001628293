import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Header } from "../../components/Common/Header";
import { Helmet } from "react-helmet";
import { AnswerList } from "../../components/List/AnswerList";
import { IntroQuestion } from "../../assets/data/jsons/CurationData";
import { GA4EventTracker } from "../../hook/GA4EventTracker";

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  padding: 40px 16px 84px;
`;

const HeadingText = styled.h2`
  margin: 55px auto 28px;
  padding: 16px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-poola-blue);
  background: var(--color-poola-orange-light);
  border-radius: 8px;
  transition: all 0.3s ease 0s;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const BottomNavigator = styled.nav`
  width: 100%;
  max-width: 500px;
  padding: 16px 16px 24px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: right;
  background-color: var(--color-white);
`;

const Button = styled.button`
  padding: 0;
  border: none;
  border-radius: 12px;
`;

const TestIntroPage = ({ history }) => {
  const question = IntroQuestion[0];
  const [selected, setSelected] = useState(""); // 테스트 유형

  const handleButtonClick = (e) => {
    e.preventDefault();
    setSelected(e.currentTarget.value);
  };

  return (
    <>
      <Helmet>
        <title>체형 분석 | 풀라</title>
        <meta
          property="og:title"
          content="3분 체형 분석 | 체형별 속옷 플랫폼 풀라"
        />
        <meta
          property="og:description"
          content="1,000개의 속옷 중, 나와 잘맞는 것은? 진짜 사이즈와 찰떡브라를 찾아드려요!"
        />
      </Helmet>
      <Header type="BACK" title="가슴분석" history={history} />
      <PageContainer>
        <HeadingText>{question.question}</HeadingText>
        <AnswerList
          data={question.answers}
          size={question.size}
          selected={selected}
          _onClick={handleButtonClick}
        />
        <BottomNavigator>
          {selected !== "" ? (
            selected === "1" ? ( // 실측 선택
              <Link
                to="/test/size"
                onClick={() => {
                  GA4EventTracker("테스트_진행", {
                    방식: "실측",
                  });
                }}
              >
                <img
                  src={require("../../assets/icons/curation/next-active.svg")}
                  alt="다음"
                  width="60px"
                  height="44px"
                />
              </Link>
            ) : (
              // 착용감 선택
              <Link
                to="/test/fit"
                onClick={() => {
                  GA4EventTracker("테스트_진행", {
                    방식: "간편",
                  });
                }}
              >
                <img
                  src={require("../../assets/icons/curation/next-active.svg")}
                  alt="다음"
                  width="60px"
                  height="44px"
                />
              </Link>
            )
          ) : (
            // 테스트 유형이 선택되지 않음, 비활성된 다음 버튼
            <Button disabled>
              <img
                src={require("../../assets/icons/curation/next-disabled.svg")}
                alt=""
              />
            </Button>
          )}
        </BottomNavigator>
      </PageContainer>
    </>
  );
};

export default TestIntroPage;
