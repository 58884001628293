import React from "react";
import styled from "styled-components";
import user from "../../modules/user";

const MatchLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ productMatch, keyword }) =>
    productMatch ? "2px 6px" : keyword ? "6px 6px" : "2px 4px"};
  height: ${({ keyword, collection }) => !keyword && !collection && "28px"};
  margin-right: ${({ keyword }) => keyword && "6px"};
  border: ${({ userType, guide, keyword, noBorder }) =>
    userType || guide || keyword || noBorder ? "none" : "1px solid #87a3d5"};
  box-shadow: ${({ userType, guide, keyword, noBorder }) =>
    userType || guide || keyword || noBorder
      ? "none"
      : "0px 4px 8px rgba(0, 0, 0, 0.11)"};
  border-radius: 3px;
  background-color: ${({ userType, guide, match, keyword, notRecommend }) =>
    keyword
      ? "#F4F4F4"
      : !guide && !notRecommend
      ? "#dee8f9"
      : !userType && guide
      ? "#F4F4F4"
      : userType && guide && match
      ? "#dee8f9"
      : !match || notRecommend
      ? "#FDE9E9"
      : null};
  color: ${({ guide, match, userType, keyword, notRecommend }) =>
    (!userType && guide) || keyword
      ? "#444444"
      : !match || notRecommend
      ? "#EB5757"
      : "var(--color-poola-blue)"};
  font-size: ${({ productMatch }) => (productMatch ? "13px" : "12px")};
  font-weight: ${({ keyword }) => (keyword ? "400" : "700")};
  cursor: pointer;
`;

export function Label({
  _onClick,
  productMatch,
  text,
  userType,
  guide,
  keyword,
  match = true,
  flag = false,
  notRecommend,
  collection,
  noBorder,
}) {
  return (
    <MatchLabel
      userType={userType}
      guide={guide}
      match={match}
      keyword={keyword}
      flag={flag}
      notRecommend={notRecommend}
      productMatch={productMatch}
      onClick={_onClick}
      noBorder={noBorder}
      collection={collection}
    >
      {text}
      &nbsp;
      {userType && !flag ? (
        <img
          src={require(`../../assets/icons/${match ? `up` : `down`}.png`)}
          alt="match"
          width="12px"
          height="12px"
        />
      ) : null}
    </MatchLabel>
  );
}
