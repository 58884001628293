import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const SnackBarBlock = styled.div`
  position: fixed;
  padding: 16px 24px;
  bottom: ${(props) => props.bottom}px;
  width: 100%;
  display: flex;
  max-width: 500px;
  z-index: 100;
  visibility: ${(props) => (props.show ? "" : "hidden")};
  ${(props) =>
    props.show &&
    css`
      animation: fadeout 2s;
      -moz-animation: fadeout 2s; /* Firefox */
      -webkit-animation: fadeout 2s; /* Safari and Chrome */
      -o-animation: fadeout 2s; /* Opera */
      animation-delay: 2s;
      -webkit-animation-delay: 2s;
      animation-fill-mode: forwards;
    `}
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-moz-keyframes fadeout {
    /* Firefox */
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-webkit-keyframes fadeout {
    /* Safari and Chrome */
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-o-keyframes fadeout {
    /* Opera */
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  } ;
`;

const SnackBarContainer = styled.div`
  background: #000000;
  opacity: 0.8;
  box-shadow: 0 16px 48px rgba(0, 0, 0, 0.22);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
`;

const IconImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

const SnackBarLink = styled(Link)`
  margin-left: auto;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-white);
  text-decoration: underline !important;

  &:hover {
    color: var(--color-white);
    text-decoration: underline !important;
  }
`;

export const SnackBar = ({ type, text, bottom, show, linkTo, linkToText }) => {
  return (
    <SnackBarBlock bottom={bottom} show={show}>
      <SnackBarContainer type={type}>
        <IconImg src={require(`../../assets/icons/snackbar_${type}.png`)} />
        <span style={{ fontSize: "14px", lineHeight: "22px", color: "white" }}>
          {text}
        </span>
        <br />
        {linkTo && (
          <SnackBarLink to={linkTo}>
            {linkToText ? linkToText : "보러가기"}
          </SnackBarLink>
        )}
      </SnackBarContainer>
    </SnackBarBlock>
  );
};

SnackBar.defaultProps = {
  text: "스낵바",
  type: "CHECK",
};
