import { createAction, handleActions } from 'redux-actions';
import {call, put, takeLatest} from 'redux-saga/effects';
import {createRequestActionTypes} from '../lib/createRequestSaga';
import * as authAPI from '../lib/api/auth';

const [REGISTER, REGISTER_SUCCESS, REGISTER_FAILURE] = createRequestActionTypes(
  'auth/REGISTER'
);

export const register = createAction(REGISTER, body => body);

// const registerSaga = createRequestSaga(REGISTER, authAPI.register);

function* registerSaga(action) {
  try {
    const response = yield call(authAPI.register, action.payload);
    // console.log(response.data);
    if (response.data.success) yield put({ type: REGISTER_SUCCESS, payload: response.data });
    else return alert('로그인에 실패했습니다. 관리자에게 문의하세요.');
  } catch (e) {
    yield put({ type: REGISTER_FAILURE, payload: e });
  }
}

export function* authSaga() {
  yield takeLatest(REGISTER, registerSaga);
}

const initialState = {
  auth: null,
  authError: null
};

const auth = handleActions(
  {
    // 회원가입 or 로그인 성공
    [REGISTER_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      auth: auth,
      authError: null
    }),
    // 회원가입 or 로그인 실패
    [REGISTER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      authError: error
    })
  },
  initialState
);

export default auth;
