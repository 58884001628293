import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Axios from "axios";
import { API_ENDPOINT } from "../../Config";
import { Header } from "../../components/Common/Header";
import { OrderCard } from "../../components/Card/OrderCard";
import { ReviewDialog } from "../../components/Modal/ReviewDialog";
import { clickTrigger } from "../../modules/trigger";
import { CupBand } from "../../assets/data/jsons/CupBandOptions";
import Clayful from "clayful/client-js";
import { LoginAlert } from "../../components/Common/LoginAlert";
import Spinner from "react-bootstrap/Spinner";
import { GA4EventTracker } from "../../hook/GA4EventTracker";

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  padding-top: 40px;
  padding-bottom: 56px;
  background-color: var(--color-white);
`;

const Section = styled.section`
  padding: ${(props) => props.top || 0}px 16px 0;
`;

const HeadingText = styled.h2`
  margin-bottom: ${(props) => props.bottom || 0}px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-black);
`;

const SubText = styled.div`
  font-size: 10px;
  color: var(--color-tertiary-black);
  margin-bottom: 8px;
`;

const Text = styled.p`
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 20px;
  color: var(--color-tertiary-black);
`;

const Caption = styled.span`
  padding-top: 7px;
  display: block;
  font-size: 11px;
  line-height: 11px;
  color: var(--color-tertiary-black);
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 109px;
  padding: 16px;
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 19px;
  color: var(--color-black);
  border: 1px solid var(--color-disabled-gray);
  border-radius: 4px;
  resize: none;
  &::placeholder {
    color: var(--color-tertiary-black);
  }
  &:focus {
    outline: none !important;
  }
`;

const TextCounter = styled.div`
  padding-bottom: 24px;
  font-size: 12px;
  display: flex;
  flex-direction: row-reverse;
  color: var(--color-secondary-black);
`;

const ImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-disabled-gray);
  border-radius: 4px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: 56px;
  position: fixed;
  bottom: 0;
`;

const RadioButtonWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
`;

const RadioButton = styled.input.attrs({ type: "radio" })`
  -webkit-appearance: none;
  background: #f4f4f4;
  border: 1px solid #dddddd;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  &:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    margin: 30% auto;
    border-radius: 100%;
    box-sizing: border-box;
  }
  &:checked {
    background: #00398f;
  }
  &:checked:before {
    background: #ffffff;
  }
`;

const RadioButtonBox = styled.div`
  width: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  width: 100%;
  max-width: 500px;
  height: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(
    --color-${(props) => (props.type === "submit" && `white`) || `poola-blue`}
  );
  background: var(
    --color-${(props) => (props.type === "submit" && "poola-blue") || "white"}
  );
  box-shadow: ${(props) =>
    (props.type === "submit" && null) ||
    `inset 0 0.5px 0 rgba(0, 57, 143, 0.5)`};
  border: none;

  &:disabled {
    background-color: var(--color-disabled-gray);
  }
`;

const ReviewFormPage = ({ history }) => {
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  let { reviewId } = useParams(); // 작성된 후기(수정)
  const product = history.location.state && history.location.state.review; // 제품, 후기 정보
  const [size, setSize] = useState({ cup: "", band: "" }); // 테스트 안한 유저 사이즈
  const [body, setBody] = useState(""); // 후기 내용
  const [rate, setRate] = useState(0); // 별점
  const [images, setImages] = useState([]); // 업로드한 사진 url, id
  const [delImages, setDelImages] = useState([]); // 클레이풀에서 삭제할 이미지 id
  const [modalVisible, setModalVisible] = useState(false); // 수정 모달 클릭 여부
  let submitted = false; // 폼 작성 여부

  useEffect(() => {
    // 후기 수정인 경우 데이터 불러오기
    if (reviewId) {
      setRate(product.rate);
      setBody(product.body);
      setImages(product.img);
      setSize({
        cup: product.user_size?.size.replace(/[0-9]/g, ""),
        band: product.user_size?.size.replace(/[^0-9]/g, ""),
      });
    }
  }, [user.email, product, reviewId]);

  const handleRateClick = (index) => {
    // 별점 입력
    if (index + 1 === rate) setRate(0);
    else setRate(index + 1);
  };

  const handleUserSize = (e) => {
    // 테스트 안한 유저 - 사이즈 입력
    setSize({
      ...size,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleInputChange = (e) => {
    // 후기 입력
    setBody(e.target.value);
  };

  const handleAddImage = (e) => {
    // 이미지 추가
    if (images.length >= 5) {
      alert("이미지를 더이상 업로드할 수 없습니다.");
      e.target.value = "";
      return;
    }

    const maxSize = 5 * 1024 * 1024; // 이미지 최대 크기 (5MB)
    const Image = Clayful.Image; // 클레이풀 api
    const formData = new FormData(document.getElementById("image-form"));
    const options = { customer: sessionStorage.getItem("__tct__") };

    for (const keyValue of formData) {
      if (keyValue[0] === "file" && keyValue[1].size > maxSize) {
        alert("5MB 이상의 이미지를 올릴 수 없습니다.");
        e.target.value = "";
        return false;
      } else if (keyValue[0] === "file" && maxSize > keyValue[1].size) {
        Image.createForMe(formData, options, (err, result) => {
          if (err) console.log(err.code);
          const data = result.data;
          setImages([...images, { url: data.url, id: data._id }]);
        });
        e.target.value = "";
      }
    }
  };

  const handleDeleteImage = (e, index) => {
    // 이미지 삭제
    e.preventDefault();
    e.stopPropagation();

    setImages(images.filter((image) => image !== images[index]));
    setDelImages([...delImages, images[index].id]);
  };

  const checkSubmit = () => {
    // 폼 중복 작성 확인
    if (submitted) return submitted;
    else {
      submitted = true;
      return false;
    }
  };

  const handleSubmit = (e) => {
    // 작성 완료
    e.preventDefault();

    if (checkSubmit()) {
      return;
    }
    if (user.email && !reviewId) {
      // 후기 생성
      let reviewBody = {
        clayful_orderId: product.clayful_orderId,
        clayful_productId: product.clayful_productId,
        clayful_optionId: product.clayful_optionId,
        option_name: {
          사이즈: product.option_name.사이즈,
          색상: product.option_name.색상,
        },
        body: body,
        img: images,
        rate: rate,
        user_size: size.band + size.cup,
      };
      Axios.post(API_ENDPOINT + `/api/reviews/${user._id}`, reviewBody).then(
        (response) => {
          if (response.data.success) {
            history.push("/my/reviews?type=complete");
          } else {
            console.log("제품 정보를 가져오는데에 실패했습니다.");
          }
        }
      );
      !reviewId &&
        clickTrigger("후기작성", {
          reviewInfo: {
            productName: product.product_name,
            brand: product.brand_name,
            option:
              product.option_name &&
              product.option_name.사이즈 + " / " + product.option_name.색상,
            text: body,
            rate: rate,
          },
        });
      GA4EventTracker("리뷰_작성", {
        제품명: product.product_name,
      });
      GA4EventTracker("리뷰_작성", {
        브랜드명: product.brand_name,
      });
      GA4EventTracker("리뷰_작성", {
        별점: rate,
      });
      GA4EventTracker("유저ID", {
        별점: user._id,
      });
    } else if (user?.email && reviewId) {
      // 후기 수정 확인 모달
      setModalVisible(true);
    }
    return false;
  };

  const handleCancel = () => {
    // 작성 취소
    history.goBack();
    clickTrigger("취소");
    GA4EventTracker("리뷰_작성_취소");
  };

  const handleOverlayClick = () => {
    setModalVisible(false);
  };

  const handleUpdateButtonClick = () => {
    // 후기 수정
    const Image = Clayful.Image;
    const options = { customer: sessionStorage.getItem("__tct__") };

    for (let i = 0; i < delImages.length; i++) {
      Image.deleteForMe(delImages[i], options, function (err, result) {
        if (err) console.log(err.code);
      });
    }

    let reviewBody = {
      body: body,
      img: images,
      rate: rate,
      user_size: size.band + size.cup,
    };
    Axios.put(API_ENDPOINT + `/api/reviews/${reviewId}`, reviewBody).then(
      (response) => {
        if (response.data.success) {
          history.goBack();
        } else {
          console.log("제품 정보를 가져오는데에 실패했습니다.");
        }
      }
    );

    clickTrigger("후기수정", {
      reviewInfo: {
        productName: product.product_name,
        brand: product.brand_name,
        option:
          product.option_name &&
          product.option_name.사이즈 + " / " + product.option_name.색상,
        text: body,
        rate: rate,
      },
    });
  };

  const CupBandBoxes = (type) =>
    CupBand.filter((f) => f.filter === type)[0].options.map((option, i) => {
      return (
        <RadioButtonBox key={i}>
          <RadioButton
            id={option.value}
            value={option.value}
            name={type}
            type="radio"
            checked={size[type] === option.value}
            onChange={handleUserSize}
          />
          <p
            style={{
              margin: "4px 0 16px 0",
              fontSize: "11px",
              lineHeight: "11px",
              color: "var(--color-tertiary-black)",
            }}
          >
            {option.value}
          </p>
        </RadioButtonBox>
      );
    });

  return (
    <>
      <Header
        type="CLOSE"
        title={reviewId ? "리뷰 수정" : "리뷰 작성"}
        history={history}
      />
      {user.email ? (
        <PageContainer>
          <Section top={4}>
            <OrderCard data={product} type="WRITER" history={history} />
          </Section>
          <Section>
            <form id="review-form" onSubmit={(e) => handleSubmit(e)}>
              <HeadingText bottom={16}>만족도를 알려주세요</HeadingText>
              <div style={{ paddingBottom: "32px" }}>
                {[...Array(5)].map((r, i) => {
                  if (i < rate)
                    return (
                      <img
                        src={require("../../assets/icons/star-filled.svg")}
                        alt=""
                        width="32px"
                        height="32px"
                        style={{ marginRight: "8px" }}
                        onClick={() => handleRateClick(i)}
                        key={i}
                      />
                    );
                  else
                    return (
                      <img
                        src={require("../../assets/icons/star-default.svg")}
                        alt=""
                        width="32px"
                        height="32px"
                        style={{ marginRight: "8px" }}
                        onClick={() => handleRateClick(i)}
                        key={i}
                      />
                    );
                })}
              </div>
              {(!user?.size || !user?.type) && (
                <>
                  <HeadingText bottom={16}>
                    고객님의 사이즈를 선택해 주세요
                  </HeadingText>
                  <div
                    style={{
                      marginBottom: "16px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <RadioButtonWrapper>
                      {CupBandBoxes("band")}
                    </RadioButtonWrapper>
                    <RadioButtonWrapper>
                      {CupBandBoxes("cup")}
                    </RadioButtonWrapper>
                  </div>
                </>
              )}
              <HeadingText bottom={10}>구매 리뷰를 남겨주세요</HeadingText>
              <SubText>
                구매와 관련없는 리뷰 작성 시 2회 경고 후 구매가 제한될 수
                있습니다.
              </SubText>
              <TextArea
                value={body || ""}
                placeholder="내용을 입력해주세요"
                onChange={(e) => handleInputChange(e)}
                maxLength={500}
              >
                {reviewId && product && product.body}
              </TextArea>
              <TextCounter>{body.length} / 최소 100자</TextCounter>
            </form>

            <form id="image-form" name="image">
              <HeadingText bottom={8}>사진을 업로드해주세요</HeadingText>
              <Text>
                관련 없는 사진일 경우, 사전 경고 없이 사진이 삭제될 수 있습니다
              </Text>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {images &&
                  images.length > 0 &&
                  images.map((image, i) => {
                    return (
                      <div
                        style={{
                          height: "60px",
                          margin: "0 8px 8px 0",
                          position: "relative",
                          textAlign: "center",
                        }}
                        onClick={() => window.open(image.url)}
                        key={i}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "60px",
                            position: "absolute",
                            background:
                              "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)",
                            borderRadius: "4px",
                          }}
                        />
                        <img
                          src={image.url}
                          alt=""
                          height="60px"
                          style={{ borderRadius: "4px", cursor: "pointer" }}
                        />
                        <button
                          style={{
                            padding: "4px 4px 0 0",
                            display: "inline-flex",
                            position: "absolute",
                            top: "0",
                            right: "0",
                            background: "none",
                            border: "none",
                          }}
                          onClick={(e) => handleDeleteImage(e, i)}
                        >
                          <img
                            src={require("../../assets/icons/close-white.svg")}
                            alt="삭제"
                            width="12px"
                            height="12px"
                          />
                        </button>
                      </div>
                    );
                  })}
                <ImageWrapper>
                  <label
                    htmlFor="input-file"
                    className="input-files"
                    style={{
                      margin: "0",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={require("../../assets/icons/image-upload.svg")}
                      alt="업로드"
                      width="24px"
                      height="24px"
                    />
                    <Caption>사진({images.length}/5)</Caption>
                  </label>
                  <input type="hidden" name="model" value="Review" />
                  <input type="hidden" name="application" value="images" />
                  <input
                    type="file"
                    id="input-file"
                    name="file"
                    style={{ display: "none" }}
                    onChange={handleAddImage}
                  />
                </ImageWrapper>
              </div>
            </form>
          </Section>
          <ButtonWrapper>
            <Button
              type="submit"
              form="review-form"
              disabled={rate === 0 || body.length < 100 ? true : false}
            >
              {reviewId ? "수정 완료" : "작성 완료"}
            </Button>
          </ButtonWrapper>
        </PageContainer>
      ) : (
        <>
          <div
            style={{
              height: "calc(100vh - 40px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              animation="border"
              style={{
                color: "var(--color-poola-blue)",
                borderRightColor: "transparent",
              }}
            />
          </div>
          <LoginAlert isLogin={user.email} history={history} />
        </>
      )}
      {modalVisible && (
        <ReviewDialog
          text="수정"
          visible={modalVisible}
          _onClose={handleOverlayClick}
          _onClick={handleUpdateButtonClick}
        />
      )}
    </>
  );
};

export default ReviewFormPage;
