import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Clayful from "clayful/client-js";
import { SnackBar } from "../Common/SnackBar";
import BackIcon from "../../assets/icons/arrow/left-tertiary-black.svg";

const BUTTON = {
  // width, height, margin-right, font-size, font-weight, color, bgColor
  SMALL: ["37px", 24, 6, 11, 400, "secondary-black", "bg-gray"],
  MEDIUM: ["102px", 27, 0, 11, 400, "white", "poola-blue"],
  "MEDIUM-R": ["102px", 27, 0, 11, 400, "poola-blue", "white"],
  LARGE: ["49%", 40, 0, 14, 500, "white", "poola-blue"],
  "LARGE-R": ["49%", 40, 0, 14, 500, "poola-blue", "white"],
};

const ModalContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: ${(props) => (props.isOpen ? "" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
`;

const BottomSheet = styled.div`
  width: 100%;
  max-width: 500px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background-color: var(--color-white);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
`;

const Dialog = styled.dialog`
  width: 100%;
  max-width: ${(props) => props.width - 48}px;
  margin: 0;
  padding: 24px 17px;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: var(--color-white);
  border: none;
  border-radius: 16px;

  div {
    display: flex;
    justify-content: space-between;
  }
`;

const ModalHeader = styled.header`
  padding: 0 12px;
`;

const HeadingText = styled.h2`
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;

  ${(props) =>
    props.isDialog
      ? css`
          margin-bottom: 32px;
          text-align: center;
          color: var(--color-black);
        `
      : css`
          margin: 0;
          padding: 16px 0;
          display: inline-block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          color: var(--color-poola-blue);
        `}
`;

const BackButton = styled.button`
  width: 24px;
  height: 24px;
  margin: 12px 0;
  border: none;
  background: url(${BackIcon}) no-repeat center center;
`;

const Section = styled.section`
  position: absolute;
  top: 48px;
  right: 0;
  bottom: 56px;
  left: 0;
  overflow-y: scroll;

  ${(props) =>
    props.type !== "선택" &&
    css`
      padding: 20px 16px;
    `}
`;

const AddressList = styled.ul`
  margin: 0;
  padding: 0 16px;
  list-style: none;
  border-top: 8px solid var(--color-bg-gray);
`;

const AddressItem = styled.li`
  padding: 20px 0;
  border-bottom: 1px solid var(--color-disabled-gray);

  .info_container {
    margin-bottom: 20px;

    div {
      margin-bottom: 8px;
      display: flex;
    }
  }

  .button_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const Button = styled.button`
  width: ${(props) => BUTTON[props.size][0]};
  height: ${(props) => BUTTON[props.size][1]}px;
  margin-right: ${(props) => BUTTON[props.size][2]}px;
  padding: 0;
  font-size: ${(props) => BUTTON[props.size][3]}px;
  color: var(--color-${(props) => BUTTON[props.size][5]});
  background-color: var(--color-${(props) => BUTTON[props.size][6]});
  border: ${(props) =>
    props.size.slice(-2) === "-R"
      ? "1px solid var(--color-poola-blue)"
      : "none"};
  border-radius: 4px;
`;

const BottomButton = styled.button`
  width: 100%;
  max-width: 500px;
  height: 56px;
  position: fixed;
  bottom: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-white);
  background-color: var(--color-poola-blue);
  border: none;

  &:disabled {
    color: var(--color-tertiary-black);
    background-color: var(--color-disabled-gray);
  }
`;

const AddButton = styled.button`
  width: 100%;
  height: 40px;
  margin: 10px 0;
  background-color: var(--color-white);
  border: none;

  div {
    display: flex;
    justify-content: center;
    align-items: end;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: var(--color-poola-blue);
  }
`;

const DefaultIcon = styled.em`
  width: 66px;
  height: 23px;
  margin-top: ${(props) => props.mt}px;
  padding: 5px;
  display: inline-block;
  flex-basis: 66px;
  flex-shrink: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 11px;
  color: var(--color-poola-blue);
  border: 1px solid rgba(0, 57, 143, 0.2);
  border-radius: 20px;
`;

const ShippingName = styled.span`
  margin-right: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-black);
`;

const Text = styled.p`
  margin-bottom: 0;
  flex-grow: 0;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-black);
`;

const InputContainer = styled.div`
  margin-top: 12px;
  display: flex;

  &:first-child {
    margin-top: 0;
  }
`;

const Label = styled.label`
  margin: 0 10px 0 0;
  flex-basis: 64px;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 38px;
  color: var(--color-tertiary-black);

  span {
    color: var(--color-poola-blue);
  }
`;

const Input = styled.input`
  width: 100%;
  height: 38px;
  padding: 0 12px;
  flex-grow: 1;
  border-radius: 4px;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-black);
  border: 1px solid var(--color-border-gray);
  caret-color: var(--color-poola-blue);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::placeholder {
    color: var(--color-tertiary-black);
  }
  &:focus {
    outline: none;
    border: 1px solid var(--color-poola-blue);
  }
  &:read-only {
    outline: none;
    border: 1px solid var(--color-border-gray);
  }
`;

const TextButton = styled.button`
  width: 100%;
  max-width: 114px;
  min-width: 66px;
  height: ${(props) => props.height || 40}px;
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  word-break: keep-all;
  color: var(--color-poola-blue);
  background-color: var(--color-${(props) => props.bg || "white"});
  border: 1px solid var(--color-poola-blue);
  border-radius: 4px;
`;

const CheckButton = styled.button`
  height: 19px;
  margin-top: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 19px;
  color: var(--color-secondary-black);
  background-color: var(--color-white);
  border: none;
`;

export const AddressBottomSheet = ({
  open,
  setOpen,
  allAddress,
  setAllAddress,
  selectedAddress,
  setSelectedAddress,
}) => {
  const [type, setType] = useState("선택"); // 선택, 추가, 수정, 삭제
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [newAddress, setNewAddress] = useState({}); // 추가 or 수정할 배송지(주소 + 기본 배송지 여부)
  const [isDefaultAddress, setIsDefaultAddress] = useState(false); // 추가, 수정 - 기본 배송지 체크 여부
  const [addressIdx, setAddressIdx] = useState(); // 수정 or 삭제할 배송지 인덱스
  const [isActive, setIsActive] = useState(false); // 하단 버튼 활성화 여부
  const [showSnackBar, setShowSnackBar] = useState(false); // 추가 주소 초과 스낵바
  const [width, setWidth] = useState(
    window.innerWidth > 500 ? 500 : window.innerWidth
  ); // dialog width

  useEffect(() => {
    // 모달 배경 스크롤 방지
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`;

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = 'position: ""; top: "";';
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    };
  }, []);

  useEffect(() => {
    if (type === "삭제") {
      window.addEventListener("resize", function () {
        setWidth(window.innerWidth > 500 ? 500 : window.innerWidth);
      });
    }
    setIsActive(false);
  }, [type]);

  useEffect(() => {
    if (type === "추가" || type === "수정") {
      if (
        newAddress.postcode !== "" &&
        newAddress.country === "KR" &&
        newAddress.city !== "" &&
        newAddress.address1 !== "" &&
        newAddress.address2 !== ""
      ) {
        setIsActive(true);
      } else setIsActive(false);
    }
  }, [newAddress]);

  function loadPostcode() {
    // 도로명 주소 불러오기
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function (data) {
          const address = {
            postcode: data.zonecode || data.postcode,
            country: "KR",
            state: data.sido,
            city: data.sigungu.split(" ")[0] || data.sido,
            address1: data.address,
          };
          setNewAddress({ ...newAddress, ...address });
        },
      });
      postcode.open();
    });
  }

  function deleteAddress(index) {
    // 주소 삭제
    const newSecondaries = allAddress
      .filter((address, i) => index !== i)
      .slice(1)
      .map((secondary) => ({ ...secondary, country: "KR" }));
    const Customer = Clayful.Customer;
    const payload = { address: { secondaries: newSecondaries } };
    const options = { customer: sessionStorage.getItem("__tct__") };

    Customer.updateMe(payload, options, function (err, result) {
      if (err) console.log(err.code);

      const addressList = result.data.address.secondaries;
      addressList.unshift(result.data.address.primary);
      setAllAddress(addressList);

      if (selectedAddress === addressIdx) setSelectedAddress(0);
      else if (selectedAddress > addressIdx)
        setSelectedAddress(selectedAddress - 1);
    });
  }

  function handleOverlayClick(e) {
    // 딤 영역 클릭
    if (e.target === e.currentTarget) {
      if (type === "선택") setOpen(false);
      else setType("선택");
    }
  }

  function handleBackButtonClick(action) {
    // 뒤로가기 버튼 클릭
    if (action === "선택") setOpen(false);
    else {
      setNewAddress({});
      setType("선택");
    }
  }

  function handleAddButtonClick() {
    // 새 주소 추가 버튼 클릭
    if (allAddress.length > 10) {
      setShowSnackBar(true);
      setTimeout(() => {
        setShowSnackBar(false);
      }, 6000);
    } else {
      setNewAddress({
        name: { full: allAddress[0].name.full },
        company: "",
        postcode: "",
        country: "KR",
        state: "",
        city: "",
        address1: "",
        address2: "",
        mobile: allAddress[0].mobile,
      });
      setIsDefaultAddress(false);
      setType("추가");
    }
  }

  function handleButtonClick(e, address, index) {
    // 선택, 수정, 삭제 버튼 클릭
    if (e.target.value === "선택") {
      setSelectedIndex(index);
      setIsActive(true);
    } else if (e.target.value === "수정") {
      setNewAddress({
        name: { full: address.name.full },
        company: address.company,
        postcode: address.postcode,
        country: "KR",
        state: address.state,
        city: address.city,
        address1: address.address1,
        address2: address.address2,
        mobile: address.mobile,
      });
      setIsDefaultAddress(false);
      setAddressIdx(index);
      setType(e.target.value);
    } else if (e.target.value === "삭제") {
      setAddressIdx(index);
      setType(e.target.value);
    }
  }

  function handleBottomButtonClick(e) {
    // 하단 버튼 클릭
    // 배송지 선택
    if (e.target.value === "선택") {
      setSelectedAddress(selectedIndex);
      setOpen(false);
    }
    // 배송지 추가
    else if (e.target.value === "추가") {
      const Customer = Clayful.Customer;
      const options = { customer: sessionStorage.getItem("__tct__") };

      if (isDefaultAddress) {
        // 기본 배송지로 선택 시
        const payload = { address: { primary: newAddress } };

        Customer.updateMe(payload, options, function (err, result) {
          if (err) console.log(err.code);

          const addressList = result.data.address.secondaries;
          addressList.unshift(result.data.address.primary);
          setAllAddress(addressList);
        });
      } else {
        const secondaries =
          allAddress &&
          allAddress
            .slice(1)
            .map((secondary) => ({ ...secondary, country: "KR" }));
        const payload = {
          address: {
            primary: { ...allAddress[0], country: "KR" },
            secondaries: [...secondaries, newAddress],
          },
        };

        Customer.updateMe(payload, options, function (err, result) {
          if (err) console.log(err.code);

          const addressList = result.data.address.secondaries;
          addressList.unshift(result.data.address.primary);
          setAllAddress(addressList);
        });
      }
      setNewAddress({});
      setType("선택");
    }
    // 배송지 수정
    else if (e.target.value === "수정") {
      const Customer = Clayful.Customer;
      const options = { customer: sessionStorage.getItem("__tct__") };

      if (addressIdx === 0) {
        // 기본 주소 수정인 경우
        const payload = { address: { primary: newAddress } };

        Customer.updateMe(payload, options, function (err, result) {
          if (err) console.log(err.code);

          const addressList = result.data.address.secondaries;
          addressList.unshift(result.data.address.primary);
          setAllAddress(addressList);
        });
      } else {
        // 추가 주소 수정인 경우
        if (isDefaultAddress) {
          // 기본 배송지로 선택 시
          const secondaries =
            allAddress &&
            allAddress
              .filter((address, i) => addressIdx !== i)
              .slice(1)
              .map((secondary) => ({ ...secondary, country: "KR" }));
          const payload = {
            address: { primary: newAddress, secondaries: secondaries },
          };

          Customer.updateMe(payload, options, function (err, result) {
            if (err) console.log(err.code);

            const addressList = result.data.address.secondaries;
            addressList.unshift(result.data.address.primary);
            setAllAddress(addressList);
            setSelectedAddress(0);
          });
        } else {
          const secondaries =
            allAddress &&
            allAddress.slice(1).map((secondary, i) => {
              if (addressIdx === i + 1) return newAddress;
              else return { ...secondary, country: "KR" };
            });
          const payload = {
            address: {
              primary: { ...allAddress[0], country: "KR" },
              secondaries: secondaries,
            },
          };

          Customer.updateMe(payload, options, function (err, result) {
            if (err) console.log(err.code);

            const addressList = result.data.address.secondaries;
            addressList.unshift(result.data.address.primary);
            setAllAddress(addressList);
          });
        }
      }
      setType("선택");
    }
  }

  function handleDialogButtonClick({ target: { value } }) {
    // 삭제 확인 dialog
    if (value === "삭제") {
      deleteAddress(addressIdx);
    }

    setAddressIdx(null);
    setType("선택");
  }

  function handleInputChange({ target: { id, value } }) {
    // 배송지 입력
    if (id === "company") {
      setNewAddress({ ...newAddress, company: value });
    } else if (id === "address2") {
      setNewAddress({ ...newAddress, address2: value });
    }
  }

  // 주소 목록
  const addressList =
    allAddress &&
    allAddress.map((address, i) => {
      return (
        <AddressItem key={i}>
          <div className="info_container">
            {(address?.company || i === 0) && (
              <div>
                {address?.company && (
                  <ShippingName>{address?.company}</ShippingName>
                )}
                {i === 0 && <DefaultIcon>기본 배송지</DefaultIcon>}
              </div>
            )}
            <Text>
              {address?.address1 +
                ((address?.address2 && " " + address?.address2) || "") +
                " (" +
                address?.postcode +
                ")"}
            </Text>
          </div>
          <div className="button_container">
            <div>
              <Button
                size="SMALL"
                value="수정"
                onClick={(e) => {
                  handleButtonClick(e, address, i);
                }}
              >
                수정
              </Button>
              {i !== 0 && (
                <Button
                  size="SMALL"
                  value="삭제"
                  onClick={(e) => handleButtonClick(e, null, i)}
                >
                  삭제
                </Button>
              )}
            </div>
            <Button
              size={selectedIndex === i ? "MEDIUM" : "MEDIUM-R"}
              value="선택"
              onClick={(e) => handleButtonClick(e, address, i)}
            >
              선택
            </Button>
          </div>
        </AddressItem>
      );
    });

  return (
    <>
      <ModalContainer isOpen={open} onClick={handleOverlayClick}>
        {type === "삭제" ? (
          <Dialog open width={width}>
            <HeadingText isDialog>배송지를 삭제하겠습니까?</HeadingText>
            <div>
              <Button
                size="LARGE-R"
                value="취소"
                onClick={(e) => handleDialogButtonClick(e)}
              >
                취소
              </Button>
              <Button
                size="LARGE"
                value="삭제"
                onClick={(e) => handleDialogButtonClick(e)}
              >
                삭제
              </Button>
            </div>
          </Dialog>
        ) : (
          <BottomSheet>
            <ModalHeader>
              <BackButton onClick={() => handleBackButtonClick(type)} />
              <HeadingText>배송지 {type}</HeadingText>
            </ModalHeader>
            <Section type={type}>
              {type === "선택" ? (
                <>
                  <AddButton onClick={handleAddButtonClick}>
                    <div>
                      <img
                        src={require("../../assets/icons/plus-blue.svg")}
                        alt=""
                        width="16px"
                        height="16px"
                        style={{ marginRight: "8px" }}
                      />
                      <span>새 배송지 추가</span>
                    </div>
                  </AddButton>
                  <AddressList>{addressList}</AddressList>
                </>
              ) : (
                <>
                  <InputContainer>
                    <Label htmlFor="company">배송지명</Label>
                    <Input
                      id="company"
                      value={newAddress.company || ""}
                      onChange={handleInputChange}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="postcode">
                      우편번호<span>*</span>
                    </Label>
                    <Input
                      id="postcode"
                      value={newAddress.postcode || ""}
                      readOnly
                      onClick={loadPostcode}
                    />
                    <TextButton height={38} onClick={loadPostcode}>
                      우편번호 찾기
                    </TextButton>
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="address1" onClick={loadPostcode}>
                      주소<span>*</span>
                    </Label>
                    <Input
                      id="address1"
                      value={newAddress.address1 || ""}
                      readOnly
                      onClick={loadPostcode}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="address2">
                      상세주소<span>*</span>
                    </Label>
                    <Input
                      id="address2"
                      value={newAddress.address2 || ""}
                      placeholder="상세주소를 입력해주세요"
                      onChange={handleInputChange}
                    />
                  </InputContainer>
                  {type === "수정" && addressIdx === 0 ? (
                    <DefaultIcon mt={20}>기본 배송지</DefaultIcon>
                  ) : (
                    <CheckButton
                      onClick={() => setIsDefaultAddress(!isDefaultAddress)}
                    >
                      <img
                        src={require(`../../assets/icons/check-${
                          (isDefaultAddress && "clicked.svg") || "default.png"
                        }`)}
                        alt=""
                        width="16px"
                        height="16px"
                        style={{ marginRight: "4px" }}
                      />
                      기본 배송지로 저장
                    </CheckButton>
                  )}
                </>
              )}
            </Section>
            <BottomButton
              value={type}
              disabled={!isActive}
              onClick={handleBottomButtonClick}
            >
              {type === "선택" ? "확인" : "저장"}
            </BottomButton>
          </BottomSheet>
        )}
      </ModalContainer>
      {showSnackBar && (
        <SnackBar
          type="ALERT"
          text="추가 주소는 최대 10개까지 생성할 수 있습니다."
          bottom={56}
          show={showSnackBar}
        />
      )}
    </>
  );
};
