import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Axios from "axios";
import HelpIcon from "../../assets/icons/help-filled.svg";
import { API_ENDPOINT, IMAGE_SERVER_LOCATION } from "../../Config";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Header } from "../../components/Common/Header";
import { SlideReviewList } from "../../components/List/SlideReviewList";
import {formatPrice, getRandomData, getTranslateType} from "../../modules/util";
import { Label } from "../../components/Common/Label";
import { TypeDesc } from "../../assets/data/jsons/CurationData";
import { ReviewContentCard } from "../../components/Card/ReviewContentCard";
import { TitleCard } from "../../components/Card/TitleCard";
import Spinner from "react-bootstrap/Spinner";

const PageContainer = styled.main`
  padding-top: 40px;
  width: 100%;
  max-width: 500px;
  background-color: var(--color-white);
`;

const Section = styled.section`
  padding: ${({ padding }) => padding};
  border-bottom: ${({ border }) => border + " solid #EBEBEB"};
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 16.28px;
  cursor: pointer;
`;

const DetailType = styled.div`
  margin-top: 8px;
  padding: 20px;
  background-color: #f1f4f8;
  color: var(--color-poola-blue);
  line-height: 22px;
  font-size: 13px;

  p {
    display: inline;
    margin: 0;
  }
`;

const ReviewRating = styled.div`
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 700;
    color: var(--color-poola-blue);
  }
`;

const DetailButton = styled.button`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-black);
  background-color: var(--color-white);
  border: 1px solid var(--color-border-gray);
  border-radius: 4px;

  img {
    margin-left: 8px;
  }
`;

const Caption = styled.span`
  display: inline-block;
  padding-right: 4px;
  font-size: 12px;
  line-height: 15.02px;
  color: var(--color-${(props) => props.color});
`;

export const ReviewDetailPage = ({ history }) => {
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  const { reviewId } = useParams();
  const [data, setData] = useState([]); // 리뷰 상세 정보
  const [img, setImg] = useState([]); // 리뷰 이미지
  const [randomData, setRandomData] = useState(); // 랜덤 데이터
  const [products, setProducts] = useState([]); // 상품 정보
  const [userReviews, setUserReviews] = useState([]); // 유저 리뷰
  const [dataReviews, setDataReviews] = useState([]); // 크롤링 리뷰
  const [spinner, setSpinner] = useState(true); // 로딩
  const [toggle, setToggle] = useState(false);

  let userCup = user?.size?.replace(/[0-9]/g, ""); // 유저 컵 사이즈
  let userBand = user?.size?.replace(/[^0-9]/g, ""); // 유저 밴드 사이즈

  const getData = async () => {
    setSpinner(true);
    try {
      const response1 = await Axios.get(API_ENDPOINT + "/api/reviews");
      const response = response1.data
      const reviewData = response.data.filter(
        (x) => x.id === Number(reviewId)
      )[0];
      if (reviewData?.productId !== "" || reviewData?.productId !== null) {
        reviewData.type = getTranslateType(reviewData.type)
        const rawCup = userCup === "AA" || userCup === "AAA" ? "A" : userCup;
        const rawBand = userBand < 65 ? 65 : userBand > 100 ? 100 : userBand;

        try {
          const res = await Axios.get(
            API_ENDPOINT +
              `/v1/api/products/${reviewData?.productId}?userEmail=${user.email}&band=${rawBand}&cup=${rawCup}`
          );
          setSpinner(false);
          setProducts(res.data.productDetail);
          setUserReviews(res.data.reviews);

          try {
            const reviewRes = await Axios.get(
              API_ENDPOINT +
                `/api/dummy-reviews/products/${res.data.productDetail?.No}`
            );
            setDataReviews(reviewRes.data.reviews);
          } catch (e) {
            console.log(e);
          }
        } catch (e) {
          console.log(e);
        }
      }
      const size = reviewData?.size;
      setData(response.data.filter((x) => x.id === Number(reviewId)));
      setRandomData(
        getRandomData(
          response.data
            .filter((x) => x.size === size)
            .filter((x) => x.id !== Number(reviewId))
        ).filter((x) => x !== undefined)
      );
      if (reviewData?.thumb !== "" && reviewData?.thumb !== null) {
        setImg([reviewData?.thumb]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const productThumb = () => {
    if (products?.No !== undefined) {
      return products?.isMP
        ? products?.thumb
        : IMAGE_SERVER_LOCATION + "s600/" + products?.No + ".png?h=500";
    } else {
      return null;
    }
  };

  const reviewData = () => {
    let sum = 0;
    let count = 0;
    let userRateSum = 0;
    let dataRateSum = 0;

    if (userReviews && userReviews.length !== 0) {
      userReviews.forEach((data) => {
        count += 1;
        userRateSum += data.rate;
      });
    }
    if (dataReviews && dataReviews.length !== 0) {
      dataReviews.forEach((data) => {
        count += 1;
        sum += data.rate;
        dataRateSum += data.rate;
      });
    }

    return Math.round(((userRateSum + dataRateSum) / count) * 10) / 10; // 소수점 아래 한자리
  };

  useEffect(() => {
    getData();
    setImg([]);
    setToggle(false);
  }, [reviewId]);

  const typeDesc =
    data[0]?.type &&
    ["FULLNESS", "PROJECTION", "CLOSENESS"].reduce(
      (acc, cur, i) =>
        acc +
        TypeDesc.find((desc) => desc.category === cur).data.find(
          (d) => d.type === data[0]?.type[i]
        )?.desc,
      ""
    );

  // 상품 콘텐츠
  const Product = () => {
    return (
      <FlexBox
        onClick={() => {
          data[0]?.isPoolaProduct &&
            history.push(`/product/${data[0]?.productId}`);
        }}
      >
        {data[0]?.isPoolaProduct && (
          <img
            src={productThumb()}
            alt="thumb"
            width="85px"
            height="85px"
            style={{ marginRight: "12px" }}
          />
        )}
        <div style={{ display: "inline-block" }}>
          <div
            style={{
              paddingBottom: "8px",
              fontSize: "13px",
              fontWeight: 700,
            }}
          >
            <span style={{ paddingRight: "4px" }}>
              {!data[0]?.isPoolaProduct && data[0]?.brand !== null
                ? data[0]?.brand
                : products?.brand}
            </span>
            <span
              style={{
                fontWeight: 400,
              }}
            >
              {65 &&
              data[0]?.productName !== null &&
              data[0]?.productName !== ""
                ? data[0]?.productName
                : products?.name}
            </span>
          </div>
          {data[0]?.isPoolaProduct && (
            <>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: 700,
                  paddingBottom: "7px",
                }}
              >
                {formatPrice(products?.discount?.price)}원
              </div>
              <div style={{ display: "inline-block", paddingRight: "6px" }}>
                <Label
                  productMatch
                  notRecommend={
                    user.type && products?.recommend < 0.75 ? true : false
                  }
                  userType={!user.type ? false : true}
                  noBorder
                  flag={user.type && products?.recommend < 0.84 ? true : false}
                  text={
                    !user.type
                      ? "나와 잘 맞을까?"
                      : 0.9 <= products?.recommend
                      ? "찰떡궁합"
                      : 0.84 <= products?.recommend && products?.recommend < 90
                      ? "잘 맞아요"
                      : 0.75 <= products?.recommend &&
                        products?.recommend < 0.84
                      ? "보통이에요"
                      : products?.recommend < 0.75
                      ? "잘 안맞아요"
                      : null
                  }
                />
              </div>
              <div
                style={{
                  marginTop: "6px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../../assets/icons/star-filled.svg")}
                  alt=""
                  width="12px"
                  height="12px"
                />
                <Caption color="poola-blue">{reviewData()}</Caption>
                <Caption color="tertiary-black">
                  {`(${userReviews?.length + dataReviews?.length})`}
                </Caption>
              </div>
            </>
          )}
        </div>
      </FlexBox>
    );
  };

  // 사이즈 정보
  const OwnerInfo = () => {
    return (
      <>
        <p
          style={{
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "16px",
            margin: 0,
          }}
        >
          작성자의 가슴 정보
        </p>
        <div
          style={{
            paddingTop: "12px",
            fontSize: "13px",
            fontWeight: 400,
          }}
        >
          <div>가슴 사이즈 : {data[0]?.size}</div>
          {data[0]?.type && data[0]?.type !== "모름" && (
            <div onClick={() => setToggle((prev) => !prev)}>
              <span style={{ paddingRight: "4px" }}>
                가슴 유형 : {data[0]?.type}
              </span>
            </div>
          )}
        </div>
      </>
    );
  };
// Modify the reviews array before mapping
  const updatedReviews = randomData?.map((review) => {
    const translatedType = getTranslateType(review.type);
    // Return a new object with the updated type
    return {
      ...review,
      type: translatedType,
    };
  });
  // 리뷰 콘텐츠
  const Review = updatedReviews?.map((review) => {
    return (
      <ReviewContentCard key={review?.id} data={review} history={history} />
    );
  });

  return (
    <>
      {spinner ? (
        <div
          style={{
            height: "calc(100vh - 153px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner
            animation="border"
            style={{
              color: "var(--color-poola-blue)",
              borderRightColor: "transparent",
            }}
          />
        </div>
      ) : (
        <PageContainer>
          <Header type={"BACK-HOME-REVIEW"} history={history} />
          <SlideReviewList params={reviewId} img={img} />
          {data[0]?.isPoolaProduct || data[0]?.brand !== null ? (
            <Section padding="16px" border="1px">
              {Product()}
            </Section>
          ) : null}
          <Section padding="20px 16px 4px 16px">{OwnerInfo()}</Section>
          {toggle && (
            <DetailType>
              <span>
                <strong> {data[0]?.type} 유형</strong>은&nbsp;
              </span>
              <p dangerouslySetInnerHTML={{ __html: typeDesc }} />
            </DetailType>
          )}
          <Section padding="20px 16px 24px" border="8px">
            <ReviewRating>
              {[...Array(5)].map((r, i) => {
                if (i > data[0]?.rating && i !== 4)
                  return (
                    <img
                      src={require("../../assets/icons/star-default.svg")}
                      alt=""
                      style={{ marginRight: "2px" }}
                      key={i}
                    />
                  );
                else if (i > data[0]?.rating && i === 4)
                  return (
                    <img
                      src={require("../../assets/icons/star-default.svg")}
                      alt=""
                      key={i}
                    />
                  );
                else if (i < data[0]?.rating && i !== 4)
                  return (
                    <img
                      src={require("../../assets/icons/star-filled.svg")}
                      alt=""
                      style={{ marginRight: "2px" }}
                      key={i}
                    />
                  );
                else if (i < data[0]?.rating && i === 4)
                  return (
                    <img
                      src={require("../../assets/icons/star-filled.svg")}
                      alt=""
                      key={i}
                    />
                  );
                else if (i === data[0]?.rating && i !== 4) {
                  return (
                    <img
                      src={require("../../assets/icons/star-default.svg")}
                      alt=""
                      style={{ marginRight: "2px" }}
                      key={i}
                    />
                  );
                } else {
                  return (
                    <img
                      src={require("../../assets/icons/star-default.svg")}
                      alt=""
                      key={i}
                    />
                  );
                }
              })}
              <span>{data[0]?.rating}</span>
            </ReviewRating>
            <div
              style={{
                fontSize: "13px",
                lineHeight: "21px",
                paddingTop: "40px",
              }}
            >
              {data[0]?.text}
            </div>
          </Section>
          <Section padding="20px 0 24px">
            {randomData?.length !== 0 && (
              <>
                <TitleCard text="비슷한 체형의 리뷰를 살펴보세요" />
                {Review}
              </>
            )}
            <div style={{ padding: "10px 16px 0 16px" }}>
              <DetailButton onClick={() => history.push("/reviewList")}>
                더보기
                <img
                  src={require("../../assets/icons/arrow/right-tertiary-black.svg")}
                  alt="이동"
                  width="20px"
                  height="20px"
                />
              </DetailButton>
            </div>
          </Section>
        </PageContainer>
      )}
    </>
  );
};
