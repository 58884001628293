import React from "react";
import styled from "styled-components";

import downBlack from "../../assets/icons/arrow/down-black.svg";
import upBlack from "../../assets/icons/arrow/up-black.svg";

export const Selectbox = ({
  label,
  select,
  toggle,
  _onClick,
  options,
  _onClickOptions,
}) => {
  return (
    <Container>
      <Select select={select} toggle={toggle} onClick={_onClick}>
        {select === "" ? label : select}
      </Select>
      {toggle && (
        <OptionList>
          {options.map((l, idx) => (
            <li key={idx} onClick={() => _onClickOptions(l.label)}>
              {l.label}
            </li>
          ))}
        </OptionList>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Select = styled.div`
  width: 100%;
  height: 44px;
  padding: 10px 12px;
  position: relative;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ select }) => (select !== "" ? "black" : "#999999")};
  background: ${({ toggle }) =>
      toggle ? `url(${upBlack})` : `url(${downBlack})`}
    no-repeat 96% 50%/20px 20px !important;
  border: 1px solid
    var(--color-${({ toggle }) => (toggle ? "black" : "border-gray")});
`;

const OptionList = styled.ul`
  width: 100%;
  background-color: white;
  position: absolute;
  z-index: 1;
  padding: 0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.11);
  cursor: pointer;
  font-size: 14px;

  li {
    width: 100%;
    height: 44px;
    padding: 10px 12px;
    list-style: none;
    &:hover {
      background-color: #ebeff6;
    }
  }
`;
