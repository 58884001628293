import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { clickTrigger } from "../../modules/trigger";
import border from "../../assets/icons/round-border-blue.svg";

const FONTSIZE = {
  // font-size, line-height, font-weight
  HORIZONTAL: ["16px", "24px", "bold"],
  VERTICAL: ["13px", "19px", "normal"],
};

const Card = styled(Link)`
  ${(props) =>
    props.align === "VERTICAL"
      ? css`
          display: inline-block;
          text-align: center;
          white-space: pre;
        `
      : css`
          padding: 12px 0 12px 16px;
          display: flex;
          align-items: center;
        `}

  ${(props) =>
    props.as === "button" &&
    css`
      padding: 0;
      background-color: var(--color-white);
      border: none;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
    `}
`;

const Image = styled.img`
  border-radius: 50%;
  object-fit: cover;

  ${(props) =>
    props.align === "VERTICAL" &&
    props.cardType === "button" &&
    css`
      width: 66px;
      height: 66px;
      padding: 3px;
      ${props.selected &&
      css`
        background: url(${border}) no-repeat center / cover;
      `}
    `}
`;

const TextWrapper = styled.div`
  margin: ${(props) =>
    props.align === "HORIZONTAL" ? "0 0 0 16px" : "8px 0 0 0"};
  font-size: 13px;
  line-height: 19px;
  color: var(--color-secondary-black);

  ${(props) =>
    props.align === "VERTICAL" &&
    props.cardType === "button" &&
    css`
      margin-top: 5px;
      text-align: center;
    `}
`;

export const BrandCard = ({
  data,
  type,
  align,
  margin,
  selected,
  _onClick,
}) => {
  const hashTags = data.keywords
    ? data.keywords.map((k) => {
        return "#" + k + " ";
      })
    : null;

  return (
    <Card
      as={type}
      to={`/brand/${data._id}`}
      align={align}
      margin={margin}
      onClick={() =>
        _onClick
          ? _onClick()
          : () => {
              sessionStorage.setItem("brandInfo", JSON.stringify(data));
              clickTrigger(`브랜드${type === "button" && "필터"}`, {
                brandName: data.name,
              });
            }
      }
    >
      <Image
        src={data.thumb}
        alt=""
        width="60px"
        height="60px"
        cardType={type}
        align={align}
        selected={selected}
      />
      <TextWrapper cardType={type} align={align} selected={selected}>
        <strong
          style={{
            fontSize: FONTSIZE[align][0],
            lineHeight: FONTSIZE[align][1],
            fontWeight: FONTSIZE[align][2],
          }}
        >
          {data.name.includes("POOLA") ? "POOLA" : data.name}
        </strong>
        <br />
        {align === "HORIZONTAL" && (
          <span
            style={{
              textOverflow: "ellipsis",
              color: "var(--color-tertiary-black)",
            }}
          >
            {hashTags}
          </span>
        )}
      </TextWrapper>
    </Card>
  );
};

BrandCard.defaultProps = {
  data: {
    img:
      "https://s3.ap-northeast-2.amazonaws.com/poola-photo-2.0/w200/2ndskin_17.png",
    No: "풀라",
    keywords: ["건강한", "블라 블라", "맛있는 치킨"],
  },
  align: "HORIZONTAL",
};
