import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Axios from "axios";
import { API_ENDPOINT } from "../../Config";
import { CouponList } from "../List/CouponList";
import { SnackBar } from "../Common/SnackBar";
import ArrowIcon from "../../assets/icons/arrow/left-blue.svg";

const ModalContainer = styled.div`
  width: 100%;
  max-width: 500px;
  display: ${(props) => (props.isOpen ? "" : "none !important")};
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 10;
  text-align: center;
  background-color: var(--color-bg-gray);
  border: none;
`;

const Header = styled.header`
  width: 100%;
  max-width: 500px;
  height: 40px;
  padding: 0 16px 0 12px;
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
`;

const BackButton = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  background: var(--color-white) url(${ArrowIcon}) no-repeat center;
  background-size: contain;
  border: none;
`;

const HeaderText = styled.h2`
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-poola-blue);
`;

const CouponSection = styled.section`
  width: 100%;
  max-width: 500px;
  padding: 14px 0;
  position: absolute;
  top: 40px;
  right: 0;
  bottom: 56px;
  left: 0;
  overflow-y: scroll;
`;

const NoCouponContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: calc(100vh - 40px);
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  margin: 24px 0 32px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-tertiary-black);
`;

const BottomButton = styled.button`
  width: 100%;
  max-width: 500px;
  height: 56px;
  position: fixed;
  bottom: 0;
  transform: translateX(-50%);
  font-weight: 500;
  font-size: 16px;
  background-color: var(
    --color-${(props) => (props.disabled ? "disabled-gray" : "poola-blue")}
  );
  color: var(
    --color-${(props) => (props.disabled ? "tertiary-black" : "white")}
  );
  border: none;
`;

const CouponFullScreen = ({
  isOpen,
  setClose,
  allCoupons,
  applicableCoupons,
  downloadCoupons,
  coupon,
  setCoupon,
  getCoupons,
  setSelectedReviewCoupon,
}) => {
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  const disabledCoupons = allCoupons.filter(
    (coupon) => !applicableCoupons.includes(coupon)
  ); // 적용 불가능한 쿠폰
  const [selectedCoupon, setSelectedCoupon] = useState(coupon); // 적용할 쿠폰
  const [isShowDownloadSnackBar, setIsShowDownloadSnackBar] = useState(false); // 쿠폰 다운로드 완료 스낵바
  const [
    isShowDisabledCouponSnackBar,
    setIsShowDisabledCouponSnackBar,
  ] = useState(false); // 쿠폰 삭제 완료 스낵바

  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`;

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = 'position: ""; top: "";';
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    };
  }, []);

  const addCoupon = (coupon) => {
    // 쿠폰 추가
    Axios.post(
      API_ENDPOINT + `/api/user/${user.clayful_userId}/coupon/${coupon._id}`
    ).then((response) => {
      if (response.data.success) {
        getCoupons();
        setIsShowDownloadSnackBar(true);
        setTimeout(() => {
          setIsShowDownloadSnackBar(false);
        }, 3000);
      } else {
        if (response.data.err === "duplicated-coupon")
          alert("이미 발급된 쿠폰입니다");
        else if (response.data.err === "exhausted-coupon")
          alert("쿠폰이 모두 소진되었습니다");
      }
    });
  };

  function alertDisabledCoupon() {
    // 사용 불가능 쿠폰 클릭 시
    setIsShowDisabledCouponSnackBar(true);
    setTimeout(() => {
      setIsShowDisabledCouponSnackBar(false);
    }, 3000);
  }

  function selectCoupon(coupon) {
    // 쿠폰 선택
    if (
      selectedCoupon.constructor === Object &&
      Object.keys(selectedCoupon).length >= 0
    ) {
      if (selectedCoupon === coupon) {
        setSelectedCoupon({});
        setSelectedReviewCoupon("");
      } else {
        setSelectedCoupon(coupon);
        if (coupon.name.includes("리뷰 Lv")) {
          setSelectedReviewCoupon(coupon.name);
        }
      }
    } else setSelectedCoupon({});
  }

  function handleBottomButtonClick() {
    // 적용
    setCoupon(selectedCoupon);
    setClose(false);
  }

  return (
    <>
      <ModalContainer isOpen={isOpen}>
        <Header>
          <BackButton onClick={() => setClose(false)} />
          <HeaderText>쿠폰함</HeaderText>
        </Header>
        {allCoupons.length > 0 || downloadCoupons.length > 0 ? (
          <>
            <CouponSection>
              <CouponList
                data={applicableCoupons}
                type="SELECT"
                selectedCoupon={selectedCoupon}
                _onClick={selectCoupon}
              />
              <CouponList
                data={disabledCoupons}
                type="DISABLED"
                _onClick={alertDisabledCoupon}
              />
              <CouponList
                data={downloadCoupons}
                type="DOWNLOAD"
                _onClick={addCoupon}
              />
            </CouponSection>
            <BottomButton
              disabled={
                !(
                  (coupon.constructor === Object &&
                    Object.keys(coupon).length > 0) ||
                  (selectedCoupon.constructor === Object &&
                    Object.keys(selectedCoupon).length > 0)
                )
              }
              onClick={handleBottomButtonClick}
            >
              적용
            </BottomButton>
          </>
        ) : (
          <NoCouponContainer>
            <img
              src={require("../../assets/images/common/no-coupon.png")}
              alt="쿠폰 없음"
              width="160px"
              height="80px"
            />
            <Text>사용 가능한 쿠폰이 없습니다</Text>
          </NoCouponContainer>
        )}
      </ModalContainer>
      <SnackBar
        type="ALERT"
        text="이 주문에서 사용할 수 없는 쿠폰입니다"
        bottom={49}
        show={isShowDisabledCouponSnackBar}
      />
      <SnackBar
        type="CHECK"
        text="쿠폰을 받았습니다"
        bottom={49}
        show={isShowDownloadSnackBar}
      />
    </>
  );
};

export default CouponFullScreen;
