import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Header } from "../../components/Common/Header";
import { useSelector } from "react-redux";
import { LoginAlert } from "../../components/Common/LoginAlert";
import Spinner from "react-bootstrap/Spinner";
import Clayful from "clayful/client-js";
import { OrderList } from "../../components/List/OrderList";

const OrderPage = ({ history }) => {
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  const [spinner, setSpinner] = useState(true);
  const [data, setData] = useState([]);

  async function getOrder() {
    const Order = Clayful.Order;
    const options = { customer: sessionStorage.getItem("__tct__") };

    Order.listForMe(options, function (err, result) {
      if (err) console.log(err);
      if (result) {
        setData(result.data);
        setSpinner(false);
      }
    });
  }

  useEffect(() => {
    if (user.email) {
      getOrder();
    }
  }, []);

  return (
    <>
      <Header type="BACK" title="주문 내역" history={history} func="MY" />
      {user.email && !spinner ? (
        <PageContainer>
          {data.map((order, index) => (
            <OrderList data={order} key={index} history={history} />
          ))}
        </PageContainer>
      ) : (
        <>
          <div
            style={{
              height: "calc(100vh - 40px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              animation="border"
              style={{
                color: "var(--color-poola-blue)",
                borderRightColor: "transparent",
              }}
            />
          </div>
          <LoginAlert isLogin={user.email} history={history} />
        </>
      )}
    </>
  );
};

export default OrderPage;

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  padding-top: 40px;
  background-color: var(--color-white);
`;
