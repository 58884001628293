const CupBand = [
  {
    filter: "cup",
    options: [
      // { index: 0, filter: "cup", value: "컵" },
      { index: 1, filter: "cup", value: "A" },
      { index: 2, filter: "cup", value: "B" },
      { index: 3, filter: "cup", value: "C" },
      { index: 4, filter: "cup", value: "D" },
      { index: 5, filter: "cup", value: "E" },
      { index: 6, filter: "cup", value: "F" },
      { index: 7, filter: "cup", value: "G" },
      { index: 8, filter: "cup", value: "H" },
    ],
  },
  {
    filter: "band",
    options: [
      // { index: 0, filter: "band", value: "밴드" },
      { index: 1, filter: "band", value: "65" },
      { index: 2, filter: "band", value: "70" },
      { index: 3, filter: "band", value: "75" },
      { index: 4, filter: "band", value: "80" },
      { index: 5, filter: "band", value: "85" },
      { index: 6, filter: "band", value: "90" },
      { index: 7, filter: "band", value: "95" },
      { index: 8, filter: "band", value: "100" },
    ],
  },
];

const Fit = {
  filter: "fit",
  options: [
    { index: 0, filter: "fit", value: "타이트한" },
    { index: 1, filter: "fit", value: "딱 맞는" },
    { index: 2, filter: "fit", value: "여유있는" },
  ],
};

export {CupBand, Fit}
