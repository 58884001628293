import React, { useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Axios from "axios";
import { API_ENDPOINT } from "../../Config";
import { Header } from "../../components/Common/Header";
import { SnackBar } from "../../components/Common/SnackBar";
import { Helmet } from "react-helmet";
import { clickTrigger } from "../../modules/trigger";
import { GA4EventTracker } from "../../hook/GA4EventTracker";

const COLOR = {
  welcome: ["var(--color-bg-gray)", "var(--color-poola-blue)"],
  "kakao-complete": ["var(--color-bg-gray)", "var(--color-poola-blue)"],
  "kakao-channel": ["var(--color-bg-white)", "var(--color-poola-blue)"],
  "only-kakao-channel": ["var(--color-bg-white)", "var(--color-poola-blue)"],
  forWoman: ["var(--color-bg-white)", "#4C2887"],
  sales: ["var(--color-bg-white)", "var(--color-poola-orange)"],
  wadiz: ["var(--color-bg-white)", "#00CCCB"],
  thanku: ["var(--color-bg-white)", "#65C384"],
  kakao_timecoupon: ["var(--color-bg-white)", "#DA5E66"],
  camfer_coupon: ["var(--color-bg-white)", "#00A3FF"],
  sportsbra: ["var(--color-bg-white)", "#4BCA58"],
  july: ["var(--color-bg-white)", "#1CD8F2"],
  festival: ["var(--color-bg-white)", "#00398F"],
  chuseok: ["var(--color-bg-white)", "#213059"],
};

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  padding: 40px 0 max(56px, calc(env(safe-area-inset-bottom) + 56px));
  background-color: var(--color-white);
`;

const Button = styled.button`
  width: 100%;
  max-width: 500px;
  height: max(56px, calc(env(safe-area-inset-bottom) + 56px));
  padding-bottom: calc(env(safe-area-inset-bottom));
  position: fixed;
  bottom: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-white);
  border: none;
  background-color: ${(props) => props.bgColor};
`;

const EventPage = ({ history }) => {
  let { eventId } = useParams();
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  const [alert, setAlert] = useState(false); // 스낵바
  const [errorText, setErrorText] = useState(""); // 스낵바 메시지

  const handleClick = () => {
    if (user?.email) {
      GA4EventTracker("쿠폰_다운로드", {
        버튼: "쿠폰 다운로드 클릭",
      });
      if (eventId === "welcome") {
        addCoupon(user?.clayful_userId, "WE9NH5WZQGFU");
      } else if (eventId === "kakao-complete") {
        addCoupon(user?.clayful_userId, "4TYM3B8JWU7F");
      } else if (eventId === "kakao-channel") {
        addCoupon(user?.clayful_userId, "KSUDHJN692KV");
      } else if (eventId === "only-kakao-channel") {
        addCoupon(user?.clayful_userId, "T6WABDUJ9F7R");
        addCoupon(user?.clayful_userId, "XYZQVBFGCECF");
        addCoupon(user?.clayful_userId, "HUZXCH6TKN78");
        addCoupon(user?.clayful_userId, "QHURPNTRKLRP");
      } else if (eventId === "forWoman") {
        addCoupon(user?.clayful_userId, "GQB3R3QCUDWD");
        addCoupon(user?.clayful_userId, "BKK6C5SZRH6B");
        addCoupon(user?.clayful_userId, "L2H6NXFBY64R");
        addCoupon(user?.clayful_userId, "Z9Y8ZN3YLGCN");
      } else if (eventId === "sales") {
        addCoupon(user?.clayful_userId, "4TH6SRXCEWTZ");
        addCoupon(user?.clayful_userId, "UVR6TSZWH74U");
        addCoupon(user?.clayful_userId, "L9RRPYGAQDGM");
        addCoupon(user?.clayful_userId, "F5LNEXM5XXRW");
      } else if (eventId === "wadiz") {
        addCoupon(user?.clayful_userId, "QVSPEDQ8BXE8");
      } else if (eventId === "thanku") {
        addCoupon(user?.clayful_userId, "2VH4DSNW4HR7");
      } else if (eventId === "kakao_timecoupon") {
        addCoupon(user?.clayful_userId, "5Z9X2JYYWZYV");
        addCoupon(user?.clayful_userId, "5ZLBNUTDV7VB");
        addCoupon(user?.clayful_userId, "Y4HQJRPZC98G");
        addCoupon(user?.clayful_userId, "XUA2SH3GWB3M");
        addCoupon(user?.clayful_userId, "NZLZ2ANS9UW5");
      } else if (eventId === "camfer_coupon") {
        addCoupon(user?.clayful_userId, "XDTHXHJ9JQUU");
      } else if (eventId === "sportsbra") {
        addCoupon(user?.clayful_userId, "CBLDNFA2JK59");
        addCoupon(user?.clayful_userId, "3Z8WSESCAS4M");
        addCoupon(user?.clayful_userId, "RZ3KT29FEMQ3");
        addCoupon(user?.clayful_userId, "RZK7UKUUSVDT");
        addCoupon(user?.clayful_userId, "EQDFG46RF8N9");
        addCoupon(user?.clayful_userId, "PM7NSURS26VB");
        addCoupon(user?.clayful_userId, "TVE54698FKBC");
      } else if (eventId === "july") {
        addCoupon(user?.clayful_userId, "XK47UQM999S8");
        addCoupon(user?.clayful_userId, "MS534HXHCZJZ");
        addCoupon(user?.clayful_userId, "YJ2N6ZQCN44Z");
        addCoupon(user?.clayful_userId, "9XNC7SC7L8TQ");
      } else if (eventId === "festival") {
        addCoupon(user?.clayful_userId, "3DTSHS5D7XFR");
        addCoupon(user?.clayful_userId, "YJAM7QVAATYZ");
        addCoupon(user?.clayful_userId, "6CB6QL9TNZBZ");
        addCoupon(user?.clayful_userId, "WB7JELSMELS5");
      } else if (eventId === "chuseok") {
        addCoupon(user?.clayful_userId, "YAS5AQPEQV4S");
        addCoupon(user?.clayful_userId, "DJGZEJ27XW5S");
        addCoupon(user?.clayful_userId, "XLZLBRDKWYZS");
        addCoupon(user?.clayful_userId, "7ZYNHSPLGPB7");
        addCoupon(user?.clayful_userId, "JZ986SGY25UV");
        addCoupon(user?.clayful_userId, "6YJFGLBDPT3F");
        addCoupon(user?.clayful_userId, "QNBZ2B28DJAL");
      }
    } else {
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 6000);
    }
  };

  const addCoupon = (userId, couponId) => {
    // 쿠폰 추가
    Axios.post(API_ENDPOINT + `/api/user/${userId}/coupon/${couponId}`).then(
      (response) => {
        if (response.data.success) {
        } else {
          if (response.data.err === "duplicated-coupon")
            setErrorText("이미 발급된 쿠폰입니다");
          else if (response.data.err === "exhausted-coupon")
            setErrorText("쿠폰이 모두 소진되었습니다");
        }
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 6000);
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>이벤트 | 풀라</title>
        <meta property="og:title" content="이벤트 | 풀라" />
      </Helmet>
      <Header
        type="BACK-HOME"
        title={"이벤트"}
        color={COLOR[eventId][0]}
        history={history}
      />
      <PageContainer>
        <img
          src={require(`../../assets/images/event/${eventId}.png`)}
          alt=""
          width="100%"
          height="100%"
          style={{ marginBottom: "38px" }}
        />
      </PageContainer>
      <Button
        bgColor={COLOR[eventId][1]}
        onClick={() => {
          handleClick();
          clickTrigger("쿠폰받기");
        }}
      >
        {eventId === "milytory-year-end" ? "쿠폰 다운받잔아" : "쿠폰 다운로드"}
      </Button>
      {!user?.email ? (
        <SnackBar
          type="ALERT"
          text="로그인이 필요합니다"
          bottom={56}
          show={alert}
          linkTo="/login"
          linkToText="로그인"
        />
      ) : !errorText ? (
        <SnackBar
          type="CHECK"
          text="쿠폰이 성공적으로 발급되었습니다"
          bottom={56}
          show={alert}
        />
      ) : (
        <SnackBar type="ALERT" text={errorText} bottom={56} show={alert} />
      )}
    </>
  );
};

export default EventPage;
