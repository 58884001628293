import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Axios from "axios";
import Clayful from "clayful/client-js";
import Spinner from "react-bootstrap/Spinner";
import { API_ENDPOINT } from "../../Config";
import { Header } from "../../components/Common/Header";
import { ReviewCard } from "../../components/Card/ReviewCard";
import { OrderCard } from "../../components/Card/OrderCard";
import { ReviewDialog } from "../../components/Modal/ReviewDialog";
import { SnackBar } from "../../components/Common/SnackBar";
import { BottomNavigator } from "../../components/Common/BottomNavigator";
import { LoginAlert } from "../../components/Common/LoginAlert";
import { clickTrigger } from "../../modules/trigger";
import { ReviewCompleteBottomSheet } from "../../components/Modal/ReviewCompleteBottomSheet";
import { getFilteredReviews } from "../../modules/util";

const PageContainer = styled.main`
  width: 100%;
  max-width: 500px;
  padding: 40px 0 max(49px, calc(env(safe-area-inset-bottom) + 49px));
  background-color: var(--color-white);
`;

const Section = styled.section`
  padding: 0 16px;
`;

const TabNavigator = styled.nav`
  width: 100%;
  padding: 20px 0 0;
  white-space: nowrap;
  background-color: var(--color-white);
  @media (hover: none) {
    // mobile
    -ms-overflow-style: none; // IE and Edge
    &::-webkit-scrollbar {
      display: none; // Chrome, Safari, Opera
    }
  }
`;

const TabList = styled.ul`
  margin: 0;
  padding: 0;
`;

const TabItem = styled.li`
  display: inline-block;
  width: 50%;
  padding-bottom: 10px;
  border-bottom: ${(props) =>
    props.selected
      ? "2px solid var(--color-poola-blue)"
      : "2px solid var(--color-border-gray)"};
`;

const TabButton = styled.button`
  height: 22px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: -1px;
  width: 100%;
  margin: 0;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  font-size: 16px;
  line-height: 16px;
  background-color: var(--color-white);
  color: var(
    --color-${(props) => (props.selected ? "poola-blue" : "icon-gray")}
  );
  border: none;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    color: ${(props) =>
      props.selected ? "var(--color-poola-blue)" : "var(--color-icon-gray)"};
  }
`;

const HeadingText = styled.h2`
  margin: 0;
  padding: 20px 0 8px 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-black);
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const OrderListItem = styled.li`
  border-bottom: 1px solid var(--color-disabled-gray);
  &:last-child {
    border-bottom: none;
  }
`;

const ReviewListItem = styled.li`
  border-top: 1px solid var(--color-disabled-gray);
  &:first-child {
    border-top: none;
  }
`;

const ReviewListPage = ({ history }) => {
  const { user } = useSelector(({ user }) => ({ user: user.user })); // 유저 정보
  const [orders, setOrders] = useState([]); // 후기 작성 안한 주문
  const [reviews, setReviews] = useState([]); // 작성된 후기
  const [modalVisible, setModalVisible] = useState(false); // 삭제 모달 클릭 여부
  const [delReview, setDelReview] = useState({}); // 삭제할 리뷰
  const [showDeleteSnackBar, setShowDeleteSnackBar] = useState(false); // 후기 삭제 알림 스낵바
  const [spinner, setSpinner] = useState(true); // 주문, 후기
  const [tabType, setTabType] = useState(0); // 0: 작성가능, 1: 작성완료
  const [reviewCount, setReviewCount] = useState(1); // 필터된 리뷰 개수
  const [level, setLevel] = useState(0);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const [visibleBottomSheet, setVisibleBottomSheet] = useState(false);

  const cancelToken = Axios.CancelToken;
  const source = cancelToken.source();

  useEffect(() => {
    if (user.email) {
      getReviews(source);
    }

    return () => {
      source.cancel("request cancelled");
    };
  }, [user]);

  const getReviews = (source) => {
    Axios.get(API_ENDPOINT + `/api/orders/reviews/${user._id}`, {
      cancelToken: source.token,
    }).then((response) => {
      if (response.data.success) {
        setOrders(response.data.orders);
        setReviews(response.data.reviews);
        setReviewCount(getFilteredReviews(response.data.reviews).length);
        const reviewCount = getFilteredReviews(response.data.reviews).length;
        if (reviewCount < 2) {
          setLevel(1);
        }
        if (reviewCount >= 2 && reviewCount < 5) {
          setLevel(2);
        }
        if (reviewCount >= 5 && reviewCount < 9) {
          setLevel(3);
        }
        if (reviewCount >= 9) {
          setLevel(4);
        }
        if (type === "complete") {
          setVisibleBottomSheet(true);
        }
        setSpinner(false);
      } else {
        console.log("후기 목록을 가져오는 데에 실패했습니다.");
      }
    });
  };

  const handleOverlayClick = () => {
    setModalVisible(false);
  };

  const handleDeleteButtonClick = () => {
    // 리뷰 삭제
    const Image = Clayful.Image;
    const options = { customer: sessionStorage.getItem("__tct__") };
    const delClayfulReview = reviews.filter(
      (review) => review._id === delReview._id
    )[0];
    const cancelToken = Axios.CancelToken;
    const source = cancelToken.source();

    for (let i = 0; i < delClayfulReview.img.length; i++) {
      // 클레이풀 이미지 삭제
      Image.deleteForMe(
        delClayfulReview.img[i].id,
        options,
        function (err, result) {
          if (err) console.log(err.code);
        }
      );
    }

    Axios.delete(API_ENDPOINT + `/api/reviews/${delReview._id}`).then(
      (response) => {
        if (response.data.success) {
          getReviews(source);
          setModalVisible(false);
          setShowDeleteSnackBar(true);
          setTimeout(() => {
            setShowDeleteSnackBar(false);
          }, 4000);
        } else {
          console.log("후기를 삭제하는 데에 실패했습니다.");
        }
      }
    );

    clickTrigger("후기삭제", {
      productInfo: {
        name: delReview.product_name,
        brand: delReview.brand_name,
        option:
          delReview.option_name &&
          delReview.option_name.사이즈 + " / " + delReview.option_name.색상,
      },
    });
  };

  const Orders = orders.map((order, i) => {
    return (
      <OrderListItem type="ORDER" key={i}>
        <OrderCard data={order} type="LIST" history={history} />
      </OrderListItem>
    );
  });

  const Reviews = reviews.map((review) => {
    return (
      <ReviewListItem type="REVIEW" key={review._id}>
        <ReviewCard
          data={review}
          type="MY"
          setDelReview={setDelReview}
          setModalVisible={setModalVisible}
          history={history}
        />
      </ReviewListItem>
    );
  });

  function handleClickTab(tabIndex) {
    setTabType(tabIndex);
  }

  function handleBottomSheetClose() {
    setVisibleBottomSheet(false);
  }

  return (
    <>
      <Header type="BACK" title="후기 목록" history={history} func="MY" />
      <PageContainer>
        <TabNavigator>
          <TabList>
            <TabItem selected={tabType === 0}>
              <TabButton
                onClick={() => handleClickTab(0)}
                selected={tabType === 0}
              >
                작성 가능 {orders.length || 0}
              </TabButton>
            </TabItem>
            <TabItem selected={tabType === 1}>
              <TabButton
                onClick={() => handleClickTab(1)}
                selected={tabType === 1}
              >
                작성 완료 {reviews.length || 0}
              </TabButton>
            </TabItem>
          </TabList>
        </TabNavigator>
        {spinner ? (
          <div
            style={{
              height: "calc(100vh - 89px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              animation="border"
              style={{
                color: "var(--color-poola-blue)",
                borderRightColor: "transparent",
              }}
            />
          </div>
        ) : (
          <>
            {tabType === 0 ? (
              <Section>
                <List>{Orders}</List>
              </Section>
            ) : (
              <Section>
                <List>{Reviews}</List>
              </Section>
            )}
          </>
        )}
      </PageContainer>
      <BottomNavigator />
      {modalVisible && (
        <ReviewDialog
          text="삭제"
          visible={modalVisible}
          _onClose={handleOverlayClick}
          _onClick={handleDeleteButtonClick}
        />
      )}
      <SnackBar
        type="CHECK"
        text="후기가 삭제되었습니다."
        bottom={49}
        show={showDeleteSnackBar}
      />
      <LoginAlert isLogin={user.email} history={history} />
      {visibleBottomSheet && (
        <ReviewCompleteBottomSheet
          level={level}
          reviewCount={reviewCount}
          _onClose={handleBottomSheetClose}
        />
      )}
    </>
  );
};

export default ReviewListPage;
